import { Modal, ModalContent } from '@src/components/atoms/Modal'
import { Text } from '@src/components/atoms/Text'
import { SelectOptionOrGroupType, SelectOptionType, SelectValueBaseType } from '@src/logic/data/manipulation'
import { CommonStyles, Theme } from '@src/logic/design'
import { getMouseHandlers, ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

const Styles = StyleSheet.create({
  groupText: {
    marginBottom: 8,
  },
  groupView: {
    paddingTop: 20,
  },
  optionPressable: {
    alignItems: 'center',
    borderRadius: Theme.geometry.control.radius,
    flexDirection: 'row',
    height: Theme.geometry.control.height,
    paddingLeft: 8,
    paddingRight: 8,
  },
  optionPressableActive: {
    backgroundColor: Theme.colors.lightDim.borderColor,
  },
  optionPressableHighlight: {
    backgroundColor: Theme.colors.lightDim.backgroundColor,
  },
})

export type SelectModalProps<T extends SelectValueBaseType> = {
  isOpen: boolean
  onChange: (option: SelectOptionType<T>) => void
  onClose: () => void
  optionsOrGroups: Array<SelectOptionOrGroupType<T>>
  selectedOption: SelectOptionType<T>
}

export function SelectModal<T extends SelectValueBaseType>({
  isOpen,
  onChange,
  onClose,
  optionsOrGroups,
  selectedOption,
}: SelectModalProps<T>) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} variant='select'>
      <ModalContent style={CommonStyles.paddingBottomCard}>
        {optionsOrGroups.map((oog, i) =>
          'options' in oog ? (
            <View key={`group-${i}`} style={ifTrue(i > 0, Styles.groupView)}>
              <Text colorVariant='lightDim' style={Styles.groupText} textVariant='eyebrow'>
                {oog.label}
              </Text>
              {oog.options.map((o) => (
                <SelectModalOption
                  isActive={o.stringValue === selectedOption.stringValue}
                  key={o.stringValue}
                  onPress={() => onChange(o)}
                  option={o}
                />
              ))}
            </View>
          ) : (
            <SelectModalOption
              isActive={oog.stringValue === selectedOption.stringValue}
              key={oog.stringValue}
              onPress={() => onChange(oog)}
              option={oog}
            />
          ),
        )}
      </ModalContent>
    </Modal>
  )
}

type SelectModalOptionProps<T extends SelectValueBaseType> = {
  isActive: boolean
  onPress: () => void
  option: SelectOptionType<T>
}

function SelectModalOption<T extends SelectValueBaseType>({ isActive, onPress, option }: SelectModalOptionProps<T>) {
  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  const pressableStyle = useMemo(
    () => [
      Styles.optionPressable,
      ifTrue(isHover || isPressed, Styles.optionPressableHighlight),
      ifTrue(isActive, Styles.optionPressableActive),
    ],
    [isActive, isHover, isPressed],
  )

  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handlePressIn = useCallback(() => setIsPressed(true), [setIsPressed])
  const handlePressOut = useCallback(() => setIsPressed(false), [setIsPressed])

  return (
    <Pressable
      {...handleMouse}
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      style={pressableStyle}>
      <Text colorVariant='light' textVariant='body'>
        {option.label}
      </Text>
    </Pressable>
  )
}
