import 'fast-text-encoding'
import Joi, { StringSchema } from 'joi'

export function stringSchemaToValidator(schema: StringSchema | undefined, isRequired: boolean, isEmail: boolean) {
  let alwaysSchema = schema ? schema : Joi.string()

  if (!isRequired) {
    alwaysSchema = alwaysSchema.allow('')
  } else {
    alwaysSchema = alwaysSchema.required()
  }

  if (isEmail) {
    alwaysSchema = alwaysSchema.email({ tlds: { allow: false } })
  }

  alwaysSchema = alwaysSchema.messages({
    'any.required': 'Please provide a value.',
    'string.empty': 'Please provide a value.',
    'string.min': 'Please provide at least {#limit} characters.',
    'string.max': 'Please provide at least {#limit} characters.',
    'string.email': 'Please provide a valid email.',
    'string.pattern.name': 'Please provide a valid number.',
  })

  return (value: string) => alwaysSchema.validate(value).error?.message
}
