import { IconButton } from '@src/components/atoms/IconButton'
import { LogoBox } from '@src/components/atoms/LogoBox'
import { Theme } from '@src/logic/design'
import React, { memo, ReactNode, useMemo } from 'react'
import { ScrollView, StyleSheet, View, ViewStyle } from 'react-native'
import { XIcon } from 'react-native-heroicons/solid'

export type MobileNavProps = {
  children?: ReactNode
  footerChildren?: ReactNode
  onClose: () => void
  style?: ViewStyle
}

export const MobileNav = memo(MobileNavComponent)

const Styles = StyleSheet.create({
  view: {
    alignItems: 'stretch',
    backgroundColor: Theme.colors.dark.backgroundColor,
    flex: 1,
    paddingBottom: Theme.geometry.structure.spacing,
    paddingTop: Theme.geometry.structure.spacing,
  },
  topView: {
    alignItems: 'stretch',
    flex: 1,
    flexDirection: 'row',
  },
  leftView: {
    paddingLeft: Theme.geometry.structure.spacing,
    paddingTop: 3,
    width: 64,
  },
  rightView: {
    width: 260,
  },
  logoBox: {
    paddingBottom: Theme.geometry.structure.spacing * 2,
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
  },
})

function MobileNavComponent({ children, footerChildren, onClose, style }: MobileNavProps) {
  const viewStyle = useMemo(() => [Styles.view, style], [style])

  return (
    <View style={viewStyle}>
      <View style={Styles.topView}>
        <View style={Styles.leftView}>
          <IconButton colorVariant='dark' Icon={XIcon} onPress={onClose} />
        </View>
        <ScrollView bounces={false}>
          <View style={Styles.rightView}>
            <LogoBox style={Styles.logoBox} />
            {children}
          </View>
        </ScrollView>
      </View>
      {footerChildren && <View>{footerChildren}</View>}
    </View>
  )
}
