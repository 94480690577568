import { FullScreen } from '@src/components/atoms'
import { SignIn } from '@src/components/parts'
import {
  AnonIssueEmailTokenDocument,
  AnonIssueEmailTokenMutation,
  AnonIssueEmailTokenMutationVariables,
} from '@src/gen/graphql/bindings'
import { useConfig } from '@src/logic/config'
import { UnauthorizedError } from '@src/logic/errors/errors'
import { useErrors } from '@src/logic/errors/ErrorsProvider'
import { isNotWeb } from '@src/logic/utils'
import * as Linking from 'expo-linking'
import { EventType } from 'expo-linking'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Text } from 'react-native'

export type ErrorFallbackProps = {
  error: Error
  resetError: () => void
}

export function ErrorFallback({ error, resetError }: ErrorFallbackProps) {
  const { setRecoveryUrl } = useErrors()

  if (isNotWeb()) {
    useEffect(() => {
      const urlCallback = async (e: EventType) => {
        setRecoveryUrl(e.url)
        resetError()
      }

      Linking.addEventListener('url', urlCallback)
      return () => Linking.removeEventListener('url', urlCallback)
    }, [setRecoveryUrl, resetError])
  }

  if (error instanceof UnauthorizedError) {
    return <UnauthorizedErrorFallback />
  }

  return (
    <FullScreen>
      <Text>Unauthorized {JSON.stringify(error)}</Text>
    </FullScreen>
  )
}

export function UnauthorizedErrorFallback() {
  const config = useConfig()
  const client = useMemo(() => new GraphQLClient(config.hasuraUrl), [config.hasuraUrl])

  const issueEmailToken = useCallback(
    async (email: string) => {
      await client.request<AnonIssueEmailTokenMutation, AnonIssueEmailTokenMutationVariables>(
        AnonIssueEmailTokenDocument,
        {
          email,
        },
      )
    },
    [client],
  )

  return <SignIn issueEmailToken={issueEmailToken} />
}
