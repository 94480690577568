import { FlowFieldEditActionParams } from '@src/components/actions'
import { Item, Pane, PaneButton, PaneHeaderProps, PaneStyles } from '@src/components/atoms'
import { Flow_Field_Visibilities_Enum, Flow_Fields } from '@src/gen/graphql/bindings'
import { ArrayElement, ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { GlobeAltIcon } from 'react-native-heroicons/outline'

export type FlowPaneFieldsProps = {
  dispatchFlowFieldEditAction?: (params: FlowFieldEditActionParams) => void
  flow: {
    flow_fields: Array<
      Pick<
        Flow_Fields,
        'id' | 'flow_id' | 'name' | 'visibility' | 'type' | 'value_text' | 'value_double_precision' | 'updated_at'
      >
    >
  }
  showActivity?: boolean
}

export function FlowPaneFields({ dispatchFlowFieldEditAction, flow, showActivity }: FlowPaneFieldsProps) {
  const header = useMemo<PaneHeaderProps>(
    () => ({
      label: 'Fields',
      showActivity,
    }),
    [showActivity],
  )

  return (
    <Pane header={header}>
      {flow.flow_fields.map((ff) => (
        <FlowPaneFieldsItem key={ff.id} dispatchFlowFieldEditAction={dispatchFlowFieldEditAction} flowField={ff} />
      ))}
    </Pane>
  )
}

type FlowPaneFieldsItemProps = {
  dispatchFlowFieldEditAction?: (params: FlowFieldEditActionParams) => void
  flowField: ArrayElement<FlowPaneFieldsProps['flow']['flow_fields']>
}

function FlowPaneFieldsItem({ dispatchFlowFieldEditAction, flowField }: FlowPaneFieldsItemProps) {
  const handlePress = useCallback(() => {
    if (dispatchFlowFieldEditAction) {
      dispatchFlowFieldEditAction({ flowField })
    }
  }, [dispatchFlowFieldEditAction, flowField])

  if (dispatchFlowFieldEditAction) {
    return (
      <PaneButton isActive={false} onPress={handlePress}>
        <Item
          Icon={ifTrue(flowField.visibility === Flow_Field_Visibilities_Enum.InternalAndExternal, GlobeAltIcon)}
          body={flowField.value_text ?? flowField.value_double_precision}
          colorVariant='dark'
          label={flowField.name}
          numberOfLines={1}
        />
      </PaneButton>
    )
  }

  return (
    <Item
      Icon={ifTrue(flowField.visibility === Flow_Field_Visibilities_Enum.InternalAndExternal, GlobeAltIcon)}
      body={flowField.value_text ?? flowField.value_double_precision}
      colorVariant='dark'
      label={flowField.name}
      numberOfLines={1}
      style={PaneStyles.item}
    />
  )
}
