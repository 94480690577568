import { useRegisterContactCreateAction } from '@src/components/actions'
import { OrgContactsQuery, useOrgContactsQuery } from '@src/gen/graphql/bindings'
import { useOrg } from '@src/logic/data/providers/index'
import { ifTrue, useRequiredContext } from '@src/logic/utils'
import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState } from 'react'

const CONTACTS_PAGE_SIZE = 25

export type OrgContactsContextType = {
  contacts: OrgContactsQuery['contacts']
  fetchMoreContacts: () => Promise<void>
  nameQuery: string
  setNameQuery: Dispatch<SetStateAction<string>>
}

export const OrgContactsContext = createContext<OrgContactsContextType | undefined>(undefined)
export const useOrgContacts = () => useRequiredContext(OrgContactsContext)

export type OrgContactsProviderProps = {
  children?: ReactNode
}

export function OrgContactsProvider({ children }: OrgContactsProviderProps) {
  const [nameQuery, setNameQuery] = useState('')
  const { org } = useOrg()

  const { data, error, fetchMore, loading, previousData } = useOrgContactsQuery({
    variables: {
      orgId: org.id,
      ...ifTrue(!!nameQuery, { nameQuery }),
      offset: 0,
      limit: CONTACTS_PAGE_SIZE,
    },
  })

  let isFetchingMore = false

  const fetchMoreContacts = useCallback(async () => {
    if (data !== undefined && !isFetchingMore) {
      try {
        isFetchingMore = true
        await fetchMore({
          variables: {
            offset: data.contacts.length,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return {
              contacts: [...previousResult.contacts, ...(fetchMoreResult?.contacts || [])],
            }
          },
        })
      } finally {
        isFetchingMore = false
      }
    }
  }, [data, fetchMore, isFetchingMore])

  if (error) {
    throw error
  }

  if (loading && data === undefined && previousData === undefined) {
    return null
  }

  const finalData = data ?? previousData
  if (finalData === undefined) {
    throw new Error('No data.')
  }

  return (
    <OrgContactsProviderInner
      contacts={finalData.contacts}
      fetchMoreContacts={fetchMoreContacts}
      nameQuery={nameQuery}
      setNameQuery={setNameQuery}>
      {children}
    </OrgContactsProviderInner>
  )
}

type OrgContactsProviderInnerProps = OrgContactsContextType & {
  children?: ReactNode
}

function OrgContactsProviderInner({
  contacts,
  fetchMoreContacts,
  nameQuery,
  setNameQuery,
  children,
}: OrgContactsProviderInnerProps) {
  useRegisterContactCreateAction()

  const context = useMemo<OrgContactsContextType>(
    () => ({
      contacts,
      fetchMoreContacts,
      nameQuery,
      setNameQuery,
    }),
    [contacts, fetchMoreContacts, nameQuery, setNameQuery],
  )

  return <OrgContactsContext.Provider value={context}>{children}</OrgContactsContext.Provider>
}
