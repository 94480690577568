import { Card, Item, Text } from '@src/components/atoms'
import { Action_Items, Maybe, Users } from '@src/gen/graphql/bindings'
import {
  getActionItemBadge,
  getActionItemParticipantsSummary,
  getActionItemTypeLabel,
} from '@src/logic/data/manipulation'
import { CommonStyles } from '@src/logic/design'
import { formatTimeSince } from '@src/logic/utils'
import React, { memo, useMemo } from 'react'
import { ViewStyle } from 'react-native'

export type ActionItemCardProps = {
  actionItem: Pick<
    Action_Items,
    'completed_at' | 'created_at' | 'id' | 'input' | 'prompt_cta' | 'question_answer' | 'type'
  > & {
    assignee: Pick<Users, 'id' | 'first_name' | 'last_name'>
    creator: Pick<Users, 'id' | 'first_name' | 'last_name'>
    completer?: Maybe<Pick<Users, 'id' | 'first_name' | 'last_name'>>
    upload_attachments: Array<{
      id: string
      content_length: any
      content_type: string
      image_width?: number
      image_height?: number
      url?: Maybe<string>
    }>
  }
  onPress: () => void
  showActivity?: boolean
  style?: ViewStyle
  user?: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

export const ActionItemCard = memo(ActionItemCardComponent)

function ActionItemCardComponent({ actionItem, onPress, showActivity, style, user }: ActionItemCardProps) {
  const avatar = useMemo(
    () => ({
      user: actionItem.completer ? actionItem.completer : actionItem.creator,
      sizeVariant: 'sm',
    }),
    [actionItem],
  )

  const badge = useMemo(() => getActionItemBadge('solid', actionItem, user), [actionItem])

  return (
    <Card colorVariant={badge.colorVariant} onPress={onPress} style={style}>
      <Item
        avatar={avatar}
        badge={badge}
        colorVariant='light'
        body={getActionItemParticipantsSummary(actionItem, user)}
        label={`${getActionItemTypeLabel(actionItem.type)} · ${formatTimeSince(actionItem.created_at)}`}
        labelPosition='bottom'
        numberOfLines={1}
        showActivity={showActivity}
        style={CommonStyles.marginBottomCard}
      />
      <Text colorVariant='light' numberOfLines={2} style={CommonStyles.marginBottomCard} textVariant='body'>
        {actionItem.input}
      </Text>
    </Card>
  )
}
