import jwtDecode from 'jwt-decode'

export type HasuraClaims = {
  allowedRoles: string[]
  defaultRole: string
  userId?: string
  unverifiedUserId?: string
  flowId?: string
}

const HASURA_CLAIMS_KEY = 'https://hasura.io/jwt/claims'
const HASURA_CLAIMS_ALLOWED_ROLES_KEY = 'x-hasura-allowed-roles'
const HASURA_CLAIMS_DEFAULT_ROLE_KEY = 'x-hasura-default-role'
const HASURA_CLAIMS_USER_ID_KEY = 'x-hasura-user-id'
const HASURA_CLAIMS_UNVERIFIED_USER_ID_KEY = 'x-hasura-unverified-user-id'
const HASURA_CLAIMS_FLOW_ID_KEY = 'x-hasura-flow-id'

export function parseAccessToken(accessToken: string): HasuraClaims | undefined {
  let parsedAccessToken

  try {
    parsedAccessToken = jwtDecode(accessToken) as any
  } catch (err) {
    console.error(err)
    return
  }

  const hasuraClaims = parsedAccessToken[HASURA_CLAIMS_KEY]
  if (!hasuraClaims) {
    return
  }

  const allowedRoles = hasuraClaims[HASURA_CLAIMS_ALLOWED_ROLES_KEY]
  if (!allowedRoles || !Array.isArray(allowedRoles)) {
    return
  }

  const defaultRole = hasuraClaims[HASURA_CLAIMS_DEFAULT_ROLE_KEY]
  if (!defaultRole) {
    return
  }

  return {
    allowedRoles: allowedRoles,
    defaultRole: defaultRole,
    userId: hasuraClaims[HASURA_CLAIMS_USER_ID_KEY],
    unverifiedUserId: hasuraClaims[HASURA_CLAIMS_UNVERIFIED_USER_ID_KEY],
    flowId: hasuraClaims[HASURA_CLAIMS_FLOW_ID_KEY],
  }
}
