import { Organization_Roles_Enum } from '@src/gen/graphql'
import { SelectPreOptionOrGroupType } from '@src/logic/data/manipulation/select'

export function getRoleTypeLabel(actionItemType: Organization_Roles_Enum) {
  switch (actionItemType) {
    case Organization_Roles_Enum.Admin:
      return 'Admin'
    case Organization_Roles_Enum.Editor:
      return 'Editor'
    case Organization_Roles_Enum.None:
      return 'None'
    case Organization_Roles_Enum.Viewer:
      return 'Viewer'
  }
}

export function getOrgRolesOptionsOrGroups(): SelectPreOptionOrGroupType<Organization_Roles_Enum>[] {
  return Object.entries(Organization_Roles_Enum)
    .map(([_, type], i) => ({
      isDefault: i === 0,
      label: getRoleTypeLabel(type),
      value: type,
    }))
    .filter(({ label }) => label !== 'None')
}
