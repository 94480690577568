import {
  initSelect,
  SelectOptionOrGroupType,
  SelectOptionType,
  SelectPreOptionOrGroupType,
} from '@src/logic/data/manipulation/select'
import React, { useMemo, useState } from 'react'

export type FilterType<T extends {}> = {
  label: string
  mobileFiltersValue: Array<{
    id: string
    label: string
  }>
  onChange: (option: SelectOptionType<T>) => void
  onMobileFiltersClear: (id: string) => void
  optionsOrGroups: Array<SelectOptionOrGroupType<T>>
  selectedOption: SelectOptionType<T>
}

export function useFilter<T extends {}>(
  label: string,
  preOptionsOrGroups: SelectPreOptionOrGroupType<T>[],
): FilterType<T> {
  const { defaultOption, optionsOrGroups } = useMemo(
    () => initSelect(JSON.stringify, preOptionsOrGroups),
    [preOptionsOrGroups],
  )
  const [selectedOption, setSelectedOption] = useState(defaultOption)

  return {
    label,
    mobileFiltersValue: !selectedOption.isDefault ? [{ id: label, label }] : [],
    onChange: setSelectedOption,
    onMobileFiltersClear: (id: string) => id === label && setSelectedOption(defaultOption),
    optionsOrGroups,
    selectedOption,
  }
}
