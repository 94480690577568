import { Theme, useScreen } from '@src/logic/design'
import { ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'
import { SvgCircle } from './SvgCircle'

const Styles = StyleSheet.create({
  mainView: {
    backgroundColor: Theme.colors.light.backgroundColor,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    overflow: 'hidden',
  },
})

type CircleBackgroundScreenProps = {
  children?: ReactNode
}

export function CircleBackgroundScreen({ children }: CircleBackgroundScreenProps) {
  const { isPhone, isTablet } = useScreen()

  return (
    <View style={Styles.mainView}>
      <SvgCircle
        fillColor={Theme.colors.primary.backgroundColor}
        circumferenceToHeightPercentage={{
          phone: 0.25,
          tablet: 0.4,
          desktop: 0.56,
        }}
        percentageHiddenXAxis={{
          phone: 0.5,
          tablet: 0.5,
          desktop: 0.5,
        }}
        percentageHiddenYAxis={{
          phone: 0.5,
          tablet: 0.5,
          desktop: 0.5,
        }}
        absolutePositionStyle={{
          top: 0,
          right: 0,
        }}
      />
      <SvgCircle
        fillColor={Theme.colors.primary.backgroundColor}
        circumferenceToHeightPercentage={{
          phone: 0.36,
          tablet: 0.58,
          desktop: 0.67,
        }}
        percentageHiddenXAxis={{
          phone: 0.29,
          tablet: 0.35,
          desktop: 0.34,
        }}
        percentageHiddenYAxis={{
          phone: 0,
          tablet: 0,
          desktop: 0.17,
        }}
        absolutePositionStyle={{
          top: isPhone ? '17%' : isTablet ? '13%' : 0,
          left: 0,
        }}
      />
      <SvgCircle
        fillColor={Theme.colors.primary.backgroundColor}
        circumferenceToHeightPercentage={{
          phone: 0.53,
          tablet: 0.78,
          desktop: 0.99,
        }}
        percentageHiddenXAxis={{
          phone: 0.28,
          tablet: 0.35,
          desktop: 0.1,
        }}
        percentageHiddenYAxis={{
          phone: 0.28,
          tablet: 0.5,
          desktop: 0.5,
        }}
        absolutePositionStyle={{
          bottom: 0,
          right: 0,
        }}
      />
      {children}
    </View>
  )
}
