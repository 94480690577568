import { ActivityIndicator } from '@src/components/atoms/ActivityIndicator'
import { Avatar, AvatarProps } from '@src/components/atoms/Avatar'
import { Badge, BadgeProps } from '@src/components/atoms/Badge'
import { Text } from '@src/components/atoms/Text'
import { dim, Theme } from '@src/logic/design'
import React, { memo, useMemo } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { SvgProps } from 'react-native-svg'

export type ItemProps = {
  avatar?: Omit<AvatarProps, 'sizeVariant' | 'style'>
  badge?: Omit<BadgeProps, 'displayVariant' | 'style'>
  Icon?: (props: SvgProps) => JSX.Element
  body?: string
  colorVariant: 'light' | 'dark'
  label?: string
  labelPosition?: 'top' | 'bottom'
  numberOfLines?: number
  showActivity?: boolean
  style?: ViewStyle
}

export const Item = memo(ItemComponent)

const Styles = StyleSheet.create({
  view: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  avatar: {
    marginRight: 8,
  },
  innerViewDirect: {
    alignItems: 'flex-start',
    flex: 1,
    justifyContent: 'center',
  },
  innerViewReverse: {
    alignItems: 'flex-start',
    flex: 1,
    flexDirection: 'column-reverse',
    justifyContent: 'center',
  },
  spacerViewCompact: {
    height: 2,
  },
  spacerViewSparse: {
    height: 4,
  },
  badge: {
    marginLeft: 8,
  },
  activityIndicator: {
    marginLeft: 8,
  },
})

function ItemComponent({
  avatar,
  badge,
  body,
  colorVariant,
  Icon,
  label,
  labelPosition = 'top',
  numberOfLines,
  showActivity,
  style,
}: ItemProps) {
  const viewStyle = useMemo(() => [Styles.view, style], [colorVariant, style])

  return (
    <View style={viewStyle}>
      {avatar && <Avatar sizeVariant='sm' style={Styles.avatar} {...avatar} />}
      <View style={labelPosition === 'bottom' ? Styles.innerViewDirect : Styles.innerViewReverse}>
        {body && (
          <Text colorVariant={colorVariant} numberOfLines={numberOfLines} textVariant='body'>
            {body}
          </Text>
        )}
        {body && label && <View style={avatar || badge || Icon ? Styles.spacerViewCompact : Styles.spacerViewSparse} />}
        {label && (
          <Text
            colorVariant={colorVariant === 'light' ? 'lightDim' : 'darkDim'}
            numberOfLines={numberOfLines}
            textVariant='body'>
            {label}
          </Text>
        )}
      </View>
      {showActivity && <ActivityIndicator style={Styles.activityIndicator} />}
      {badge && <Badge displayVariant='solid' style={Styles.badge} {...badge} />}
      {Icon && <Icon color={Theme.colors[dim(colorVariant)].color} height={20} width={20} />}
    </View>
  )
}
