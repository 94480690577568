import { useResolvedControl } from '@src/components/atoms/Control'
import { CommonStyles, dim, ForegroundStyles, Theme } from '@src/logic/design'
import { getMouseHandlers, ifTrue } from '@src/logic/utils'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { StyleSheet, TextInput as RNTextInput, TextInputProps as RNTextInputProps, View, ViewStyle } from 'react-native'
import { SvgProps } from 'react-native-svg'

export type TextInputProps = {
  colorVariant: 'light' | 'dark'
  isDisabled?: boolean
  isInvalid?: boolean
  Icon?: (props: SvgProps) => JSX.Element
  multiline?: boolean
  numberOfLines?: number
  onBlur?: () => void
  onChange: (value: string) => void
  onFocus?: () => void
  placeholder?: string
  style?: ViewStyle
  textContentType?: RNTextInputProps['textContentType']
  value: string
}

export const TextInput = memo(TextInputComponent)

const Styles = StyleSheet.create({
  view: {
    alignItems: 'stretch',
    borderRadius: Theme.geometry.control.radius,
    borderWidth: 1,
    flexDirection: 'row',
    height: Theme.geometry.control.height,
    justifyContent: 'flex-start',
  },
  viewLight: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.light.borderColor,
  },
  viewLightDisabled: {
    backgroundColor: Theme.colors.lightDim.backgroundColor,
    borderColor: Theme.colors.lightDim.borderColor,
  },
  viewLightInvalid: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.danger.color,
  },
  viewLightHighlighted: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.light.color,
  },
  viewDark: {
    backgroundColor: Theme.colors.darkDim.backgroundColor,
    borderColor: Theme.colors.dark.borderColor,
  },
  viewDarkDisabled: {
    backgroundColor: Theme.colors.darkDim.backgroundColor,
    borderColor: Theme.colors.darkDim.borderColor,
  },
  viewDarkInvalid: {
    backgroundColor: Theme.colors.darkDim.backgroundColor,
    borderColor: Theme.colors.danger.color,
  },
  viewDarkHighlighted: {
    backgroundColor: Theme.colors.darkDim.backgroundColor,
    borderColor: Theme.colors.dark.color,
  },
  textInputView: {
    alignItems: 'stretch',
    flex: 1,
    justifyContent: 'center',
  },
  textInput: {
    ...Theme.text.body,
    paddingLeft: Theme.geometry.control.spacing,
    paddingRight: Theme.geometry.control.spacing,
  },
  iconView: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: Theme.geometry.control.spacing / 2,
  },
})

function TextInputComponent({
  colorVariant,
  Icon,
  isDisabled,
  isInvalid,
  multiline,
  numberOfLines,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  style,
  textContentType,
  value,
}: TextInputProps) {
  const [isFocused, setIsFocused] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const { finalIsDisabled, finalIsInvalid } = useResolvedControl({ isDisabled, isInvalid })

  const viewStyle = useMemo(
    () => [
      Styles.view,
      ifTrue(finalIsDisabled, CommonStyles.cursorNotAllowed),
      colorVariant === 'light'
        ? [
            ifTrue(finalIsDisabled, Styles.viewLightDisabled),
            ifTrue(!finalIsDisabled && finalIsInvalid, Styles.viewLightInvalid),
            ifTrue(!finalIsDisabled && !finalIsInvalid && (isFocused || isHover), Styles.viewLightHighlighted),
            ifTrue(!finalIsDisabled && !finalIsInvalid && !isFocused && !isHover, Styles.viewLight),
          ]
        : [
            ifTrue(finalIsDisabled, Styles.viewDarkDisabled),
            ifTrue(!finalIsDisabled && finalIsInvalid, Styles.viewDarkInvalid),
            ifTrue(!finalIsDisabled && !finalIsInvalid && (isFocused || isHover), Styles.viewDarkHighlighted),
            ifTrue(!finalIsDisabled && !finalIsInvalid && !isFocused && !isHover, Styles.viewDark),
          ],
      style,
    ],
    [colorVariant, finalIsDisabled, finalIsInvalid, isFocused, isHover, style],
  )

  const textInputStyle = useMemo(
    () => [
      Styles.textInput,
      ForegroundStyles[colorVariant],
      ifTrue(finalIsDisabled, CommonStyles.cursorNotAllowed),
      CommonStyles.outlineNone,
    ],
    [colorVariant, finalIsDisabled],
  )

  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handleBlur = useCallback(() => {
    setIsFocused(false)
    onBlur && onBlur()
  }, [onBlur, setIsFocused])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
    onFocus && onFocus()
  }, [onFocus, setIsFocused])

  return (
    <View {...handleMouse} style={viewStyle}>
      <View style={Styles.textInputView}>
        <RNTextInput
          editable={!finalIsDisabled}
          multiline={multiline}
          numberOfLines={numberOfLines}
          onBlur={handleBlur}
          onChangeText={onChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          placeholderTextColor={
            isDisabled ? Theme.colors[dim(colorVariant)].borderColor : Theme.colors[dim(colorVariant)].color
          }
          style={textInputStyle}
          textContentType={textContentType}
          value={value}
        />
      </View>
      {Icon && (
        <View style={Styles.iconView}>
          <Icon color={Theme.colors[dim(colorVariant)].color} height={20} width={20} />
        </View>
      )}
    </View>
  )
}
