import { useScreen } from '@src/logic/design'
import Svg, { Circle } from 'react-native-svg'
import { StyleSheet, View } from 'react-native'
import { useState } from 'react'

const Styles = StyleSheet.create({
  absolute: {
    position: 'absolute',
  },
  container: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 999,
  },
})

type SvgCircleProps = {
  fillColor: string
  circumferenceToHeightPercentage: {
    phone: number
    tablet: number
    desktop: number
  }
  percentageHiddenXAxis: {
    phone: number
    tablet: number
    desktop: number
  }
  percentageHiddenYAxis: {
    phone: number
    tablet: number
    desktop: number
  }
  absolutePositionStyle: {
    top?: any
    bottom?: any
    left?: any
    right?: any
  }
}

export function SvgCircle({
  fillColor,
  circumferenceToHeightPercentage,
  percentageHiddenXAxis,
  percentageHiddenYAxis,
  absolutePositionStyle,
}: SvgCircleProps) {
  const getPercentageHiddenXAxis = () => {
    const { isPhone, isTablet } = useScreen()
    return isPhone
      ? percentageHiddenXAxis.phone
      : isTablet
      ? percentageHiddenXAxis.tablet
      : percentageHiddenXAxis.desktop
  }

  const getPercentageHiddenYAxis = () => {
    const { isPhone, isTablet } = useScreen()
    return isPhone
      ? percentageHiddenYAxis.phone
      : isTablet
      ? percentageHiddenYAxis.tablet
      : percentageHiddenYAxis.desktop
  }

  const getCircumferenceToHeightPercentage = () => {
    const { isPhone, isTablet } = useScreen()
    return isPhone
      ? circumferenceToHeightPercentage.phone
      : isTablet
      ? circumferenceToHeightPercentage.tablet
      : circumferenceToHeightPercentage.desktop
  }

  const getCircleCircumference = () => {
    return viewHeight * getCircumferenceToHeightPercentage()
  }

  const getViewBox = () => {
    if ('top' in absolutePositionStyle && 'left' in absolutePositionStyle) {
      return `${getCircleCircumference() * getPercentageHiddenXAxis()} ${
        getCircleCircumference() * getPercentageHiddenYAxis()
      } ${getCircleCircumference()} ${getCircleCircumference()}`
    } else if ('top' in absolutePositionStyle && 'right' in absolutePositionStyle) {
      return `-${getCircleCircumference() * getPercentageHiddenXAxis()} ${
        getCircleCircumference() * getPercentageHiddenYAxis()
      } ${getCircleCircumference()} ${getCircleCircumference()}`
    } else if ('bottom' in absolutePositionStyle && 'left' in absolutePositionStyle) {
      return `${getCircleCircumference() * getPercentageHiddenXAxis()} -${
        getCircleCircumference() * getPercentageHiddenYAxis()
      } ${getCircleCircumference()} ${getCircleCircumference()}`
    } else if ('bottom' in absolutePositionStyle && 'right' in absolutePositionStyle) {
      return `-${getCircleCircumference() * getPercentageHiddenXAxis()} -${
        getCircleCircumference() * getPercentageHiddenYAxis()
      } ${getCircleCircumference()} ${getCircleCircumference()}`
    }
    return `0 0 ${getCircleCircumference()} ${getCircleCircumference()}`
  }

  const [viewHeight, setHeight] = useState(0)

  return (
    <View
      onLayout={(event) => setHeight(event.nativeEvent.layout.height)}
      style={Styles.container}
      pointerEvents='none'>
      <View style={[Styles.absolute, absolutePositionStyle]}>
        <Svg width={getCircleCircumference()} height={getCircleCircumference()} viewBox={getViewBox()}>
          <Circle
            cx={getCircleCircumference() / 2}
            cy={getCircleCircumference() / 2}
            r={getCircleCircumference() / 2}
            fill={fillColor}
          />
        </Svg>
      </View>
    </View>
  )
}
