import { StackActions, TabActions, useNavigation } from '@react-navigation/native'
import { useScreen } from '@src/logic/design'
import {
  NavigateFuncContact,
  NavigateFuncFlow,
  NavigateFuncOrg,
  OrgConfigurationRouteIds,
  OrgDashboardRouteIds,
  OrgFlowRouteIds,
  OrgFlowsRouteIds,
  OrgNavRouteIds,
  OrgUserRouteIds,
  ROUTE_ID_TO_PATH,
  RouteIds,
  ScreenIds,
  Tab,
  Tabs,
  useMobileTabs,
  useWebTabs,
  webUseParam,
} from '@src/logic/routing/common'
import { useRequiredContext } from '@src/logic/utils'
import React, { createContext, ReactNode, useCallback, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

export const ORG_NAV_TABS: Tab<OrgNavRouteIds>[] = [
  {
    id: RouteIds.ORG_DASHBOARD_SUMMARY,
    label: 'Dashboard',
    secondaryIds: {
      [RouteIds.ORG_DASHBOARD_FLOWS]: true,
    },
  },
  {
    id: RouteIds.ORG_FLOWS_LIST,
    label: 'Flows',
    navigateToScreenId: ScreenIds.ORG_FLOWS_ROOT,
    secondaryIds: {
      [RouteIds.ORG_FLOWS_BOARD]: true,
      [RouteIds.ORG_FLOW_SUMMARY]: true,
      [RouteIds.ORG_FLOW_CHAT]: true,
      [RouteIds.ORG_FLOW_ACTION_ITEMS]: true,
    },
  },
  {
    id: RouteIds.ORG_CONTACTS,
    label: 'Contacts',
    secondaryIds: {
      [RouteIds.ORG_CONTACT]: true,
    },
  },
  {
    id: RouteIds.ORG_CONFIGURATION_PROFILE,
    label: 'Configuration',
    secondaryIds: {
      [RouteIds.ORG_CONFIGURATION_SETTINGS]: true,
    },
  },
  {
    id: RouteIds.ORG_USER_PROFILE,
    label: 'User',
    secondaryIds: {
      [RouteIds.ORG_USER_NOTIFICATIONS]: true,
    },
  },
]

export const ORG_DASHBOARD_TABS: Tab<OrgDashboardRouteIds>[] = [
  {
    breakpoints: {
      phone: true,
    },
    id: RouteIds.ORG_DASHBOARD_SUMMARY,
    label: 'Summary',
  },
  {
    id: RouteIds.ORG_DASHBOARD_FLOWS,
    label: 'Flows',
  },
]

export const ORG_FLOWS_TABS: Tab<OrgFlowsRouteIds>[] = [
  {
    id: RouteIds.ORG_FLOWS_LIST,
    label: 'List View',
  },
  {
    id: RouteIds.ORG_FLOWS_BOARD,
    label: 'Board View',
  },
]

export const ORG_FLOW_TABS: Tab<OrgFlowRouteIds>[] = [
  {
    breakpoints: {
      phone: true,
    },
    id: RouteIds.ORG_FLOW_SUMMARY,
    label: 'Summary',
  },
  {
    id: RouteIds.ORG_FLOW_CHAT,
    label: 'Chat',
  },
  {
    id: RouteIds.ORG_FLOW_ACTION_ITEMS,
    label: 'Action Items',
  },
]

export const ORG_CONFIGURATION_TABS: Tab<OrgConfigurationRouteIds>[] = [
  {
    breakpoints: {
      phone: true,
    },
    id: RouteIds.ORG_CONFIGURATION_PROFILE,
    label: 'Profile',
  },
  {
    id: RouteIds.ORG_CONFIGURATION_SETTINGS,
    label: 'Settings',
  },
]

export const ORG_USER_TABS: Tab<OrgUserRouteIds>[] = [
  {
    breakpoints: {
      phone: true,
    },
    id: RouteIds.ORG_USER_PROFILE,
    label: 'Profile',
  },
  {
    id: RouteIds.ORG_USER_NOTIFICATIONS,
    label: 'Notifications',
  },
]

export type OrgRoutingProviderType = {
  useNavTabs: () => Tabs<OrgNavRouteIds>
  useDashboardTabs: () => Tabs<OrgDashboardRouteIds>
  useFlowsTabs: () => Tabs<OrgFlowsRouteIds>
  useFlowTabs: () => Tabs<OrgFlowRouteIds>
  useConfigurationTabs: () => Tabs<OrgConfigurationRouteIds>
  useUserTabs: () => Tabs<OrgUserRouteIds>
  useNavigateToOrg: () => NavigateFuncOrg
  useNavigateToFlow: () => NavigateFuncFlow
  useNavigateToContact: () => NavigateFuncContact
}

export const OrgRoutingContext = createContext<OrgRoutingProviderType | undefined>(undefined)
export const useOrgRouting = () => useRequiredContext(OrgRoutingContext)

export const OrgRoutingProvider = {
  Web: OrgRoutingProviderWeb,
  Mobile: OrgRoutingProviderMobile,
}

export type OrgRoutingProviderWebProps = {
  children?: ReactNode
}

function OrgRoutingProviderWeb({ children }: OrgRoutingProviderWebProps) {
  const value = useMemo<OrgRoutingProviderType>(
    () => ({
      useNavTabs: () => useWebTabs<OrgNavRouteIds>(['orgId'], ORG_NAV_TABS),
      useDashboardTabs: () => useWebTabs<OrgDashboardRouteIds>(['orgId'], ORG_DASHBOARD_TABS),
      useFlowsTabs: () => useWebTabs<OrgFlowsRouteIds>(['orgId'], ORG_FLOWS_TABS),
      useFlowTabs: () => useWebTabs<OrgFlowRouteIds>(['orgId', 'flowId'], ORG_FLOW_TABS),
      useConfigurationTabs: () => useWebTabs<OrgConfigurationRouteIds>(['orgId'], ORG_CONFIGURATION_TABS),
      useUserTabs: () => useWebTabs<OrgUserRouteIds>(['orgId'], ORG_USER_TABS),
      useNavigateToOrg: () => {
        const { push } = useHistory()
        const orgId = webUseParam('orgId')

        return useCallback(
          (orgId: string) => push(generatePath(ROUTE_ID_TO_PATH[RouteIds.ORG_DASHBOARD_SUMMARY], { orgId })),
          [push, orgId],
        )
      },
      useNavigateToFlow: () => {
        const { push } = useHistory()
        const orgId = webUseParam('orgId')
        return useCallback(
          (flowId: string) =>
            push(
              generatePath(ROUTE_ID_TO_PATH[RouteIds.ORG_FLOW_CHAT], {
                orgId,
                flowId,
              }),
            ),
          [push, orgId],
        )
      },
      useNavigateToContact: () => {
        const { push } = useHistory()
        const orgId = webUseParam('orgId')
        return useCallback(
          (contactId: string) =>
            push(
              generatePath(ROUTE_ID_TO_PATH[RouteIds.ORG_CONTACT], {
                orgId,
                contactId,
              }),
            ),
          [push, orgId],
        )
      },
    }),
    [],
  )

  return <OrgRoutingContext.Provider value={value}>{children}</OrgRoutingContext.Provider>
}

export type OrgRoutingProviderMobileProps = {
  children?: ReactNode
}

function OrgRoutingProviderMobile({ children }: OrgRoutingProviderMobileProps) {
  const value = useMemo<OrgRoutingProviderType>(
    () => ({
      useNavTabs: () => useMobileTabs<OrgNavRouteIds>(ORG_NAV_TABS),
      useDashboardTabs: () => useMobileTabs<OrgDashboardRouteIds>(ORG_DASHBOARD_TABS),
      useFlowsTabs: () => useMobileTabs<OrgFlowsRouteIds>(ORG_FLOWS_TABS),
      useFlowTabs: () => useMobileTabs<OrgFlowRouteIds>(ORG_FLOW_TABS),
      useConfigurationTabs: () => useMobileTabs<OrgConfigurationRouteIds>(ORG_CONFIGURATION_TABS),
      useUserTabs: () => useMobileTabs<OrgUserRouteIds>(ORG_USER_TABS),
      useNavigateToOrg: () => {
        const { dispatch } = useNavigation()

        return useCallback((orgId: string) => dispatch(TabActions.jumpTo(ScreenIds.ORG_ROOT, { orgId })), [dispatch])
      },
      useNavigateToFlow: () => {
        const { dispatch } = useNavigation()

        return useCallback(
          (flowId: string) => dispatch(StackActions.push(ScreenIds.ORG_FLOW_ROOT, { flowId })),
          [dispatch],
        )
      },
      useNavigateToContact: () => {
        const { dispatch } = useNavigation()
        const { isTablet } = useScreen()

        return useCallback(
          (contactId: string) =>
            dispatch(
              isTablet
                ? TabActions.jumpTo(ScreenIds.ORG_CONTACT, { contactId })
                : StackActions.push(ScreenIds.ORG_CONTACT, { contactId }),
            ),
          [dispatch, isTablet],
        )
      },
    }),
    [],
  )

  return <OrgRoutingContext.Provider value={value}>{children}</OrgRoutingContext.Provider>
}
