import { NavigateFunc, NavigateFuncFlow, NavigateFuncOrg, NavigateFuncOrgFlow } from '@src/logic/routing/common/routes'
import { useEffect, useState } from 'react'
import { useAuth } from '@src/logic/auth'
import useAsyncEffect from 'use-async-effect'
import { useAnonInitiateFlowAsUnverifiedExternalUserMutation } from '@src/gen/graphql/bindings'

export function useLinkRoot(navigateToOrgDashboard: NavigateFuncOrg, navigateToExtFlows: NavigateFunc) {
  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      user.organizations.length > 0 ? navigateToOrgDashboard(user.organizations[0].id) : navigateToExtFlows()
    }
  }, [user])
}

export function useLinkAuthEmail(
  emailToken: string | undefined,
  navigateToOrgDashboard: NavigateFuncOrg,
  navigateToExtFlows: NavigateFunc,
  navigateToExtFlow: NavigateFuncFlow,
  navigateToOrgFlow: NavigateFuncOrgFlow,
) {
  const auth = useAuth()
  const [error, setError] = useState<any>()

  if (!emailToken) {
    throw new Error('Route param "emailToken" unexpectedly unset.')
  }

  useAsyncEffect(async (isMounted) => {
    try {
      const { newState, originatingFlowId, originatingFlowOrgId, originatingOrgInviteOrgId } =
        await auth.verifyEmailToken(emailToken)
      const user = newState.user

      if (!user) {
        if (isMounted()) {
          setError(new Error('Invalid email token.'))
        }
        return
      }

      if (originatingFlowId) {
        if (originatingFlowOrgId) {
          navigateToOrgFlow(originatingFlowOrgId, originatingFlowId)
        } else {
          navigateToExtFlow(originatingFlowId)
        }
      } else if (originatingOrgInviteOrgId) {
        navigateToOrgDashboard(originatingOrgInviteOrgId)
      } else if (user.organizations.length > 0) {
        navigateToOrgDashboard(user.organizations[0].id)
      } else {
        navigateToExtFlows()
      }
    } catch (err: any) {
      if (isMounted()) {
        setError(err)
      }
    }
  }, [])

  if (error) {
    throw error
  }
}

export function useLinkFlowInitiate(
  flowConfigurationId: string | null | undefined,
  unverifiedEmail: string | null | undefined,
  unverifiedFirstName: string | null | undefined,
  unverifiedLastName: string | null | undefined,
  navigateToUnvFlowSummary: NavigateFuncFlow,
) {
  const auth = useAuth()
  const [error, setError] = useState()
  const [initiateFlowAsUnverifiedUser] = useAnonInitiateFlowAsUnverifiedExternalUserMutation()

  if (!flowConfigurationId || !unverifiedFirstName || !unverifiedLastName || !unverifiedEmail) {
    throw new Error('Invalid parameters.')
  }

  useAsyncEffect(async (isMounted) => {
    try {
      // TODO(ibrt): Handle already signed in case.
      const resp = await initiateFlowAsUnverifiedUser({
        variables: {
          flowConfigurationId,
          unverifiedFirstName,
          unverifiedLastName,
          unverifiedEmail,
        },
      })
      if (resp.data) {
        await auth.storeUnverifiedAccessToken(resp.data.initiate_flow_as_unverified_external_user.access_token)
        navigateToUnvFlowSummary(resp.data.initiate_flow_as_unverified_external_user.flow_id)
      }
    } catch (err: any) {
      if (isMounted()) {
        setError(err)
      }
    }
  }, [])

  if (error) {
    throw error
  }
}
