import {
  useRegisterUserProfileEditAction,
  useRegisterUserAvatarEditAction,
  useRegisterUserSignOutAction,
} from '@src/components/actions'

import { ReactNode } from 'react'

type UserActionsProviderProps = {
  children?: ReactNode
}

export function UserActionsProvider({ children }: UserActionsProviderProps) {
  useRegisterUserSignOutAction()
  useRegisterUserAvatarEditAction()
  useRegisterUserProfileEditAction()

  return <>{children}</>
}
