export type SelectValueBaseType = string | {}

export type SelectOptionType<T extends SelectValueBaseType> = {
  isDefault: boolean
  label: string
  selectedLabel?: string
  value: T
  stringValue: string
}

export type SelectGroupType<T extends SelectValueBaseType> = {
  label: string
  options: SelectOptionType<T>[]
}

export type SelectPreOptionOrGroupType<T extends {}> =
  | Omit<SelectOptionType<T>, 'stringValue'>
  | (Pick<SelectGroupType<T>, 'label'> & { options: Array<Omit<SelectOptionType<T>, 'stringValue'>> })

export type SelectOptionOrGroupType<T extends SelectValueBaseType> = SelectOptionType<T> | SelectGroupType<T>

export type SelectInitType<T extends SelectValueBaseType> = {
  defaultOption: SelectOptionType<T>
  optionsOrGroups: SelectOptionOrGroupType<T>[]
}

export function initSelect<T extends SelectValueBaseType>(
  valueToString: (value: T) => string,
  preOptionsOrGroups: SelectPreOptionOrGroupType<T>[],
): SelectInitType<T> {
  const optionsOrGroups = preOptionsOrGroups.map((oog) =>
    'options' in oog
      ? { ...oog, options: oog.options.map((o) => ({ ...o, stringValue: valueToString(o.value) })) }
      : { ...oog, stringValue: valueToString(oog.value) },
  )

  return {
    defaultOption: findOption(optionsOrGroups, (o) => o.isDefault),
    optionsOrGroups,
  }
}

export function findOption<T extends SelectValueBaseType>(
  optionsOrGroups: Array<SelectOptionOrGroupType<T>>,
  predicate: (option: SelectOptionType<T>) => boolean,
): SelectOptionType<T> {
  for (let i = 0; i < optionsOrGroups.length; i++) {
    const optionOrGroup = optionsOrGroups[i]

    if ('options' in optionOrGroup) {
      for (let j = 0; j < optionOrGroup.options.length; j++) {
        if (predicate(optionOrGroup.options[j])) {
          return optionOrGroup.options[j]
        }
      }
    } else if (predicate(optionOrGroup)) {
      return optionOrGroup
    }
  }

  throw new Error('Option unexpectedly not found.')
}
