import { useActions } from '@src/logic/actions'
import { useAuthenticatedAuth } from '@src/logic/auth'
import React, { useMemo } from 'react'
import { PencilIcon } from 'react-native-heroicons/solid'
import useAsyncEffect from 'use-async-effect'

export const USER_SIGN_OUT_ACTION_ID = 'userSignOut'
export const USER_SIGN_OUT_ACTION_LABEL = 'Sign Out'

export function UserSignOutAction() {
  const { signOut } = useAuthenticatedAuth()
  useAsyncEffect(signOut, [])
  return null
}

export function useRegisterUserSignOutAction() {
  const { dispatchAction, useRegisterAction } = useActions()

  useRegisterAction<undefined>(
    {
      id: USER_SIGN_OUT_ACTION_ID,
      menuItem: {
        Icon: PencilIcon,
        label: USER_SIGN_OUT_ACTION_LABEL,
        params: undefined,
      },
      modal: () => <UserSignOutAction />,
    },
    [],
  )

  return useMemo(
    () => () =>
      dispatchAction<undefined>({
        id: USER_SIGN_OUT_ACTION_ID,
        params: undefined,
      }),
    [dispatchAction],
  )
}
