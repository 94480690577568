import { ifDef } from '@src/logic/utils/misc'
import { debounce, DebouncedFunc } from 'lodash'
import type { MutableRefObject } from 'react'
import { Context, useContext, useEffect, useMemo, useRef } from 'react'

export function usePrevious<T>(value: T): MutableRefObject<T | undefined>['current'] {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function useIsFirstRender() {
  const ref = useRef(true)
  useEffect(() => {
    ref.current = false
  }, [])
  return ref.current
}

export function useRequiredContext<T>(context: Context<T>): NonNullable<T> {
  const value = useContext<T>(context)
  if (value === undefined || value === null) {
    throw new Error('Unset context.')
  }
  return value!
}

export function useRequiredDebounced<T extends (...args: any[]) => any>(
  cb: T,
  mode?: 'leading' | 'trailing',
): DebouncedFunc<T> {
  return useMemo(
    () => debounce(cb, 500, ifDef(mode, { leading: mode === 'leading', trailing: mode === 'trailing' })),
    [cb],
  )
}

export function useRequiredDebouncedOnPress(onPress: () => void): () => void {
  return useMemo(() => debounce(onPress, 500, { leading: true }), [onPress])
}

export function useDebouncedOnPress(onPress: (() => void) | undefined): (() => void) | undefined {
  return useMemo(() => (onPress ? debounce(onPress, 500, { leading: true }) : undefined), [onPress])
}
