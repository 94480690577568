import { IconButton } from '@src/components/atoms/IconButton'
import { Text } from '@src/components/atoms/Text'
import { Theme } from '@src/logic/design'
import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { PlusIcon, XIcon } from 'react-native-heroicons/solid'

export type MobileFiltersProps = {
  activeFilters: MobileFiltersPropsFilter[]
  children?: ReactNode
  style?: ViewStyle
}

export type MobileFiltersPropsFilter = {
  id: string
  label: string
}

export const MobileFilters = memo(MobileFiltersComponent)

const Styles = StyleSheet.create({
  view: {
    backgroundColor: Theme.colors.dark.backgroundColor,
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
  },
  expandedView: {
    paddingTop: Theme.geometry.structure.spacing,
  },
  toolbarView: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 64,
    justifyContent: 'flex-start',
  },
  text: {
    flex: 1,
    paddingLeft: 8,
    paddingRight: 8,
  },
})

function MobileFiltersComponent({ activeFilters, children, style }: MobileFiltersProps) {
  const [isExpanded, setIsExpanded] = useState(false)

  const viewStyle = useMemo(() => [Styles.view, style], [style])
  const handleExpand = useCallback(() => setIsExpanded(!isExpanded), [isExpanded, setIsExpanded])

  return (
    <View style={viewStyle}>
      {isExpanded && <View style={Styles.expandedView}>{children}</View>}
      <View style={Styles.toolbarView}>
        <Text colorVariant='darkDim' textVariant='eyebrow'>
          Filters
        </Text>
        <Text colorVariant='dark' numberOfLines={1} style={Styles.text} textVariant='cta'>
          {activeFilters.map((f) => f.label).join(', ')}
        </Text>
        <IconButton colorVariant='dark' Icon={isExpanded ? XIcon : PlusIcon} onPress={handleExpand} />
      </View>
    </View>
  )
}
