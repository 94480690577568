import {
  FLOW_ACTION_ITEM_CREATE_ACTION_ID,
  FLOW_ACTION_ITEM_CREATE_ACTION_LABEL,
  FLOW_EXTERNAL_PARTICIPANT_INVITE_ACTION_ID,
  FLOW_EXTERNAL_PARTICIPANT_INVITE_ACTION_LABEL,
  FLOW_INTERNAL_PARTICIPANTS_EDIT_ACTION_ID,
  FLOW_INTERNAL_PARTICIPANTS_EDIT_ACTION_LABEL,
  FLOW_STATUS_EDIT_ACTION_ID,
  FLOW_STATUS_EDIT_ACTION_LABEL,
  FlowActionItemCreateAction,
  FlowActionItemCreateActionProps,
  FlowActionItemCreateActionValuesType,
  FlowExternalParticipantInviteAction,
  FlowExternalParticipantInviteActionProps,
  FlowExternalParticipantInviteActionValuesType,
  FlowInternalParticipantsEditAction,
  FlowInternalParticipantsEditActionProps,
  FlowInternalParticipantsEditActionValuesType,
  FlowStatusEditAction,
  FlowStatusEditActionProps,
  FlowStatusEditActionValuesType,
  useRegisterOrgFlowFieldCreateAction,
  useRegisterOrgFlowFieldEditAction,
} from '@src/components/actions'
import { useRegisterOrgFlowUserEditAction } from '@src/components/actions/FlowUserEditAction'
import {
  Flow_Roles_Enum,
  Flow_Statuses_Enum,
  Organization_Roles_Enum,
  OrgFlowDocument,
  OrgFlowSubscription,
  OrgFlowSubscriptionVariables,
  useOrgActionItemCancelMutation,
  useOrgInsertActionItemMutation,
  useOrgInsertMessageMutation,
  useOrgSendFlowInviteMutation,
  useOrgUpdateFlowStatusMutation,
  useOrgUpdateFlowsUsersSeenMutation,
  useOrgUpsertFlowsUsersMutation,
} from '@src/gen/graphql/bindings'
import { useActions } from '@src/logic/actions'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { useOrg } from '@src/logic/data/providers/OrgProvider'
import { processError } from '@src/logic/errors'
import { useSubscription } from '@src/logic/graphql'
import { useRequiredContext } from '@src/logic/utils'
import { ifTrue } from '@src/logic/utils/misc'
import React, { createContext, Fragment, ReactNode, useCallback } from 'react'
import { PencilIcon, PlusIcon, UserAddIcon, UserGroupIcon } from 'react-native-heroicons/solid'
import useAsyncEffect from 'use-async-effect'

const MESSAGES_LIMIT = 50

export type OrgFlowContextType = ReturnType<typeof useContext>
export const OrgFlowContext = createContext<OrgFlowContextType | undefined>(undefined)
export const useOrgFlow = () => useRequiredContext(OrgFlowContext)

export type OrgFlowProviderProps = {
  children?: ReactNode
  flowId: string
}

export function OrgFlowProvider({ children, flowId }: OrgFlowProviderProps) {
  const { data, error, loading } = useSubscription<OrgFlowSubscription, OrgFlowSubscriptionVariables>(OrgFlowDocument, {
    flowId: flowId,
    messagesOffset: 0,
    messagesLimit: MESSAGES_LIMIT,
  })

  if (error) {
    throw error
  }

  if (loading && data === undefined) {
    return null
  }

  if (data === undefined) {
    throw new Error('No data.')
  }

  return <OrgFlowProviderContext data={data}>{children}</OrgFlowProviderContext>
}

type OrgFlowProviderInnerProps = {
  children?: ReactNode
  data: OrgFlowSubscription
}

function OrgFlowProviderContext({ children, data }: OrgFlowProviderInnerProps) {
  return (
    <OrgFlowContext.Provider value={useContext(data)}>
      <OrgFlowProviderActions>{children}</OrgFlowProviderActions>
    </OrgFlowContext.Provider>
  )
}

type OrgFlowProviderActionsProps = {
  children?: ReactNode
}

function OrgFlowProviderActions({ children }: OrgFlowProviderActionsProps) {
  useRegisterOrgFlowStatusEditAction()
  useRegisterOrgFlowActionItemCreateAction()
  useRegisterOrgFlowInternalParticipantsEditAction()
  useRegisterOrgFlowExternalParticipantInviteAction()
  useRegisterOrgFlowFieldCreateAction()

  return <Fragment>{children}</Fragment>
}

function useContext(data: OrgFlowSubscription) {
  const { org } = useOrg()
  const { user } = useAuthenticatedAuth()

  const [actionItemCancelMutation] = useOrgActionItemCancelMutation()
  const [messageCreateMutation] = useOrgInsertMessageMutation()
  const [updateFlowsUsersSeenMutation] = useOrgUpdateFlowsUsersSeenMutation()

  const flow = data.flow
  if (!flow) {
    throw new Error('Not found.')
  }

  const isEditable =
    flow.status !== Flow_Statuses_Enum.Closed &&
    [Organization_Roles_Enum.Editor, Organization_Roles_Enum.Admin].includes(org.role)

  const flowUser = flow.flows_users.find(
    (fu) =>
      fu.user.id === user.id &&
      (fu.role === Flow_Roles_Enum.OrganizationOwner || fu.role === Flow_Roles_Enum.OrganizationParticipant),
  )

  const hasUnseenActionItems =
    flowUser && flow.action_items_activity_counter > flowUser.seen_action_items_activity_counter
  const hasUnseenMessages = flowUser && flow.messages_activity_counter > flowUser.seen_messages_activity_counter
  const hasUnseenStatus = flowUser && flow.status_activity_counter > flowUser.seen_status_activity_counter
  const hasUnseenFields = flowUser && flow.fields_activity_counter > flowUser.seen_fields_activity_counter
  const hasUnseenInternalParticipants =
    flowUser && flow.internal_participants_activity_counter > flowUser.seen_internal_participants_activity_counter
  const hasUnseenExternalParticipants =
    flowUser && flow.external_participants_activity_counter > flowUser.seen_external_participants_activity_counter

  let seenActionItemsUpdated = false
  let seenMessagesUpdated = false
  let seenStatusUpdated = false
  let seenFieldsUpdated = false
  let seenInternalParticipantsUpdated = false
  let seenExternalParticipantsUpdated = false

  const useSeen = (sections: {
    actionItems?: boolean
    messages?: boolean
    status?: boolean
    fields?: boolean
    internalParticipants?: boolean
    externalParticipants?: boolean
  }) => {
    useAsyncEffect(async () => {
      try {
        if (!flowUser) {
          return
        }

        if (
          (!sections.actionItems || seenActionItemsUpdated || !hasUnseenActionItems) &&
          (!sections.messages || seenMessagesUpdated || !hasUnseenMessages) &&
          (!sections.status || seenStatusUpdated || !hasUnseenStatus) &&
          (!sections.fields || seenFieldsUpdated || !hasUnseenFields) &&
          (!sections.internalParticipants || seenInternalParticipantsUpdated || !hasUnseenInternalParticipants) &&
          (!sections.externalParticipants || seenExternalParticipantsUpdated || !hasUnseenExternalParticipants)
        ) {
          return
        }
      } finally {
        seenActionItemsUpdated = seenActionItemsUpdated || (sections.actionItems ?? false)
        seenMessagesUpdated = seenMessagesUpdated || (sections.messages ?? false)
        seenStatusUpdated = seenStatusUpdated || (sections.status ?? false)
        seenFieldsUpdated = seenFieldsUpdated || (sections.fields ?? false)
        seenInternalParticipantsUpdated = seenInternalParticipantsUpdated || (sections.internalParticipants ?? false)
        seenExternalParticipantsUpdated = seenExternalParticipantsUpdated || (sections.externalParticipants ?? false)
      }

      try {
        await updateFlowsUsersSeenMutation({
          variables: {
            flowId: flow.id,
            seenActionItemsActivityCounter: sections.actionItems ? flow.action_items_activity_counter : 0,
            seenMessagesActivityCounter: sections.messages ? flow.messages_activity_counter : 0,
            seenStatusActivityCounter: sections.status ? flow.status_activity_counter : 0,
            seenFieldsActivityCounter: sections.fields ? flow.fields_activity_counter : 0,
            seenInternalParticipantsActivityCounter: sections.internalParticipants
              ? flow.internal_participants_activity_counter
              : 0,
            seenExternalParticipantsActivityCounter: sections.externalParticipants
              ? flow.external_participants_activity_counter
              : 0,
          },
        })
      } catch (err: any) {
        processError(err)
      }
    })
  }

  const actionItemCancel = async (actionItemId: string) => {
    await actionItemCancelMutation({
      variables: {
        actionItemId,
        canceledByUserId: user.id,
      },
    })
  }

  const messageCreate = async (chatContent: string) => {
    await messageCreateMutation({
      variables: {
        flowId: flow.id,
        chatContent,
      },
    })
  }

  const dispatchFlowUserEditAction = useRegisterOrgFlowUserEditAction()
  const dispatchFlowFieldEditAction = useRegisterOrgFlowFieldEditAction()

  return {
    flow,
    flowUser,
    isEditable,
    actionItemCancel: ifTrue(isEditable, actionItemCancel),
    messageCreate: ifTrue(isEditable, messageCreate),
    hasUnseen: {
      actionItems: hasUnseenActionItems,
      messages: hasUnseenMessages,
      status: hasUnseenStatus,
      fields: hasUnseenFields,
      internalParticipants: hasUnseenInternalParticipants,
      externalParticipants: hasUnseenExternalParticipants,
    },
    useSeen,
    dispatchFlowUserEditAction,
    dispatchFlowFieldEditAction,
  }
}

function useRegisterOrgFlowStatusEditAction() {
  const [flowStatusEditMutation] = useOrgUpdateFlowStatusMutation()
  const { flow, isEditable } = useOrgFlow()
  const { orgConfig } = useOrg()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()

  const handleSubmit = useCallback(
    async (values: FlowStatusEditActionValuesType) => {
      await flowStatusEditMutation({
        variables: values,
      })
      dismissAllActions()
    },
    [dismissAllActions, flowStatusEditMutation],
  )

  useRegisterAction(
    ifTrue(isEditable, {
      id: FLOW_STATUS_EDIT_ACTION_ID,
      menuItem: {
        Icon: PencilIcon,
        label: FLOW_STATUS_EDIT_ACTION_LABEL,
        params: { flow },
      },
      modal: ({ flow }: Pick<FlowStatusEditActionProps, 'flow'>) => (
        <FlowStatusEditAction flow={flow} onSubmit={handleSubmit} orgConfig={orgConfig} />
      ),
    }),
    [dispatchAction, flow, flowStatusEditMutation, isEditable, orgConfig],
  )
}

function useRegisterOrgFlowActionItemCreateAction() {
  const [insertActionItemMutation] = useOrgInsertActionItemMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { flow, isEditable } = useOrgFlow()
  const { orgConfig } = useOrg()

  const handleSubmit = useCallback(
    async (values: FlowActionItemCreateActionValuesType) => {
      await insertActionItemMutation({
        variables: values,
      })
      dismissAllActions()
    },
    [dismissAllActions, insertActionItemMutation],
  )

  useRegisterAction(
    ifTrue(isEditable, {
      id: FLOW_ACTION_ITEM_CREATE_ACTION_ID,
      menuItem: {
        Icon: PlusIcon,
        label: FLOW_ACTION_ITEM_CREATE_ACTION_LABEL,
        params: { flow, onSubmit: handleSubmit, orgConfig },
      },
      modal: ({
        flow,
        onSubmit,
        orgConfig,
      }: Pick<FlowActionItemCreateActionProps, 'flow' | 'onSubmit' | 'orgConfig'>) => (
        <FlowActionItemCreateAction flow={flow} onSubmit={onSubmit} orgConfig={orgConfig} />
      ),
    }),
    [dispatchAction, flow, handleSubmit, isEditable, orgConfig],
  )
}

function useRegisterOrgFlowInternalParticipantsEditAction() {
  const [flowInternalParticipantsEditMutation] = useOrgUpsertFlowsUsersMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { flow, isEditable } = useOrgFlow()
  const { orgConfig } = useOrg()

  const handleSubmit = useCallback(
    async (values: FlowInternalParticipantsEditActionValuesType) => {
      await flowInternalParticipantsEditMutation({
        variables: values,
      })
      dismissAllActions()
    },
    [dismissAllActions, flowInternalParticipantsEditMutation],
  )

  useRegisterAction(
    ifTrue(isEditable, {
      id: FLOW_INTERNAL_PARTICIPANTS_EDIT_ACTION_ID,
      menuItem: {
        Icon: UserGroupIcon,
        label: FLOW_INTERNAL_PARTICIPANTS_EDIT_ACTION_LABEL,
        params: { flow },
      },
      modal: ({ flow }: Pick<FlowInternalParticipantsEditActionProps, 'flow'>) => (
        <FlowInternalParticipantsEditAction flow={flow} onSubmit={handleSubmit} orgConfig={orgConfig} />
      ),
    }),
    [dispatchAction, flow, flowInternalParticipantsEditMutation, isEditable],
  )
}

function useRegisterOrgFlowExternalParticipantInviteAction() {
  const [sendFlowInviteMutation] = useOrgSendFlowInviteMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { flow, isEditable } = useOrgFlow()

  const handleSubmit = useCallback(
    async (values: FlowExternalParticipantInviteActionValuesType) => {
      await sendFlowInviteMutation({
        variables: values,
      })
      dismissAllActions()
    },
    [dismissAllActions, sendFlowInviteMutation],
  )

  useRegisterAction(
    ifTrue(isEditable, {
      id: FLOW_EXTERNAL_PARTICIPANT_INVITE_ACTION_ID,
      menuItem: {
        Icon: UserAddIcon,
        label: FLOW_EXTERNAL_PARTICIPANT_INVITE_ACTION_LABEL,
        params: { flowId: flow.id },
      },
      modal: ({ flowId }: Pick<FlowExternalParticipantInviteActionProps, 'flowId'>) => (
        <FlowExternalParticipantInviteAction flowId={flowId} onSubmit={handleSubmit} />
      ),
    }),
    [dispatchAction, flow.id, sendFlowInviteMutation, isEditable],
  )
}
