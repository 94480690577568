import { ErrorFallback } from '@src/logic/errors/ErrorFallback'
import React, { createContext, ReactNode, useContext, useMemo, useRef } from 'react'
import ErrorBoundary from 'react-native-error-boundary'
import { processError } from '@src/logic/errors/errors'

export type ErrorsContextType = {
  setRecoveryUrl: (url: string) => void
  getRecoveryUrl: () => string | null
}

const ErrorsContext = createContext<ErrorsContextType>(undefined!)
export const useErrors = () => useContext(ErrorsContext)

export type ErrorsProviderProps = {
  children?: ReactNode
}

export function ErrorsProvider({ children }: ErrorsProviderProps) {
  const recoveryUrlRef = useRef<string | null>(null)

  const value = useMemo(
    () => ({
      setRecoveryUrl: (url: string) => {
        recoveryUrlRef.current = url
      },
      getRecoveryUrl: () => {
        const recoveryUrl = recoveryUrlRef.current
        recoveryUrlRef.current = null
        return recoveryUrl
      },
    }),
    [recoveryUrlRef],
  )

  return (
    <ErrorsContext.Provider value={value}>
      <ErrorBoundary onError={(err: Error) => processError(err)} FallbackComponent={ErrorFallback}>
        {children}
      </ErrorBoundary>
    </ErrorsContext.Provider>
  )
}
