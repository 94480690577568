import React, { createContext, ReactNode } from 'react'
import {
  ExtFlowNotificationsQuery,
  useExtFlowNotificationsQuery,
  ExtFlowNotificationsDocument,
  useExtFlowNotificationClearMutation,
} from '@src/gen/graphql/bindings'
import { useRequiredContext } from '@src/logic/utils'
import { useAuthenticatedAuth } from '@src/logic/auth'

const NOTIFICATIONS_LIMIT = 25

export type ExtFlowNotificationsContextType = ReturnType<typeof useContext>
export const ExtFlowNotificationsContext = createContext<ExtFlowNotificationsContextType | undefined>(undefined)
export const useExtFlowNotifications = () => useRequiredContext(ExtFlowNotificationsContext)

export type ExtFlowNotificationsProviderProps = {
  children?: ReactNode
}

export function ExtFlowNotificationsProvider({ children }: ExtFlowNotificationsProviderProps) {
  const { user } = useAuthenticatedAuth()
  const { data, error, loading, previousData, fetchMore } = useExtFlowNotificationsQuery({
    variables: {
      limit: NOTIFICATIONS_LIMIT,
    },
  })

  let isFetchingMore = false

  const [clearNotificationMutation] = useExtFlowNotificationClearMutation({
    update: (cache, { data }) => {
      const existingNotifications = cache.readQuery<ExtFlowNotificationsQuery>({
        query: ExtFlowNotificationsDocument,
        variables: {
          limit: NOTIFICATIONS_LIMIT,
        },
      })
      const newNotifications = existingNotifications?.flow_notifications.filter(
        (notification) => data?.update_flow_notifications?.returning[0].flow_id !== notification.flow_id,
      )
      cache.writeQuery({
        query: ExtFlowNotificationsDocument,
        variables: {
          limit: NOTIFICATIONS_LIMIT,
        },
        data: { flow_notifications: newNotifications },
      })
    },
  })

  const clearNotification = async (flowId: string, runAnimation: (toValue: number) => Promise<boolean>) => {
    if (flowId === undefined) {
      throw new Error('flow_id is not passed')
    }

    try {
      await runAnimation(1)
      await clearNotificationMutation({
        variables: {
          userId: user.id,
          flowId,
        },
      })
    } catch (e) {
      await runAnimation(0)
    }
  }

  const fetchMoreNotifications = async () => {
    if (data !== undefined && !isFetchingMore) {
      try {
        isFetchingMore = true
        await fetchMore({
          variables: {
            offset: data.flow_notifications.length,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return {
              flow_notifications: [
                ...previousResult.flow_notifications,
                ...(fetchMoreResult?.flow_notifications || []),
              ],
            }
          },
        })
      } finally {
        isFetchingMore = false
      }
    }
  }

  if (error) {
    throw error
  }

  if (loading && data === undefined && previousData === undefined) {
    return null
  }

  const finalData = data ?? previousData
  if (finalData === undefined) {
    throw new Error('No data.')
  }

  return (
    <ExtFlowNotificationsContext.Provider value={useContext(finalData, fetchMoreNotifications, clearNotification)}>
      {children}
    </ExtFlowNotificationsContext.Provider>
  )
}

function useContext(
  data: ExtFlowNotificationsQuery,
  fetchMoreNotifications: () => Promise<void>,
  clearNotification: (flowId: string, runAnimation: (toValue: number) => Promise<boolean>) => Promise<void>,
) {
  return {
    flow_notifications: data.flow_notifications,
    fetchMoreNotifications,
    clearNotification,
  }
}
