import { FileUploadProps } from '@src/components/atoms'
import { ManagedFile, Uploader, useManagedFilesList } from '@src/logic/data/uploads/index'
import { useEffect } from 'react'
import { Accept } from './accept'

export type FileUploadOptions = {
  accept: Accept
  disabled?: boolean
  maxFiles: number
  maxSize: number
  onChange?: (value: string[]) => void
  uploader: Uploader
}

export type FileUploadState = {
  fileUploadProps: Omit<FileUploadProps, 'colorVariant'>
  managedFiles: ManagedFile[]
  reset: () => void
}

export function useFileUpload({
  accept,
  disabled,
  maxFiles,
  maxSize,
  onChange,
  uploader,
}: FileUploadOptions): FileUploadState {
  const { complete, fail, managedFiles, queue, remove, reset, start } = useManagedFilesList({
    accept,
    maxFiles,
    maxSize,
    onChange,
  })

  useEffect(() => {
    managedFiles.forEach((managedFile) => {
      if (!managedFile.started) {
        start(managedFile.internalId)
        ;(async () => {
          try {
            const id = await uploader(managedFile.file)
            complete(managedFile.internalId, id)
          } catch (err: any) {
            fail(managedFile.internalId, err)
          }
        })()
      }
    })
  })

  return {
    managedFiles,
    fileUploadProps: {
      accept,
      isDisabled: disabled,
      managedFiles,
      maxFiles,
      maxSize,
      queue,
      remainingMaxFiles: maxFiles - managedFiles.filter((f) => !f.error).length,
      remove,
    },
    reset,
  }
}
