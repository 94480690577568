import { Button, CircleBackgroundScreen, Text } from '@src/components/atoms'
import { StyleSheet } from 'react-native'
import Svg, { Path, G } from 'react-native-svg'
import { Theme } from '@src/logic/design'

type EmptyStateProps = {
  message: string
  buttonText: string
  onClick: () => void
}

const Styles = StyleSheet.create({
  buttonStyle: {
    marginTop: 24,
    zIndex: 1000,
  },
  messageStyle: {
    zIndex: 1000,
    textAlign: 'center',
  },
  iconStyle: {
    zIndex: 1000,
  },
})

export function EmptyState({ message, buttonText, onClick }: EmptyStateProps) {
  return (
    <CircleBackgroundScreen>
      <IconPlaceholderSvgComponent />
      <Text colorVariant='light' style={Styles.messageStyle} textVariant='h3'>
        {message}
      </Text>
      <Button style={Styles.buttonStyle} colorVariant='primary' onPress={onClick}>
        {buttonText}
      </Button>
    </CircleBackgroundScreen>
  )
}

function IconPlaceholderSvgComponent() {
  return (
    <Svg width={108} height={108} style={Styles.iconStyle} viewBox='0 0 108 108' fill='none'>
      <G>
        <Path
          d='M78 54c0 13.255-10.745 24-24 24S30 67.255 30 54s10.745-24 24-24 24 10.745 24 24z'
          fill={Theme.colors.primary.backgroundColor}
        />
        <Path
          d='M77.5 54c0 12.979-10.521 23.5-23.5 23.5S30.5 66.979 30.5 54 41.021 30.5 54 30.5 77.5 41.021 77.5 54z'
          stroke={Theme.colors.lightDim.borderColor}
        />
      </G>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.6 57.6l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4-10.6 10.6z'
        fill={Theme.colors.lightDim.color}
      />
    </Svg>
  )
}
