import { Text } from '@src/components/atoms/Text'
import { Theme } from '@src/logic/design'
import { processError } from '@src/logic/errors'
import { FORM_ERROR, FormApi, MutableState } from 'final-form'
import React, { Fragment, useCallback, useMemo } from 'react'
import { Form as RFForm, FormProps as RFFormProps } from 'react-final-form'
import { StyleSheet, View } from 'react-native'

export type FormProps<V extends Record<string, any>> = Pick<
  RFFormProps<V>,
  'children' | 'initialValues' | 'onSubmit' | 'validate'
>

const Styles = StyleSheet.create({
  errorText: {
    paddingBottom: Theme.geometry.card.spacing,
  },
})

export function Form<V extends Record<string, any>>({ children, initialValues, onSubmit, validate }: FormProps<V>) {
  const mutators = useMemo(
    () => ({
      setFieldTouched: function ([name, touched]: [string, boolean], state: MutableState<V>) {
        const field = state.fields[name]
        if (field) {
          field.touched = touched
        }
      },
    }),
    [],
  )

  const handleSubmit = useCallback(
    async (values: V, form: FormApi<V>) => {
      try {
        return await onSubmit(values, form)
      } catch (err: any) {
        processError(err)
        return {
          [FORM_ERROR]: err.message,
        }
      }
    },
    [onSubmit],
  )

  return (
    <RFForm<V> initialValues={initialValues} mutators={mutators} onSubmit={handleSubmit} validate={validate}>
      {({ submitErrors }) => {
        return (
          <Fragment>
            {children}
            {submitErrors?.[FORM_ERROR] && (
              <Text colorVariant='danger' style={Styles.errorText} textVariant='body'>
                {submitErrors?.[FORM_ERROR]}
              </Text>
            )}
          </Fragment>
        )
      }}
    </RFForm>
  )
}
