import {
  Button,
  Form,
  Item,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SubmitButton,
  TextInputControl,
} from '@src/components/atoms'
import {
  Flow_Field_Types_Enum,
  Flow_Fields,
  Organization_Roles_Enum,
  OrgFlowFieldUpdateMutationVariables,
  useOrgFlowFieldDeleteMutation,
  useOrgFlowFieldUpdateMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { getFlowFieldTypeLabel, getFlowFieldVisibilityLabel } from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'
import { ifTrue, ifTrueThen } from '@src/logic/utils'
import Joi from 'joi'
import React, { useCallback, useMemo } from 'react'

export const FLOW_FIELD_EDIT_ACTION_ID = 'flowFieldEdit'
export const FLOW_FIELD_EDIT_ACTION_LABEL = 'Edit Flow Field'

export type FlowFieldEditActionValues = OrgFlowFieldUpdateMutationVariables

export type FlowFieldEditActionParams = {
  flowField: Pick<
    Flow_Fields,
    'id' | 'flow_id' | 'name' | 'visibility' | 'type' | 'value_text' | 'value_double_precision' | 'updated_at'
  >
}

export type FlowFieldEditActionProps = FlowFieldEditActionParams & {
  onDelete: (id: string) => Promise<void>
  onSubmit: (values: FlowFieldEditActionValues) => Promise<void>
}

type FormType = {
  valueText: string
  valueDoublePrecision: string
}

export function FlowFieldEditAction({ flowField, onDelete, onSubmit }: FlowFieldEditActionProps) {
  const initialValues = useMemo<FormType>(
    () => ({
      valueText: flowField.value_text || '',
      valueDoublePrecision: flowField.value_double_precision ? `${flowField.value_double_precision}` : '',
    }),
    [],
  )

  const handleDelete = useCallback(() => onDelete(flowField.id), [flowField.id, onDelete])

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        id: flowField.id,
        valueText: ifTrue(flowField.type === Flow_Field_Types_Enum.Text, values.valueText),
        valueDoublePrecision: ifTrueThen(flowField.type === Flow_Field_Types_Enum.Number, () =>
          parseFloat(values.valueDoublePrecision),
        ),
      })
    },
    [onSubmit, flowField],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={FLOW_FIELD_EDIT_ACTION_LABEL} />
      <ModalContent>
        <Item colorVariant='light' label='Name' body={flowField.name} style={CommonStyles.marginBottomCard} />
        <Item
          colorVariant='light'
          label='Visibility'
          body={getFlowFieldVisibilityLabel(flowField.visibility)}
          style={CommonStyles.marginBottomCard}
        />
        <Item
          colorVariant='light'
          label='Type'
          body={getFlowFieldTypeLabel(flowField.type)}
          style={CommonStyles.marginBottomCard}
        />
      </ModalContent>
      <ModalFooter variant='card' style={{ paddingBottom: 0 }}>
        {flowField.type === Flow_Field_Types_Enum.Text && (
          <TextInputControl isRequired={true} label='Value' name='valueText' />
        )}
        {flowField.type === Flow_Field_Types_Enum.Number && (
          <TextInputControl
            isRequired={true}
            label='Value'
            name='valueDoublePrecision'
            schema={Joi.string().regex(/^[+-]?([0-9]*[.])?[0-9]+$/, { name: 'string.float' })}
          />
        )}
      </ModalFooter>
      <ModalFooter>
        <Button colorVariant='light' onPress={handleDelete}>
          Delete
        </Button>
        <SubmitButton style={CommonStyles.marginLeftCard}>Save</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterOrgFlowFieldEditAction() {
  const [doFlowFieldUpdate] = useOrgFlowFieldUpdateMutation()
  const [doFlowFieldDelete] = useOrgFlowFieldDeleteMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org } = useOrg()

  const handleSubmit = useCallback(
    async (values: FlowFieldEditActionValues) => {
      await doFlowFieldUpdate({
        variables: values,
      })
      dismissAllActions()
    },
    [doFlowFieldUpdate, dismissAllActions],
  )

  const handleDelete = useCallback(
    async (id: string) => {
      await doFlowFieldDelete({
        variables: {
          id,
        },
      })
      dismissAllActions()
    },
    [doFlowFieldDelete, dismissAllActions],
  )

  useRegisterAction<FlowFieldEditActionParams>(
    ifTrue(org.role === Organization_Roles_Enum.Admin || org.role === Organization_Roles_Enum.Editor, {
      id: FLOW_FIELD_EDIT_ACTION_ID,
      modal: ({ flowField }) => (
        <FlowFieldEditAction flowField={flowField} onDelete={handleDelete} onSubmit={handleSubmit} />
      ),
    }),
    [handleDelete, handleSubmit, org.role],
  )

  return useMemo(
    () =>
      ifTrue(
        org.role === Organization_Roles_Enum.Admin || org.role === Organization_Roles_Enum.Editor,
        (params: FlowFieldEditActionParams) =>
          dispatchAction<FlowFieldEditActionParams>({
            id: FLOW_FIELD_EDIT_ACTION_ID,
            params,
          }),
      ),
    [dispatchAction, org.role],
  )
}
