import { Form, ModalContent, ModalFooter, ModalHeader, SubmitButton, TextInputControl } from '@src/components/atoms'
import {
  Organization_Roles_Enum,
  OrgConfigDocument,
  OrgFlowRoleTypeInsertMutationVariables,
  useOrgFlowRoleTypeInsertMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { useOrg } from '@src/logic/data/providers'
import { ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { PlusSmIcon } from 'react-native-heroicons/solid'

export const FLOW_ROLE_TYPE_CREATE_ACTION_ID = 'flowRoleTypeCreate'
export const FLOW_ROLE_TYPE_CREATE_ACTION_LABEL = 'Create Flow Role'

export type FlowRoleTypeCreateActionValues = Omit<OrgFlowRoleTypeInsertMutationVariables, 'orgId'>

export type FlowRoleTypeCreateActionProps = {
  onSubmit: (values: FlowRoleTypeCreateActionValues) => Promise<void>
}

type FormType = {
  name: string
}

export function FlowRoleTypeCreateAction({ onSubmit }: FlowRoleTypeCreateActionProps) {
  const initialValues = useMemo<FormType>(
    () => ({
      name: '',
    }),
    [],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={onSubmit}>
      <ModalHeader title={FLOW_ROLE_TYPE_CREATE_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl isRequired={true} label='Name' name='name' placeholder='e.g. Lawyer, Sales Manager...' />
      </ModalContent>
      <ModalFooter>
        <SubmitButton>Create</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterFlowRoleTypeCreateAction() {
  const [doFlowRoleTypeInsert] = useOrgFlowRoleTypeInsertMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org } = useOrg()

  const handleSubmit = useCallback(
    async (values: FlowRoleTypeCreateActionValues) => {
      await doFlowRoleTypeInsert({
        variables: {
          ...values,
          orgId: org.id,
        },
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doFlowRoleTypeInsert, dismissAllActions, org.id],
  )

  useRegisterAction<undefined>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: FLOW_ROLE_TYPE_CREATE_ACTION_ID,
      menuItem: {
        Icon: PlusSmIcon,
        label: FLOW_ROLE_TYPE_CREATE_ACTION_LABEL,
        params: undefined,
      },
      modal: () => <FlowRoleTypeCreateAction onSubmit={handleSubmit} />,
    }),
    [handleSubmit, org.role],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, () =>
        dispatchAction<undefined>({
          id: FLOW_ROLE_TYPE_CREATE_ACTION_ID,
          params: undefined,
        }),
      ),
    [dispatchAction, org.role],
  )
}
