import { Badge, Item, Pane, PaneHeaderProps, PaneStyles, Text } from '@src/components/atoms'
import { getFlowBadge } from '@src/logic/data/manipulation'
import { Flow_Type_Statuses, Flow_Types, Flows, Maybe } from '@src/gen/graphql/bindings'
import { CommonStyles, Theme } from '@src/logic/design'
import { formatTimeSince } from '@src/logic/utils'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { PencilIcon } from 'react-native-heroicons/solid'

export type FlowPaneStatusProps = {
  flow: Pick<Flows, 'last_activity_at' | 'name' | 'outcome' | 'status'> & {
    flow_type_status?: Maybe<
      Pick<Flow_Type_Statuses, 'name'> & {
        flow_type: Pick<Flow_Types, 'name'>
      }
    >
  }
  onEdit?: () => void
  showActivity?: boolean
}

const Styles = StyleSheet.create({
  badgeView: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  nameText: {
    marginLeft: Theme.geometry.structure.spacing,
    marginRight: Theme.geometry.structure.spacing,
  },
})

export function FlowPaneStatus({ flow, onEdit, showActivity }: FlowPaneStatusProps) {
  const badgeViewStyle = useMemo(() => [Styles.badgeView, PaneStyles.item], [])
  const badge = useMemo(() => getFlowBadge('solid', flow.status, flow.outcome), [flow.status, flow.outcome])

  const header = useMemo<PaneHeaderProps>(
    () => ({
      iconButton: onEdit ? { Icon: PencilIcon, onPress: onEdit } : undefined,
      label: 'Status',
      showActivity,
    }),
    [onEdit, showActivity],
  )

  return (
    <Pane header={header}>
      <Text colorVariant='dark' textVariant='title' style={Styles.nameText}>
        {flow.name}
      </Text>
      <View style={badgeViewStyle}>
        <Badge {...badge} />
      </View>
      {flow.flow_type_status && flow.flow_type_status.flow_type && (
        <Item body={flow.flow_type_status.flow_type.name} colorVariant='dark' label='Type' style={PaneStyles.item} />
      )}
      {flow.flow_type_status && flow.flow_type_status.flow_type && (
        <Item body={flow.flow_type_status.name} colorVariant='dark' label='Stage' style={PaneStyles.item} />
      )}
      <Item
        body={formatTimeSince(flow.last_activity_at)}
        colorVariant='dark'
        label='Last Activity'
        style={PaneStyles.item}
      />
    </Pane>
  )
}
