import { useResolvedControl } from '@src/components/atoms/Control'
import { SelectModal } from '@src/components/atoms/SelectModal'
import {
  findOption,
  SelectOptionOrGroupType,
  SelectOptionType,
  SelectValueBaseType,
} from '@src/logic/data/manipulation'
import { CommonStyles, dim, Theme } from '@src/logic/design'
import { getMouseHandlers, ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo, useState } from 'react'
import { Platform, Pressable, StyleSheet, Text as RNText, View, ViewStyle } from 'react-native'
import { ChevronDownIcon } from 'react-native-heroicons/solid'

export type SelectProps<T extends SelectValueBaseType> = {
  colorVariant: 'light' | 'dark'
  isDisabled?: boolean
  isInvalid?: boolean
  onChange: (option: SelectOptionType<T>) => void
  optionsOrGroups: Array<SelectOptionOrGroupType<T>>
  selectedOption: SelectOptionType<T>
  style?: ViewStyle
}

const Styles = StyleSheet.create({
  view: {
    alignItems: 'center',
    borderRadius: Theme.geometry.control.radius,
    borderWidth: 1,
    flexDirection: 'row',
    height: Theme.geometry.control.height,
    paddingRight: Theme.geometry.control.spacing / 2,
  },
  viewLight: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.light.borderColor,
  },
  viewLightDisabled: {
    backgroundColor: Theme.colors.lightDim.backgroundColor,
    borderColor: Theme.colors.lightDim.borderColor,
  },
  viewLightInvalid: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.danger.color,
  },
  viewLightHighlighted: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.light.color,
  },
  viewDark: {
    backgroundColor: Theme.colors.darkDim.backgroundColor,
    borderColor: Theme.colors.dark.borderColor,
  },
  viewDarkDisabled: {
    backgroundColor: Theme.colors.darkDim.backgroundColor,
    borderColor: Theme.colors.darkDim.borderColor,
  },
  viewDarkInvalid: {
    backgroundColor: Theme.colors.darkDim.backgroundColor,
    borderColor: Theme.colors.danger.color,
  },
  viewDarkHighlighted: {
    backgroundColor: Theme.colors.darkDim.backgroundColor,
    borderColor: Theme.colors.dark.color,
  },
  text: {
    ...Theme.text.body,
    flex: 1,
    paddingLeft: Theme.geometry.control.spacing,
    paddingRight: Theme.geometry.control.spacing,
  },
  textLight: {
    color: Theme.colors.light.color,
  },
  textLightDisabled: {
    color: Theme.colors.light.borderColor,
  },
  textDark: {
    color: Theme.colors.dark.color,
  },
  textDarkDisabled: {
    color: Theme.colors.dark.borderColor,
  },
})

export function Select<T extends SelectValueBaseType>({
  colorVariant,
  isDisabled,
  isInvalid,
  onChange,
  optionsOrGroups,
  selectedOption,
  style,
}: SelectProps<T>) {
  const [isFocused, setIsFocused] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const { finalIsDisabled, finalIsInvalid } = useResolvedControl({ isDisabled, isInvalid })
  const ViewElement = Platform.OS === 'web' ? View : Pressable

  const viewStyle = useMemo(
    () => [
      Styles.view,
      ifTrue(finalIsDisabled, CommonStyles.cursorNotAllowed),
      colorVariant === 'light'
        ? [
            ifTrue(finalIsDisabled, Styles.viewLightDisabled),
            ifTrue(!finalIsDisabled && finalIsInvalid, Styles.viewLightInvalid),
            ifTrue(!finalIsDisabled && !finalIsInvalid && (isFocused || isHover), Styles.viewLightHighlighted),
            ifTrue(!finalIsDisabled && !finalIsInvalid && !isFocused && !isHover, Styles.viewLight),
          ]
        : [
            ifTrue(finalIsDisabled, Styles.viewDarkDisabled),
            ifTrue(!finalIsDisabled && finalIsInvalid, Styles.viewDarkInvalid),
            ifTrue(!finalIsDisabled && !finalIsInvalid && (isFocused || isHover), Styles.viewDarkHighlighted),
            ifTrue(!finalIsDisabled && !finalIsInvalid && !isFocused && !isHover, Styles.viewDark),
          ],
      style,
    ],
    [colorVariant, finalIsDisabled, finalIsInvalid, isFocused, isHover, style],
  )

  const textStyle = useMemo(
    () => [
      Styles.text,
      colorVariant === 'light'
        ? [ifTrue(finalIsDisabled, Styles.textLightDisabled), ifTrue(!finalIsDisabled, Styles.textLight)]
        : [ifTrue(finalIsDisabled, Styles.textDarkDisabled), ifTrue(!finalIsDisabled, Styles.textDark)],
    ],
    [colorVariant, finalIsDisabled],
  )

  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handleBlur = useCallback(() => setIsFocused(false), [setIsFocused])
  const handleFocus = useCallback(() => setIsFocused(true), [setIsFocused])
  const handlePress = useCallback(() => setIsOpen(true), [setIsOpen])
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen])

  const handleChange = useCallback(
    (option: SelectOptionType<T>) => {
      setIsOpen(false)
      onChange(option)
    },
    [onChange, setIsOpen],
  )

  return (
    <ViewElement disabled={finalIsDisabled} onPress={handlePress} style={viewStyle}>
      <RNText numberOfLines={1} style={textStyle}>
        {selectedOption.selectedLabel ? selectedOption.selectedLabel : selectedOption.label}
      </RNText>
      <ChevronDownIcon
        color={Theme.colors[dim(colorVariant)].color}
        // Note: the SVG path is still focusable, it's a bug in "react-native-svg". Find workaround.
        focusable={false}
        size={20}
      />
      {Platform.OS === 'web' && (
        <SelectVirtualOverlayWeb
          {...handleMouse}
          isDisabled={finalIsDisabled}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          optionsOrGroups={optionsOrGroups}
          selectedOption={selectedOption}
        />
      )}
      {Platform.OS !== 'web' && (
        <SelectModal
          isOpen={isOpen}
          onChange={handleChange}
          onClose={handleClose}
          optionsOrGroups={optionsOrGroups}
          selectedOption={selectedOption}
        />
      )}
    </ViewElement>
  )
}

export type SelectVirtualOverlayWebProps<T extends SelectValueBaseType> = {
  isDisabled: boolean
  onBlur?: () => void
  onChange: (option: SelectOptionType<T>) => void
  onFocus?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  optionsOrGroups: Array<SelectOptionOrGroupType<T>>
  selectedOption: SelectOptionType<T>
}

export function SelectVirtualOverlayWeb<T extends SelectValueBaseType>({
  isDisabled,
  onBlur,
  onChange,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  optionsOrGroups,
  selectedOption,
}: SelectVirtualOverlayWebProps<T>) {
  return (
    <select
      disabled={isDisabled}
      onBlur={onBlur}
      onChange={(e) => onChange(findOption(optionsOrGroups, (o) => o.stringValue === e.target.value))}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        bottom: 0,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        left: 0,
        opacity: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 2,
      }}
      value={selectedOption.stringValue}>
      {optionsOrGroups.map((oog, i) =>
        'options' in oog ? (
          <optgroup key={`group-${i}`} label={oog.label}>
            {oog.options.map((o) => (
              <option key={o.stringValue} value={o.stringValue}>
                {o.label}
              </option>
            ))}
          </optgroup>
        ) : (
          <option key={oog.stringValue} value={oog.stringValue}>
            {oog.label}
          </option>
        ),
      )}
    </select>
  )
}
