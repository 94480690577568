import { useCallback } from 'react'
import { ifTrue, ifDefThen } from '@src/logic/utils'
import {
  Item,
  Pane,
  PaneButton,
  PaneStyles,
  ScreenContent,
  ScreenPrimary,
  screenPrimaryIdKeyExtractor,
  ScreenPrimaryItemSeparator,
  ScreenPrimarySectionSeparator,
  ScreenPrimaryStyles,
  ScreenSecondary,
  Text,
  TextInput,
  UserProfile,
} from '@src/components/atoms'
import { getUserFullName, getUserRole } from '@src/logic/data/manipulation'

import { FlatList, Image, StyleSheet, View } from 'react-native'
import { useConfig } from '@src/logic/config'
import { CommonStyles, Theme } from '@src/logic/design'
import {
  OrgConfigQuery,
  Users,
  Organization_Roles_Enum,
  Organization_Invites,
  Organizations,
} from '@src/gen/graphql/bindings'
import React, { useMemo } from 'react'

export enum MemberType {
  Invite = 'invite',
  Member = 'member',
}

export type PaneOrganizationProfileProps = {
  label: string
  onEditMember?: (member: Member, type: MemberType) => void
  org: Pick<Organizations, 'id' | 'name'> & {
    role?: Organization_Roles_Enum
  }
  orgConfig?: OrgConfigQuery
}

const Styles = StyleSheet.create({
  view: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.dark.borderColor,
    borderRadius: 8,
    borderWidth: 1,
    marginLeft: Theme.geometry.structure.spacing,
    marginRight: Theme.geometry.structure.spacing,
    paddingBottom: 8,
    paddingTop: 8,
  },
  image: {
    alignSelf: 'center',
    height: 48,
    resizeMode: 'contain',
    width: '100%',
  },
  text: {
    alignSelf: 'center',
    paddingTop: 12,
  },
  nonClickable: {
    height: 48,
    justifyContent: 'center',
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
  },
})

type Member = {
  role: Organization_Roles_Enum
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

type OrgUsersListPartItemProps = {
  member?: Member
  invitee?: Organization_Invites
  onPress?: (member: Member, type: MemberType) => void
}

function OrgUsersListPartItem({ member, invitee, onPress }: OrgUsersListPartItemProps) {
  const orgMember: Member | undefined = useMemo(() => {
    if (invitee) {
      return {
        user: {
          first_name: invitee.first_name,
          last_name: invitee.last_name,
          id: invitee.user_id,
        },
        role: invitee.initial_role,
      }
    } else {
      return member
    }
  }, [member, invitee])

  const memberType = useMemo(() => (invitee ? MemberType.Invite : MemberType.Member), [member, invitee])
  const handlePress = useCallback(() => {
    onPress && orgMember && onPress(orgMember, memberType)
  }, [orgMember, onPress])

  const MemberContainer = useMemo(
    () =>
      onPress
        ? PaneButton
        : ({ children }: { children: React.ReactNode }) => <View style={Styles.nonClickable}>{children}</View>,
    [onPress],
  )

  return (
    <MemberContainer isActive={false} onPress={handlePress}>
      {member ? (
        <Item
          avatar={{ user: member.user }}
          body={getUserFullName(member.user)}
          colorVariant='dark'
          label={getUserRole(member.role)}
          labelPosition='bottom'
        />
      ) : null}
      {invitee && orgMember ? (
        <Item
          avatar={orgMember}
          badge={{ label: 'INVITED', colorVariant: 'warning' }}
          body={getUserFullName(orgMember?.user)}
          label={getUserRole(orgMember?.role)}
          colorVariant='dark'
          labelPosition='bottom'
        />
      ) : null}
    </MemberContainer>
  )
}

type OrgUsersListPartProps = {
  users?: OrgConfigQuery['users_organizations']
  invites?: OrgConfigQuery['organization_invites']
  dispatchEditMemberAction?: (member: Member, type: MemberType) => void
}
function OrgUsersListPart({ users, invites, dispatchEditMemberAction }: OrgUsersListPartProps) {
  const header = useMemo(() => ({ label: 'Members' }), [])

  const renderMember = useCallback(
    ({ item }) => <OrgUsersListPartItem member={item} onPress={dispatchEditMemberAction} />,
    [users, invites],
  )
  const renderMemberInvitee = useCallback(
    ({ item }) => <OrgUsersListPartItem invitee={item} onPress={dispatchEditMemberAction} />,
    [users, invites],
  )

  return (
    <Pane header={header} scrollableBehavior='managed'>
      <FlatList data={users} keyExtractor={({ user }) => user.id} renderItem={renderMember} />
      <FlatList data={invites} keyExtractor={(invite) => invite.user_id} renderItem={renderMemberInvitee} />
    </Pane>
  )
}

export function PaneOrganizationProfile({ label, org, orgConfig, onEditMember }: PaneOrganizationProfileProps) {
  const { getOrganizationProfilePictureUrl } = useConfig()

  const source = useMemo(() => ({ uri: getOrganizationProfilePictureUrl(org.id) }), [org.id])

  const dispatchEditMemberAction = useCallback(
    (member: Member, type: MemberType) => {
      onEditMember && onEditMember(member, type)
    },
    [onEditMember],
  )

  return (
    <>
      <Pane
        header={{
          label,
        }}>
        <View style={Styles.view}>
          <Image accessibilityLabel={org.name} source={source} style={Styles.image} />
        </View>
        <Text colorVariant='dark' numberOfLines={1} style={Styles.text} textVariant='cta'>
          {org.name}
        </Text>
      </Pane>
      {org?.role
        ? ifTrue(
            org.role === Organization_Roles_Enum.Admin || org.role === Organization_Roles_Enum.Editor,
            <OrgUsersListPart
              users={orgConfig?.users_organizations}
              invites={orgConfig?.organization_invites}
              dispatchEditMemberAction={ifTrue(org.role === Organization_Roles_Enum.Admin, dispatchEditMemberAction)}
            />,
          )
        : null}
    </>
  )
}
