import { Avatar } from '@src/components/atoms/Avatar'
import { Text } from '@src/components/atoms/Text'
import { getUserFullName } from '@src/logic/data/manipulation'
import { Theme } from '@src/logic/design'
import { Users } from '@src/gen/graphql/bindings'
import React, { memo, useMemo } from 'react'
import { Pressable, StyleSheet, ViewStyle } from 'react-native'

export type NavUserItemProps = {
  isActive: boolean
  onPress: () => void
  style?: ViewStyle
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

const Styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    backgroundColor: Theme.colors.dark.backgroundColor,
    flexDirection: 'row',
    height: 48,
    justifyContent: 'flex-start',
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
  },
  avatar: {
    marginRight: 16,
  },
})

export const NavUserItem = memo(NavUserItemComponent)

function NavUserItemComponent({ isActive, onPress, style, user }: NavUserItemProps) {
  const pressableStyle = useMemo(() => [Styles.pressable, style], [isActive, style])

  return (
    <Pressable onPress={onPress} style={pressableStyle}>
      <Avatar sizeVariant='md' user={user} style={Styles.avatar} />
      <Text colorVariant={isActive ? 'dark' : 'darkDim'} textVariant={isActive ? 'nav1' : 'nav2'}>
        {getUserFullName(user)}
      </Text>
    </Pressable>
  )
}
