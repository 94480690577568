import { FlowUserEditActionParams } from '@src/components/actions'
import { FlowUserViewActionParams } from '@src/components/actions/FlowUserViewAction'
import { BadgeProps, Item, Pane, PaneButton, PaneHeaderProps } from '@src/components/atoms'
import { Flow_Invites, Flow_Role_Types, Flow_Roles_Enum, Flows_Users, Maybe, Users } from '@src/gen/graphql/bindings'
import { getUserFullName } from '@src/logic/data/manipulation'
import { ArrayElement } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { PlusIcon } from 'react-native-heroicons/solid'

export type FlowPaneExternalParticipantsProps = {
  dispatchFlowUserAction: (params: FlowUserEditActionParams | FlowUserViewActionParams) => void
  flow: {
    id: string
    flows_users: Array<
      Pick<Flows_Users, 'role' | 'flow_id'> & {
        flow_role_type?: Maybe<Pick<Flow_Role_Types, 'id' | 'name'>>
        user: Pick<Users, 'id' | 'first_name' | 'last_name'>
      }
    >
    flow_invites: Array<Pick<Flow_Invites, 'user_id' | 'first_name' | 'last_name'>>
  }
  onInvite?: () => void
  showActivity?: boolean
}

export function FlowPaneExternalParticipants({
  dispatchFlowUserAction,
  flow,
  onInvite,
  showActivity,
}: FlowPaneExternalParticipantsProps) {
  const externalParticipants = flow.flows_users
    .filter(
      (fu) =>
        fu.role == Flow_Roles_Enum.UnverifiedExternalParticipant || fu.role == Flow_Roles_Enum.ExternalParticipant,
    )
    .map((fu) => ({ ...fu, fullName: getUserFullName(fu.user) }))
    .concat(
      flow.flow_invites.map((fi) => ({
        fullName: getUserFullName(fi),
        role: Flow_Roles_Enum.ExternalNone,
        flow_id: flow.id,
        user: {
          id: fi.user_id,
          first_name: fi.first_name,
          last_name: fi.last_name,
        },
      })),
    )
    .sort((fu1, fu2) => fu1.fullName.localeCompare(fu2.fullName))

  const header = useMemo<PaneHeaderProps>(
    () => ({
      iconButton: onInvite ? { Icon: PlusIcon, onPress: onInvite } : undefined,
      label: 'External Participants',
      showActivity,
    }),
    [onInvite, showActivity],
  )

  return (
    <Pane header={header}>
      {externalParticipants.map((p) => (
        <FlowPaneExternalParticipantsItem
          key={p.user.id}
          dispatchFlowUserAction={dispatchFlowUserAction}
          flowUser={p}
        />
      ))}
    </Pane>
  )
}

type FlowPaneExternalParticipantsItemProps = {
  dispatchFlowUserAction: (params: FlowUserEditActionParams | FlowUserViewActionParams) => void
  flowUser: ArrayElement<FlowPaneExternalParticipantsProps['flow']['flows_users']> & { fullName: string }
}

function FlowPaneExternalParticipantsItem({ dispatchFlowUserAction, flowUser }: FlowPaneExternalParticipantsItemProps) {
  const user = flowUser.user
  const avatar = useMemo(() => ({ user }), [user.id, user.first_name, user.last_name])
  const handlePress = useCallback(() => dispatchFlowUserAction({ flowUser }), [dispatchFlowUserAction, flowUser])

  const badge = useMemo<BadgeProps | undefined>(() => {
    switch (flowUser.role) {
      case Flow_Roles_Enum.UnverifiedExternalParticipant:
        return { colorVariant: 'warning', displayVariant: 'solid', label: 'Unverified' }
      case Flow_Roles_Enum.ExternalNone:
        return { colorVariant: 'lightDim', displayVariant: 'solid', label: 'Invited' }
    }
  }, [flowUser.role])

  return (
    <PaneButton isActive={false} onPress={handlePress}>
      <Item
        avatar={avatar}
        badge={badge}
        body={flowUser.fullName}
        colorVariant='dark'
        label={flowUser.flow_role_type?.name}
        labelPosition='bottom'
        numberOfLines={1}
      />
    </PaneButton>
  )
}
