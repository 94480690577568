import { Text } from '@src/components/atoms/Text'
import { Theme } from '@src/logic/design'
import React, { memo } from 'react'
import { StyleSheet, View } from 'react-native'

export type NavEyebrowProps = {
  title: string
}

export const NavEyebrow = memo(NavEyebrowComponent)

const Styles = StyleSheet.create({
  view: {
    alignItems: 'flex-start',
    backgroundColor: Theme.colors.dark.backgroundColor,
    paddingBottom: Theme.geometry.structure.spacing / 2,
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
    paddingTop: Theme.geometry.structure.spacing / 2,
  },
})

function NavEyebrowComponent({ title }: NavEyebrowProps) {
  return (
    <View style={Styles.view}>
      <Text colorVariant='dark' textVariant='eyebrow'>
        {title}
      </Text>
    </View>
  )
}
