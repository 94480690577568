import { MobileHeader, Modal } from '@src/components/atoms'
import { OrgNav } from '@src/components/parts/OrgNav'
import { ROUTE_ID_TO_TITLE } from '@src/logic/routing/common'
import { useOrgRouting } from '@src/logic/routing/providers'
import React, { memo, useCallback, useState } from 'react'

export const OrgMobileHeader = memo(OrgMobileHeaderComponent)

function OrgMobileHeaderComponent() {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = useCallback(() => setIsOpen(true), [setIsOpen])
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen])
  const { useNavTabs } = useOrgRouting()

  const navTabs = useNavTabs()
  const title = navTabs.activeTab ? ROUTE_ID_TO_TITLE[navTabs.activeTab.id] : 'Title'

  return (
    <>
      <MobileHeader onPress={handleOpen} title={title} variant='main' />
      <Modal isOpen={isOpen} onClose={handleClose} variant='bare'>
        <OrgNav onClose={handleClose} />
      </Modal>
    </>
  )
}
