import { SelectPreOptionOrGroupType } from '@src/logic/data/manipulation/select'
import { getUserFullName } from '@src/logic/data/manipulation/users'
import { Action_Item_Configurations, Action_Item_Types_Enum, Action_Items, Users } from '@src/gen/graphql/bindings'
import { formatTimeSince } from '@src/logic/utils'

export function getActionItemBadge(
  displayVariant: 'outline' | 'solid',
  actionItem: Pick<Action_Items, 'completed_at'> & {
    assignee: Pick<Users, 'id' | 'first_name' | 'last_name'>
  },
  user?: Pick<Users, 'id' | 'first_name' | 'last_name'>,
): {
  colorVariant: 'lightDim' | 'primary' | 'success' | 'warning' | 'danger'
  displayVariant: 'outline' | 'solid'
  label: string
} {
  return actionItem.completed_at
    ? { colorVariant: 'success', displayVariant, label: 'Done' }
    : actionItem.assignee.id === user?.id
    ? { colorVariant: 'warning', displayVariant, label: 'To Do' }
    : { colorVariant: 'lightDim', displayVariant, label: 'Pending' }
}

export function getActionItemTypeLabel(actionItemType: Action_Item_Types_Enum) {
  switch (actionItemType) {
    case Action_Item_Types_Enum.Freeform:
      return 'Task'
    case Action_Item_Types_Enum.Prompt:
      return 'Task with Action'
    case Action_Item_Types_Enum.Question:
      return 'Question'
    case Action_Item_Types_Enum.Upload:
      return 'File Request'
  }
}

export function getActionItemShortSummary(
  actionItem: Pick<Action_Items, 'completed_at' | 'type'> & {
    creator: Pick<Users, 'id' | 'first_name' | 'last_name'>
  },
) {
  return [
    getActionItemTypeLabel(actionItem.type),
    `from ${actionItem.creator.first_name} ${actionItem.creator.last_name}`,
  ].join(' · ')
}

export function getActionItemParticipantsSummary(
  actionItem: Pick<Action_Items, 'created_at' | 'type'> & {
    assignee: Pick<Users, 'id' | 'first_name' | 'last_name'>
    creator: Pick<Users, 'id' | 'first_name' | 'last_name'>
  },
  user?: Pick<Users, 'id' | 'first_name' | 'last_name'>,
) {
  const from = actionItem.creator.id === user?.id ? 'You' : getUserFullName(actionItem.creator)
  const to = actionItem.assignee.id === user?.id ? 'you' : getUserFullName(actionItem.assignee)
  return `${from} for ${to}`
}

export function getActionItemMediumSummary(
  actionItem: Pick<Action_Items, 'created_at' | 'type'> & {
    assignee: Pick<Users, 'id' | 'first_name' | 'last_name'>
    creator: Pick<Users, 'id' | 'first_name' | 'last_name'>
  },
) {
  return [
    getActionItemTypeLabel(actionItem.type),
    `from ${actionItem.creator.first_name} ${actionItem.creator.last_name}`,
    `for ${actionItem.assignee.first_name} ${actionItem.assignee.last_name}`,
    formatTimeSince(actionItem.created_at),
  ].join(' · ')
}

export type ActionItemStartFromOptionType = {
  input: string
  promptCta?: string
  type: Action_Item_Types_Enum
}

export function getActionItemStartFromPreOptionsOrGroups(
  actionItemConfigurations: Array<Pick<Action_Item_Configurations, 'name' | 'type' | 'input' | 'prompt_cta'>>,
): SelectPreOptionOrGroupType<ActionItemStartFromOptionType>[] {
  return [
    {
      label: 'Blank',
      options: Object.entries(Action_Item_Types_Enum).map(([_, type], i) => ({
        isDefault: i === 0,
        label: getActionItemTypeLabel(type),
        selectedLabel: `Blank / ${getActionItemTypeLabel(type)}`,
        value: {
          type: type,
          input: '',
          promptCta: type === Action_Item_Types_Enum.Prompt ? '' : undefined,
        },
      })),
    },
    {
      label: 'Templates',
      options: actionItemConfigurations.map((aic) => ({
        isDefault: false,
        label: aic.name,
        selectedLabel: `Templates / ${aic.name}`,
        value: {
          type: aic.type,
          input: aic.input,
          promptCta: aic.prompt_cta || undefined,
        },
      })),
    },
  ]
}

export function getActionItemTypePreOptionsOrGroups(): SelectPreOptionOrGroupType<Action_Item_Types_Enum>[] {
  return Object.entries(Action_Item_Types_Enum).map(([_, type], i) => ({
    isDefault: i === 0,
    label: getActionItemTypeLabel(type),
    value: type,
  }))
}
