import React, { createContext, ReactNode } from 'react'
import { useRequiredContext } from '@src/logic/utils'
import { useFlowFilterOwner, useFlowFilterStatusType } from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers/OrgProvider'

export type OrgFlowsFiltersContextType = ReturnType<typeof useContext>
export const OrgFlowsFiltersContext = createContext<OrgFlowsFiltersContextType | undefined>(undefined)
export const useOrgFlowsFilters = () => useRequiredContext(OrgFlowsFiltersContext)

export type OrgFlowsFiltersProviderProps = {
  children?: ReactNode
}

export function OrgFlowsFiltersProvider({ children }: OrgFlowsFiltersProviderProps) {
  return <OrgFlowsFiltersContext.Provider value={useContext()}>{children}</OrgFlowsFiltersContext.Provider>
}

function useContext() {
  const { orgConfig } = useOrg()
  const flowFilterOwner = useFlowFilterOwner(orgConfig.users_organizations)
  const flowFilterStatusType = useFlowFilterStatusType(orgConfig.flow_types)

  return {
    flowFilterOwner,
    flowFilterStatusType,
  }
}
