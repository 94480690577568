import {
  ScreenPrimary,
  screenPrimaryIdKeyExtractor,
  ScreenPrimaryItemSeparator,
  ScreenPrimarySectionSeparator,
  ScreenPrimaryStyles,
} from '@src/components/atoms'
import { ExtFlowCard } from '@src/components/molecules'
import { ExtFlowsQuery } from '@src/gen/graphql/bindings'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { useExtFlows } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'
import { NavigateFuncFlow } from '@src/logic/routing/common'
import { useExtRouting } from '@src/logic/routing/providers'
import { ArrayElement } from '@src/logic/utils'
import React, { useCallback } from 'react'
import { FlatList } from 'react-native'

export function ExtFlowsPart() {
  const { flows } = useExtFlows()
  const { useNavigateToFlow } = useExtRouting()
  const navigateToFlow = useNavigateToFlow()

  const renderItem = useCallback(
    ({ item }) => <ExtFlowsPartItem flow={item} navigateToFlow={navigateToFlow} />,
    [navigateToFlow],
  )

  return (
    <ScreenPrimary>
      <FlatList
        ListHeaderComponent={ScreenPrimarySectionSeparator}
        ListFooterComponent={ScreenPrimaryItemSeparator}
        contentContainerStyle={ScreenPrimaryStyles.containerViewCenteredMedium}
        data={flows}
        style={CommonStyles.flexOne}
        keyExtractor={screenPrimaryIdKeyExtractor}
        renderItem={renderItem}
      />
    </ScreenPrimary>
  )
}

type ExtFlowsPartItemProps = {
  flow: ArrayElement<ExtFlowsQuery['flows']>
  navigateToFlow: NavigateFuncFlow
}

function ExtFlowsPartItem({ flow, navigateToFlow }: ExtFlowsPartItemProps) {
  const { user } = useAuthenticatedAuth()
  const handlePress = useCallback(() => navigateToFlow(flow.id), [flow.id, navigateToFlow])
  return <ExtFlowCard flow={flow} onPress={handlePress} style={CommonStyles.marginBottomStructureHalf} user={user} />
}
