import { Card, Item, Text } from '@src/components/atoms'
import { Flow_Field_Configurations } from '@src/gen/graphql/bindings'
import { getFlowFieldTypeLabel, getFlowFieldVisibilityLabel } from '@src/logic/data/manipulation'
import { CommonStyles } from '@src/logic/design'
import React from 'react'
import { ViewStyle } from 'react-native'

export type FlowFieldTemplateConfigurationCardProps = {
  flowFieldConfiguration: Pick<Flow_Field_Configurations, 'name' | 'visibility' | 'type'>
  onPress?: () => void
  style?: ViewStyle
}

export function FlowFieldConfigurationCard({
  flowFieldConfiguration,
  onPress,
  style,
}: FlowFieldTemplateConfigurationCardProps) {
  return (
    <Card colorVariant='lightDim' style={style} onPress={onPress}>
      <Text colorVariant='light' numberOfLines={1} style={CommonStyles.marginBottomCard} textVariant='title'>
        {flowFieldConfiguration.name}
      </Text>
      <Item
        colorVariant='light'
        body={getFlowFieldVisibilityLabel(flowFieldConfiguration.visibility)}
        label='Visibility'
        style={CommonStyles.marginBottomCard}
      />
      <Item
        colorVariant='light'
        body={getFlowFieldTypeLabel(flowFieldConfiguration.type)}
        label='Type'
        style={CommonStyles.marginBottomCard}
      />
    </Card>
  )
}
