import { Item } from '@src/components/atoms'
import {
  FlowActivity,
  FlowActivityActionItemCanceled,
  FlowActivityActionItemCompleted,
  FlowActivityActionItemCreated,
  FlowActivityFlowFieldUpdated,
  FlowActivityFlowFlowTypeStatusUpdated,
  FlowActivityFlowNameUpdated,
  FlowActivityMessageSent,
  FlowActivityParticipantFlowRoleTypeUpdated,
  FlowActivityParticipantInvited,
  FlowActivityParticipantJoined,
  FlowActivityParticipantLeft,
  FlowActivityParticipantVerified,
  FlowActivityTypes,
  getActionItemTypeLabel,
  getUserFullName,
} from '@src/logic/data/manipulation'
import React, { memo } from 'react'
import { ViewStyle } from 'react-native'

export type FlowActivityItemProps = {
  activity: FlowActivity
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

export const FlowActivityItem = memo(FlowActivityItemComponent)

function FlowActivityItemComponent({ activity, colorVariant, style }: FlowActivityItemProps) {
  switch (activity.type) {
    case FlowActivityTypes.MESSAGE_SENT:
      return <FlowActivityItemMessageSent activity={activity} colorVariant={colorVariant} style={style} />
    case FlowActivityTypes.ACTION_ITEM_CREATED:
      return <FlowActivityItemActionItemCreated activity={activity} colorVariant={colorVariant} style={style} />
    case FlowActivityTypes.ACTION_ITEM_COMPLETED:
      return <FlowActivityItemActionItemCompleted activity={activity} colorVariant={colorVariant} style={style} />
    case FlowActivityTypes.ACTION_ITEM_CANCELED:
      return <FlowActivityItemActionItemCanceled activity={activity} colorVariant={colorVariant} style={style} />
    case FlowActivityTypes.PARTICIPANT_JOINED:
      return <FlowActivityItemParticipantJoined activity={activity} colorVariant={colorVariant} style={style} />
    case FlowActivityTypes.PARTICIPANT_LEFT:
      return <FlowActivityItemParticipantLeft activity={activity} colorVariant={colorVariant} style={style} />
    case FlowActivityTypes.PARTICIPANT_VERIFIED:
      return <FlowActivityItemParticipantVerified activity={activity} colorVariant={colorVariant} style={style} />
    case FlowActivityTypes.PARTICIPANT_INVITED:
      return <FlowActivityItemParticipantInvited activity={activity} colorVariant={colorVariant} style={style} />
    case FlowActivityTypes.FLOW_FLOW_TYPE_STATUS_UPDATED:
      return <FlowActivityItemFlowFlowTypeStatusUpdated activity={activity} colorVariant={colorVariant} style={style} />
    case FlowActivityTypes.FLOW_NAME_UPDATED:
      return <FlowActivityItemFlowNameUpdated activity={activity} colorVariant={colorVariant} style={style} />
    case FlowActivityTypes.PARTICIPANT_FLOW_ROLE_TYPE_UPDATED:
      return (
        <FlowActivityItemParticipantFlowRoleTypeUpdated activity={activity} colorVariant={colorVariant} style={style} />
      )
    case FlowActivityTypes.FLOW_FIELD_UPDATED:
      return <FlowActivityItemFlowFieldUpdated activity={activity} colorVariant={colorVariant} style={style} />
  }
}

type FlowActivityItemMessageSentProps = {
  activity: FlowActivityMessageSent
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemMessageSent({ activity, colorVariant, style }: FlowActivityItemMessageSentProps) {
  return (
    <Item
      avatar={{ user: activity.sender }}
      body={activity.chat_content_summary}
      colorVariant={colorVariant}
      label={`${getUserFullName(activity.sender)} sent a Message`}
      numberOfLines={1}
      style={style}
    />
  )
}

type FlowActivityItemActionItemCreatedProps = {
  activity: FlowActivityActionItemCreated
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemActionItemCreated({ activity, colorVariant, style }: FlowActivityItemActionItemCreatedProps) {
  return (
    <Item
      avatar={{ user: activity.creator }}
      body={`${getActionItemTypeLabel(activity.action_item.type)} · ${activity.action_item.input_summary}`}
      colorVariant={colorVariant}
      label={`${getUserFullName(activity.creator)} created an Action Item for ${getUserFullName(activity.assignee)}`}
      numberOfLines={1}
      style={style}
    />
  )
}

type FlowActivityItemActionItemCompletedProps = {
  activity: FlowActivityActionItemCompleted
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemActionItemCompleted({
  activity,
  colorVariant,
  style,
}: FlowActivityItemActionItemCompletedProps) {
  return (
    <Item
      avatar={{ user: activity.completer }}
      body={`${getActionItemTypeLabel(activity.action_item.type)} · ${activity.action_item.input_summary}`}
      colorVariant={colorVariant}
      label={`${getUserFullName(activity.completer)} completed an Action Item from ${getUserFullName(
        activity.creator,
      )}`}
      numberOfLines={1}
      style={style}
    />
  )
}

type FlowActivityItemActionItemCanceledProps = {
  activity: FlowActivityActionItemCanceled
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemActionItemCanceled({
  activity,
  colorVariant,
  style,
}: FlowActivityItemActionItemCanceledProps) {
  return (
    <Item
      avatar={{ user: activity.canceler }}
      body={`${getActionItemTypeLabel(activity.action_item.type)} · ${activity.action_item.input_summary}`}
      colorVariant={colorVariant}
      label={`${getUserFullName(activity.canceler)} deleted an Action Item for ${getUserFullName(activity.assignee)}`}
      numberOfLines={1}
      style={style}
    />
  )
}

type FlowActivityItemParticipantJoinedProps = {
  activity: FlowActivityParticipantJoined
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemParticipantJoined({ activity, colorVariant, style }: FlowActivityItemParticipantJoinedProps) {
  return (
    <Item
      avatar={{ user: activity.participant }}
      colorVariant={colorVariant}
      label={`${getUserFullName(activity.participant)} joined the Flow`}
      numberOfLines={1}
      style={style}
    />
  )
}

type FlowActivityItemParticipantLeftProps = {
  activity: FlowActivityParticipantLeft
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemParticipantLeft({ activity, colorVariant, style }: FlowActivityItemParticipantLeftProps) {
  return (
    <Item
      avatar={{ user: activity.participant }}
      colorVariant={colorVariant}
      label={`${getUserFullName(activity.participant)} left the Flow`}
      numberOfLines={1}
      style={style}
    />
  )
}

type FlowActivityItemParticipantVerifiedProps = {
  activity: FlowActivityParticipantVerified
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemParticipantVerified({
  activity,
  colorVariant,
  style,
}: FlowActivityItemParticipantVerifiedProps) {
  return (
    <Item
      avatar={{ user: activity.participant }}
      colorVariant={colorVariant}
      label={`${getUserFullName(activity.participant)} is now a Verified Participant`}
      numberOfLines={1}
      style={style}
    />
  )
}

type FlowActivityItemParticipantInvitedProps = {
  activity: FlowActivityParticipantInvited
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemParticipantInvited({
  activity,
  colorVariant,
  style,
}: FlowActivityItemParticipantInvitedProps) {
  return (
    <Item
      avatar={{ user: activity.inviter }}
      body={`${getUserFullName(activity.invitee)}`}
      colorVariant={colorVariant}
      label={`${getUserFullName(activity.inviter)} invited a new Participant`}
      numberOfLines={1}
      style={style}
    />
  )
}

type FlowActivityItemFlowFlowTypeStatusUpdatedProps = {
  activity: FlowActivityFlowFlowTypeStatusUpdated
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemFlowFlowTypeStatusUpdated({
  activity,
  colorVariant,
  style,
}: FlowActivityItemFlowFlowTypeStatusUpdatedProps) {
  if (activity.new_flow_type_status) {
    return (
      <Item
        avatar={{ user: activity.updater }}
        body={`${activity.new_flow_type_status.flow_type_name} · ${activity.new_flow_type_status.name}`}
        colorVariant={colorVariant}
        label={`${getUserFullName(activity.updater)} updated the Flow Stage`}
        numberOfLines={1}
        style={style}
      />
    )
  }

  return (
    <Item
      avatar={{ user: activity.updater }}
      colorVariant={colorVariant}
      label={`${getUserFullName(activity.updater)} cleared the Flow Stage`}
      numberOfLines={1}
      style={style}
    />
  )
}

type FlowActivityItemFlowNameUpdatedProps = {
  activity: FlowActivityFlowNameUpdated
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemFlowNameUpdated({ activity, colorVariant, style }: FlowActivityItemFlowNameUpdatedProps) {
  return (
    <Item
      avatar={{ user: activity.updater }}
      body={activity.new_name}
      colorVariant={colorVariant}
      label={`${getUserFullName(activity.updater)} updated the Flow Name`}
      numberOfLines={1}
      style={style}
    />
  )
}

type FlowActivityParticipantFlowRoleTypeUpdatedProps = {
  activity: FlowActivityParticipantFlowRoleTypeUpdated
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemParticipantFlowRoleTypeUpdated({
  activity,
  colorVariant,
  style,
}: FlowActivityParticipantFlowRoleTypeUpdatedProps) {
  const label = activity.new_flow_role_type_name
    ? `${getUserFullName(activity.updater)} updated the Flow Role of ${getUserFullName(activity.participant)}`
    : `${getUserFullName(activity.updater)} cleared the Flow Role of ${getUserFullName(activity.participant)}`

  return (
    <Item
      avatar={{ user: activity.updater }}
      body={activity.new_flow_role_type_name ? activity.new_flow_role_type_name : undefined}
      colorVariant={colorVariant}
      label={label}
      numberOfLines={1}
      style={style}
    />
  )
}

type FlowActivityFlowFieldUpdatedProps = {
  activity: FlowActivityFlowFieldUpdated
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

function FlowActivityItemFlowFieldUpdated({ activity, colorVariant, style }: FlowActivityFlowFieldUpdatedProps) {
  if (!activity.old_flow_field && activity.new_flow_field) {
    return (
      <Item
        avatar={{ user: activity.updater }}
        body={activity.new_flow_field.name}
        colorVariant={colorVariant}
        label={`${activity.updater.first_name} ${activity.updater.last_name} created a Flow Field`}
        numberOfLines={1}
        style={style}
      />
    )
  }

  if (activity.old_flow_field && activity.new_flow_field) {
    return (
      <Item
        avatar={{ user: activity.updater }}
        body={activity.new_flow_field.name}
        colorVariant={colorVariant}
        label={`${activity.updater.first_name} ${activity.updater.last_name} updated a Flow Field`}
        numberOfLines={1}
        style={style}
      />
    )
  }

  if (activity.old_flow_field && !activity.new_flow_field) {
    return (
      <Item
        avatar={{ user: activity.updater }}
        body={activity.old_flow_field.name}
        colorVariant={colorVariant}
        label={`${activity.updater.first_name} ${activity.updater.last_name} deleted a Flow Field`}
        numberOfLines={1}
        style={style}
      />
    )
  }

  throw new Error('Invalid activity.')
}
