import {
  Inter_300Light,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  useFonts,
} from '@expo-google-fonts/inter'
import { useAssets } from 'expo-asset'

export function useDesignLoaders(): boolean {
  const [assetsLoaded] = useAssets([
    require('@assets/backgrounds/splash-mobile.png'),
    require('@assets/emails/powered-by-v1.png'),
  ])

  const [fontsLoaded] = useFonts({
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
  })

  return (assetsLoaded && fontsLoaded) || false
}
