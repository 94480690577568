import { CommonStyles, Theme } from '@src/logic/design'
import { getMouseHandlers, ifDef, ifTrue } from '@src/logic/utils'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { Pressable, StyleSheet, Text as RNText, ViewStyle } from 'react-native'
import { ChevronDownIcon } from 'react-native-heroicons/solid'
import { SvgProps } from 'react-native-svg'

export type ViewButtonProps = {
  children: string
  hasChevron?: boolean
  Icon?: (props: SvgProps) => JSX.Element
  isActive?: boolean
  isDisabled?: boolean
  onPress: () => void
  style?: ViewStyle
}

export const ViewButton = memo(ViewButtonComponent)

const Styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    ...Theme.text.eyebrow,
    color: Theme.colors.lightDim.color,
  },
  textWithIcon: {
    paddingLeft: 8,
  },
  textWithChevron: {
    paddingRight: 0,
  },
  textHighlight: {
    color: Theme.colors.light.color,
  },
  textDisabled: {
    color: Theme.colors.light.borderColor,
  },
  chevronIcon: {
    marginTop: 1,
  },
})

function ViewButtonComponent({
  children,
  Icon,
  isActive = false,
  isDisabled = false,
  hasChevron = false,
  onPress,
  style,
}: ViewButtonProps) {
  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  const pressableStyle = useMemo(
    () => [Styles.pressable, ifTrue(isDisabled, CommonStyles.cursorNotAllowed), style],
    [isDisabled, style],
  )

  const textStyle = useMemo(
    () => [
      Styles.text,
      ifDef(Icon, Styles.textWithIcon),
      ifTrue(hasChevron, Styles.textWithChevron),
      ifTrue(isDisabled, Styles.textDisabled),
      ifTrue(!isDisabled && (isActive || isHover || isPressed), Styles.textHighlight),
    ],
    [isDisabled, isActive, isHover, isPressed],
  )

  const iconColor = isDisabled
    ? Theme.colors.light.borderColor
    : isActive || isHover || isPressed
    ? Theme.colors.light.color
    : Theme.colors.lightDim.color

  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handlePressIn = useCallback(() => setIsPressed(true), [setIsPressed])
  const handlePressOut = useCallback(() => setIsPressed(false), [setIsPressed])

  return (
    <Pressable
      {...handleMouse}
      disabled={isDisabled}
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      style={pressableStyle}>
      {Icon && <Icon color={iconColor} focusable={false} height={20} width={20} />}
      <RNText style={textStyle}>{children}</RNText>
      {hasChevron && (
        <ChevronDownIcon color={iconColor} focusable={false} height={20} style={Styles.chevronIcon} width={20} />
      )}
    </Pressable>
  )
}
