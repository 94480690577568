import { Theme } from '@src/logic/design'
import React, { memo, ReactNode, useMemo } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

export type IndentProps = {
  children?: ReactNode
  colorVariant: 'light' | 'dark'
  style?: ViewStyle
}

export const Indent = memo(IndentComponent)

const Styles = StyleSheet.create({
  view: {
    borderLeftWidth: 1,
    marginLeft: 12,
    paddingLeft: 12,
  },
  viewLight: {
    borderColor: Theme.colors.light.borderColor,
  },
  viewDark: {
    borderColor: Theme.colors.dark.borderColor,
  },
})

function IndentComponent({ children, colorVariant, style }: IndentProps) {
  const viewStyle = useMemo(
    () => [Styles.view, colorVariant === 'light' ? Styles.viewLight : Styles.viewDark, style],
    [colorVariant, style],
  )

  return <View style={viewStyle}>{children}</View>
}
