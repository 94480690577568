import { Card, Item, ItemLogoLabel, Text } from '@src/components/atoms'
import { Flows, Flows_Users, Organizations, Users } from '@src/gen/graphql/bindings'
import { useConfig } from '@src/logic/config'
import { getFlowOwner, getUserFullName } from '@src/logic/data/manipulation'
import { formatTimeSince } from '@src/logic/utils'
import { endsWith } from 'lodash'
import React, { memo, useMemo } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'

export type ExtFlowCardProps = {
  flow: Pick<
    Flows,
    | 'last_activity_at'
    | 'status'
    | 'action_items_activity_counter'
    | 'messages_activity_counter'
    | 'internal_participants_activity_counter'
    | 'external_participants_activity_counter'
  > & {
    organization: Pick<Organizations, 'id' | 'name'>
    status_activity_counter: number
    flows_users: Array<
      Pick<
        Flows_Users,
        | 'seen_action_items_activity_counter'
        | 'seen_messages_activity_counter'
        | 'seen_status_activity_counter'
        | 'seen_internal_participants_activity_counter'
        | 'seen_external_participants_activity_counter'
        | 'role'
      > & {
        user: Pick<Users, 'id' | 'first_name' | 'last_name'>
      }
    >
  }
  onPress?: () => void
  style?: ViewStyle
  user?: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

export const ExtFlowCard = memo(ExtFlowCardComponent)

const Styles = StyleSheet.create({
  title: {
    marginBottom: 8,
    marginTop: 8,
  },
  row: {
    paddingBottom: 16,
  },
})

function ExtFlowCardComponent({ flow, onPress, style, user }: ExtFlowCardProps) {
  const { getOrganizationProfilePictureUrl } = useConfig()
  const owner = useMemo(() => getFlowOwner(flow.flows_users), [flow])
  const avatar = useMemo(() => ({ user: owner }), [owner.id, owner.first_name, owner.last_name])
  const flowUser = flow.flows_users.find((fu) => fu.user.id === user?.id && !endsWith(fu.role, 'none'))

  const hasUnseen =
    flowUser &&
    (flowUser.seen_action_items_activity_counter < flow.action_items_activity_counter ||
      flowUser.seen_messages_activity_counter < flow.messages_activity_counter ||
      flowUser.seen_status_activity_counter < flow.status_activity_counter ||
      flowUser.seen_external_participants_activity_counter < flow.external_participants_activity_counter ||
      flowUser.seen_internal_participants_activity_counter < flow.internal_participants_activity_counter)

  return (
    <Card colorVariant='lightDim' onPress={onPress} style={style}>
      <ItemLogoLabel
        label={formatTimeSince(flow.last_activity_at)}
        logoAlt={flow.organization.name}
        logoUri={getOrganizationProfilePictureUrl(flow.organization.id)}
        showActivity={hasUnseen}
      />
      <Text colorVariant='light' numberOfLines={1} style={Styles.title} textVariant='title'>
        {flow.organization.name}
      </Text>
      <Item
        avatar={avatar}
        body={getUserFullName(owner)}
        colorVariant='light'
        label='Your point of contact'
        style={Styles.row}
      />
    </Card>
  )
}
