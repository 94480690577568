import { Form, SingleCircleBackgroundScreen, SubmitButton, Text, TextInputControl } from '@src/components/atoms'
import { CommonStyles, Theme, useScreen } from '@src/logic/design'
import { ifTrue } from '@src/logic/utils'
import React, { useMemo, useState } from 'react'
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { MailIcon } from 'react-native-heroicons/solid'

export type SignInProps = {
  issueEmailToken: (email: string) => Promise<void>
}

const Styles = StyleSheet.create({
  mainView: {
    flex: 1,
    width: '100%',
    backgroundColor: Theme.colors.dark.backgroundColor,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  mainViewMobile: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingHorizontal: 16,
  },
  welcomeContainerWeb: {
    width: 566,
  },
  formContainer: {
    maxWidth: 448,
  },
  formInner: {
    alignItems: 'flex-start',
  },
  fontLight: {
    fontFamily: 'Inter_300Light',
  },
  welcomeTitle: {
    lineHeight: 40 * 1.5,
  },
  welcomeTitleMobile: {
    fontSize: 32,
    lineHeight: 32 * 1.5,
  },
  submitButton: {
    marginLeft: 24,
    marginTop: 8,
  },
  submitButtonMobile: {
    position: 'absolute',
    right: 0,
    top: 60,
  },
  almostThereMainView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  almostThereContainer: {
    padding: 16,
    backgroundColor: Theme.colors.darkDim.backgroundColor,
  },
  gettingStartedContainer: {
    flex: 2,
  },
  gettingStartedTitle: {
    marginBottom: 48,
  },
  iconWrapperStyle: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
})

export function SignIn({ issueEmailToken }: SignInProps) {
  const [done, setDone] = useState(false)
  const { isPhone, isTablet, isDesktop } = useScreen()

  const handleSubmit = async (values: { email: string }) => {
    await issueEmailToken(values.email.trim())
    setDone(true)
  }

  const welcomeContainerStyle = useMemo(() => [ifTrue(isDesktop, Styles.welcomeContainerWeb)], [])

  const mainViewStyle = useMemo(
    () => [Styles.mainView, ifTrue(isPhone || isTablet, Styles.mainViewMobile)],
    [],
  ) as ViewStyle

  const welcomeTextStyle = useMemo(() => [Styles.welcomeTitle, ifTrue(isPhone, Styles.welcomeTitleMobile)], [])

  if (!done) {
    return (
      <SingleCircleBackgroundScreen style={mainViewStyle}>
        <View style={welcomeContainerStyle}>
          <View style={CommonStyles.flexRow}>
            <Text colorVariant='dark' textVariant='h1' style={[welcomeTextStyle, Styles.fontLight] as TextStyle}>
              Welcome to Krilo
            </Text>
            <Text colorVariant='primary' textVariant='h1'>
              .
            </Text>
          </View>
          <Text colorVariant='dark' textVariant='h3'>
            To get started, please
          </Text>
          <Text colorVariant='dark' textVariant='h3'>
            enter your email address.
          </Text>
        </View>
        <View style={[Styles.formContainer, ifTrue(isPhone, { width: '100%' })]}>
          <Form initialValues={{ email: '' }} onSubmit={handleSubmit}>
            <View style={[ifTrue(!isPhone, [CommonStyles.flexRow, Styles.formInner])]}>
              <View style={[ifTrue(isTablet, CommonStyles.flexOne)]}>
                <TextInputControl
                  colorVariant='dark'
                  placeholder='Enter your email address'
                  isRequired={true}
                  name='email'
                />
              </View>
              {isPhone ? (
                <Text colorVariant='lightDim' textVariant='smallText' style={{ maxWidth: '50%' }}>
                  By continuing, you agree that you have read and accept our Terms of Service and Privacy Policy.
                </Text>
              ) : null}
              <SubmitButton style={[Styles.submitButton, ifTrue(isPhone, Styles.submitButtonMobile)]}>
                Continue
              </SubmitButton>
            </View>
          </Form>
          {!isPhone ? (
            <Text colorVariant='lightDim' textVariant='smallText'>
              By continuing, you agree that you have read and accept our Terms of Service and Privacy Policy.
            </Text>
          ) : null}
        </View>
      </SingleCircleBackgroundScreen>
    )
  }

  return (
    <SingleCircleBackgroundScreen style={[mainViewStyle, Styles.almostThereMainView]}>
      <View style={[Styles.almostThereContainer, CommonStyles.flexRow]}>
        <View style={Styles.gettingStartedContainer}>
          <Text colorVariant='dark' textVariant='h3' style={Styles.gettingStartedTitle}>
            Getting started
          </Text>
          <Text colorVariant='dark' textVariant='h1' style={welcomeTextStyle as TextStyle}>
            Almost there!
          </Text>
          <Text colorVariant='dark' textVariant={isPhone ? 'smallText' : 'body'}>
            Please check your email and click on the confirmation link to proceed.
          </Text>
        </View>
        <View style={[CommonStyles.flexOne, Styles.iconWrapperStyle]}>
          <MailIcon color={Theme.colors.darkDim.borderColor} height={53.33} width={66.67} />
        </View>
      </View>
    </SingleCircleBackgroundScreen>
  )
}
