import { IconButton } from '@src/components/atoms/IconButton'
import { Text } from '@src/components/atoms/Text'
import { BackgroundStyles, CommonStyles, Theme } from '@src/logic/design'
import { ifTrue, ifWeb, ifWebOr, useRequiredContext } from '@src/logic/utils'
import React, { createContext, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { Platform, SafeAreaView, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { XIcon } from 'react-native-heroicons/solid'
import RNModal from 'react-native-modal'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export type ModalContextType = {
  onClose: () => void
}

export const ModalContext = createContext<ModalContextType | undefined>(undefined)
export const useModal = () => useRequiredContext(ModalContext)

export type ModalProps = {
  children?: ReactNode
  isOpen: boolean
  onClose: () => void
  variant?: 'bare' | 'modal' | 'select'
}

const Styles = StyleSheet.create({
  bareView: {
    margin: 0,
  },
  modalView: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderRadius: Theme.geometry.card.radius,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxHeight: ifWeb('100%'),
    width: ifWebOr(480, '100%'),
  },
  headerView: {
    alignItems: 'center',
    borderBottomColor: Theme.colors.lightDim.borderColor,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: Theme.geometry.card.spacing,
  },
  contentScrollViewContainer: {
    flexGrow: 1,
  },
  contentView: {
    paddingLeft: Theme.geometry.card.spacing,
    paddingRight: Theme.geometry.card.spacing,
    paddingTop: Theme.geometry.card.spacing,
  },
  footerViewControl: {
    alignItems: 'center',
    borderTopColor: Theme.colors.lightDim.borderColor,
    borderTopWidth: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: Theme.geometry.card.spacing,
  },
  footerViewCard: {
    alignItems: 'stretch',
    borderTopColor: Theme.colors.lightDim.borderColor,
    borderTopWidth: 1,
    padding: Theme.geometry.card.spacing,
  },
})

export function Modal({ children, isOpen, onClose, variant = 'modal' }: ModalProps) {
  const [, setRender] = useState({})
  const context = useMemo(() => ({ onClose }), [onClose])
  const safeAreaViewStyle = useMemo(() => [CommonStyles.flexOne, BackgroundStyles.dark], [])
  const screenSizeRef = useRef<{ height?: number; width?: number }>()
  const { top, bottom } = useSafeAreaInsets()

  const modalViewStyle = useMemo(
    () => [
      Styles.modalView,
      {
        // Note: it seems like the Modal library container overflows the screen by an amount equal to the inset.
        marginBottom: bottom * 2,
        marginTop: top * 2,
        maxWidth: variant === 'modal' ? '100%' : '90%',
      },
    ],
    [bottom, top, variant],
  )

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return
    }

    const handleResize = () => {
      screenSizeRef.current = { height: window.innerHeight, width: window.innerWidth }
      isOpen && setRender({})
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isOpen, screenSizeRef, setRender])

  return (
    <RNModal
      animationIn='fadeIn'
      animationOut='fadeOut'
      avoidKeyboard={true}
      backdropColor={Theme.colors.darkDim.backgroundColor}
      backdropOpacity={0.9}
      deviceHeight={screenSizeRef.current?.height}
      deviceWidth={screenSizeRef.current?.width}
      hasBackdrop={variant === 'modal' || variant === 'select'}
      isVisible={isOpen}
      onBackdropPress={onClose}
      style={ifTrue(variant === 'bare', Styles.bareView)}
      useNativeDriverForBackdrop={true}>
      <ModalContext.Provider value={context}>
        {variant === 'bare' && <SafeAreaView style={safeAreaViewStyle}>{children}</SafeAreaView>}
        {(variant === 'modal' || variant === 'select') && <View style={modalViewStyle}>{children}</View>}
      </ModalContext.Provider>
    </RNModal>
  )
}

export type ModalHeaderProps = {
  title: string
}

export function ModalHeader({ title }: ModalHeaderProps) {
  const { onClose } = useModal()

  return (
    <View style={Styles.headerView}>
      <Text colorVariant='light' textVariant='title'>
        {title}
      </Text>
      <IconButton colorVariant='light' Icon={XIcon} onPress={onClose} />
    </View>
  )
}

export type ModalContentProps = {
  children?: ReactNode
  style?: StyleProp<ViewStyle>
}

export function ModalContent({ children, style }: ModalContentProps) {
  const contentContainerStyle = useMemo(() => [Styles.contentScrollViewContainer, style], [style])

  return (
    <ScrollView contentContainerStyle={contentContainerStyle}>
      <View onStartShouldSetResponder={() => true} style={Styles.contentView}>
        {children}
      </View>
    </ScrollView>
  )
}

export type ModalFooterProps = {
  children?: ReactNode
  style?: StyleProp<ViewStyle>
  variant?: 'control' | 'card'
}

export function ModalFooter({ children, style, variant = 'control' }: ModalFooterProps) {
  const viewStyle = useMemo(
    () => [
      ifTrue(variant === 'control', Styles.footerViewControl),
      ifTrue(variant === 'card', Styles.footerViewCard),
      style,
    ],
    [style],
  )

  return <View style={viewStyle}>{children}</View>
}
