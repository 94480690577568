import { useConfig } from '@src/logic/config'
import { getUserFullName, getUserInitials } from '@src/logic/data/manipulation'
import { Theme } from '@src/logic/design'
import { Users } from '@src/gen/graphql/bindings'
import { ifDefThen } from '@src/logic/utils'
import React, { memo, useMemo } from 'react'
import { FlexStyle, Image, StyleSheet, Text, View } from 'react-native'

export type AvatarProps = {
  count?: number
  noCache?: boolean
  sizeVariant: 'sm' | 'md' | 'lg'
  style?: Omit<FlexStyle, 'overflow'>
  user?: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

export const Avatar = memo(AvatarComponent)

const Styles = StyleSheet.create({
  view: {
    alignItems: 'center',
    backgroundColor: Theme.colors.primary.backgroundColor,
    borderColor: Theme.colors.primary.borderColor,
    borderWidth: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  image: {
    backgroundColor: 'transparent',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
  },
  sm: {
    borderRadius: 16,
    height: 32,
    width: 32,
  },
  md: {
    borderRadius: 24,
    height: 48,
    width: 48,
  },
  lg: {
    borderRadius: 60,
    height: 120,
    width: 120,
  },
})

const ImageStyles = StyleSheet.create({
  sm: {
    borderRadius: 15,
    height: 30,
    width: 30,
  },
  md: {
    borderRadius: 22,
    height: 46,
    width: 46,
  },
  lg: {
    borderRadius: 59,
    height: 118,
    width: 118,
  },
})

const TextStyles = StyleSheet.create({
  sm: {
    color: Theme.colors.primary.color,
    fontFamily: 'Inter_600SemiBold',
    fontSize: 14,
    fontWeight: '600',
    includeFontPadding: false,
    lineHeight: 28,
    textAlignVertical: 'center',
  },
  md: {
    color: Theme.colors.primary.color,
    fontFamily: 'Inter_600SemiBold',
    fontSize: 20,
    fontWeight: '600',
    includeFontPadding: false,
    lineHeight: 44,
    textAlignVertical: 'center',
  },
  lg: {
    color: Theme.colors.primary.color,
    fontFamily: 'Inter_600SemiBold',
    fontSize: 50,
    fontWeight: '600',
    includeFontPadding: false,
    lineHeight: 116,
    textAlignVertical: 'center',
  },
})

function AvatarComponent({ count, noCache, sizeVariant, style, user }: AvatarProps) {
  const { getUserProfilePictureUrl } = useConfig()

  const source = useMemo(
    () => ifDefThen(user?.id, (id) => ({ uri: getUserProfilePictureUrl(id, noCache) })),
    [user?.id, getUserProfilePictureUrl],
  )

  const viewStyle = useMemo(() => [Styles.view, Styles[sizeVariant], style], [sizeVariant, style])
  const imageStyle = useMemo(() => [Styles.image, ImageStyles[sizeVariant]], [sizeVariant])

  return (
    <View style={viewStyle}>
      {user && source && (
        <Image accessibilityLabel={getUserFullName(user)} resizeMode='contain' source={source} style={imageStyle} />
      )}
      <Text style={TextStyles[sizeVariant]}>{user ? getUserInitials(user) : `+${count}`}</Text>
    </View>
  )
}
