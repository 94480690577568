import { useRegisterFlowUserViewAction } from '@src/components/actions/FlowUserViewAction'
import {
  ExtFlowDocument,
  ExtFlowSubscription,
  ExtFlowSubscriptionVariables,
  Flow_Roles_Enum,
  Flow_Statuses_Enum,
  useExtMessageCreateMutation,
  useExtUpdateFlowsUsersSeenMutation,
} from '@src/gen/graphql/bindings'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { processError } from '@src/logic/errors'
import { useSubscription } from '@src/logic/graphql'
import { useRequiredContext } from '@src/logic/utils'
import { ifTrue } from '@src/logic/utils/misc'
import React, { createContext, ReactNode } from 'react'
import useAsyncEffect from 'use-async-effect'

const MESSAGES_LIMIT = 50

export type ExtFlowContextType = ReturnType<typeof useContext>
export const ExtFlowContext = createContext<ExtFlowContextType | undefined>(undefined)
export const useExtFlow = () => useRequiredContext(ExtFlowContext)

export type ExtFlowProviderProps = {
  children?: ReactNode
  flowId: string
}

export function ExtFlowProvider({ children, flowId }: ExtFlowProviderProps) {
  const { data, error, loading } = useSubscription<ExtFlowSubscription, ExtFlowSubscriptionVariables>(ExtFlowDocument, {
    flowId: flowId,
    messagesLimit: MESSAGES_LIMIT,
  })

  if (error) {
    throw error
  }

  if (loading && data === undefined) {
    return null
  }

  if (data === undefined) {
    throw new Error('No data.')
  }

  return <ExtFlowProviderInner data={data}>{children}</ExtFlowProviderInner>
}

type ExtFlowProviderInnerProps = {
  children?: ReactNode
  data: ExtFlowSubscription
}

function ExtFlowProviderInner({ children, data }: ExtFlowProviderInnerProps) {
  return <ExtFlowContext.Provider value={useContext(data)}>{children}</ExtFlowContext.Provider>
}

function useContext(data: ExtFlowSubscription) {
  const { user } = useAuthenticatedAuth()

  const [messageCreateMutation] = useExtMessageCreateMutation()
  const [updateFlowsUsersSeenMutation] = useExtUpdateFlowsUsersSeenMutation()

  const flow = data.flows_by_pk
  if (!flow) {
    throw new Error('Not found.')
  }

  const isEditable = flow.status !== Flow_Statuses_Enum.Closed
  const flowUser = flow.flows_users.find(
    (fu) => fu.user.id === user.id && fu.role === Flow_Roles_Enum.ExternalParticipant,
  )

  const hasUnseenActionItems =
    flowUser && flow.action_items_activity_counter > flowUser.seen_action_items_activity_counter
  const hasUnseenMessages = flowUser && flow.messages_activity_counter > flowUser.seen_messages_activity_counter
  const hasUnseenStatus = flowUser && flow.status_activity_counter > flowUser.seen_status_activity_counter
  const hasUnseenFields = flowUser && flow.fields_activity_counter > flowUser.seen_fields_activity_counter
  const hasUnseenInternalParticipants =
    flowUser && flow.internal_participants_activity_counter > flowUser.seen_internal_participants_activity_counter
  const hasUnseenExternalParticipants =
    flowUser && flow.external_participants_activity_counter > flowUser.seen_external_participants_activity_counter

  let seenActionItemsUpdated = false
  let seenMessagesUpdated = false
  let seenStatusUpdated = false
  let seenFieldsUpdated = false
  let seenInternalParticipantsUpdated = false
  let seenExternalParticipantsUpdated = false

  const useSeen = (sections: {
    actionItems?: boolean
    messages?: boolean
    status?: boolean
    fields?: boolean
    internalParticipants?: boolean
    externalParticipants?: boolean
  }) => {
    useAsyncEffect(async () => {
      try {
        if (!flowUser) {
          return
        }

        if (
          (!sections.actionItems || seenActionItemsUpdated || !hasUnseenActionItems) &&
          (!sections.messages || seenMessagesUpdated || !hasUnseenMessages) &&
          (!sections.status || seenStatusUpdated || !hasUnseenStatus) &&
          (!sections.fields || seenFieldsUpdated || !hasUnseenFields) &&
          (!sections.internalParticipants || seenInternalParticipantsUpdated || !hasUnseenInternalParticipants) &&
          (!sections.externalParticipants || seenExternalParticipantsUpdated || !hasUnseenExternalParticipants)
        ) {
          return
        }
      } finally {
        seenActionItemsUpdated = seenActionItemsUpdated || (sections.actionItems ?? false)
        seenMessagesUpdated = seenMessagesUpdated || (sections.messages ?? false)
        seenStatusUpdated = seenStatusUpdated || (sections.status ?? false)
        seenFieldsUpdated = seenFieldsUpdated || (sections.fields ?? false)
        seenInternalParticipantsUpdated = seenInternalParticipantsUpdated || (sections.internalParticipants ?? false)
        seenExternalParticipantsUpdated = seenExternalParticipantsUpdated || (sections.externalParticipants ?? false)
      }

      try {
        await updateFlowsUsersSeenMutation({
          variables: {
            flowId: flow.id,
            seenActionItemsActivityCounter: sections.actionItems ? flow.action_items_activity_counter : 0,
            seenMessagesActivityCounter: sections.messages ? flow.messages_activity_counter : 0,
            seenStatusActivityCounter: sections.status ? flow.status_activity_counter : 0,
            seenFieldsActivityCounter: sections.fields ? flow.fields_activity_counter : 0,
            seenInternalParticipantsActivityCounter: sections.internalParticipants
              ? flow.internal_participants_activity_counter
              : 0,
            seenExternalParticipantsActivityCounter: sections.externalParticipants
              ? flow.external_participants_activity_counter
              : 0,
          },
        })
      } catch (err: any) {
        processError(err)
      }
    })
  }

  const messageCreate = async (chatContent: string) => {
    await messageCreateMutation({
      variables: {
        flowId: flow.id,
        chatContent,
      },
    })
  }

  const dispatchFlowUserViewAction = useRegisterFlowUserViewAction()

  return {
    flow,
    flowUser,
    isEditable,
    messageCreate: ifTrue(isEditable, messageCreate),
    hasUnseen: {
      actionItems: hasUnseenActionItems,
      messages: hasUnseenMessages,
      status: hasUnseenStatus,
      fields: hasUnseenFields,
      internalParticipants: hasUnseenInternalParticipants,
      externalParticipants: hasUnseenExternalParticipants,
    },
    useSeen,
    dispatchFlowUserViewAction,
  }
}
