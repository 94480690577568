import React from 'react'
import { useField } from 'react-final-form'

export type ConditionalControlProps<T> = {
  subscription: string
  predicate: (value: T) => boolean
  render: (value: T) => JSX.Element
}

export function ConditionalControl<T>({ subscription, predicate, render }: ConditionalControlProps<T>) {
  // TODO(ibrt): Handle undefined value when predicate is false predicate transitions.

  const {
    input: { value },
  } = useField<T>(subscription, { subscription: { value: true } })

  return predicate(value) ? render(value) : null
}
