import { ViewButton, ViewSelect } from '@src/components/atoms'
import { useOrgFlowsFilters } from '@src/logic/data/providers'
import { CommonStyles, Theme, useScreen } from '@src/logic/design'
import { RouteIds } from '@src/logic/routing/common'
import { useOrgRouting } from '@src/logic/routing/providers'
import { ifTrue } from '@src/logic/utils'
import React, { Fragment, useCallback, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { ViewBoardsIcon, ViewListIcon } from 'react-native-heroicons/solid'

const Styles = StyleSheet.create({
  view: {
    ...Theme.shadows.tabs,
    backgroundColor: Theme.colors.light.backgroundColor,
    zIndex: 1,
  },
  viewInner: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 64,
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
    width: '100%',
  },
  viewInnerLarge: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 960,
  },
  headerSpacer: {
    width: Theme.geometry.structure.spacing,
  },
})

type OrgFlowsHeaderPartProps = {
  centeredVariant: 'large' | 'full'
}

export function OrgFlowsHeaderPart({ centeredVariant }: OrgFlowsHeaderPartProps) {
  const { flowFilterOwner, flowFilterStatusType } = useOrgFlowsFilters()
  const { useFlowsTabs } = useOrgRouting()
  const { isPhone } = useScreen()
  const flowsTabs = useFlowsTabs()

  const handleListPress = useCallback(() => flowsTabs.navigateTo(RouteIds.ORG_FLOWS_LIST), [flowsTabs])
  const handleBoardPress = useCallback(() => flowsTabs.navigateTo(RouteIds.ORG_FLOWS_BOARD), [flowsTabs])

  const viewStyle = useMemo(
    () => [Styles.viewInner, ifTrue(centeredVariant === 'large', Styles.viewInnerLarge)],
    [centeredVariant],
  )

  return (
    <View style={Styles.view}>
      <View style={viewStyle}>
        <ViewButton
          Icon={ViewListIcon}
          isActive={flowsTabs.activeTab?.id === RouteIds.ORG_FLOWS_LIST}
          onPress={handleListPress}>
          List View
        </ViewButton>
        <View style={Styles.headerSpacer} />
        <ViewButton
          Icon={ViewBoardsIcon}
          isActive={flowsTabs.activeTab?.id === RouteIds.ORG_FLOWS_BOARD}
          onPress={handleBoardPress}>
          Board View
        </ViewButton>
        {!isPhone && (
          <Fragment>
            <View style={CommonStyles.flexOne} />
            <ViewSelect {...flowFilterOwner} />
            <View style={Styles.headerSpacer} />
            <ViewSelect {...flowFilterStatusType} />
          </Fragment>
        )}
      </View>
    </View>
  )
}

//style={ifTrue(centeredVariant === 'large', CommonStyles.marginBottomStructure)}>
