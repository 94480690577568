import {
  ConditionalControl,
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectControl,
  SubmitButton,
  TextInputControl,
} from '@src/components/atoms'
import {
  Action_Item_Types_Enum,
  OrgActionItemConfigurationInsertMutationVariables,
  Organization_Roles_Enum,
  OrgConfigDocument,
  useOrgActionItemConfigurationInsertMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { getActionItemTypePreOptionsOrGroups, initSelect, SelectOptionType } from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers'
import { ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { PlusSmIcon } from 'react-native-heroicons/solid'

export const ACTION_ITEM_CONFIGURATION_CREATE_ACTION_ID = 'actionItemConfigurationCreate'
export const ACTION_ITEM_CONFIGURATION_CREATE_ACTION_LABEL = 'Create Action Item Template'

export type ActionItemConfigurationCreateActionValues = Omit<OrgActionItemConfigurationInsertMutationVariables, 'orgId'>

export type ActionItemConfigurationCreateActionProps = {
  onSubmit: (values: ActionItemConfigurationCreateActionValues) => Promise<void>
}

type FormType = {
  input: string
  name: string
  promptCta?: string
  type: SelectOptionType<Action_Item_Types_Enum>
}

export function ActionItemConfigurationCreateAction({ onSubmit }: ActionItemConfigurationCreateActionProps) {
  const { defaultOption: typeDefaultOption, optionsOrGroups: typeOptionsOrGroups } = useMemo(
    () => initSelect((value) => value, getActionItemTypePreOptionsOrGroups()),
    [],
  )

  const initialValues = useMemo<FormType>(
    () => ({
      input: '',
      name: '',
      type: typeDefaultOption,
    }),
    [typeDefaultOption],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        ...values,
        type: values.type.value,
      })
    },
    [onSubmit],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={ACTION_ITEM_CONFIGURATION_CREATE_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl isRequired={true} label='Name' name='name' placeholder='e.g. Upload Purchase Order...' />
        <SelectControl isRequired={true} label='Type' name='type' optionsOrGroups={typeOptionsOrGroups} />
        <TextInputControl isRequired={true} label='Prompt' name='input' />
        <ConditionalControl<SelectOptionType<Action_Item_Types_Enum>>
          subscription='type'
          predicate={(value) => value.value === Action_Item_Types_Enum.Prompt}
          render={() => <TextInputControl isRequired={true} label='Action' name='promptCta' />}
        />
      </ModalContent>
      <ModalFooter>
        <SubmitButton>Create</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterActionItemConfigurationCreateAction() {
  const [doActionItemConfigurationInsert] = useOrgActionItemConfigurationInsertMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org } = useOrg()

  const handleSubmit = useCallback(
    async (values: ActionItemConfigurationCreateActionValues) => {
      await doActionItemConfigurationInsert({
        variables: {
          ...values,
          orgId: org.id,
        },
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doActionItemConfigurationInsert, dismissAllActions, org.id],
  )

  useRegisterAction<undefined>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: ACTION_ITEM_CONFIGURATION_CREATE_ACTION_ID,
      menuItem: {
        Icon: PlusSmIcon,
        label: ACTION_ITEM_CONFIGURATION_CREATE_ACTION_LABEL,
        params: undefined,
      },
      modal: () => <ActionItemConfigurationCreateAction onSubmit={handleSubmit} />,
    }),
    [handleSubmit, org.role],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, () =>
        dispatchAction<undefined>({
          id: ACTION_ITEM_CONFIGURATION_CREATE_ACTION_ID,
          params: undefined,
        }),
      ),
    [dispatchAction, org.role],
  )
}
