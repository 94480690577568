import { Text } from '@src/components/atoms/Text'
import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

export type ControlProps = {
  children?: ReactNode
  error?: string
  isDisabled?: boolean
  isRequired?: boolean
  label?: string
}

export type ControlContextType = {
  isDisabled: boolean
  isInvalid: boolean
}

const ControlContext = createContext<ControlContextType | undefined>(undefined)
export const useControl = () => useContext(ControlContext)

export function useResolvedControl({ isDisabled, isInvalid }: Partial<ControlContextType>) {
  const context = useControl()

  return {
    finalIsDisabled: isDisabled ?? context?.isDisabled ?? false,
    finalIsInvalid: isInvalid ?? context?.isInvalid ?? false,
  }
}

const Styles = StyleSheet.create({
  view: {
    alignItems: 'stretch',
    paddingBottom: 16,
  },
  labelView: {
    flexDirection: 'row',
    paddingBottom: 8,
  },
  errorText: {
    paddingTop: 8,
  },
})

export function Control({ children, error, isDisabled, isRequired, label }: ControlProps) {
  const context = useMemo(
    () => ({
      isDisabled: isDisabled ?? false,
      isInvalid: !!error,
    }),
    [error, isDisabled, isRequired],
  )

  return (
    <ControlContext.Provider value={context}>
      <View style={Styles.view}>
        <View style={Styles.labelView}>
          {label ? (
            <>
              <Text colorVariant='lightDim' textVariant='smallText'>
                {label}
              </Text>
              {isRequired && (
                <Text colorVariant='danger' textVariant='smallText'>
                  {' *'}
                </Text>
              )}
            </>
          ) : null}
        </View>
        {children}
        {error && (
          <Text colorVariant='danger' style={Styles.errorText} textVariant='smallText'>
            {error}
          </Text>
        )}
      </View>
    </ControlContext.Provider>
  )
}
