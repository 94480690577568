import * as React from 'react'
import { memo } from 'react'
import Svg, { Path } from 'react-native-svg'
import { Theme } from '@src/logic/design'

export type LogoProps = {
  colorVariant: 'light' | 'dark'
  height?: number
  width?: number
}

export const Logo = memo(LogoComponent)

function LogoComponent({ colorVariant, height, width }: LogoProps) {
  return (
    <Svg fill='none' height={height} title='Krilo - Powered by Hope.Tech' viewBox='0 0 117 10' width={width}>
      <Path
        d='M70.08 2.028h-5.007V0h12.115v2.028h-4.992V10H70.08V2.03ZM91.688 1.6c-.002-.218.04-.434.123-.635a1.544 1.544 0 0 1 .841-.843c.197-.082.407-.124.62-.122h9.626v2.028h-9.08v5.943h9.08v2.028h-9.555a1.636 1.636 0 0 1-.648-.128 1.724 1.724 0 0 1-.525-.343 1.604 1.604 0 0 1-.352-.5 1.437 1.437 0 0 1-.13-.6V1.6ZM104.204 0h2.115v3.871h7.67V0h2.115v10h-2.115V5.9h-7.67V10h-2.115V0ZM90.403.012h-11.97V2.03h11.97V.012ZM90.403 3.948h-11.97v2.017h11.97V3.948ZM90.403 7.982h-11.97v2.017h11.97V7.982Z'
        fill={Theme.colors[colorVariant].color}
      />
      <Path
        d='M90.403.012h-11.97V2.03h11.97V.012ZM90.403 3.948h-11.97v2.017h11.97V3.948ZM90.403 7.982h-11.97v2.017h11.97V7.982ZM0 .013h2.09v3.866H9.67V.013h2.09V10h-2.09V5.905H2.09V10H0V.013Zm22.962 7.96V2.04h-7.805v5.935h7.805Zm-9.896-6.362c-.002-.218.04-.434.121-.635a1.544 1.544 0 0 1 1.444-.963h8.786c.22-.002.438.042.64.128.193.082.368.198.519.343.147.143.265.312.348.5.085.188.129.392.128.599v6.82a1.601 1.601 0 0 1-.469 1.155 1.466 1.466 0 0 1-.498.328c-.19.076-.393.115-.597.114h-8.786a1.602 1.602 0 0 1-.64-.128 1.7 1.7 0 0 1-.52-.343 1.605 1.605 0 0 1-.348-.5 1.45 1.45 0 0 1-.128-.599V1.61Zm13.373 0a1.645 1.645 0 0 1 .12-.635 1.544 1.544 0 0 1 1.443-.963h8.859c.217-.002.433.041.632.128.189.082.36.198.505.343a1.563 1.563 0 0 1 .455 1.099v4.796c.002.218-.039.433-.12.635a1.544 1.544 0 0 1-1.43.963H28.53V10h-2.09V1.61Zm9.924 4.34V2.039h-7.834v3.912h7.834ZM51.655 0h-11.97v2.017h11.97V0ZM51.655 3.93h-11.97v2.017h11.97V3.93ZM51.655 7.958h-11.97v2.017h11.97V7.958Z'
        fill={Theme.colors[colorVariant].color}
      />
      <Path d='M58.824 10a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z' fill='#4DA6D6' />
    </Svg>
  )
}
