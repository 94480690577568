import {
  Item,
  Pane,
  PaneButton,
  PaneStyles,
  ScreenContent,
  ScreenPrimary,
  screenPrimaryIdKeyExtractor,
  ScreenPrimaryItemSeparator,
  ScreenPrimarySectionSeparator,
  ScreenPrimaryStyles,
  ScreenSecondary,
  Text,
  TextInput,
  UserProfile,
} from '@src/components/atoms'
import { OrgFlowCard } from '@src/components/molecules'
import { OrgContactFlowsQuery, OrgContactsQuery } from '@src/gen/graphql'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { getUserFullName } from '@src/logic/data/manipulation'
import { useOrgContact, useOrgContacts } from '@src/logic/data/providers'
import { CommonStyles, Theme, useScreen } from '@src/logic/design'
import { NavigateFuncContact, NavigateFuncFlow } from '@src/logic/routing/common'
import { useOrgRouting } from '@src/logic/routing/providers'
import { ArrayElement, useRequiredDebounced } from '@src/logic/utils'
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { SearchIcon } from 'react-native-heroicons/solid'

export type OrgContactsPartProps = {
  contactId?: string
  view: 'list' | 'detail'
}

const Styles = StyleSheet.create({
  searchView: {
    paddingBottom: 8,
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
    paddingTop: 8,
  },
})

export function OrgContactsPart({ contactId, view }: OrgContactsPartProps) {
  const { isPhone } = useScreen()

  return (
    <ScreenContent>
      {(!isPhone || view === 'list') && <OrgContactsListPart contactId={contactId} />}
      {(!isPhone || view === 'detail') && (contactId ? <OrgContactsDetailPart /> : <ScreenPrimary />)}
    </ScreenContent>
  )
}

export type OrgContactsListPartProps = {
  contactId?: string
}

function OrgContactsListPart({ contactId }: OrgContactsListPartProps) {
  const orgContacts = useOrgContacts()
  const { useNavigateToContact } = useOrgRouting()

  const header = useMemo(() => ({ label: 'Contacts' }), [])
  const navigateToContact = useNavigateToContact()

  const renderItem = useCallback(
    ({ item }) => (
      <OrgContactsListPartItem contact={item} isActive={contactId === item.id} navigateToContact={navigateToContact} />
    ),
    [navigateToContact, contactId],
  )

  return (
    <ScreenSecondary isScrollable={false}>
      <Pane header={header} headerChildren={<OrgContactsListPartSearch />} scrollableBehavior='delegated'>
        <FlatList
          data={orgContacts.contacts}
          contentContainerStyle={PaneStyles.contentView}
          style={CommonStyles.flexOne}
          keyExtractor={screenPrimaryIdKeyExtractor}
          onEndReached={orgContacts.fetchMoreContacts}
          onEndReachedThreshold={0.2}
          renderItem={renderItem}
        />
      </Pane>
    </ScreenSecondary>
  )
}

function OrgContactsListPartSearch() {
  const { nameQuery, setNameQuery } = useOrgContacts()
  const [displayValue, setDisplayValue] = useState(nameQuery)
  const debouncedSetNameQuery = useRequiredDebounced(setNameQuery, 'trailing')

  const handleChange = useCallback(
    (value: string) => {
      setDisplayValue(value)
      debouncedSetNameQuery(value)
    },
    [debouncedSetNameQuery, setDisplayValue],
  )

  useEffect(() => {
    if (nameQuery !== displayValue) {
      setDisplayValue(nameQuery)
    }
  }, [nameQuery])

  return (
    <View style={Styles.searchView}>
      <TextInput
        colorVariant='dark'
        Icon={SearchIcon}
        onChange={handleChange}
        placeholder='Search...'
        value={displayValue}
      />
    </View>
  )
}

type OrgContactsListPartItemProps = {
  contact: ArrayElement<OrgContactsQuery['contacts']>
  isActive: boolean
  navigateToContact: NavigateFuncContact
}

function OrgContactsListPartItem({ contact, isActive, navigateToContact }: OrgContactsListPartItemProps) {
  const handlePress = useCallback(() => navigateToContact(contact.id), [contact.id, navigateToContact])

  return (
    <PaneButton isActive={isActive} onPress={handlePress}>
      <Item
        avatar={{ user: contact.user }}
        badge={contact.user.is_verified ? undefined : { label: 'Unverified', colorVariant: 'warning' }}
        body={getUserFullName(contact.user)}
        colorVariant='dark'
      />
    </PaneButton>
  )
}

function OrgContactsDetailPart() {
  const { contact, recentFlows } = useOrgContact()
  const { useNavigateToFlow } = useOrgRouting()
  const navigateToFlow = useNavigateToFlow()

  const ListHeaderComponent = useCallback(
    () => (
      <Fragment>
        <ScreenPrimarySectionSeparator />
        <UserProfile colorVariant='light' user={contact.user} style={CommonStyles.marginBottomStructure} />
        <Text colorVariant='lightDim' style={CommonStyles.marginBottomStructure} textVariant='eyebrow'>
          Recent Flows
        </Text>
      </Fragment>
    ),
    [contact],
  )

  const renderItem = useCallback(
    ({ item }) => <OrgContactsDetailPartItem flow={item} navigateToFlow={navigateToFlow} />,
    [navigateToFlow],
  )

  return (
    <ScreenPrimary>
      <FlatList
        ItemSeparatorComponent={ScreenPrimaryItemSeparator}
        ListFooterComponent={ScreenPrimarySectionSeparator}
        ListHeaderComponent={ListHeaderComponent}
        contentContainerStyle={ScreenPrimaryStyles.containerViewCenteredMedium}
        data={recentFlows}
        keyExtractor={screenPrimaryIdKeyExtractor}
        renderItem={renderItem}
        style={CommonStyles.flexOne}
      />
    </ScreenPrimary>
  )
}

type OrgContactsDetailPartItemProps = {
  flow: ArrayElement<OrgContactFlowsQuery['flows']>
  navigateToFlow: NavigateFuncFlow
}

function OrgContactsDetailPartItem({ flow, navigateToFlow }: OrgContactsDetailPartItemProps) {
  const { user } = useAuthenticatedAuth()
  const handlePress = useCallback(() => navigateToFlow(flow.id), [flow.id, navigateToFlow])
  return <OrgFlowCard flow={flow} onPress={handlePress} user={user} />
}
