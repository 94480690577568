import { FullScreen } from '@src/components/atoms/FullScreen'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { SvgCircle } from '@src/components/atoms/SvgCircle'
import { Theme } from '@src/logic/design'
import React, { ReactNode } from 'react'

type SingleCircleBackgroundScreenLayoutProps = {
  children?: ReactNode
  style?: ViewStyle | ViewStyle[]
}

const Styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: Theme.colors.dark.backgroundColor,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export const SingleCircleBackgroundScreen = ({ children, style }: SingleCircleBackgroundScreenLayoutProps) => {
  return (
    <FullScreen>
      <View style={style ? style : Styles.container}>{children}</View>
      <SvgCircle
        fillColor={Theme.colors.primary.lighterColor!}
        circumferenceToHeightPercentage={{
          phone: 0.44,
          tablet: 0.59,
          desktop: 0.59,
        }}
        percentageHiddenXAxis={{
          phone: 0.5,
          tablet: 0.38,
          desktop: 0.21,
        }}
        percentageHiddenYAxis={{
          phone: 0.5,
          tablet: 0.4,
          desktop: 0.4,
        }}
        absolutePositionStyle={{
          bottom: 0,
          right: 0,
        }}
      />
    </FullScreen>
  )
}
