import { Avatar } from '@src/components/atoms/Avatar'
import { Text } from '@src/components/atoms/Text'
import { getUserFullName } from '@src/logic/data/manipulation'
import { dim } from '@src/logic/design'
import { Maybe, Users } from '@src/gen/graphql/bindings'
import React, { memo, useMemo } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

export type UserProfileProps = {
  colorVariant: 'light' | 'dark'
  user: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    email?: Maybe<string>
  }
  style?: ViewStyle
}

export const UserProfile = memo(UserProfileComponent)

const Styles = StyleSheet.create({
  view: {
    alignItems: 'center',
  },
  avatar: {
    marginBottom: 16,
  },
  title: {
    marginBottom: 4,
  },
})

function UserProfileComponent({ colorVariant, user, style }: UserProfileProps) {
  const viewStyle = useMemo(() => [Styles.view, style], [style])

  return (
    <View style={viewStyle}>
      <Avatar noCache={true} sizeVariant='lg' style={Styles.avatar} user={user} />
      <Text colorVariant={colorVariant} style={Styles.title} textVariant='title'>
        {getUserFullName(user)}
      </Text>
      {user.email && (
        <Text colorVariant={dim(colorVariant)} textVariant='body'>
          {user.email}
        </Text>
      )}
    </View>
  )
}
