import {
  ACTION_ITEM_ACTION_ID,
  ActionItemAction,
  ActionItemActionProps,
  ActionItemActionValuesType,
} from '@src/components/actions'
import {
  Attachment_Types_Enum,
  Organization_Roles_Enum,
  OrgConfigQuery,
  useOrgActionItemCancelMutation,
  useOrgActionItemCompleteMutation,
  useOrgConfigQuery,
} from '@src/gen/graphql/bindings'
import { useActions } from '@src/logic/actions'
import { OrganizationType, useAuthenticatedAuth } from '@src/logic/auth'
import { ifTrue, useRequiredContext } from '@src/logic/utils'
import React, { createContext, Fragment, ReactNode, useCallback } from 'react'

export type OrgContextType = {
  org: OrganizationType
  orgConfig: OrgConfigQuery
}

export const OrgContext = createContext<OrgContextType | undefined>(undefined)
export const useOrg = () => useRequiredContext(OrgContext)

export type OrgProviderProps = {
  children?: ReactNode
  orgId: string
}

export function OrgProvider({ children, orgId }: OrgProviderProps) {
  const { user } = useAuthenticatedAuth()

  const org = user.organizations.find((org) => org.id === orgId)
  if (!org) {
    throw new Error('Organization not found.')
  }

  const { data, error, loading, previousData } = useOrgConfigQuery({
    variables: {
      orgId: orgId,
    },
  })

  if (error) {
    throw error
  }

  if (loading && data === undefined && previousData === undefined) {
    return null
  }

  const finalData = data ?? previousData
  if (finalData === undefined) {
    throw new Error('No data.')
  }

  return (
    <OrgContext.Provider value={{ org, orgConfig: finalData }}>
      <OrgProviderActions>{children}</OrgProviderActions>
    </OrgContext.Provider>
  )
}

type OrgProviderActionsProps = {
  children?: ReactNode
}

function OrgProviderActions({ children }: OrgProviderActionsProps) {
  useRegisterOrgActionItemCompleteAction()

  return <Fragment>{children}</Fragment>
}

function useRegisterOrgActionItemCompleteAction() {
  const [actionItemCompleteMutation] = useOrgActionItemCompleteMutation()
  const [cancelActionItemMutation] = useOrgActionItemCancelMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { user, useUploader } = useAuthenticatedAuth()
  const { org } = useOrg()
  const uploader = useUploader(Attachment_Types_Enum.SecureFile)

  const handleSubmit = useCallback(
    async (values: ActionItemActionValuesType) => {
      await actionItemCompleteMutation({
        variables: values,
      })
      dismissAllActions()
    },
    [actionItemCompleteMutation, dismissAllActions],
  )

  const handleCancel = useCallback(async (actionItemId: string, canceledByUserId: string) => {
    await cancelActionItemMutation({
      variables: {
        actionItemId,
        canceledByUserId,
      },
    })
    dismissAllActions()
  }, [])

  useRegisterAction(
    {
      id: ACTION_ITEM_ACTION_ID,
      modal: ({ actionItem }: Pick<ActionItemActionProps, 'actionItem'>) => (
        <ActionItemAction
          actionItem={actionItem}
          onDelete={ifTrue(
            (org.role === Organization_Roles_Enum.Admin || org.role === Organization_Roles_Enum.Editor) &&
              !actionItem.completed_at,
            handleCancel,
          )}
          onSubmit={handleSubmit}
          uploader={uploader}
          user={user}
        />
      ),
    },
    [dispatchAction, handleSubmit, org.role, uploader, user],
  )
}
