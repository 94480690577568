import {
  screenPrimaryIdKeyExtractor,
  ScreenPrimaryItemSeparator,
  ScreenPrimarySectionSeparator,
  ScreenPrimaryStyles,
  Text,
} from '@src/components/atoms'
import { Action_Items, Maybe, Messages, Users } from '@src/gen/graphql/bindings'
import { CommonStyles, Theme } from '@src/logic/design'
import { absoluteDifferenceInMinutes, ArrayElement, formatTimeSince } from '@src/logic/utils'
import React, { memo, useCallback } from 'react'
import { FlatList, Platform, ScrollView, StyleSheet, View } from 'react-native'
import { ChatRow } from './ChatRow'

const TIME_MARKER_THRESHOLD_MINUTES = 60

export type FlowChatThreadProps = {
  messages: Array<
    Pick<Messages, 'chat_content' | 'created_at' | 'id' | 'system_activity' | 'type'> & {
      creator: Pick<Users, 'id' | 'first_name' | 'last_name'>
      action_item?: Maybe<
        Pick<Action_Items, 'id' | 'completed_at' | 'input' | 'type'> & {
          assignee: Pick<Users, 'id' | 'first_name' | 'last_name'>
          creator: Pick<Users, 'id' | 'first_name' | 'last_name'>
          completer?: Maybe<Pick<Users, 'id' | 'first_name' | 'last_name'>>
        }
      >
    }
  >
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

const Styles = StyleSheet.create({
  webScrollView: {
    flex: 1,
    flexDirection: 'column-reverse',
  },
  webScrollViewContentContainer: {
    paddingBottom: Theme.geometry.structure.spacing / 2,
    paddingTop: Theme.geometry.structure.spacing / 2,
  },
  webRowView: {
    flexDirection: 'column-reverse',
  },
  itemText: {
    alignSelf: 'center',
    paddingTop: Theme.geometry.structure.spacing / 2,
    paddingBottom: Theme.geometry.structure.spacing,
  },
})

export function FlowChat({ messages, user }: FlowChatThreadProps) {
  // Note: On web the implementation of "inverted" FlatList is broken, so we fall back to a ScrollView.
  if (Platform.OS === 'web') {
    return (
      <ScrollView contentContainerStyle={Styles.webScrollViewContentContainer} style={Styles.webScrollView}>
        <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
          <View style={Styles.webRowView}>
            {messages.map((message, index) => (
              <FlowChatItem index={index} key={message.id} message={message} messages={messages} user={user} />
            ))}
          </View>
        </View>
      </ScrollView>
    )
  }

  const renderItem = useCallback(
    ({ item, index }) => <FlowChatItem index={index} message={item} messages={messages} user={user} />,
    [messages, user],
  )

  return (
    <FlatList
      ListFooterComponent={ScreenPrimarySectionSeparator}
      ListHeaderComponent={ScreenPrimaryItemSeparator}
      data={messages}
      inverted={true}
      keyExtractor={screenPrimaryIdKeyExtractor}
      renderItem={renderItem}
      style={CommonStyles.flexOne}
    />
  )
}

type FlowChatItemProps = {
  index: number
  message: ArrayElement<FlowChatThreadProps['messages']>
  messages: FlowChatThreadProps['messages']
  user: FlowChatThreadProps['user']
}

const FlowChatItem = memo(FlowChatItemComponent)

function FlowChatItemComponent({ index, message, messages, user }: FlowChatItemProps) {
  const insertChatTimeMarker =
    index > 0 &&
    absoluteDifferenceInMinutes(messages[index - 1].created_at, message.created_at) > TIME_MARKER_THRESHOLD_MINUTES

  return (
    <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
      {insertChatTimeMarker && (
        <Text colorVariant='lightDim' style={Styles.itemText} textVariant='body'>
          {formatTimeSince(message.created_at)}
        </Text>
      )}
      <ChatRow message={message} user={user} />
    </View>
  )
}
