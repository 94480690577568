import React, { useCallback, useMemo } from 'react'
import { ifTrue } from '@src/logic/utils'
import {
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SubmitButton,
  Button,
  SelectControl,
  UserProfile,
} from '@src/components/atoms'
import {
  Organization_Roles_Enum,
  OrgUserOrganizationUpdateRoleMutationVariables,
  useOrgUserOrganizationUpdateRoleMutation,
  OrgConfigDocument,
  Users,
} from '@src/gen/graphql'
import { getOrgRolesOptionsOrGroups } from '@src/logic/data/manipulation/org'
import { useActions } from '@src/logic/actions'
import { initSelect, SelectOptionType, findOption } from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'
import { MemberType } from '@src/components/molecules'
import { useAuthenticatedAuth, OrganizationType, UserType } from '@src/logic/auth'

export const ORG_CONFIGURATION_EDIT_MEMBER_ACTION_ID = 'orgConfigurationEditMember'
export const ORG_CONFIGURATION_EDIT_MEMBER_ACTION_LABEL = 'Update Organization Member Role'

export type OrgConfigurationEditMemberValues = OrgUserOrganizationUpdateRoleMutationVariables

export type OrgConfigurationEditMemberParams = {
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
  role: Organization_Roles_Enum
  org: OrganizationType
  type: MemberType
}

export type OrgConfigurationEditMemberProps = Omit<OrgConfigurationEditMemberParams, 'type'> & {
  onSubmit?: (values: OrgConfigurationEditMemberValues) => Promise<void>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
  role: Organization_Roles_Enum
  org: OrganizationType
}

type FormType = {
  role: SelectOptionType<Organization_Roles_Enum>
}

export function OrgConfigurationEditMember({ onSubmit, org, user, role }: OrgConfigurationEditMemberProps) {
  const handleSubmit = useCallback(
    async (values: FormType) => {
      if (onSubmit) {
        await onSubmit({
          userId: user?.id,
          orgId: org.id,
          role: values.role.value,
        })
      }
    },
    [onSubmit],
  )

  const handleDelete = useCallback(async () => {
    if (onSubmit) {
      await onSubmit({
        userId: user?.id,
        orgId: org.id,
      })
    }
  }, [user, org])

  const { defaultOption: defaultOption, optionsOrGroups: orgRoles } = useMemo(
    () => initSelect((value) => value, getOrgRolesOptionsOrGroups()),
    [],
  )

  const initialValues = useMemo<FormType>(
    () => ({
      role: findOption(orgRoles, (o) => o.value === role),
    }),
    [defaultOption, orgRoles, role],
  )

  return (
    <Form<FormType> onSubmit={handleSubmit} initialValues={initialValues}>
      <ModalHeader title={ORG_CONFIGURATION_EDIT_MEMBER_ACTION_LABEL} />
      <ModalContent>
        <UserProfile colorVariant='light' user={user} />
        {onSubmit && <SelectControl isRequired={true} label='Role' name='role' optionsOrGroups={orgRoles} />}
      </ModalContent>
      {onSubmit ? (
        <ModalFooter>
          <Button colorVariant='light' style={CommonStyles.marginLeftCard} onPress={handleDelete}>
            Remove
          </Button>
          <SubmitButton style={CommonStyles.marginLeftCard}>Save</SubmitButton>
        </ModalFooter>
      ) : null}
    </Form>
  )
}

export function useRegisterOrgConfigurationEditMemberAction() {
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org } = useOrg()
  const { refresh } = useAuthenticatedAuth()

  const [doOrgRoleUpdateMutation] = useOrgUserOrganizationUpdateRoleMutation()

  const handleSubmit = useCallback(
    async (values: OrgConfigurationEditMemberValues) => {
      await doOrgRoleUpdateMutation({
        variables: {
          ...values,
          role: values?.role || Organization_Roles_Enum.None,
          orgId: org?.id,
        },
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
      refresh()
    },
    [dismissAllActions, doOrgRoleUpdateMutation],
  )

  useRegisterAction<OrgConfigurationEditMemberParams>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: ORG_CONFIGURATION_EDIT_MEMBER_ACTION_ID,
      modal: ({ user, role, type }) => (
        <OrgConfigurationEditMember
          user={user}
          onSubmit={type === MemberType.Member ? handleSubmit : undefined}
          org={org}
          role={role}
        />
      ),
    }),
    [handleSubmit, org.role],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, (params: OrgConfigurationEditMemberParams) =>
        dispatchAction({
          id: ORG_CONFIGURATION_EDIT_MEMBER_ACTION_ID,
          params,
        }),
      ),
    [dispatchAction, org.role],
  )
}
