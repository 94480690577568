import { Card, Indent, Pane, Text } from '@src/components/atoms'
import { FlowActivityItem } from '@src/components/molecules'
import { OrgDashboardQuery } from '@src/gen/graphql/bindings'
import { FlowActivity } from '@src/logic/data/manipulation'
import { NavigateFuncFlow } from '@src/logic/routing/common'
import { ArrayElement } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { StyleSheet } from 'react-native'

export type FlowPaneRecentActivityProps = Pick<OrgDashboardQuery, 'flow_notifications_recent'> & {
  navigateToFlow: NavigateFuncFlow
}

const Styles = StyleSheet.create({
  card: {
    marginBottom: 12,
    marginLeft: 24,
    marginRight: 24,
  },
  indent: {
    marginTop: 16,
    marginBottom: 16,
  },
  secondFlowActivityItem: {
    marginTop: 12,
  },
})

export function FlowPaneRecentActivity({ flow_notifications_recent, navigateToFlow }: FlowPaneRecentActivityProps) {
  const header = useMemo(() => ({ label: 'Recent Activity' }), [])

  if (!flow_notifications_recent.length) {
    return null
  }

  return (
    <Pane header={header} scrollableBehavior='managed'>
      {flow_notifications_recent.map((fn) => (
        <FlowPaneRecentActivityCard flowNotification={fn} key={fn.flow_id} navigateToFlow={navigateToFlow} />
      ))}
    </Pane>
  )
}

type FlowPaneRecentActivityCardProps = {
  flowNotification: ArrayElement<OrgDashboardQuery['flow_notifications_recent']>
  navigateToFlow: NavigateFuncFlow
}

function FlowPaneRecentActivityCard({ flowNotification, navigateToFlow }: FlowPaneRecentActivityCardProps) {
  const handlePress = useCallback(() => navigateToFlow(flowNotification.flow_id), [flowNotification.flow_id])
  const recentActivities = flowNotification.recent_activities as FlowActivity[]

  if (recentActivities.length === 0) {
    return null
  }

  return (
    <Card colorVariant='dark' onPress={handlePress} style={Styles.card}>
      <Text colorVariant='dark' numberOfLines={1} textVariant='body'>
        {flowNotification.flow_name}
      </Text>
      <Indent colorVariant='dark' style={Styles.indent}>
        <FlowActivityItem activity={recentActivities[0]} colorVariant='dark' />
        {recentActivities.length > 1 && (
          <FlowActivityItem activity={recentActivities[1]} colorVariant='dark' style={Styles.secondFlowActivityItem} />
        )}
      </Indent>
    </Card>
  )
}
