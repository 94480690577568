import { ActivityIndicator } from '@src/components/atoms/ActivityIndicator'
import { Badge, BadgeProps } from '@src/components/atoms/Badge'
import { Text } from '@src/components/atoms/Text'
import { CommonStyles, Theme } from '@src/logic/design'
import React, { memo, useMemo } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { SvgProps } from 'react-native-svg'

export type ItemBadgeLabelProps = {
  badge: Omit<BadgeProps, 'displayVariant' | 'style'>
  Icon?: (props: SvgProps) => JSX.Element
  label: string
  style?: ViewStyle
  showActivity?: boolean
}

export const ItemBadgeLabel = memo(ItemBadgeLabelComponent)

const Styles = StyleSheet.create({
  view: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  icon: {
    marginLeft: 8,
  },
  activityIndicator: {
    marginRight: 8,
  },
})

function ItemBadgeLabelComponent({ badge, Icon, label, style, showActivity }: ItemBadgeLabelProps) {
  const viewStyle = useMemo(() => [Styles.view, style], [style])

  return (
    <View style={viewStyle}>
      <Badge displayVariant='solid' {...badge} />
      <View style={CommonStyles.flexOne} />
      {showActivity && <ActivityIndicator style={Styles.activityIndicator} />}
      <Text colorVariant='lightDim' textVariant='body'>
        {label}
      </Text>
      {Icon && <Icon color={Theme.colors.lightDim.color} height={20} width={20} style={Styles.icon} />}
    </View>
  )
}
