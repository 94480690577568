import { TabActions, useNavigation } from '@react-navigation/native'
import { Breakpoint, useScreen } from '@src/logic/design'
import {
  mobileStateToScreenId,
  NavigateFuncRouteIds,
  pathnameToRouteId,
  ROUTE_ID_TO_PATH,
  RouteIds,
  ScreenIds,
  screenNameToRouteId,
} from '@src/logic/routing/common/routes'
import { useMemo } from 'react'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'

export type Tab<T extends RouteIds> = {
  breakpoints?: { [key in Breakpoint]?: true }
  id: T
  label: string
  navigateToScreenId?: ScreenIds
  secondaryIds?: { [key in RouteIds]?: true }
  showActivity?: boolean
}

export type Tabs<T extends RouteIds> = {
  activeTab?: Tab<T>
  navigateTo: NavigateFuncRouteIds<T>
  tabs: Tab<T>[]
}

export function useMobileTabs<T extends RouteIds>(preTabs: Tab<T>[]): Tabs<T> {
  const { dispatch, getState } = useNavigation()
  const state = getState()
  const internalTabs = useInternalTabs(screenNameToRouteId(mobileStateToScreenId(state)), preTabs)

  return useMemo(
    () => ({
      activeTab: internalTabs.activeTab,
      tabs: internalTabs.tabs,
      navigateTo: (routeId: T) => {
        const screenId = preTabs.filter((t) => t.id === routeId)[0]?.navigateToScreenId
        dispatch(TabActions.jumpTo(screenId ?? routeId))
      },
    }),
    [dispatch, internalTabs, preTabs],
  )
}

export function useWebTabs<T extends RouteIds>(preserveParamKeys: string[], preTabs: Tab<T>[]): Tabs<T> {
  const params = useParams<{ [key: string]: string }>()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const internalTabs = useInternalTabs(pathnameToRouteId(pathname), preTabs)

  return useMemo(
    () => ({
      activeTab: internalTabs.activeTab,
      tabs: internalTabs.tabs,
      navigateTo: (routeId: T) => {
        const newParams: any = {}
        preserveParamKeys.forEach((k) => {
          if (!params[k]) {
            throw new Error('Unset param "${k}".')
          }
          newParams[k] = params[k]
        })

        push(generatePath(ROUTE_ID_TO_PATH[routeId], newParams))
      },
    }),
    [internalTabs, params, push],
  )
}

export function mergeTabShowActivity<T extends RouteIds>(
  tabs: Tabs<T>,
  showActivity: { [key in T]?: boolean },
): Tabs<T> {
  return {
    ...tabs,
    tabs: tabs.tabs.map((t) => ({
      ...t,
      showActivity: showActivity[t.id],
    })),
  }
}

function useInternalTabs<T extends RouteIds>(
  routeId: RouteIds,
  preTabs: Tab<T>[],
): Pick<Tabs<T>, 'activeTab' | 'tabs'> {
  const { breakpoint } = useScreen()

  const tabs = useMemo(() => preTabs.filter((t) => !t.breakpoints || t.breakpoints[breakpoint]), [preTabs, breakpoint])

  const activeTab = useMemo(
    () => preTabs.filter((t) => routeId === t.id || (t.secondaryIds && t.secondaryIds[routeId]))[0],
    [preTabs, routeId],
  )

  return useMemo(
    () => ({
      activeTab,
      tabs,
    }),
    [activeTab, tabs],
  )
}
