import { Modal } from '@src/components/atoms'
import { Uploader } from '@src/logic/data/uploads'
import { useScreen } from '@src/logic/design'
import { ImageEditor } from 'expo-image-editor'
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator'
import * as ImagePicker from 'expo-image-picker'
import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Platform, StyleSheet, View } from 'react-native'

export type PhotoUploadProps = {
  uploader: Uploader
  onSubmit: () => Promise<void>
}

const blobToFile = (theBlob: Blob, fileName: string): File => {
  return new File([theBlob], fileName)
}

async function toBlob(base64: string) {
  const result = await fetch(base64, {
    mode: 'no-cors',
  })
  return await result.blob()
}

export const PhotoUpload = ({ onSubmit, uploader }: PhotoUploadProps) => {
  const { isDesktop } = useScreen()
  const [isEdit, setIsEdit] = useState(false)
  const [imageUri, setImageUri] = useState('')

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: false,
    })

    if (!result.cancelled) {
      try {
        setImageUri(result.uri)
        setIsEdit(true)
      } catch (e: any) {
        Alert.alert('Upload error', e?.message as string)
      }
    }
  }

  const uploadImage = useCallback(
    async (image) => {
      let imageToUpload = image
      if (image.height > 512 || image.width > 512) {
        imageToUpload = await manipulateAsync(
          image.localUri || image.uri,
          [
            {
              resize: {
                height: 512,
                width: 512,
              },
            },
          ],
          { compress: 0.9, format: SaveFormat.JPEG, base64: true },
        )
      }
      const fileName = `avatar_${new Date().getTime()}`
      let uploadResult = ''
      if (Platform.OS !== 'web') {
        uploadResult = await uploader({
          ...imageToUpload,
          name: fileName as string,
        } as any)
      } else {
        const blob = await toBlob(imageToUpload.base64 || imageToUpload.uri)
        const file = blobToFile(blob, fileName)
        uploadResult = await uploader(file)
        await onSubmit()
      }
    },
    [onSubmit],
  )

  const onEdit = () => {
    ;(async () => {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        Alert.alert('Sorry, we need camera roll permissions to make this work!')
      }
      pickImage()
    })()
  }

  useEffect(() => {
    onEdit()
  }, [])

  const getImageEditor = (asView = false) => (
    <ImageEditor
      asView={asView}
      visible={isEdit}
      onCloseEditor={async () => {
        setIsEdit(false)
      }}
      imageUri={imageUri}
      fixedCropAspectRatio={1}
      lockAspectRatio
      minimumCropDimensions={{
        width: 100,
        height: 100,
      }}
      onEditingComplete={(result) => {
        uploadImage(result)
      }}
      mode='crop-only'
    />
  )

  return isDesktop ? (
    <Modal isOpen={isEdit} onClose={() => setIsEdit(false)}>
      <View style={Styles.edidtorView}>{getImageEditor(true)}</View>
    </Modal>
  ) : (
    getImageEditor()
  )
}

const Styles = StyleSheet.create({
  edidtorView: {
    minHeight: 512,
  },
})
