import { Form, ModalContent, ModalFooter, ModalHeader, SubmitButton, TextInputControl } from '@src/components/atoms'
import {
  Organization_Roles_Enum,
  OrgContactInsertMutationVariables,
  OrgContactsDocument,
  useOrgContactInsertMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { useOrg } from '@src/logic/data/providers'
import { useOrgRouting } from '@src/logic/routing/providers'
import { ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { UserAddIcon } from 'react-native-heroicons/solid'

export const CONTACT_CREATE_ACTION_ID = 'contactCreate'
export const CONTACT_CREATE_ACTION_LABEL = 'Create Contact'

export type ContactCreateActionValues = Omit<OrgContactInsertMutationVariables, 'orgId'>

export type ContactCreateActionProps = {
  onSubmit: (values: ContactCreateActionValues) => Promise<void>
}

type FormType = {
  email: string
  firstName: string
  lastName: string
}

export function ContactCreateAction({ onSubmit }: ContactCreateActionProps) {
  const initialValues = useMemo<FormType>(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
    }),
    [],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={onSubmit}>
      <ModalHeader title={CONTACT_CREATE_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl isRequired={true} label='First Name' name='firstName' />
        <TextInputControl isRequired={true} label='Last Name' name='lastName' />
        <TextInputControl isRequired={true} textContentType='emailAddress' label='Email' name='email' />
      </ModalContent>
      <ModalFooter>
        <SubmitButton>Create</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterContactCreateAction() {
  const [doOrgContactInsert] = useOrgContactInsertMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org } = useOrg()
  const { useNavigateToContact } = useOrgRouting()
  const navigateToContact = useNavigateToContact()

  const handleSubmit = useCallback(
    async (values: ContactCreateActionValues) => {
      const resp = await doOrgContactInsert({
        variables: {
          ...values,
          orgId: org.id,
        },
        refetchQueries: [OrgContactsDocument],
      })
      dismissAllActions()

      const contactId = resp.data?.create_contact.contact_id
      if (contactId) {
        navigateToContact(contactId)
      }
    },
    [doOrgContactInsert, dismissAllActions, org.id],
  )

  useRegisterAction<undefined>(
    ifTrue(org.role === Organization_Roles_Enum.Admin || org.role === Organization_Roles_Enum.Editor, {
      id: CONTACT_CREATE_ACTION_ID,
      menuItem: {
        Icon: UserAddIcon,
        label: CONTACT_CREATE_ACTION_LABEL,
        params: undefined,
      },
      modal: () => <ContactCreateAction onSubmit={handleSubmit} />,
    }),
    [handleSubmit, org.role],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin || org.role === Organization_Roles_Enum.Editor, () =>
        dispatchAction<undefined>({
          id: CONTACT_CREATE_ACTION_ID,
          params: undefined,
        }),
      ),
    [dispatchAction, org.role],
  )
}
