import { Theme } from '@src/logic/design'
import React from 'react'
import { StyleSheet, View } from 'react-native'

const Styles = StyleSheet.create({
  view: {
    backgroundColor: Theme.colors.dark.backgroundColor,
    flex: 1,
  },
})

export function Splash() {
  return <View style={Styles.view} />
}
