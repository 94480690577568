import {
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SubmitButton,
  TextInputControl,
  UserProfile,
} from '@src/components/atoms'
import { OrgConfigDocument, Users, UserUserUpdateMutationVariables, useUserUserUpdateMutation } from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { CommonStyles } from '@src/logic/design'
import { ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { PencilIcon } from 'react-native-heroicons/solid'

export const USER_PROFILE_EDIT_ACTION_ID = 'userProfileEdit'
export const USER_PROFILE_EDIT_DETAILS_ACTION_LABEL = 'Edit User Profile'

export type UserProfileEditActionValues = UserUserUpdateMutationVariables

export type UserProfileEditActionParams = {
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

export type UserProfileEditActionProps = UserProfileEditActionParams & {
  onSubmit: (values: UserProfileEditActionValues) => Promise<void>
}

type FormType = {
  firstName: string
  lastName: string
}

export function UserProfileEditAction({ user, onSubmit }: UserProfileEditActionProps) {
  const initialValues = useMemo<FormType>(
    () => ({
      firstName: user.first_name,
      lastName: user.last_name,
    }),
    [user],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      if (onSubmit) {
        await onSubmit({
          ...values,
          userId: user.id,
        })
      }
    },
    [onSubmit, user],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={USER_PROFILE_EDIT_DETAILS_ACTION_LABEL} />
      <ModalContent style={ifTrue(!onSubmit, CommonStyles.marginBottomCard)}>
        <UserProfile colorVariant='light' user={user} style={CommonStyles.marginBottomCard} />
        <TextInputControl isRequired={true} label='First Name' name='firstName' />
        <TextInputControl isRequired={true} label='Last Name' name='lastName' />
      </ModalContent>
      <ModalFooter>
        <SubmitButton>Save</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterUserProfileEditAction() {
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const [doUserUserUpdate] = useUserUserUpdateMutation()
  const { user, refresh } = useAuthenticatedAuth()

  const handleSubmit = useCallback(
    async (values: UserProfileEditActionValues) => {
      await doUserUserUpdate({
        variables: {
          ...values,
          userId: user.id,
        },
        refetchQueries: [OrgConfigDocument],
      })
      await refresh()
      dismissAllActions()
    },
    [doUserUserUpdate, dismissAllActions, refresh, user.id],
  )

  useRegisterAction<UserProfileEditActionParams>(
    {
      id: USER_PROFILE_EDIT_ACTION_ID,
      menuItem: {
        Icon: PencilIcon,
        label: USER_PROFILE_EDIT_DETAILS_ACTION_LABEL,
        params: { user },
      },
      modal: ({ user }) => <UserProfileEditAction user={user} onSubmit={handleSubmit} />,
    },
    [handleSubmit],
  )

  return useMemo(
    () => (params: UserProfileEditActionParams) =>
      dispatchAction({
        id: USER_PROFILE_EDIT_ACTION_ID,
        params,
      }),
    [dispatchAction],
  )
}
