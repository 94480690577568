import { MobileNav, NavItem, NavItemSelect, NavUserItem, WebNav } from '@src/components/atoms'
import { OrganizationType, useAuthenticatedAuth } from '@src/logic/auth'
import { initSelect, SelectOptionType } from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers'
import { useScreen } from '@src/logic/design'
import { RouteIds } from '@src/logic/routing/common'
import { useOrgRouting } from '@src/logic/routing/providers'
import { noop } from 'lodash'
import React, { memo, useCallback, useMemo } from 'react'

export type OrgNavProps = {
  onClose?: () => void
}

export const OrgNav = memo(OrgNavComponent)

function OrgNavComponent({ onClose }: OrgNavProps) {
  const { isPhone, isTablet } = useScreen()
  const { org } = useOrg()
  const { useNavTabs, useNavigateToOrg } = useOrgRouting()
  const { user } = useAuthenticatedAuth()

  const Nav = isPhone || isTablet ? MobileNav : WebNav
  const navTabs = useNavTabs()
  const navigateToOrg = useNavigateToOrg()

  const handleOrgChange = useCallback(
    (option: SelectOptionType<OrganizationType>) => navigateToOrg(option.value.id),
    [],
  )

  const handleDashboardPress = useCallback(() => {
    navTabs.navigateTo(RouteIds.ORG_DASHBOARD_SUMMARY)
    onClose && onClose()
  }, [navTabs])

  const handleFlowsPress = useCallback(() => {
    navTabs.navigateTo(RouteIds.ORG_FLOWS_LIST)
    onClose && onClose()
  }, [navTabs])

  const handleContactsPress = useCallback(() => {
    navTabs.navigateTo(RouteIds.ORG_CONTACTS)
    onClose && onClose()
  }, [navTabs])

  const handleConfigurationPress = useCallback(() => {
    navTabs.navigateTo(RouteIds.ORG_CONFIGURATION_PROFILE)
    onClose && onClose()
  }, [navTabs])

  const handleUserPress = useCallback(() => {
    navTabs.navigateTo(RouteIds.ORG_USER_PROFILE)
    onClose && onClose()
  }, [navTabs])

  const footerChildren = useMemo(
    () => (
      <NavUserItem
        isActive={navTabs.activeTab?.id === RouteIds.ORG_USER_PROFILE}
        onPress={handleUserPress}
        user={user}
      />
    ),
    [navTabs.activeTab?.id, handleUserPress, user],
  )

  const { defaultOption, optionsOrGroups } = useMemo(
    () =>
      initSelect(
        JSON.stringify,
        user.organizations.map((o) => ({
          isDefault: o.id === org.id,
          label: o.name,
          value: o,
        })),
      ),
    [org, user.organizations],
  )

  return (
    <Nav footerChildren={footerChildren} onClose={onClose ?? noop}>
      {user.organizations.length > 1 && (
        <NavItemSelect onChange={handleOrgChange} optionsOrGroups={optionsOrGroups} selectedOption={defaultOption} />
      )}
      <NavItem
        isActive={navTabs.activeTab?.id === RouteIds.ORG_DASHBOARD_SUMMARY}
        onPress={handleDashboardPress}
        title='Dashboard'
      />
      <NavItem isActive={navTabs.activeTab?.id === RouteIds.ORG_FLOWS_LIST} onPress={handleFlowsPress} title='Flows' />
      <NavItem
        isActive={navTabs.activeTab?.id === RouteIds.ORG_CONTACTS}
        onPress={handleContactsPress}
        title='Contacts'
      />
      <NavItem
        isActive={navTabs.activeTab?.id === RouteIds.ORG_CONFIGURATION_PROFILE}
        onPress={handleConfigurationPress}
        title='Configuration'
      />
    </Nav>
  )
}
