import { FilterType, useFilter } from '@src/logic/data/manipulation/filters'
import { SelectPreOptionOrGroupType } from '@src/logic/data/manipulation/select'
import { getUserFullName } from '@src/logic/data/manipulation/users'
import {
  Flow_Outcomes_Enum,
  Flow_Roles_Enum,
  Flow_Statuses_Enum,
  Flow_Type_Statuses,
  Flow_Types,
  Flows,
  Flows_Users,
  Maybe,
  Organization_Roles_Enum,
  OrgConfigQuery,
  Users,
} from '@src/gen/graphql/bindings'
import { useMemo } from 'react'

export function getFlowOwner(
  flowsUsers: Array<Pick<Flows_Users, 'role'> & { user: Pick<Users, 'id' | 'first_name' | 'last_name'> }>,
): Pick<Users, 'id' | 'first_name' | 'last_name'> {
  const owner = flowsUsers.find((fu) => fu.role === Flow_Roles_Enum.OrganizationOwner)?.user
  if (!owner) {
    throw new Error('Owner unexpectedly not found.')
  }
  return owner
}

export function getFlowBadge(
  displayVariant: 'outline' | 'solid',
  status: Flow_Statuses_Enum,
  outcome?: Flow_Outcomes_Enum | null,
): {
  colorVariant: 'lightDim' | 'primary' | 'success' | 'warning' | 'danger'
  displayVariant: 'outline' | 'solid'
  label: string
} {
  switch (status) {
    case Flow_Statuses_Enum.New:
    case Flow_Statuses_Enum.Ready:
      return { label: 'New', colorVariant: 'warning', displayVariant }
    case Flow_Statuses_Enum.Flow:
      return { label: 'In Flow', colorVariant: 'primary', displayVariant }
    case Flow_Statuses_Enum.Closed:
      switch (outcome) {
        case Flow_Outcomes_Enum.Won:
          return { label: 'Won', colorVariant: 'success', displayVariant }
        case Flow_Outcomes_Enum.Lost:
          return { label: 'Lost', colorVariant: 'danger', displayVariant }
        default: {
          return { label: 'Neutral', colorVariant: 'lightDim', displayVariant }
        }
      }
  }
}

export function getFlowFlowsUsersSummary(
  flowsUsers: Array<
    Pick<Flows_Users, 'role'> & {
      user: Pick<Users, 'id' | 'first_name' | 'last_name'>
    }
  >,
  max: number = 10,
) {
  const sortPriorities: { [key in Flow_Roles_Enum]: number } = {
    [Flow_Roles_Enum.UnverifiedExternalParticipant]: 0,
    [Flow_Roles_Enum.ExternalParticipant]: 1,
    [Flow_Roles_Enum.OrganizationOwner]: 2,
    [Flow_Roles_Enum.OrganizationParticipant]: 3,
    [Flow_Roles_Enum.ExternalNone]: 4,
    [Flow_Roles_Enum.OrganizationNone]: 4,
  }

  const fus = flowsUsers
    .filter((fu) => fu.role != Flow_Roles_Enum.OrganizationNone && fu.role !== Flow_Roles_Enum.ExternalNone)
    .sort((fu1, fu2) => sortPriorities[fu2.role] - sortPriorities[fu1.role])
    .map((fu) => `${fu.user.first_name} ${fu.user.last_name}`)

  return fus.slice(0, max).join(', ') + (fus.length > max ? ` +${fus.length - max}` : '')
}

export type FlowFlowsUsersOptionType = Pick<Users, 'id' | 'first_name' | 'last_name'>

export function getFlowFlowsUsersPreOptionsOrGroups(
  flowsUsers: Array<
    Pick<Flows_Users, 'role'> & {
      user: Pick<Users, 'id' | 'first_name' | 'last_name'>
    }
  >,
  usersOrganizations?: OrgConfigQuery['users_organizations'],
): SelectPreOptionOrGroupType<FlowFlowsUsersOptionType>[] {
  return [
    {
      label: 'Internal Participants',
      options: flowsUsers
        .filter(
          (fu) => fu.role === Flow_Roles_Enum.OrganizationOwner || fu.role === Flow_Roles_Enum.OrganizationParticipant,
        )
        .map((fu, i) => ({
          isDefault: i === 0,
          label: getUserFullName(fu.user),
          selectedLabel: `Internal Participants / ${getUserFullName(fu.user)}`,
          value: fu.user,
        }))
        .sort((o1, o2) => o1.label.localeCompare(o2.label)),
    },
    {
      label: 'External Participants',
      options: flowsUsers
        .filter(
          (fu) =>
            fu.role === Flow_Roles_Enum.ExternalParticipant ||
            fu.role === Flow_Roles_Enum.UnverifiedExternalParticipant,
        )
        .map((fu, i) => ({
          isDefault: false,
          label: `${getUserFullName(fu.user)}${
            fu.role === Flow_Roles_Enum.UnverifiedExternalParticipant ? ' (Unverified)' : ''
          }`,
          selectedLabel: `External Participants / ${getUserFullName(fu.user)}${
            fu.role === Flow_Roles_Enum.UnverifiedExternalParticipant ? ' (Unverified)' : ''
          }`,
          value: fu.user,
        }))
        .sort((o1, o2) => o1.label.localeCompare(o2.label)),
    },
    ...(usersOrganizations && usersOrganizations.length > 0
      ? [
          {
            label: 'Organization Members (Add)',
            options: usersOrganizations
              .filter((uo) => !flowsUsers.find((fu) => fu.user.id === uo.user.id))
              .filter((uo) => uo.role === Organization_Roles_Enum.Admin || uo.role === Organization_Roles_Enum.Editor)
              .map((uo) => ({
                isDefault: false,
                label: getUserFullName(uo.user),
                selectedLabel: `Organization Members (Add) / ${getUserFullName(uo.user)}`,
                value: uo.user,
              }))
              .sort((o1, o2) => o1.label.localeCompare(o2.label)),
          },
        ]
      : []),
  ]
}

export type FlowStatusTypeOptionType = {
  flowTypeId?: string
  outcome?: Flow_Outcomes_Enum
  status: Flow_Statuses_Enum
}

export function getFlowStatusTypePreOptionsOrGroups(
  flow: Pick<Flows, 'outcome' | 'status'> & {
    flow_type_status?: Maybe<
      Pick<Flow_Type_Statuses, 'id' | 'name'> & {
        flow_type: Pick<Flow_Types, 'id' | 'name'>
      }
    >
  },
  flowTypes: OrgConfigQuery['flow_types'],
): SelectPreOptionOrGroupType<FlowStatusTypeOptionType>[] {
  return [
    ...(flow.status === Flow_Statuses_Enum.New || flow.status === Flow_Statuses_Enum.Ready
      ? [
          {
            isDefault: true,
            label: 'New (No Change)',
            value: { status: flow.status },
          },
        ]
      : []),
    {
      label: 'In Flow',
      options: flowTypes.map((ft) => {
        const isDefault = flow.status === Flow_Statuses_Enum.Flow && flow.flow_type_status?.flow_type.id === ft.id
        return {
          isDefault,
          label: `${ft.name}${isDefault ? ' (No Change)' : ''}`,
          selectedLabel: `In Flow / ${ft.name}${isDefault ? ' (No Change)' : ''}`,
          value: { flowTypeId: ft.id, status: Flow_Statuses_Enum.Flow },
        }
      }),
    },
    {
      label: 'Closed',
      options: [
        { label: 'Won', value: { status: Flow_Statuses_Enum.Closed, outcome: Flow_Outcomes_Enum.Won } },
        { label: 'Lost', value: { status: Flow_Statuses_Enum.Closed, outcome: Flow_Outcomes_Enum.Lost } },
        { label: 'Neutral', value: { status: Flow_Statuses_Enum.Closed, outcome: Flow_Outcomes_Enum.Irrelevant } },
      ].map((item) => {
        const isDefault = flow.status === item.value.status && flow.outcome === item.value.outcome
        return {
          isDefault,
          label: `${item.label}${isDefault ? ' (No Change)' : ''}`,
          selectedLabel: `Closed / ${item.label}${isDefault ? ' (No Change)' : ''}`,
          value: item.value,
        }
      }),
    },
  ]
}

export type FlowTypeStatusOptionType = {
  flowTypeStatusId: string
}

export function getFlowTypeStatusPreOptionsOrGroups(
  currentFlowTypeId: string,
  originalFlowTypeStatus:
    | Maybe<
        Pick<Flow_Type_Statuses, 'id' | 'name'> & {
          flow_type: Pick<Flow_Types, 'id' | 'name'>
        }
      >
    | undefined,
  flowTypes: OrgConfigQuery['flow_types'],
): SelectPreOptionOrGroupType<FlowTypeStatusOptionType>[] {
  const flowType = flowTypes.find((ft) => ft.id === currentFlowTypeId)
  if (!flowType) {
    throw new Error('Flow type unexpectedly not found.')
  }

  return flowType.flow_type_statuses.map((fts, i) => {
    const isOriginalFlowType = flowType.id === originalFlowTypeStatus?.flow_type.id
    const isDefault = isOriginalFlowType ? fts.id === originalFlowTypeStatus?.id : i === 0

    return {
      isDefault: isDefault,
      label: `${fts.name}${isDefault && isOriginalFlowType ? ' (No Change)' : ''}`,
      value: { flowTypeStatusId: fts.id },
    }
  })
}

export type FlowFilterOwnerState = {
  ownerId: string | null
}

export function useFlowFilterOwner(
  usersOrganizations: OrgConfigQuery['users_organizations'],
): FilterType<FlowFilterOwnerState> {
  const getOption = (label: string, ownerId: FlowFilterOwnerState['ownerId'], isDefault: boolean = false) => {
    const value = { ownerId }
    return { isDefault, label, value, stringValue: JSON.stringify(value) }
  }

  const preOptionsOrGroups: SelectPreOptionOrGroupType<FlowFilterOwnerState>[] = useMemo(
    () => [
      getOption('Any Owner', null, true),
      ...usersOrganizations
        .map(({ user }) => getOption(getUserFullName(user), user.id))
        .sort((o1, o2) => o1.label.localeCompare(o2.label)),
    ],
    [usersOrganizations],
  )

  return useFilter<FlowFilterOwnerState>('Owner', preOptionsOrGroups)
}

export type FlowFilterStatusTypeState = {
  flowTypeId: string | null
  outcome: Flow_Outcomes_Enum | null
  status: Flow_Statuses_Enum | null
}

export function useFlowFilterStatusType(
  flowTypes: OrgConfigQuery['flow_types'],
): FilterType<FlowFilterStatusTypeState> {
  const getOption = (
    label: string,
    selectedLabel: string | null,
    flowTypeId: FlowFilterStatusTypeState['flowTypeId'],
    outcome: FlowFilterStatusTypeState['outcome'],
    status: FlowFilterStatusTypeState['status'],
    isDefault: boolean = false,
  ) => {
    const value = { flowTypeId, outcome, status }
    return { isDefault, label, selectedLabel: selectedLabel || undefined, value }
  }

  const preOptionsOrGroups: SelectPreOptionOrGroupType<FlowFilterStatusTypeState>[] = useMemo(
    () => [
      getOption('Any Status', null, null, null, null, true),
      getOption('New', null, null, null, Flow_Statuses_Enum.New, true),
      {
        label: 'In Flow',
        options: [
          getOption('Any Type', 'In Flow / Any Type', null, null, Flow_Statuses_Enum.Flow),
          ...flowTypes
            .map(({ id, name }) => getOption(name, null, id, null, Flow_Statuses_Enum.Flow))
            .sort((o1, o2) => o1.label.localeCompare(o2.label)),
        ],
      },
      {
        label: 'Closed',
        options: [
          getOption('Any Outcome', 'Closed / Any Outcome', null, null, Flow_Statuses_Enum.Closed),
          getOption('Won', 'Closed / Won', null, Flow_Outcomes_Enum.Won, Flow_Statuses_Enum.Closed),
          getOption('Lost', 'Closed / Lost', null, Flow_Outcomes_Enum.Lost, Flow_Statuses_Enum.Closed),
          getOption('Neutral', 'Closed / Neutral', null, Flow_Outcomes_Enum.Irrelevant, Flow_Statuses_Enum.Closed),
        ],
      },
    ],
    [flowTypes],
  )

  return useFilter<FlowFilterStatusTypeState>('Status / Type', preOptionsOrGroups)
}
