import { Avatar, AvatarProps } from '@src/components/atoms/Avatar'
import { Text } from '@src/components/atoms/Text'
import { BackgroundStyles, BorderStyles, Theme } from '@src/logic/design'
import { getMouseHandlers, ifDef, ifTrue } from '@src/logic/utils'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import Svg, { Path } from 'react-native-svg'

export type ChatBubbleProps = {
  avatar?: Omit<AvatarProps, 'sizeVariant' | 'style'>
  body: string
  colorVariant: 'lightDim' | 'primary' | 'success' | 'warning' | 'danger'
  label: string
  onPress?: () => void
  own: boolean
  style?: StyleProp<ViewStyle>
}

export const ChatBubble = memo(ChatBubbleComponent)

const Styles = StyleSheet.create({
  viewOwn: {
    alignItems: 'flex-start',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
  },
  viewOther: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  avatar: {
    marginTop: 4,
  },
  pressable: {
    ...Theme.shadows.standard,
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: 'transparent',
    borderRadius: Theme.geometry.bubble.radius,
    borderWidth: 1,
    flex: 1,
    padding: Theme.geometry.bubble.spacing,
  },
  labelText: {
    marginBottom: 6,
  },
  svgView: {
    elevation: Theme.shadows.standard.elevation,
    zIndex: 2,
  },
  svgOwn: {
    marginLeft: -1,
    marginRight: 8,
    marginTop: 16,
    zIndex: 2,
  },
  svgOther: {
    marginLeft: 8,
    marginRight: -1,
    marginTop: 16,
    zIndex: 2,
  },
})

function ChatBubbleComponent({ avatar, body, colorVariant, label, onPress, own, style }: ChatBubbleProps) {
  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  const viewStyle = useMemo(() => [own ? Styles.viewOwn : Styles.viewOther, style], [own, style])

  const pressableStyle = useMemo(
    () => [
      Styles.pressable,
      BackgroundStyles[isPressed ? 'lightDim' : 'light'],
      ifTrue(!isHover && !isPressed, BorderStyles[colorVariant]),
    ],
    [colorVariant, isHover, isPressed],
  )

  const handleMouse = useMemo(
    () =>
      ifDef(
        onPress,
        getMouseHandlers(
          () => setIsHover(true),
          () => setIsHover(false),
        ),
      ),
    [onPress, setIsHover],
  )

  const handlePressIn = useCallback(() => setIsPressed(true), [setIsPressed])
  const handlePressOut = useCallback(() => setIsPressed(false), [setIsPressed])

  return (
    <View style={viewStyle}>
      <Avatar sizeVariant='md' style={Styles.avatar} {...avatar} />
      <Pointer colorVariant={colorVariant} isHover={isHover} isPressed={isPressed} own={own} />
      <Pressable
        {...handleMouse}
        disabled={!onPress}
        onPress={onPress}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
        style={pressableStyle}>
        <Text colorVariant='lightDim' style={Styles.labelText} textVariant='body'>
          {label}
        </Text>
        <Text colorVariant='light' textVariant='body' enableLinks={true}>
          {body}
        </Text>
      </Pressable>
    </View>
  )
}

type PointerProps = Pick<ChatBubbleProps, 'colorVariant' | 'own'> & {
  isHover: boolean
  isPressed: boolean
}

function Pointer({ colorVariant, isHover, isPressed, own }: PointerProps) {
  return (
    <View style={Styles.svgView}>
      <Svg height={24} style={own ? Styles.svgOwn : Styles.svgOther} viewBox='0 0 4 8' width={12}>
        <Path
          d={own ? 'M 0 0 L 0 8 L 2 6 C 4 4 4 4 2 2 L 0 0' : 'M 4 0 L 4 8 L 2 6 C 0 4 0 4 2 2 L 4 0'}
          fill={isPressed ? Theme.colors.lightDim.backgroundColor : Theme.colors.light.backgroundColor}
        />
        <Path
          d={own ? 'M 0 8 L 2 6 C 4 4 4 4 2 2 L 0 0' : 'M 4 8 L 2 6 C 0 4 0 4 2 2 L 4 0'}
          fill='transparent'
          stroke={isHover || isPressed ? 'transparent' : Theme.colors[colorVariant].borderColor}
          strokeWidth={0.3}
        />
      </Svg>
    </View>
  )
}
