import { NavItem } from '@src/components/atoms/NavItem'
import { SelectModal } from '@src/components/atoms/SelectModal'
import { SelectOptionOrGroupType, SelectOptionType, SelectValueBaseType } from '@src/logic/data/manipulation'
import React, { useCallback, useState } from 'react'
import { View, ViewStyle } from 'react-native'

export type NavItemSelectProps<T extends SelectValueBaseType> = {
  onChange: (option: SelectOptionType<T>) => void
  optionsOrGroups: Array<SelectOptionOrGroupType<T>>
  selectedOption: SelectOptionType<T>
  style?: ViewStyle
}

export function NavItemSelect<T extends SelectValueBaseType>({
  onChange,
  optionsOrGroups,
  selectedOption,
  style,
}: NavItemSelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false)

  const handlePress = useCallback(() => setIsOpen(true), [setIsOpen])
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen])

  const handleChange = useCallback(
    (option: SelectOptionType<T>) => {
      setIsOpen(false)
      onChange(option)
    },
    [onChange, setIsOpen],
  )

  return (
    <View style={style}>
      <NavItem
        hasChevron={true}
        isActive={false}
        onPress={handlePress}
        title={selectedOption.selectedLabel ? selectedOption.selectedLabel : selectedOption.label}
      />
      <SelectModal
        isOpen={isOpen}
        onChange={handleChange}
        onClose={handleClose}
        optionsOrGroups={optionsOrGroups}
        selectedOption={selectedOption}
      />
    </View>
  )
}
