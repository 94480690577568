import { Pane, UserProfile } from '@src/components/atoms'
import { UserType } from '@src/logic/auth'
import { CommonStyles } from '@src/logic/design'
import React from 'react'

export type UserPaneProfileProps = {
  user: UserType
}

export function UserPaneProfile({ user }: UserPaneProfileProps) {
  return (
    <Pane
      header={{
        label: 'Profile',
      }}>
      <UserProfile colorVariant='dark' style={CommonStyles.marginBottomStructure} user={user} />
    </Pane>
  )
}
