import {
  ConditionalControl,
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectControl,
  SubmitButton,
  Text,
  TextInputControl,
} from '@src/components/atoms'
import {
  Flow_Field_Types_Enum,
  Flow_Field_Visibilities_Enum,
  Organization_Roles_Enum,
  OrgConfigQuery,
  OrgFlowFieldInsertMutationVariables,
  useOrgFlowFieldInsertMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import {
  findOption,
  getFlowFieldStartFromPreOptionsOrGroups,
  getFlowFieldTypesPreOptionsOrGroups,
  getFlowFieldVisibilitiesPreOptionsOrGroups,
  initSelect,
  SelectOptionType,
} from '@src/logic/data/manipulation'
import { useOrg, useOrgFlow } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'
import { ifTrue, ifTrueThen } from '@src/logic/utils'
import Joi from 'joi'
import React, { useCallback, useMemo, useState } from 'react'
import { PlusSmIcon } from 'react-native-heroicons/solid'

export const FLOW_FIELD_CREATE_ACTION_ID = 'flowFieldCreate'
export const FLOW_FIELD_CREATE_ACTION_LABEL = 'Create Flow Field'

export type FlowFieldCreateActionValues = Omit<OrgFlowFieldInsertMutationVariables, 'flowId'>

export type FlowFieldCreateActionProps = {
  onSubmit: (values: FlowFieldCreateActionValues) => Promise<void>
  orgConfig: OrgConfigQuery
}

type FormType = {
  name: string
  visibility: SelectOptionType<Flow_Field_Visibilities_Enum>
  type: SelectOptionType<Flow_Field_Types_Enum>
  valueText: string
  valueDoublePrecision: string
}

export function FlowFieldCreateAction({ onSubmit, orgConfig }: FlowFieldCreateActionProps) {
  const { defaultOption: defaultStartFromOption, optionsOrGroups: startFromOptionsOrGroups } = useMemo(
    () => initSelect(JSON.stringify, getFlowFieldStartFromPreOptionsOrGroups(orgConfig.flow_field_configurations)),
    [orgConfig],
  )

  const { defaultOption: visibilityDefaultOption, optionsOrGroups: visibilityOptionsOrGroups } = useMemo(
    () => initSelect(JSON.stringify, getFlowFieldVisibilitiesPreOptionsOrGroups()),
    [],
  )

  const { defaultOption: typeDefaultOption, optionsOrGroups: typeOptionsOrGroups } = useMemo(
    () => initSelect(JSON.stringify, getFlowFieldTypesPreOptionsOrGroups()),
    [],
  )

  const [startFrom, setStartFrom] = useState(defaultStartFromOption)

  const initialValues = useMemo<FormType>(
    () => ({
      name: startFrom.value.name,
      visibility: findOption(visibilityOptionsOrGroups, (o) => o.value === startFrom.value.visibility),
      type: findOption(typeOptionsOrGroups, (o) => o.value === startFrom.value.type),
      valueText: '',
      valueDoublePrecision: '',
    }),
    [startFrom, visibilityOptionsOrGroups, typeOptionsOrGroups],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        name: values.name,
        visibility: values.visibility.value,
        type: values.type.value,
        valueText: ifTrue(values.type.value === Flow_Field_Types_Enum.Text, values.valueText),
        valueDoublePrecision: ifTrueThen(values.type.value === Flow_Field_Types_Enum.Number, () =>
          parseFloat(values.valueDoublePrecision),
        ),
      })
    },
    [onSubmit],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={FLOW_FIELD_CREATE_ACTION_LABEL} />
      <ModalContent>
        <Text colorVariant='lightDim' textVariant='smallText' style={CommonStyles.marginBottomCardHalf}>
          Start From
        </Text>
        <Select
          colorVariant='light'
          onChange={setStartFrom}
          optionsOrGroups={startFromOptionsOrGroups}
          selectedOption={startFrom}
          style={CommonStyles.marginBottomCard}
        />
        <TextInputControl isRequired={true} label='Name' name='name' placeholder='e.g. Deal Value...' />
        <SelectControl
          isRequired={true}
          label='Visibility'
          name='visibility'
          optionsOrGroups={visibilityOptionsOrGroups}
        />
        <SelectControl isRequired={true} label='Type' name='type' optionsOrGroups={typeOptionsOrGroups} />
        <ConditionalControl<SelectOptionType<Flow_Field_Types_Enum>>
          subscription='type'
          predicate={(value) => value.value === Flow_Field_Types_Enum.Text}
          render={() => <TextInputControl isRequired={true} label='Value' name='valueText' />}
        />
        <ConditionalControl<SelectOptionType<Flow_Field_Types_Enum>>
          subscription='type'
          predicate={(value) => value.value === Flow_Field_Types_Enum.Number}
          render={() => (
            <TextInputControl
              isRequired={true}
              label='Value'
              name='valueDoublePrecision'
              schema={Joi.string().regex(/^[+-]?([0-9]*[.])?[0-9]+$/, { name: 'string.float' })}
            />
          )}
        />
      </ModalContent>
      <ModalFooter>
        <SubmitButton>Create</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterOrgFlowFieldCreateAction() {
  const [doFlowFieldInsert] = useOrgFlowFieldInsertMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org, orgConfig } = useOrg()
  const { flow } = useOrgFlow()

  const handleSubmit = useCallback(
    async (values: FlowFieldCreateActionValues) => {
      await doFlowFieldInsert({
        variables: {
          ...values,
          flowId: flow.id,
        },
      })
      dismissAllActions()
    },
    [doFlowFieldInsert, dismissAllActions, flow.id],
  )

  useRegisterAction<undefined>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: FLOW_FIELD_CREATE_ACTION_ID,
      menuItem: {
        Icon: PlusSmIcon,
        label: FLOW_FIELD_CREATE_ACTION_LABEL,
        params: undefined,
      },
      modal: () => <FlowFieldCreateAction orgConfig={orgConfig} onSubmit={handleSubmit} />,
    }),
    [handleSubmit, orgConfig, org.role, flow.id],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, () =>
        dispatchAction<undefined>({
          id: FLOW_FIELD_CREATE_ACTION_ID,
          params: undefined,
        }),
      ),
    [dispatchAction, org.role],
  )
}
