import { ActivityIndicator } from '@src/components/atoms/ActivityIndicator'
import { Text } from '@src/components/atoms/Text'
import { Theme } from '@src/logic/design'
import { getMouseHandlers } from '@src/logic/utils'
import React, { useCallback, useMemo, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

export type TabsProps<T extends string> = {
  active?: T
  onChange: (id: T) => void
  tabs: TabsPropsTab<T>[]
}

export type TabsPropsTab<T extends string> = {
  id: T
  label: string
  showActivity?: boolean
}

const Styles = StyleSheet.create({
  view: {
    ...Theme.shadows.tabs,
    backgroundColor: Theme.colors.light.backgroundColor,
    flexDirection: 'row',
    zIndex: 1,
  },
  pressable: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 64,
    justifyContent: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  text: {
    marginRight: 16,
  },
  activityIndicator: {
    position: 'absolute',
    top: 30,
    right: 4,
  },
})

export function Tabs<T extends string>({ active, onChange, tabs }: TabsProps<T>) {
  return (
    <View style={Styles.view}>
      {tabs.map((t) => (
        <Tab {...t} isActive={active === t.id} key={t.id} onChange={onChange} showActivity={t.showActivity} />
      ))}
    </View>
  )
}

type TabProps<T extends string> = TabsPropsTab<T> & {
  isActive: boolean
  onChange: (id: T) => void
}

function Tab<T extends string>({ id, isActive, label, onChange, showActivity }: TabProps<T>) {
  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handleChange = useCallback(() => onChange(id), [id, onChange])
  const handlePressIn = useCallback(() => setIsPressed(true), [setIsPressed])
  const handlePressOut = useCallback(() => setIsPressed(false), [setIsPressed])

  return (
    <Pressable
      {...handleMouse}
      onPress={handleChange}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      style={Styles.pressable}>
      <Text
        colorVariant={isActive || isHover || isPressed ? 'light' : 'lightDim'}
        textVariant={isActive ? 'cta' : 'body'}>
        {label}
      </Text>
      {showActivity && <ActivityIndicator style={Styles.activityIndicator} />}
    </Pressable>
  )
}
