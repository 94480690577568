import { SvgProps } from 'react-native-svg'
import { StyleSheet, View } from 'react-native'
import { CommonStyles, Theme, useScreen } from '@src/logic/design'
import { SingleCircleBackgroundScreen } from '@src/components/atoms/SingleCircleBackgroundScreen'
import { Text } from '@src/components/atoms/Text'
import { Button } from '@src/components/atoms/Button'
import React from 'react'
import { ifTrue } from '@src/logic/utils'

type MessageScreenProps = {
  title: string
  mainText: string
  ctaProps?: CTAProps
  iconProps?: IconProps
}

const Styles = StyleSheet.create({
  container: {
    backgroundColor: Theme.colors.darkDim.backgroundColor,
    width: '95%',
    maxWidth: 566,
    borderRadius: 8,
    padding: 16,
  },
  rowContainerCta: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    marginTop: 48,
  },
  rowContainerIcon: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 48,
  },
  mainTextPhone: {
    marginTop: 48,
  },
  buttonTextPhone: {
    alignSelf: 'flex-end',
    marginTop: 24,
  },
  bodyText: {
    marginTop: 16,
    width: '75%',
  },
  columnContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  columnContainerMobile: {
    flexDirection: 'column',
  },
  iconMobile: {
    alignSelf: 'flex-end',
  },
})

type CTAProps = {
  buttonText: string
  buttonAction: () => void
}

type IconProps = {
  body: string
  Icon: (props: SvgProps) => JSX.Element
  colorVariant: 'light' | 'dark'
}

export function MessageScreen({ title, mainText, ctaProps, iconProps }: MessageScreenProps) {
  if ((ctaProps && iconProps) || (!ctaProps && !iconProps)) {
    throw new Error('Provide exactly one of ctaProps or iconProps.')
  }
  const { isPhone } = useScreen()
  if (ctaProps) {
    const { buttonText, buttonAction } = ctaProps
    return (
      <SingleCircleBackgroundScreen>
        <View style={Styles.container}>
          <Text colorVariant='dark' textVariant='h3'>
            {title}
          </Text>
          <View style={ifTrue(!isPhone, Styles.rowContainerCta)}>
            <Text colorVariant='dark' textVariant='h1' style={isPhone ? Styles.mainTextPhone : CommonStyles.flexOne}>
              {mainText}
            </Text>
            <Button colorVariant='primary' onPress={buttonAction} style={ifTrue(isPhone, Styles.buttonTextPhone)}>
              {buttonText}
            </Button>
          </View>
        </View>
      </SingleCircleBackgroundScreen>
    )
  } else {
    const { Icon, body, colorVariant } = iconProps!
    return (
      <SingleCircleBackgroundScreen>
        <View style={Styles.container}>
          <Text colorVariant='dark' textVariant='h3'>
            {title}
          </Text>
          <View style={ifTrue(!isPhone, Styles.rowContainerIcon)}>
            <View style={isPhone ? Styles.columnContainerMobile : Styles.columnContainer}>
              <Text colorVariant='dark' textVariant='h1' style={ifTrue(isPhone, Styles.mainTextPhone)}>
                {mainText}
              </Text>
              <Text colorVariant='dark' textVariant='body' style={Styles.bodyText}>
                {body}
              </Text>
            </View>
            <Icon
              color={Theme.colors[colorVariant].borderColor}
              height={67}
              width={67}
              style={ifTrue(isPhone, Styles.iconMobile)}
            />
          </View>
        </View>
      </SingleCircleBackgroundScreen>
    )
  }
}
