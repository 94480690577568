import { Text } from '@src/components/atoms/Text'
import { BackgroundStyles, Theme } from '@src/logic/design'
import { getMouseHandlers, ifTrue } from '@src/logic/utils'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import { ChevronDownIcon } from 'react-native-heroicons/solid'
import { CommonStyles } from '@src/logic/design'

export type NavItemProps = {
  hasChevron?: boolean
  isActive: boolean
  onPress: () => void
  style?: ViewStyle
  title: string
}

const Styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 48,
    justifyContent: 'flex-start',
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
  },
  chevronView: {
    alignItems: 'flex-end',
  },
})

export const NavItem = memo(NavItemComponent)

function NavItemComponent({ hasChevron, isActive, onPress, style, title }: NavItemProps) {
  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  const pressableStyle = useMemo(
    () => [
      Styles.pressable,
      BackgroundStyles.dark,
      ifTrue(isActive || isHover || isPressed, BackgroundStyles.darkDim),
      style,
    ],
    [isActive, isHover, isPressed, style],
  )

  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handlePressIn = useCallback(() => setIsPressed(true), [setIsPressed])
  const handlePressOut = useCallback(() => setIsPressed(false), [setIsPressed])

  return (
    <Pressable
      {...handleMouse}
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      style={pressableStyle}>
      <Text
        colorVariant={isActive ? 'dark' : 'darkDim'}
        numberOfLines={1}
        textVariant={isActive ? 'nav1' : 'nav2'}
        style={CommonStyles.flexOne}>
        {title}
      </Text>
      {hasChevron && (
        <View style={Styles.chevronView}>
          <ChevronDownIcon size={20} color={Theme.colors.darkDim.color} />
        </View>
      )}
    </Pressable>
  )
}
