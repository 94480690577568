import {
  ApolloClient,
  ApolloLink,
  DocumentNode,
  FetchResult,
  fromPromise,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  toPromise,
  useSubscription as useApolloSubscription,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { AuthContextType, AuthRoles, useAuth } from '@src/logic/auth'
import { ConfigType, getNewSentryApolloLink, useConfig } from '@src/logic/config'
import { processError } from '@src/logic/errors'
import { ifNotWeb, usePrevious } from '@src/logic/utils'
import { merge } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { SubscriptionClient } from 'subscriptions-transport-ws'

export function useSubscription<D, V>(subscriptionDocument: DocumentNode, variables: V) {
  const config = useConfig()
  const auth = useAuth()
  const [role, accessToken] = getAuthValues(auth, subscriptionDocument, variables)
  const isMountedRef = useRef(true)
  const clientRef = useRef<WSGraphQLClient>()
  const [, setRender] = useState({})

  if (!clientRef.current) {
    clientRef.current = createWSGraphQLClient(config.appBaseUrl, config.hasuraUrl, role, accessToken, async () => {
      clientRef.current = undefined
      if (isMountedRef.current) {
        await auth.refresh()
        setRender({})
      }
    })
  }

  useEffect(() => {
    return () => {
      isMountedRef.current = false
      clientRef.current?.subscriptionClient.close(true)
    }
  }, [])

  useEffect(() => {
    return () => {
      if (clientRef.current) {
        clientRef.current.subscriptionClient.close(true)
      }
    }
  }, [config.hasuraUrl, role, auth.user])

  const subscription = useApolloSubscription<D, V>(subscriptionDocument, {
    variables,
    client: clientRef.current?.apolloClient,
  })

  // Note: when the client flips, the query goes back into loading state. This avoids a flicker.
  const prevSubscription = usePrevious(subscription)
  if (prevSubscription && !prevSubscription.loading && subscription.loading) {
    return prevSubscription
  }

  return subscription
}

type WSGraphQLClient = {
  subscriptionClient: SubscriptionClient
  apolloClient: ApolloClient<NormalizedCacheObject>
}

export function createWSGraphQLClient(
  appBaseUrl: string,
  hasuraUrl: string,
  role: AuthRoles,
  accessToken: string | undefined,
  onDispose?: () => Promise<void>,
): WSGraphQLClient {
  const headers = {
    ...(accessToken ? { authorization: `Bearer ${accessToken}` } : undefined),
    'x-hasura-role': role,
    ...ifNotWeb({ origin: appBaseUrl }),
  }

  const subscriptionClient = new SubscriptionClient(hasuraUrl.replace(/^http/, 'ws'), {
    lazy: true,
    reconnect: false,
    connectionParams: async () => ({ headers }),
    wsOptionArguments: [{ headers }],
  })

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      (operation, forward) => {
        requireOperation(operation.query, ['subscription'])
        return forward(operation)
      },
      getNewSentryApolloLink(),
      onError((errorHandler) => {
        const { forward, operation } = errorHandler
        processError(errorHandler)
        return forward(operation)
      }),
      new WebSocketLink(subscriptionClient),
    ]),
  })

  const off = subscriptionClient.onDisconnected(async () => {
    off()
    apolloClient.stop()
    subscriptionClient.unsubscribeAll()
    subscriptionClient.close(true)
    if (onDispose) {
      await onDispose()
    }
  })

  return { subscriptionClient, apolloClient }
}

export function createHTTPGraphQLClient(config: ConfigType, auth: AuthContextType) {
  return new ApolloClient({
    connectToDevTools: config.stage === 'dev', // TODO(ibrt): Constant for this.
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    link: ApolloLink.from([
      setContext(async (operation) => {
        requireOperation(operation.query, ['query', 'mutation'])
        const [role, accessToken] = getAuthValues(auth, operation.query, operation.variables)
        return {
          headers: {
            ...(accessToken ? { authorization: `Bearer ${accessToken}` } : undefined),
            'x-hasura-role': role,
          },
        }
      }),
      getNewSentryApolloLink(),
      onError((errorHandler) => {
        processError(errorHandler)
        if (shouldRefreshTokenAndRetry(errorHandler, auth)) {
          return fromPromise(
            (async (): Promise<FetchResult> => {
              const { forward, operation } = errorHandler
              const newAuthState = await auth.refresh()

              operation.setContext(
                merge(operation.getContext(), {
                  headers: {
                    authorization: `Bearer ${newAuthState.mutable.accessToken}`,
                  },
                }),
              )

              return toPromise(forward(operation))
            })(),
          )
        }
      }),
      new HttpLink({
        uri: config.hasuraUrl,
      }),
    ]),
  })
}

function shouldRefreshTokenAndRetry(
  { graphQLErrors, networkError, operation }: ErrorResponse,
  auth: AuthContextType,
): boolean {
  if (!auth.user || !auth.mutable.accessToken || !operation.getContext()?.headers) {
    return false
  }

  if (!graphQLErrors?.find(({ extensions }) => extensions?.code === 'invalid-jwt')) {
    return false
  }

  switch (operation.getContext().headers['x-hasura-role']) {
    case AuthRoles.ORGANIZATION_USER:
    case AuthRoles.EXTERNAL_USER:
    case AuthRoles.USER:
      return true
    default:
      return false
  }
}

function getAuthValues(auth: AuthContextType, document: DocumentNode, variables: any): [AuthRoles, string | undefined] {
  const role = getAuthRole(document)

  if (role === AuthRoles.ORGANIZATION_USER || role === AuthRoles.EXTERNAL_USER || role === AuthRoles.USER) {
    if (!auth.mutable.accessToken) {
      throw new Error('Missing accessToken.')
    }

    return [role, auth.mutable.accessToken]
  }

  if (role === AuthRoles.UNVERIFIED_EXTERNAL_USER) {
    const flowId = getAuthFlowId(variables)

    if (!auth.mutable.storage.unverifiedAccessTokens[flowId]) {
      throw new Error('Missing unverifiedAccessToken.')
    }

    return [AuthRoles.UNVERIFIED_EXTERNAL_USER, auth.mutable.storage.unverifiedAccessTokens[flowId]]
  }

  if (role === AuthRoles.ANONYMOUS) {
    return [AuthRoles.UNVERIFIED_EXTERNAL_USER, undefined]
  }

  throw new Error('Unknown auth role.')
}

function getAuthRole(document: DocumentNode): AuthRoles {
  const mainDef = getMainDefinition(document)
  const name = mainDef.name?.value

  if (!name) {
    throw new Error('Missing operation name.')
  }

  if (name.startsWith('Org')) {
    return AuthRoles.ORGANIZATION_USER
  }
  if (name.startsWith('Ext')) {
    return AuthRoles.EXTERNAL_USER
  }
  if (name.startsWith('User')) {
    return AuthRoles.USER
  }
  if (name.startsWith('Unv')) {
    return AuthRoles.UNVERIFIED_EXTERNAL_USER
  }
  if (name.startsWith('Anon')) {
    return AuthRoles.ANONYMOUS
  }

  throw new Error(`Unrecognized prefix for operation ${name}.`)
}

function getAuthFlowId(variables: any): string {
  if (variables && typeof variables === 'object' && variables.flowId && typeof variables.flowId === 'string') {
    return variables.flowId
  }
  throw new Error('Missing or invalid flowId variable.')
}

function requireOperation(document: DocumentNode, allowedOperations: string[]) {
  const mainDef = getMainDefinition(document)

  if (mainDef.kind !== 'OperationDefinition' || !allowedOperations.includes(mainDef.operation)) {
    throw new Error('Operation not allowed.')
  }
}
