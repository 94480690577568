import { IconButton } from '@src/components/atoms/IconButton'
import { BackgroundStyles, BorderStyles, dim, Theme } from '@src/logic/design'
import { getMouseHandlers, ifDef, ifTrue } from '@src/logic/utils'
import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react'
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { ChevronDownIcon, ChevronUpIcon } from 'react-native-heroicons/solid'

export type CardProps = {
  children?: ReactNode
  colorVariant: 'dark' | 'lightDim' | 'primary' | 'success' | 'warning' | 'danger'
  isExpanded?: boolean
  onExpand?: (isExpanded: boolean) => void
  onPress?: () => void
  style?: StyleProp<ViewStyle>
}

export const Card = memo(CardComponent)

const Styles = StyleSheet.create({
  view: {
    ...Theme.shadows.standard,
    borderColor: 'transparent',
    borderRadius: Theme.geometry.card.radius,
    borderWidth: 1,
  },
  basePressable: {
    paddingLeft: Theme.geometry.card.spacing,
    paddingRight: Theme.geometry.card.spacing,
    paddingTop: Theme.geometry.card.spacing,
  },
  expandedView: {
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    marginLeft: Theme.geometry.card.spacing,
    marginRight: Theme.geometry.card.spacing,
  },
  iconButton: {
    paddingBottom: Theme.geometry.card.spacing / 2,
    paddingTop: Theme.geometry.card.spacing / 2,
  },
})

function CardComponent({ children, colorVariant, isExpanded, onExpand, onPress, style }: CardProps) {
  const baseColorVariant = colorVariant === 'dark' ? 'dark' : 'light'

  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  const viewStyle = useMemo(
    () => [
      Styles.view,
      BackgroundStyles[isPressed ? dim(baseColorVariant) : baseColorVariant],
      ifTrue(!isHover && !isPressed, BorderStyles[colorVariant]),
      style,
    ],
    [baseColorVariant, colorVariant, isHover, isPressed, style],
  )

  const expandedViewStyle = useMemo(() => [BorderStyles[colorVariant], Styles.expandedView], [colorVariant])

  const handleMouse = useMemo(
    () =>
      ifDef(
        onPress,
        getMouseHandlers(
          () => setIsHover(true),
          () => setIsHover(false),
        ),
      ),
    [onPress, setIsHover],
  )

  const handlePressIn = useCallback(() => setIsPressed(true), [setIsPressed])
  const handlePressOut = useCallback(() => setIsPressed(false), [setIsPressed])
  const handleExpand = useCallback(() => onExpand && onExpand(!isExpanded), [isExpanded, onExpand])

  return (
    <View style={viewStyle} {...handleMouse}>
      <Pressable
        disabled={!onPress}
        onPress={onPress}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
        style={Styles.basePressable}>
        {children}
      </Pressable>
      {onExpand && (
        <View style={expandedViewStyle}>
          <IconButton
            colorVariant={baseColorVariant}
            Icon={isExpanded ? ChevronUpIcon : ChevronDownIcon}
            onPress={handleExpand}
            style={Styles.iconButton}
          />
        </View>
      )}
    </View>
  )
}
