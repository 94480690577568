import { Users, Organization_Roles_Enum } from '@src/gen/graphql/bindings'

export function getUserFullName(user: Pick<Users, 'first_name' | 'last_name'>) {
  return `${user.first_name} ${user.last_name}`
}

export function getUserInitials(user: Pick<Users, 'first_name' | 'last_name'>) {
  return `${user.first_name.substring(0, 1)}${user.last_name.substring(0, 1)}`
}

export function getUserRole(role: Organization_Roles_Enum) {
  return `${role[0].toUpperCase()}${role.slice(1)}`
}
