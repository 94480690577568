import {
  Attachment_Types_Enum,
  useUserUploadAuthorizeMutation,
  useUserUploadProcessMutation,
} from '@src/gen/graphql/bindings'

export type Uploader = (file: File) => Promise<string>

export function useUploader(type: Attachment_Types_Enum, orgId?: string): Uploader {
  const [uploadAuthorize] = useUserUploadAuthorizeMutation()
  const [uploadProcess] = useUserUploadProcessMutation()

  return async (file: File) => {
    const authorization = await uploadAuthorize()
    if (!authorization.data) {
      throw new Error('missing authorization')
    }

    const formData = new FormData()
    for (const [key, value] of Object.entries(JSON.parse(authorization.data?.upload_authorize.form_data))) {
      formData.append(key, value as string)
    }
    formData.append('file', file)

    const resp = await fetch(authorization.data.upload_authorize.url, {
      method: 'post',
      body: formData,
    })

    const body = await resp.text()

    if (!resp.ok) {
      throw new Error(`Upload returned ${resp.status}: ${body}`)
    }

    await uploadProcess({
      variables: {
        userId: authorization.data.upload_authorize.id,
        organizationId: orgId,
        type,
      },
    })

    return authorization.data.upload_authorize.id
  }
}
