import { Control } from '@src/components/atoms/Control'
import { Select } from '@src/components/atoms/Select'
import { SelectOptionOrGroupType, SelectOptionType, SelectValueBaseType } from '@src/logic/data/manipulation'
import { ifTrue } from '@src/logic/utils'
import { FieldValidator } from 'final-form'
import React from 'react'
import { useField } from 'react-final-form'

export type SelectControlProps<T extends SelectValueBaseType> = {
  helperText?: string
  isRequired?: boolean
  label: string
  name: string
  optionsOrGroups: SelectOptionOrGroupType<T>[]
  validate?: FieldValidator<SelectOptionType<T>>
}

export function SelectControl<T extends SelectValueBaseType>({
  isRequired = false,
  label,
  name,
  optionsOrGroups,
  validate,
}: SelectControlProps<T>) {
  const {
    input: { onChange, value: selectedOption },
    meta: { error, submitting, touched },
  } = useField<SelectOptionType<T>>(name, {
    subscription: { error: true, submitting: true, touched: true, value: true },
    validate,
  })

  return (
    <Control error={ifTrue(touched ?? false, error)} isRequired={isRequired} label={label}>
      <Select
        colorVariant='light'
        isDisabled={submitting}
        onChange={onChange}
        optionsOrGroups={optionsOrGroups}
        selectedOption={selectedOption}
      />
    </Control>
  )
}
