import { MobileNav, NavItem, NavUserItem, WebNav } from '@src/components/atoms'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { useScreen } from '@src/logic/design'
import { RouteIds } from '@src/logic/routing/common'
import { useExtRouting } from '@src/logic/routing/providers'
import { noop } from 'lodash'
import React, { memo, useCallback, useMemo } from 'react'

export type ExtNavProps = {
  onClose?: () => void
}

export const ExtNav = memo(ExtNavComponent)

function ExtNavComponent({ onClose }: ExtNavProps) {
  const { isPhone, isTablet } = useScreen()
  const { useNavTabs } = useExtRouting()
  const { user } = useAuthenticatedAuth()

  const Nav = isPhone || isTablet ? MobileNav : WebNav
  const navTabs = useNavTabs()

  const handleFlowsPress = useCallback(() => {
    navTabs.navigateTo(RouteIds.EXT_FLOWS)
    onClose && onClose()
  }, [navTabs])

  const handleUserPress = useCallback(() => {
    navTabs.navigateTo(RouteIds.EXT_USER_PROFILE)
    onClose && onClose()
  }, [navTabs])

  const footerChildren = useMemo(
    () => (
      <NavUserItem
        isActive={navTabs.activeTab?.id === RouteIds.EXT_USER_PROFILE}
        onPress={handleUserPress}
        user={user}
      />
    ),
    [navTabs, user],
  )

  return (
    <Nav footerChildren={footerChildren} onClose={onClose ?? noop}>
      <NavItem
        isActive={navTabs.activeTab?.id === RouteIds.EXT_FLOWS}
        onPress={handleFlowsPress}
        title='My Conversations'
      />
    </Nav>
  )
}
