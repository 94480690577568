import {
  Form,
  Item,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectControl,
  SubmitButton,
  UserProfile,
} from '@src/components/atoms'
import {
  Flow_Role_Types,
  Flows_Users,
  Maybe,
  Organization_Roles_Enum,
  OrgConfigDocument,
  OrgConfigQuery,
  OrgFlowUserUpdateFlowRoleTypeIdMutationVariables,
  useOrgFlowUserUpdateFlowRoleTypeIdMutation,
  Users,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { initSelect, SelectOptionType } from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'
import { ifTrue, stringEmptyToUndefined } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'

export const FLOW_USER_EDIT_ACTION_ID = 'flowUserEdit'
export const FLOW_USER_EDIT_DISABLED_ACTION_LABEL = 'Flow Participant'
export const FLOW_USER_EDIT_ENABLED_ACTION_LABEL = 'Edit Flow Participant'

export type FlowUserEditActionValues = OrgFlowUserUpdateFlowRoleTypeIdMutationVariables

export type FlowUserEditActionParams = {
  flowUser: Pick<Flows_Users, 'role' | 'flow_id'> & {
    flow_role_type?: Maybe<Pick<Flow_Role_Types, 'id' | 'name'>>
    user: Pick<Users, 'id' | 'first_name' | 'last_name'>
  }
}

export type FlowUserEditActionProps = FlowUserEditActionParams & {
  onSubmit?: (values: FlowUserEditActionValues) => Promise<void>
  orgConfig: OrgConfigQuery
}

type FormType = {
  flowRoleType: SelectOptionType<string>
}

export function FlowUserEditAction({ flowUser, onSubmit, orgConfig }: FlowUserEditActionProps) {
  const defaultFlowRoleTypeId = orgConfig.flow_role_types.find((frt) => frt.id === flowUser.flow_role_type?.id)?.id

  const { defaultOption: defaultFlowRoleTypeOption, optionsOrGroups: flowRoleTypeOptionsOrGroups } = useMemo(
    () =>
      initSelect(
        (v) => v,
        [
          {
            isDefault: !defaultFlowRoleTypeId,
            label: 'Not Set',
            value: '',
          },
        ].concat(
          orgConfig.flow_role_types.map((frt) => ({
            isDefault: defaultFlowRoleTypeId === frt.id,
            label: frt.name,
            selectedLabel: frt.name,
            value: frt.id,
          })),
        ),
      ),
    [orgConfig.flow_types],
  )

  const initialValues = useMemo<FormType>(
    () => ({
      flowRoleType: defaultFlowRoleTypeOption,
    }),
    [defaultFlowRoleTypeOption],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      if (onSubmit) {
        await onSubmit({
          flowId: flowUser.flow_id,
          flowRoleTypeId: stringEmptyToUndefined(values.flowRoleType.value),
          userId: flowUser.user.id,
        })
      }
    },
    [onSubmit],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={onSubmit ? FLOW_USER_EDIT_ENABLED_ACTION_LABEL : FLOW_USER_EDIT_DISABLED_ACTION_LABEL} />
      <ModalContent style={ifTrue(!onSubmit, CommonStyles.marginBottomCard)}>
        <UserProfile colorVariant='light' user={flowUser.user} style={CommonStyles.marginBottomCard} />
        {onSubmit && (
          <SelectControl
            isRequired={true}
            label='Flow Role'
            name='flowRoleType'
            optionsOrGroups={flowRoleTypeOptionsOrGroups}
          />
        )}
        {!onSubmit && <Item colorVariant='light' body={flowUser.flow_role_type?.name ?? 'Not Set'} label='Flow Role' />}
      </ModalContent>
      {onSubmit && (
        <ModalFooter>
          <SubmitButton>Save</SubmitButton>
        </ModalFooter>
      )}
    </Form>
  )
}

export function useRegisterOrgFlowUserEditAction() {
  const [doFlowUserUpdateFlowRoleTypeUd] = useOrgFlowUserUpdateFlowRoleTypeIdMutation()
  const { dismissAllActions, dispatchAction, useRegisterAction } = useActions()
  const { org, orgConfig } = useOrg()

  const handleSubmitBase = useCallback(
    async (values: FlowUserEditActionValues) => {
      await doFlowUserUpdateFlowRoleTypeUd({
        variables: values,
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doFlowUserUpdateFlowRoleTypeUd, dismissAllActions],
  )

  const handleSubmit = ifTrue(
    org.role === Organization_Roles_Enum.Admin || org.role === Organization_Roles_Enum.Editor,
    handleSubmitBase,
  )

  useRegisterAction<FlowUserEditActionParams>(
    {
      id: FLOW_USER_EDIT_ACTION_ID,
      modal: ({ flowUser }) => <FlowUserEditAction flowUser={flowUser} onSubmit={handleSubmit} orgConfig={orgConfig} />,
    },
    [handleSubmit, orgConfig],
  )

  return useMemo(
    () => (params: FlowUserEditActionParams) =>
      dispatchAction({
        id: FLOW_USER_EDIT_ACTION_ID,
        params,
      }),
    [dispatchAction],
  )
}
