import { useEffect, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import { fileSize } from 'humanize-plus'
import { Accept, validateMimeType } from '@src/logic/data/uploads/index'

export type ManagedFile = {
  error?: string
  file: File
  id?: string // set after successful upload (i.e. if loading = false and error = undefined)
  internalId: number
  loading: boolean
  started: boolean
}

export type ManagedFilesListOptions = {
  accept: Accept
  maxFiles: number
  maxSize: number
  onChange?: (value: string[]) => void
}

export type ManagedFilesList = {
  complete: (internalId: number, id: string) => void
  fail: (internalId: number, err: Error) => void
  managedFiles: ManagedFile[]
  queue: (files: File[]) => void
  remove: (internalId: number) => void
  reset: () => void
  start: (internalId: number) => void
}

export function useManagedFilesList({
  accept,
  maxFiles,
  maxSize,
  onChange,
}: ManagedFilesListOptions): ManagedFilesList {
  const internalIdsRef = useRef(0)
  const managedFilesRef = useRef<ManagedFile[]>()
  const [_, setRender] = useState({})

  useEffect(() => {
    managedFilesRef.current = []
    return () => {
      managedFilesRef.current = undefined
    }
  }, [])

  const mutate = (mutator: (managedFiles: ManagedFile[]) => ManagedFile[]) => {
    if (managedFilesRef.current === undefined) {
      return
    }

    const oldManagedFiles = managedFilesRef.current
    managedFilesRef.current = mutator(managedFilesRef.current)
    setRender({})

    if (onChange) {
      const newIds = getIds(managedFilesRef.current)
      if (!isEqual(newIds, getIds(oldManagedFiles))) {
        onChange(newIds)
      }
    }
  }

  return {
    complete: (internalId: number, id: string) => {
      mutate((managedFiles) =>
        managedFiles.map((managedFile) =>
          managedFile.internalId === internalId ? { ...managedFile, loading: false, id } : managedFile,
        ),
      )
    },
    fail: (internalId: number, err: Error) => {
      mutate((managedFiles) =>
        managedFiles.map((managedFile) =>
          managedFile.internalId === internalId ? { ...managedFile, loading: false, error: err.message } : managedFile,
        ),
      )
    },
    managedFiles: managedFilesRef.current || [],
    queue: (files: File[]) => {
      mutate((managedFiles) => {
        const newManagedFiles = managedFiles.filter((file) => !file.error) // remove failed
        let remainingMaxFiles = maxFiles - newManagedFiles.length

        return newManagedFiles.concat(
          files.map((file) => {
            const error = validateFile(file, accept, maxFiles, maxSize, remainingMaxFiles)
            if (error) {
              return { error, file: file, internalId: internalIdsRef.current++, loading: false, started: true }
            }
            remainingMaxFiles--
            return { file: file, internalId: internalIdsRef.current++, loading: true, started: false }
          }),
        )
      })
    },
    remove: (internalId: number) => {
      mutate((managedFiles) => managedFiles.filter((managedFile) => managedFile.internalId !== internalId))
    },
    reset: () => {
      mutate(() => [])
    },
    start: (internalId: number) => {
      mutate((managedFiles) =>
        managedFiles.map((managedFile) =>
          internalId === managedFile.internalId ? { ...managedFile, started: true } : managedFile,
        ),
      )
    },
  }
}

export function fileListToArray(fileList: FileList | null | undefined): File[] {
  const files: File[] = []

  if (!fileList) {
    return files
  }

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item(i)
    if (file) {
      files.push(file)
    }
  }

  return files
}

function getIds(managedFiles: ManagedFile[]): string[] {
  return managedFiles.filter((managedFile) => !!managedFile.id).map((managedFile) => managedFile.id || '')
}

function validateFile(
  file: File,
  accept: Accept,
  maxFiles: number,
  maxSize: number,
  remainingMaxFiles: number,
): string | undefined {
  if (remainingMaxFiles === 0) {
    return `Too many files (max ${maxFiles})`
  }

  if (file.size > maxSize) {
    return `File too large (max ${fileSize(maxSize)})`
  }

  return validateMimeType(accept, file)
}
