import { Button, ButtonProps } from '@src/components/atoms/Button'
import React from 'react'
import { useForm, useFormState } from 'react-final-form'

export type SubmitButtonProps = Pick<ButtonProps, 'children' | 'style'>

export function SubmitButton({ children, ...props }: SubmitButtonProps) {
  const { submit } = useForm()
  const { submitting } = useFormState({ subscription: { submitting: true } })

  return (
    <Button {...props} colorVariant='primary' isDisabled={submitting} onPress={submit}>
      {children}
    </Button>
  )
}
