import {
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectControl,
  SubmitButton,
  TextInputControl,
} from '@src/components/atoms'
import {
  Flow_Field_Types_Enum,
  Flow_Field_Visibilities_Enum,
  Organization_Roles_Enum,
  OrgConfigDocument,
  OrgFlowFieldConfigurationInsertMutationVariables,
  useOrgFlowFieldConfigurationInsertMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import {
  getFlowFieldTypesPreOptionsOrGroups,
  getFlowFieldVisibilitiesPreOptionsOrGroups,
  initSelect,
  SelectOptionType,
} from '@src/logic/data/manipulation'
import {} from '@src/logic/data/manipulation/flowFields'
import { useOrg } from '@src/logic/data/providers'
import { ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { PlusSmIcon } from 'react-native-heroicons/solid'

export const FLOW_FIELD_CONFIGURATION_CREATE_ACTION_ID = 'flowFieldConfigurationCreate'
export const FLOW_FIELD_CONFIGURATION_CREATE_ACTION_LABEL = 'Create Flow Field Template'

export type FlowFieldConfigurationCreateActionValues = Omit<OrgFlowFieldConfigurationInsertMutationVariables, 'orgId'>

export type FlowFieldConfigurationCreateActionProps = {
  onSubmit: (values: FlowFieldConfigurationCreateActionValues) => Promise<void>
}

type FormType = {
  name: string
  visibility: SelectOptionType<Flow_Field_Visibilities_Enum>
  type: SelectOptionType<Flow_Field_Types_Enum>
}

export function FlowFieldConfigurationCreateAction({ onSubmit }: FlowFieldConfigurationCreateActionProps) {
  const { defaultOption: visibilityDefaultOption, optionsOrGroups: visibilityOptionsOrGroups } = useMemo(
    () => initSelect(JSON.stringify, getFlowFieldVisibilitiesPreOptionsOrGroups()),
    [],
  )

  const { defaultOption: typeDefaultOption, optionsOrGroups: typeOptionsOrGroups } = useMemo(
    () => initSelect(JSON.stringify, getFlowFieldTypesPreOptionsOrGroups()),
    [],
  )

  const initialValues = useMemo<FormType>(
    () => ({
      name: '',
      visibility: visibilityDefaultOption,
      type: typeDefaultOption,
    }),
    [],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        name: values.name,
        visibility: values.visibility.value,
        type: values.type.value,
      })
    },
    [onSubmit],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={FLOW_FIELD_CONFIGURATION_CREATE_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl isRequired={true} label='Name' name='name' placeholder='e.g. Deal Value...' />
        <SelectControl
          isRequired={true}
          label='Visibility'
          name='visibility'
          optionsOrGroups={visibilityOptionsOrGroups}
        />
        <SelectControl isRequired={true} label='Type' name='type' optionsOrGroups={typeOptionsOrGroups} />
      </ModalContent>
      <ModalFooter>
        <SubmitButton>Create</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterFlowFieldConfigurationCreateAction() {
  const [doFlowFieldConfigurationInsert] = useOrgFlowFieldConfigurationInsertMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org } = useOrg()

  const handleSubmit = useCallback(
    async (values: FlowFieldConfigurationCreateActionValues) => {
      await doFlowFieldConfigurationInsert({
        variables: {
          ...values,
          orgId: org.id,
        },
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doFlowFieldConfigurationInsert, dismissAllActions, org.id],
  )

  useRegisterAction<undefined>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: FLOW_FIELD_CONFIGURATION_CREATE_ACTION_ID,
      menuItem: {
        Icon: PlusSmIcon,
        label: FLOW_FIELD_CONFIGURATION_CREATE_ACTION_LABEL,
        params: undefined,
      },
      modal: () => <FlowFieldConfigurationCreateAction onSubmit={handleSubmit} />,
    }),
    [handleSubmit, org.role],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, () =>
        dispatchAction<undefined>({
          id: FLOW_FIELD_CONFIGURATION_CREATE_ACTION_ID,
          params: undefined,
        }),
      ),
    [dispatchAction, org.role],
  )
}
