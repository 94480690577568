import { Platform } from 'react-native'

export function isWeb(): boolean {
  return Platform.OS === 'web'
}

export function isNotWeb(): boolean {
  return Platform.OS !== 'web'
}

export function ifWeb<V>(value: V): V | undefined {
  return Platform.OS === 'web' ? value : undefined
}

export function ifNotWeb<V>(value: V): V | undefined {
  return Platform.OS !== 'web' ? value : undefined
}

export function ifWebOr<A, B>(ifWebValue: A, ifNotWebValue: B): A | B {
  return Platform.OS === 'web' ? ifWebValue : ifNotWebValue
}

export function getMouseHandlers(handleMouseEnter?: () => void, handleMouseLeave?: () => void) {
  return ifWeb({
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  })
}
