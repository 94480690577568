import { Card, Item, Text, IconButton } from '@src/components/atoms'
import { Flow_Configurations, Flow_Type_Statuses, Flow_Types, Maybe, Users } from '@src/gen/graphql/bindings'
import { useConfig } from '@src/logic/config'
import { getUserFullName } from '@src/logic/data/manipulation'
import { CommonStyles, Theme } from '@src/logic/design'
import React from 'react'
import { ViewStyle, Linking, View, StyleSheet } from 'react-native'
import { ExternalLinkIcon } from 'react-native-heroicons/solid'

export type FlowConfigurationCardProps = {
  flowConfiguration: Pick<Flow_Configurations, 'id' | 'name' | 'status' | 'welcome_chat_message'> & {
    flow_type_status?: Maybe<
      Pick<Flow_Type_Statuses, 'id' | 'name'> & {
        flow_type: Pick<Flow_Types, 'id' | 'name'>
      }
    >
    owner: Pick<Users, 'id' | 'first_name' | 'last_name'>
  }
  style?: ViewStyle
  onEdit?: () => void
}

const Styles = StyleSheet.create({
  headerView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Theme.geometry.card.spacing,
  },
})

export function FlowConfigurationCard({ flowConfiguration, style, onEdit }: FlowConfigurationCardProps) {
  const config = useConfig()

  return (
    <Card colorVariant='lightDim' style={style} onPress={onEdit}>
      <View style={Styles.headerView}>
        <Text colorVariant='light' numberOfLines={1} style={CommonStyles.flexOne} textVariant='title'>
          {flowConfiguration.name}
        </Text>
        <IconButton
          colorVariant='light'
          Icon={ExternalLinkIcon}
          onPress={() => Linking.openURL(`${config.widgetsBaseUrl}/${flowConfiguration.id}`)}
        />
      </View>
      {flowConfiguration.flow_type_status && (
        <Item
          colorVariant='light'
          body={[flowConfiguration.flow_type_status?.flow_type.name, flowConfiguration.flow_type_status?.name].join(
            '\n',
          )}
          label='Initial Type / Stage'
          style={CommonStyles.marginBottomCard}
        />
      )}
      {flowConfiguration.welcome_chat_message && (
        <Item
          colorVariant='light'
          body={flowConfiguration.welcome_chat_message}
          label='Welcome Message'
          style={CommonStyles.marginBottomCard}
        />
      )}
      {flowConfiguration.owner && (
        <Item
          avatar={{ user: flowConfiguration.owner }}
          body={getUserFullName(flowConfiguration.owner)}
          colorVariant='light'
          label='Initial Owner'
          numberOfLines={1}
          style={CommonStyles.marginBottomCard}
        />
      )}
    </Card>
  )
}
