import { useRegisterFlowUserViewAction } from '@src/components/actions/FlowUserViewAction'
import {
  Flow_Roles_Enum,
  Flow_Statuses_Enum,
  UnvFlowDocument,
  UnvFlowSubscription,
  UnvFlowSubscriptionVariables,
  useUnvMessageCreateMutation,
  useUnvUpdateFlowsUsersSeenMutation,
} from '@src/gen/graphql/bindings'
import { useUnverifiedHasuraClaims } from '@src/logic/auth'
import { processError } from '@src/logic/errors'
import { useSubscription } from '@src/logic/graphql'
import { useRequiredContext } from '@src/logic/utils'
import { ifDefThen } from '@src/logic/utils/misc'
import React, { createContext, ReactNode } from 'react'
import useAsyncEffect from 'use-async-effect'

const MESSAGES_LIMIT = 50

export type UnvFlowContextType = ReturnType<typeof useContext>
export const UnvFlowContext = createContext<UnvFlowContextType | undefined>(undefined)
export const useUnvFlow = () => useRequiredContext(UnvFlowContext)

export type UnvFlowProviderProps = {
  children?: ReactNode
  flowId: string
}

export function UnvFlowProvider({ children, flowId }: UnvFlowProviderProps) {
  const { data, error, loading } = useSubscription<UnvFlowSubscription, UnvFlowSubscriptionVariables>(UnvFlowDocument, {
    flowId: flowId,
    messagesLimit: MESSAGES_LIMIT,
  })

  if (error) {
    throw error
  }

  if (loading && data === undefined) {
    return null
  }

  if (data === undefined) {
    throw new Error('No data.')
  }

  return <UnvFlowProviderInner data={data}>{children}</UnvFlowProviderInner>
}

type UnvFlowProviderInnerProps = {
  children?: ReactNode
  data: UnvFlowSubscription
}

function UnvFlowProviderInner({ children, data }: UnvFlowProviderInnerProps) {
  return <UnvFlowContext.Provider value={useContext(data)}>{children}</UnvFlowContext.Provider>
}

function useContext(data: UnvFlowSubscription) {
  const [messageCreateMutation] = useUnvMessageCreateMutation()
  const [updateFlowsUsersSeenMutation] = useUnvUpdateFlowsUsersSeenMutation()

  const flow = data.flows_by_pk
  if (!flow) {
    throw new Error('Not found.')
  }

  // TODO(ibrt): Maybe we should get the user ID from data, if there's a way?
  const hasuraClaims = useUnverifiedHasuraClaims(flow.id)
  if (!hasuraClaims) {
    throw new Error('Not found.')
  }

  const unvUser = flow.flows_users.find((fu) => fu.user.id === hasuraClaims.unverifiedUserId)?.user
  if (!unvUser) {
    throw new Error('Not found.')
  }

  const isEditable = flow.status !== Flow_Statuses_Enum.Closed

  let flowUser = flow.flows_users.find(
    (fu) => fu.user.id === unvUser.id && fu.role === Flow_Roles_Enum.UnverifiedExternalParticipant,
  )

  const hasUnseenActionItems =
    flowUser && flow.action_items_activity_counter > flowUser.seen_action_items_activity_counter
  const hasUnseenMessages = flowUser && flow.messages_activity_counter > flowUser.seen_messages_activity_counter
  const hasUnseenStatus = flowUser && flow.status_activity_counter > flowUser.seen_status_activity_counter
  const hasUnseenFields = flowUser && flow.fields_activity_counter > flowUser.seen_fields_activity_counter
  const hasUnseenInternalParticipants =
    flowUser && flow.internal_participants_activity_counter > flowUser.seen_internal_participants_activity_counter
  const hasUnseenExternalParticipants =
    flowUser && flow.external_participants_activity_counter > flowUser.seen_external_participants_activity_counter

  let seenActionItemsUpdated = false
  let seenMessagesUpdated = false
  let seenStatusUpdated = false
  let seenFieldsUpdated = false
  let seenInternalParticipantsUpdated = false
  let seenExternalParticipantsUpdated = false

  const useSeen = (sections: {
    actionItems?: boolean
    messages?: boolean
    status?: boolean
    fields?: boolean
    internalParticipants?: boolean
    externalParticipants?: boolean
  }) => {
    useAsyncEffect(async () => {
      try {
        if (!flowUser) {
          return
        }

        if (
          (!sections.actionItems || seenActionItemsUpdated || !hasUnseenActionItems) &&
          (!sections.messages || seenMessagesUpdated || !hasUnseenMessages) &&
          (!sections.status || seenStatusUpdated || !hasUnseenStatus) &&
          (!sections.fields || seenFieldsUpdated || !hasUnseenFields) &&
          (!sections.internalParticipants || seenInternalParticipantsUpdated || !hasUnseenInternalParticipants) &&
          (!sections.externalParticipants || seenExternalParticipantsUpdated || !hasUnseenExternalParticipants)
        ) {
          return
        }
      } finally {
        seenActionItemsUpdated = seenActionItemsUpdated || (sections.actionItems ?? false)
        seenMessagesUpdated = seenMessagesUpdated || (sections.messages ?? false)
        seenStatusUpdated = seenStatusUpdated || (sections.status ?? false)
        seenFieldsUpdated = seenFieldsUpdated || (sections.fields ?? false)
        seenInternalParticipantsUpdated = seenInternalParticipantsUpdated || (sections.internalParticipants ?? false)
        seenExternalParticipantsUpdated = seenExternalParticipantsUpdated || (sections.externalParticipants ?? false)
      }

      try {
        await updateFlowsUsersSeenMutation({
          variables: {
            flowId: flow.id,
            seenActionItemsActivityCounter: sections.actionItems ? flow.action_items_activity_counter : 0,
            seenMessagesActivityCounter: sections.messages ? flow.messages_activity_counter : 0,
            seenStatusActivityCounter: sections.status ? flow.status_activity_counter : 0,
            seenFieldsActivityCounter: sections.fields ? flow.fields_activity_counter : 0,
            seenInternalParticipantsActivityCounter: sections.internalParticipants
              ? flow.internal_participants_activity_counter
              : 0,
            seenExternalParticipantsActivityCounter: sections.externalParticipants
              ? flow.external_participants_activity_counter
              : 0,
          },
        })
      } catch (err: any) {
        processError(err)
      }
    })
  }

  const messageCreate = async (chatContent: string) => {
    await messageCreateMutation({
      variables: {
        flowId: flow.id,
        chatContent,
      },
    })
  }

  const dispatchFlowUserViewAction = useRegisterFlowUserViewAction()

  return {
    flow,
    flowUser,
    unvUser,
    isEditable: isEditable,
    messageCreate: ifDefThen(messageCreate, (v) => v),
    hasUnseen: {
      actionItems: hasUnseenActionItems,
      messages: hasUnseenMessages,
      status: hasUnseenStatus,
      fields: hasUnseenFields,
      internalParticipants: hasUnseenInternalParticipants,
      externalParticipants: hasUnseenExternalParticipants,
    },
    useSeen,
    dispatchFlowUserViewAction,
  }
}
