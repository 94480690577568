import {
  Button,
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SubmitButton,
  TextInputControl,
} from '@src/components/atoms'
import {
  Organization_Roles_Enum,
  OrgConfigDocument,
  OrgConfigQuery,
  OrgFlowRoleTypeUpdateMutationVariables,
  useOrgFlowRoleTypeDeleteMutation,
  useOrgFlowRoleTypeUpdateMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { useOrg } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'
import { ArrayElement, ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'

export const FLOW_ROLE_TYPE_EDIT_ACTION_ID = 'flowRoleTypeEdit'
export const FLOW_ROLE_TYPE_EDIT_ACTION_LABEL = 'Edit Flow Role'

export type FlowRoleTypeEditActionValues = OrgFlowRoleTypeUpdateMutationVariables

export type FlowRoleTypeEditActionParams = {
  flowRoleType: ArrayElement<OrgConfigQuery['flow_role_types']>
}

export type FlowRoleTypeEditActionProps = FlowRoleTypeEditActionParams & {
  onDelete: (id: string) => Promise<void>
  onSubmit: (values: FlowRoleTypeEditActionValues) => Promise<void>
}

type FormType = {
  name: string
}

export function FlowRoleTypeEditAction({ flowRoleType, onDelete, onSubmit }: FlowRoleTypeEditActionProps) {
  const initialValues = useMemo<FormType>(
    () => ({
      name: flowRoleType.name,
    }),
    [],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        ...values,
        id: flowRoleType.id,
      })
    },
    [onSubmit],
  )

  const handleDelete = useCallback(() => onDelete(flowRoleType.id), [flowRoleType.id, onDelete])

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={FLOW_ROLE_TYPE_EDIT_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl isRequired={true} label='Name' name='name' placeholder='e.g. Lawyer, Sales Manager...' />
      </ModalContent>
      <ModalFooter>
        <Button colorVariant='light' onPress={handleDelete}>
          Delete
        </Button>
        <SubmitButton style={CommonStyles.marginLeftCard}>Save</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterFlowRoleTypeEditAction() {
  const [doFlowRoleTypeDelete] = useOrgFlowRoleTypeDeleteMutation()
  const [doFlowRoleTypeUpdate] = useOrgFlowRoleTypeUpdateMutation()
  const { dismissAllActions, dispatchAction, useRegisterAction } = useActions()
  const { org } = useOrg()

  const handleSubmit = useCallback(
    async (values: FlowRoleTypeEditActionValues) => {
      await doFlowRoleTypeUpdate({
        variables: values,
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doFlowRoleTypeUpdate, dismissAllActions],
  )

  const handleDelete = useCallback(
    async (id: string) => {
      await doFlowRoleTypeDelete({
        variables: {
          id,
          at: new Date().toISOString(),
        },
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doFlowRoleTypeDelete, dismissAllActions],
  )

  useRegisterAction<FlowRoleTypeEditActionParams>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: FLOW_ROLE_TYPE_EDIT_ACTION_ID,
      modal: ({ flowRoleType }) => (
        <FlowRoleTypeEditAction flowRoleType={flowRoleType} onDelete={handleDelete} onSubmit={handleSubmit} />
      ),
    }),
    [handleSubmit, handleDelete, org.role],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, (params: FlowRoleTypeEditActionParams) =>
        dispatchAction({
          id: FLOW_ROLE_TYPE_EDIT_ACTION_ID,
          params,
        }),
      ),
    [dispatchAction, org.role],
  )
}
