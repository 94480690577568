import { LogoBox } from '@src/components/atoms/LogoBox'
import { CommonStyles, Theme, useScreen } from '@src/logic/design'
import { ifTrue } from '@src/logic/utils'
import React, { memo, ReactNode, useMemo } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

export type FullScreenProps = {
  children?: ReactNode
  style?: ViewStyle
}

export const FullScreen = memo(FullScreenComponent)

const Styles = StyleSheet.create({
  sideView: {
    backgroundColor: Theme.colors.dark.backgroundColor,
    padding: Theme.geometry.structure.spacing,
  },
  mainView: {
    alignItems: 'center',
    backgroundColor: Theme.colors.light.backgroundColor,
    flex: 1,
    justifyContent: 'center',
  },
})

function FullScreenComponent({ children, style }: FullScreenProps) {
  const { isDesktop } = useScreen()

  const viewStyle = useMemo(() => [CommonStyles.flexOne, ifTrue(isDesktop, CommonStyles.flexRow), style], [style])

  return (
    <View style={viewStyle}>
      <View style={Styles.sideView}>
        <LogoBox />
      </View>
      <View style={Styles.mainView}>{children}</View>
    </View>
  )
}
