import { IconButton } from '@src/components/atoms/IconButton'
import { CommonStyles, ForegroundStyles, Theme } from '@src/logic/design'
import { getMouseHandlers, ifTrue } from '@src/logic/utils'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { StyleSheet, TextInput, View, ViewStyle } from 'react-native'
import { PaperAirplaneIcon } from 'react-native-heroicons/solid'

export type ChatBoxProps = {
  onSend: (chatContent: string) => Promise<void>
  style?: ViewStyle
}

export const ChatBox = memo(ChatBoxComponent)

const Styles = StyleSheet.create({
  view: {
    ...Theme.shadows.standard,
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.lightDim.borderColor,
    borderRadius: Theme.geometry.card.radius,
    borderWidth: 1,
    flexDirection: 'row',
    padding: Theme.geometry.control.spacing,
  },
  viewDisabled: {
    backgroundColor: Theme.colors.lightDim.backgroundColor,
    borderColor: Theme.colors.lightDim.borderColor,
  },
  viewHighlighted: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.light.color,
  },
  textInput: {
    ...Theme.text.body,
    borderWidth: 0,
    flex: 1,
  },
  rightView: {
    borderLeftColor: Theme.colors.lightDim.borderColor,
    borderLeftWidth: 1,
    justifyContent: 'center',
    marginTop: 40,
    paddingLeft: 16,
  },
  rightViewHighlighted: {
    borderLeftColor: Theme.colors.light.color,
  },
})

type ChatBoxState = {
  chatContent: string
  loading: boolean
}

function ChatBoxComponent({ onSend, style }: ChatBoxProps) {
  const inputRef = useRef<any>()

  const [state, setState] = useState<ChatBoxState>({ chatContent: '', loading: false })
  const [isFocused, setIsFocused] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const isDisabled = state.loading

  const viewStyle = useMemo(
    () => [
      Styles.view,
      ifTrue(isDisabled, CommonStyles.cursorNotAllowed),
      ifTrue(isDisabled, Styles.viewDisabled),
      ifTrue(!isDisabled && (isFocused || isHover), Styles.viewHighlighted),
      style,
    ],
    [isDisabled, isFocused, isHover, style],
  )

  const textInputStyle = useMemo(
    () => [
      Styles.textInput,
      ifTrue(isDisabled, CommonStyles.cursorNotAllowed),
      isDisabled ? ForegroundStyles.lightDim : ForegroundStyles.light,
      CommonStyles.outlineNone,
    ],
    [isDisabled],
  )

  const rightViewStyle = useMemo(
    () => [Styles.rightView, ifTrue(!isDisabled && (isFocused || isHover), Styles.rightViewHighlighted)],
    [isDisabled, isFocused, isHover],
  )

  const handleSend = async () => {
    try {
      setState({ ...state, loading: true })
      await onSend(state.chatContent)
    } finally {
      setState({ chatContent: '', loading: false })
      inputRef.current?.focus()
    }
  }

  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handleBlur = useCallback(() => setIsFocused(false), [setIsFocused])
  const handleFocus = useCallback(() => setIsFocused(true), [setIsFocused])

  return (
    <View style={viewStyle}>
      <TextInput
        {...handleMouse}
        onBlur={handleBlur}
        onChangeText={(chatContent: string) => {
          setState({ ...state, chatContent })
        }}
        onFocus={handleFocus}
        onKeyPress={async (event: any) => {
          if (event.key === 'Enter') {
            if (event.shiftKey) {
              return
            }

            event.preventDefault()
            if (state.chatContent) {
              await handleSend()
            }
          }
        }}
        multiline={true}
        numberOfLines={3}
        placeholder='Send a message...'
        placeholderTextColor={state.loading ? Theme.colors.lightDim.borderColor : Theme.colors.lightDim.color}
        ref={inputRef}
        style={textInputStyle}
        value={state.chatContent}
      />
      <View style={rightViewStyle}>
        <IconButton
          colorVariant='light'
          Icon={PaperAirplaneIcon}
          iconRotation={90}
          isDisabled={isDisabled || !state.chatContent}
          onPress={handleSend}
        />
      </View>
    </View>
  )
}
