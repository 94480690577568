import { Button, Checkbox, Item, ModalContent, ModalFooter, ModalHeader, Text } from '@src/components/atoms'
import { Flow_Roles_Enum, Flows_Users, OrgConfigQuery, Users } from '@src/gen/graphql'
import { getUserFullName } from '@src/logic/data/manipulation'
import { CommonStyles, Theme } from '@src/logic/design'
import React, { Fragment, useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'

export const FLOW_INTERNAL_PARTICIPANTS_EDIT_ACTION_ID = 'flowInternalParticipantsEdit'
export const FLOW_INTERNAL_PARTICIPANTS_EDIT_ACTION_LABEL = 'Edit Internal Participants'

export type FlowInternalParticipantsEditActionValuesType = {
  flowsUsersInsertInputs: Array<{
    flow_id: string
    role: Flow_Roles_Enum
    user_id: string
  }>
}

export type FlowInternalParticipantsEditActionProps = {
  flow: {
    id: string
    flows_users: Array<
      Pick<Flows_Users, 'role'> & {
        user: Pick<Users, 'id' | 'first_name' | 'last_name'>
      }
    >
  }
  onSubmit: (values: FlowInternalParticipantsEditActionValuesType) => Promise<void>
  orgConfig: OrgConfigQuery
}

const Styles = StyleSheet.create({
  rowView: {
    flexDirection: 'row',
    height: 48,
  },
  headerView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  item: {
    flex: 1,
    marginLeft: Theme.geometry.card.spacing,
    marginRight: Theme.geometry.card.spacing,
  },
})

export function FlowInternalParticipantsEditAction({
  flow,
  onSubmit,
  orgConfig,
}: FlowInternalParticipantsEditActionProps) {
  const [options, setOptions] = useState(getOptions(flow, orgConfig))

  const handleParticipantChange = useCallback(
    (userId: string, isChecked: boolean) =>
      setOptions(
        options.map((o) => ({
          ...o,
          isParticipant: o.user.id === userId ? isChecked : o.isParticipant,
        })),
      ),
    [options, setOptions],
  )

  const handleOwnerChange = useCallback(
    (userId: string, isChecked: boolean) => {
      if (!isChecked) {
        return
      }

      setOptions(
        options.map((o) => ({
          ...o,
          isOwner: o.user.id === userId,
          isParticipant: o.user.id === userId || o.isParticipant,
        })),
      )
    },
    [options, setOptions],
  )

  const handleSubmit = useCallback(async (values: Option[]) => {
    await onSubmit({
      flowsUsersInsertInputs: values
        .filter((v) => isChanged(v))
        .map((v) => ({
          flow_id: flow.id,
          user_id: v.user.id,
          role: v.isOwner
            ? Flow_Roles_Enum.OrganizationOwner
            : v.isParticipant
            ? Flow_Roles_Enum.OrganizationParticipant
            : Flow_Roles_Enum.OrganizationNone,
        })),
    })
  }, [])

  const handleReset = useCallback(
    () => setOptions(getOptions(flow, orgConfig)),
    [setOptions, getOptions, flow, orgConfig],
  )

  const handleApply = useCallback(() => handleSubmit(options), [handleSubmit, options])

  return (
    <Fragment>
      <ModalHeader title={FLOW_INTERNAL_PARTICIPANTS_EDIT_ACTION_LABEL} />
      <ModalContent>
        <View style={Styles.headerView}>
          <Text colorVariant='lightDim' textVariant='eyebrow'>
            Participants
          </Text>
          <Text colorVariant='lightDim' textVariant='eyebrow'>
            Owner
          </Text>
        </View>
        {options.map((o) => (
          <View key={o.user.id} style={Styles.rowView}>
            <Checkbox
              isChecked={o.isParticipant}
              isDisabled={o.isOwner}
              onChange={handleParticipantChange}
              value={o.user.id}
            />
            <Item
              colorVariant='light'
              avatar={{ user: o.user }}
              badge={isChanged(o) ? { colorVariant: 'lightDim', label: 'Changed' } : undefined}
              body={o.userFullName}
              style={Styles.item}
            />
            <Checkbox isChecked={o.isOwner} onChange={handleOwnerChange} value={o.user.id} />
          </View>
        ))}
      </ModalContent>
      <ModalFooter>
        <Button colorVariant='light' onPress={handleReset}>
          Reset
        </Button>
        <Button
          colorVariant='primary'
          isDisabled={!hasChanges(options)}
          onPress={handleApply}
          style={CommonStyles.marginLeftCard}>
          Apply
        </Button>
      </ModalFooter>
    </Fragment>
  )
}

type Option = {
  isOriginalOwner: boolean
  isOriginalParticipant: boolean
  isOwner: boolean
  isParticipant: boolean
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
  userFullName: string
}

function getOptions(flow: FlowInternalParticipantsEditActionProps['flow'], orgConfig: OrgConfigQuery): Option[] {
  return orgConfig.users_organizations
    .map((uo) => {
      const flowUser = flow.flows_users.find((fu) => fu.user.id === uo.user.id)
      const isOwner = flowUser?.role === Flow_Roles_Enum.OrganizationOwner
      const isParticipant = isOwner || flowUser?.role === Flow_Roles_Enum.OrganizationParticipant

      return {
        isOriginalOwner: isOwner,
        isOriginalParticipant: isParticipant,
        isOwner,
        isParticipant,
        user: uo.user,
        userFullName: getUserFullName(uo.user),
      }
    })
    .sort((o1, o2) => o1.userFullName.localeCompare(o2.userFullName))
}

function hasChanges(options: Option[]) {
  return !!options.find((o) => isChanged(o))
}

function isChanged(o: Option) {
  return o.isParticipant !== o.isOriginalParticipant || o.isOwner !== o.isOriginalOwner
}
