import { Control } from '@src/components/atoms/Control'
import { FileUpload } from '@src/components/atoms/FileUpload'
import { FileUploadOptions, useFileUpload } from '@src/logic/data/uploads'
import { ifTrue, usePrevious } from '@src/logic/utils'
import React, { useEffect } from 'react'
import { useField, useForm } from 'react-final-form'

export type FileUploadControlProps = Omit<FileUploadOptions, 'disabled' | 'onChange' | 'externalError'> & {
  isRequired?: boolean
  label: string
  name: string
}

export function FileUploadControl({ isRequired = false, label, name, ...props }: FileUploadControlProps) {
  const form = useForm()

  const {
    input: { value, onChange },
    meta: { error, submitting, touched },
  } = useField<string[]>(name, {
    subscription: { error: true, submitting: true, touched: true, value: true },
    validate: (value: string[]) => ((value && value.length > 0) || !isRequired ? undefined : 'Please select a file.'),
  })

  const { fileUploadProps, reset } = useFileUpload({
    ...props,
    disabled: submitting,
    onChange: (value) => {
      if (value && value.length) {
        form.mutators.setFieldTouched(name, true)
      }
      onChange(value)
    },
  })

  const previousValue = usePrevious(value)

  useEffect(() => {
    if (previousValue && previousValue.length && (!value || !value.length)) {
      reset()
    }
  })

  return (
    <Control error={ifTrue(touched ?? false, error)} isRequired={isRequired} label={label}>
      <FileUpload colorVariant='light' {...fileUploadProps} />
    </Control>
  )
}
