import {
  ScreenContent,
  ScreenPrimary,
  screenPrimaryFlowIdExtractor,
  ScreenPrimaryItemSeparator,
  screenPrimaryRenderSectionHeader,
  ScreenPrimarySectionSeparator,
  ScreenPrimaryStyles,
  ScreenSecondary,
  Tabs,
} from '@src/components/atoms'
import { FlowNotificationCard, UserPaneProfile } from '@src/components/molecules'
import { ExtFlowNotificationsQuery } from '@src/gen/graphql'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { useExtFlowNotifications } from '@src/logic/data/providers'
import { CommonStyles, useScreen } from '@src/logic/design'
import { NavigateFuncFlow } from '@src/logic/routing/common'
import { useExtRouting } from '@src/logic/routing/providers'
import { ArrayElement } from '@src/logic/utils'
import React, { Fragment, useCallback, useMemo } from 'react'
import { FlatList, View } from 'react-native'

export type ExtUserPartProps = {
  view: 'profile' | 'notifications'
}

export function ExtUserPart({ view }: ExtUserPartProps) {
  const { isPhone } = useScreen()
  const { useUserTabs } = useExtRouting()
  const userTabs = useUserTabs()

  return (
    <Fragment>
      {isPhone && <Tabs active={userTabs.activeTab?.id} onChange={userTabs.navigateTo} tabs={userTabs.tabs} />}
      <ScreenContent>
        {(!isPhone || view === 'profile') && <ExtUserProfilePart />}
        {(!isPhone || view === 'notifications') && <ExtUserNotificationsPart />}
      </ScreenContent>
    </Fragment>
  )
}

function ExtUserProfilePart() {
  const { user } = useAuthenticatedAuth()
  return (
    <ScreenSecondary isScrollable={true}>
      <UserPaneProfile user={user} />
    </ScreenSecondary>
  )
}

function ExtUserNotificationsPart() {
  const { flow_notifications, fetchMoreNotifications, clearNotification } = useExtFlowNotifications()
  const { useNavigateToFlow } = useExtRouting()

  const navigateToFlow = useNavigateToFlow()

  const ListHeaderComponent = useCallback(
    () =>
      screenPrimaryRenderSectionHeader({
        section: {
          title: 'Notifications',
          centeredVariant: 'medium',
        },
        style: CommonStyles.marginBottomStructure,
      }),
    [screenPrimaryRenderSectionHeader],
  )

  const renderItem = useCallback(
    ({ item, index }) => (
      <ExtUserNotificationsPartItem
        flowNotification={item}
        navigateToFlow={navigateToFlow}
        index={index}
        clearNotification={clearNotification}
      />
    ),
    [navigateToFlow],
  )

  return (
    <ScreenPrimary>
      <FlatList
        ItemSeparatorComponent={ScreenPrimaryItemSeparator}
        ListFooterComponent={ScreenPrimarySectionSeparator}
        ListHeaderComponent={ListHeaderComponent}
        data={flow_notifications}
        keyExtractor={screenPrimaryFlowIdExtractor}
        onEndReached={fetchMoreNotifications}
        onEndReachedThreshold={0.25}
        renderItem={renderItem}
        style={CommonStyles.flexOne}
      />
    </ScreenPrimary>
  )
}

type ExtUserNotificationsPartItemProps = {
  index: number
  flowNotification: ArrayElement<ExtFlowNotificationsQuery['flow_notifications']>
  navigateToFlow: NavigateFuncFlow
  clearNotification: (flowId: string, runAnimation: (toValue: number) => Promise<boolean>) => Promise<void>
}

function ExtUserNotificationsPartItem({
  flowNotification,
  navigateToFlow,
  index,
  clearNotification,
}: ExtUserNotificationsPartItemProps) {
  const handlePress = useCallback(
    () => navigateToFlow(flowNotification.flow_id),
    [flowNotification.flow_id, navigateToFlow],
  )

  return (
    <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
      <FlowNotificationCard
        flowNotification={flowNotification}
        onPress={handlePress}
        variant='ext'
        index={index}
        clearNotification={clearNotification}
      />
    </View>
  )
}
