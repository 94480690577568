import { Card, Item, ItemBadgeLabel, Text } from '@src/components/atoms'
import {
  Flow_Outcomes_Enum,
  Flow_Type_Statuses,
  Flow_Types,
  Flows,
  Flows_Users,
  Maybe,
  Users,
} from '@src/gen/graphql/bindings'
import { getFlowBadge, getFlowFlowsUsersSummary, getFlowOwner, getUserFullName } from '@src/logic/data/manipulation'
import { formatTimeSince, ifTrue } from '@src/logic/utils'
import { endsWith } from 'lodash'
import React, { Fragment, memo, useMemo, useState } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'

export type OrgFlowCardProps = {
  flow: Pick<
    Flows,
    | 'id'
    | 'last_activity_at'
    | 'status'
    | 'action_items_activity_counter'
    | 'messages_activity_counter'
    | 'internal_participants_activity_counter'
    | 'external_participants_activity_counter'
  > & {
    status_activity_counter: number
    flow_type_status?: Maybe<
      Pick<Flow_Type_Statuses, 'id' | 'name'> & {
        flow_type: Pick<Flow_Types, 'id' | 'name'>
      }
    >
    flows_users: Array<
      Pick<
        Flows_Users,
        | 'seen_action_items_activity_counter'
        | 'seen_messages_activity_counter'
        | 'seen_status_activity_counter'
        | 'seen_internal_participants_activity_counter'
        | 'seen_external_participants_activity_counter'
        | 'role'
      > & {
        user: Pick<Users, 'id' | 'first_name' | 'last_name'>
      }
    >
    outcome?: Maybe<Flow_Outcomes_Enum>
    name: string
  }
  onPress?: () => void
  style?: ViewStyle
  user?: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

export const OrgFlowCard = memo(OrgFlowCardComponent)

const Styles = StyleSheet.create({
  title: {
    marginBottom: 8,
  },
  row: {
    marginBottom: 16,
  },
})

function OrgFlowCardComponent({ flow, onPress, style, user }: OrgFlowCardProps) {
  const [isExpanded, setIsExpanded] = useState(false)

  const badge = useMemo(() => getFlowBadge('solid', flow.status, flow.outcome), [flow.status, flow.outcome])
  const owner = useMemo(() => getFlowOwner(flow.flows_users), [flow])
  const avatar = useMemo(() => ({ user: owner }), [owner.id, owner.first_name, owner.last_name])
  const flowUser = flow.flows_users.find((fu) => fu.user.id === user?.id && !endsWith(fu.role, 'none'))

  const hasUnseen =
    flowUser &&
    (flowUser.seen_action_items_activity_counter < flow.action_items_activity_counter ||
      flowUser.seen_messages_activity_counter < flow.messages_activity_counter ||
      flowUser.seen_status_activity_counter < flow.status_activity_counter ||
      flowUser.seen_external_participants_activity_counter < flow.external_participants_activity_counter ||
      flowUser.seen_internal_participants_activity_counter < flow.internal_participants_activity_counter)

  return (
    <Card
      colorVariant={badge.colorVariant}
      isExpanded={isExpanded}
      onExpand={setIsExpanded}
      onPress={onPress}
      style={style}>
      <ItemBadgeLabel
        badge={badge}
        label={formatTimeSince(flow.last_activity_at)}
        showActivity={hasUnseen}
        style={Styles.row}
      />
      <Text colorVariant='light' numberOfLines={ifTrue(!isExpanded, 1)} style={Styles.title} textVariant='title'>
        {flow.name}
      </Text>
      <Text colorVariant='lightDim' numberOfLines={ifTrue(!isExpanded, 1)} style={Styles.row} textVariant='body'>
        {getFlowFlowsUsersSummary(flow.flows_users)}
      </Text>
      {isExpanded && (
        <Fragment>
          {flow.flow_type_status && (
            <Item body={flow.flow_type_status.flow_type.name} colorVariant='light' label='Type' style={Styles.row} />
          )}
          {flow.flow_type_status && (
            <Item body={flow.flow_type_status.name} colorVariant='light' label='Stage' style={Styles.row} />
          )}
          <Item avatar={avatar} body={getUserFullName(owner)} colorVariant='light' label='Owner' style={Styles.row} />
        </Fragment>
      )}
    </Card>
  )
}
