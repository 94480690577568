import { Logo } from '@src/components/atoms/Logo'
import { Text } from '@src/components/atoms/Text'
import { useConfig } from '@src/logic/config'
import React, { memo } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

export type LogoBoxProps = {
  style?: ViewStyle
}

const Styles = StyleSheet.create({
  kriloText: {
    paddingBottom: 16,
  },
  poweredByText: {
    paddingBottom: 8,
  },
  versionText: {
    paddingTop: 8,
  },
})

export const LogoBox = memo(LogoBoxComponent)

function LogoBoxComponent({ style }: LogoBoxProps) {
  const config = useConfig()

  return (
    <View style={style}>
      <Text colorVariant='dark' style={Styles.kriloText} textVariant='h3'>
        Krilo
      </Text>
      <Text colorVariant='darkDim' style={Styles.poweredByText} textVariant='eyebrow'>
        Powered By
      </Text>
      <Logo colorVariant='dark' height={10} width={116.1} />
      {(config.stage === 'dev' || config.stage === 'staging') && (
        <Text colorVariant='darkDim' textVariant='smallText' style={Styles.versionText}>
          {`${config.release} [${config.stage}]`}
        </Text>
      )}
    </View>
  )
}
