import { Text } from '@src/components/atoms/Text'
import { Theme, useScreen } from '@src/logic/design'
import { ifDef, ifTrue, isWeb } from '@src/logic/utils'
import React, { ReactNode, useMemo } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

export type ScreenPrimaryProps = {
  children?: ReactNode
  style?: ViewStyle
}

export const ScreenPrimaryStyles = StyleSheet.create({
  containerViewCenteredMedium: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 720,
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
    width: '100%',
  },
  containerViewCenteredLarge: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 960,
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
    width: '100%',
  },
  containerViewCenteredFull: {
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
    width: '100%',
  },
  containerViewHorizontal: {
    paddingLeft: Theme.geometry.structure.spacing / 2,
    paddingRight: Theme.geometry.structure.spacing / 2,
  },
})

const Styles = StyleSheet.create({
  view: {
    alignItems: 'stretch',
    backgroundColor: Theme.colors.lightDim.backgroundColor,
    flex: 1,
  },
  headerInnerView: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: Theme.geometry.structure.spacing,
  },
  sectionSeparatorView: {
    height: Theme.geometry.structure.spacing,
  },
  itemSeparatorView: {
    height: isWeb() ? Theme.geometry.structure.spacing / 2 : Theme.geometry.structure.spacing * 0.75,
  },
})

export function ScreenPrimary({ children, style }: ScreenPrimaryProps) {
  const { screenHeight } = useScreen()

  const viewStyle = useMemo(
    () => [Styles.view, ifDef(screenHeight, { height: screenHeight }), style],
    [screenHeight, style],
  )

  return <View style={viewStyle}>{children}</View>
}

export type ScreenPrimaryHeaderProps = {
  centeredVariant?: 'medium' | 'large' | 'full'
  children?: ReactNode
  style?: ViewStyle
}

export function ScreenPrimaryHeader({ centeredVariant, children, style }: ScreenPrimaryHeaderProps) {
  const innerViewStyle = useMemo(
    () => [
      Styles.headerInnerView,
      ifTrue(centeredVariant === 'medium', ScreenPrimaryStyles.containerViewCenteredMedium),
      ifTrue(centeredVariant === 'large', ScreenPrimaryStyles.containerViewCenteredLarge),
      ifTrue(centeredVariant === 'full', ScreenPrimaryStyles.containerViewCenteredFull),
    ],
    [centeredVariant],
  )

  return (
    <View style={style}>
      <View style={innerViewStyle}>{children}</View>
    </View>
  )
}

export function ScreenPrimarySectionSeparator() {
  return <View style={Styles.sectionSeparatorView} />
}

export function ScreenPrimaryItemSeparator() {
  return <View style={Styles.itemSeparatorView} />
}

export function screenPrimaryIdKeyExtractor(item: any) {
  return item.id
}

export function screenPrimaryTitleKeyExtractor(item: any) {
  return item.title
}

export function screenPrimaryNameKeyExtractor(item: any) {
  return item.name
}

export function screenPrimaryFlowIdExtractor(item: any) {
  return item.flow_id
}

export type ScreenPrimaryRenderSectionHeaderType = {
  section: {
    title: string
    centeredVariant?: 'medium' | 'large'
  }
  style?: ViewStyle
}

export function screenPrimaryRenderSectionHeader({ section, style }: ScreenPrimaryRenderSectionHeaderType) {
  return (
    <ScreenPrimaryHeader centeredVariant={section.centeredVariant} style={style}>
      <Text colorVariant='lightDim' textVariant='eyebrow'>
        {section.title}
      </Text>
    </ScreenPrimaryHeader>
  )
}
