import {
  Button,
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectControl,
  SubmitButton,
  TextInputControl,
} from '@src/components/atoms'
import {
  Flow_Field_Types_Enum,
  Flow_Field_Visibilities_Enum,
  Organization_Roles_Enum,
  OrgConfigDocument,
  OrgConfigQuery,
  OrgFlowFieldConfigurationUpdateMutationVariables,
  useOrgFlowFieldConfigurationDeleteMutation,
  useOrgFlowFieldConfigurationUpdateMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import {
  getFlowFieldTypesPreOptionsOrGroups,
  getFlowFieldVisibilitiesPreOptionsOrGroups,
  initSelect,
  SelectOptionType,
} from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'
import { ArrayElement, ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'

export const FLOW_FIELD_CONFIGURATION_EDIT_ACTION_ID = 'flowFieldConfigurationEdit'
export const FLOW_FIELD_CONFIGURATION_EDIT_ACTION_LABEL = 'Edit Flow Field Template'

export type FlowFieldConfigurationEditActionParams = {
  flowFieldConfiguration: ArrayElement<OrgConfigQuery['flow_field_configurations']>
}

export type FlowFieldConfigurationEditActionValues = OrgFlowFieldConfigurationUpdateMutationVariables

export type FlowFieldConfigurationEditActionProps = FlowFieldConfigurationEditActionParams & {
  onDelete: (id: string) => Promise<void>
  onSubmit: (values: FlowFieldConfigurationEditActionValues) => Promise<void>
}

type FormType = {
  name: string
  visibility: SelectOptionType<Flow_Field_Visibilities_Enum>
  type: SelectOptionType<Flow_Field_Types_Enum>
}

export function FlowFieldConfigurationEditAction({
  flowFieldConfiguration,
  onDelete,
  onSubmit,
}: FlowFieldConfigurationEditActionProps) {
  const { defaultOption: visibilityDefaultOption, optionsOrGroups: visibilityOptionsOrGroups } = useMemo(
    () => initSelect(JSON.stringify, getFlowFieldVisibilitiesPreOptionsOrGroups(flowFieldConfiguration.visibility)),
    [],
  )

  const { defaultOption: typeDefaultOption, optionsOrGroups: typeOptionsOrGroups } = useMemo(
    () => initSelect(JSON.stringify, getFlowFieldTypesPreOptionsOrGroups(flowFieldConfiguration.type)),
    [],
  )

  const initialValues = useMemo<FormType>(
    () => ({
      name: flowFieldConfiguration.name,
      visibility: visibilityDefaultOption,
      type: typeDefaultOption,
    }),
    [flowFieldConfiguration.name, visibilityDefaultOption.value, typeDefaultOption.value],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        id: flowFieldConfiguration.id,
        name: values.name,
        visibility: values.visibility.value,
        type: values.type.value,
      })
    },
    [onSubmit],
  )

  const handleDelete = useCallback(() => onDelete(flowFieldConfiguration.id), [flowFieldConfiguration.id, onDelete])

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={FLOW_FIELD_CONFIGURATION_EDIT_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl isRequired={true} label='Name' name='name' placeholder='e.g. Deal Value...' />
        <SelectControl
          isRequired={true}
          label='Visibility'
          name='visibility'
          optionsOrGroups={visibilityOptionsOrGroups}
        />
        <SelectControl isRequired={true} label='Type' name='type' optionsOrGroups={typeOptionsOrGroups} />
      </ModalContent>
      <ModalFooter>
        <Button colorVariant='light' onPress={handleDelete}>
          Delete
        </Button>
        <SubmitButton style={CommonStyles.marginLeftCard}>Save</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterFlowFieldConfigurationEditAction() {
  const [doFlowFieldConfigurationUpdate] = useOrgFlowFieldConfigurationUpdateMutation()
  const [doFlowFieldConfigurationDelete] = useOrgFlowFieldConfigurationDeleteMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org } = useOrg()

  const handleSubmit = useCallback(
    async (values: FlowFieldConfigurationEditActionValues) => {
      await doFlowFieldConfigurationUpdate({
        variables: values,
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doFlowFieldConfigurationUpdate, dismissAllActions],
  )

  const handleDelete = useCallback(
    async (id: string) => {
      await doFlowFieldConfigurationDelete({
        variables: {
          id,
        },
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doFlowFieldConfigurationDelete, dismissAllActions],
  )

  useRegisterAction<FlowFieldConfigurationEditActionParams>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: FLOW_FIELD_CONFIGURATION_EDIT_ACTION_ID,
      modal: ({ flowFieldConfiguration }) => (
        <FlowFieldConfigurationEditAction
          flowFieldConfiguration={flowFieldConfiguration}
          onDelete={handleDelete}
          onSubmit={handleSubmit}
        />
      ),
    }),
    [handleDelete, handleSubmit, org.role],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, (params: FlowFieldConfigurationEditActionParams) =>
        dispatchAction({
          id: FLOW_FIELD_CONFIGURATION_EDIT_ACTION_ID,
          params,
        }),
      ),
    [dispatchAction, org.role],
  )
}
