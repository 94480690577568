import { LogoBox } from '@src/components/atoms/LogoBox'
import { CommonStyles, Theme } from '@src/logic/design'
import React, { memo, ReactNode, useMemo } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

export type WebNavProps = {
  children?: ReactNode
  footerChildren?: ReactNode
  style?: ViewStyle
}

export const WebNav = memo(WebNavComponent)

const Styles = StyleSheet.create({
  view: {
    alignItems: 'stretch',
    backgroundColor: Theme.colors.dark.backgroundColor,
    paddingBottom: Theme.geometry.structure.spacing,
    width: 260,
  },
  logoBox: {
    paddingBottom: Theme.geometry.structure.spacing * 2,
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
    paddingTop: Theme.geometry.structure.spacing,
  },
})

function WebNavComponent({ children, footerChildren, style }: WebNavProps) {
  const viewStyle = useMemo(() => [Styles.view, style], [style])

  return (
    <View style={viewStyle}>
      <LogoBox style={Styles.logoBox} />
      {children}
      {footerChildren && (
        <>
          <View style={CommonStyles.flexOne} />
          {footerChildren}
        </>
      )}
    </View>
  )
}
