import { PhotoUpload } from '@src/components/molecules'
import { Attachment_Types_Enum } from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { useAuthenticatedAuth, UserType } from '@src/logic/auth'
import { Uploader } from '@src/logic/data/uploads'
import React, { useCallback, useMemo } from 'react'
import { PhotographIcon } from 'react-native-heroicons/solid'

export const USER_AVATAR_EDIT_ACTION_ID = 'userAvatarEdit'
export const USER_AVATAR_EDIT_ACTION_NAME = 'Edit User Avatar'

export type UserAvatarEditActionParams = {
  user: UserType
}

export type UserAvatarEditActionProps = UserAvatarEditActionParams & {
  onSubmit: () => Promise<void>
  uploader: Uploader
}

export function UserAvatarEditAction({ onSubmit, uploader }: UserAvatarEditActionProps) {
  return <PhotoUpload onSubmit={onSubmit} uploader={uploader} />
}

export function useRegisterUserAvatarEditAction() {
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { user, useUploader } = useAuthenticatedAuth()
  const uploader = useUploader(Attachment_Types_Enum.UserProfilePicture)

  const handleSubmit = useCallback(async () => {
    dismissAllActions()
  }, [dismissAllActions])

  useRegisterAction<UserAvatarEditActionParams>(
    {
      id: USER_AVATAR_EDIT_ACTION_ID,
      menuItem: {
        Icon: PhotographIcon,
        label: USER_AVATAR_EDIT_ACTION_NAME,
        params: { user },
      },
      modal: ({ user }) => <UserAvatarEditAction user={user} uploader={uploader} onSubmit={handleSubmit} />,
    },
    [handleSubmit, uploader, user],
  )

  return useMemo(
    () => (params: UserAvatarEditActionParams) =>
      dispatchAction({
        id: USER_AVATAR_EDIT_ACTION_ID,
        params,
      }),
    [dispatchAction],
  )
}
