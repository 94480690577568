import { reactRouterV5Instrumentation } from '@sentry/react'
import { User } from '@sentry/react-native'
import { BrowserTracing } from '@sentry/tracing'
import { Integration, ScopeContext } from '@sentry/types'
import { ifTrue, isWeb } from '@src/logic/utils'
import { SentryLink } from 'apollo-link-sentry'
import Constants from 'expo-constants'
import * as Updates from 'expo-updates'
import { createBrowserHistory } from 'history'
import React, { createContext, MutableRefObject, ReactNode, useContext, useMemo } from 'react'
import { LogBox } from 'react-native'
import * as Sentry from 'sentry-expo'
import { PlatformEnvType } from '../../../app.config'

export type ConfigType = {
  stage: string
  appNativeVersion: string
  release: string
  appBaseUrl: string
  hasuraUrl: string
  profilePicturesBaseUrl: string
  widgetsBaseUrl: string
  sentryAppDsn: string
  getUserProfilePictureUrl(userId: string, noCache?: boolean): string
  getOrganizationProfilePictureUrl(orgId: string, noCache?: boolean): string
}

export const ConfigContext = createContext<ConfigType>(undefined!) // exported for testing
export const useConfig = () => useContext(ConfigContext)

export type ConfigProviderProps = {
  children?: ReactNode
}

export function ConfigProvider({ children }: ConfigProviderProps) {
  const config = useMemo(initConfig, [])
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}

export function getEnv(): PlatformEnvType {
  return (Constants.manifest ? Constants.manifest.extra : Updates.manifest?.extra?.expoClient?.extra) as PlatformEnvType
}

function initConfig(): ConfigType {
  LogBox.ignoreAllLogs()
  const env = getEnv()

  if (
    !env ||
    !env.PLATFORM_STAGE ||
    !env.PLATFORM_APP_NATIVE_VERSION ||
    !env.PLATFORM_APP_RELEASE ||
    !env.PLATFORM_APP_BASE_URL ||
    !env.PLATFORM_HASURA_URL ||
    !env.PLATFORM_PROFILE_PICTURES_BASE_URL ||
    !env.PLATFORM_WIDGETS_BASE_URL ||
    !env.PLATFORM_SENTRY_APP_DSN
  ) {
    throw new Error('Invalid config.')
  }

  return {
    stage: env.PLATFORM_STAGE,
    appNativeVersion: env.PLATFORM_APP_NATIVE_VERSION,
    release: env.PLATFORM_APP_RELEASE,
    appBaseUrl: env.PLATFORM_APP_BASE_URL,
    hasuraUrl: env.PLATFORM_HASURA_URL,
    profilePicturesBaseUrl: env.PLATFORM_PROFILE_PICTURES_BASE_URL,
    widgetsBaseUrl: env.PLATFORM_WIDGETS_BASE_URL,
    sentryAppDsn: env.PLATFORM_SENTRY_APP_DSN,
    getUserProfilePictureUrl: (id, noCache) =>
      `${env.PLATFORM_PROFILE_PICTURES_BASE_URL}/users/${id}${noCache ? `?cache=${new Date().getTime()}` : ''}`,
    getOrganizationProfilePictureUrl: (id, noCache) =>
      `${env.PLATFORM_PROFILE_PICTURES_BASE_URL}/organizations/${id}${noCache ? `?cache=${new Date().getTime()}` : ''}`,
  }
}

const sentryReactNavigationInstrumentation = isWeb() ? undefined : new Sentry.Native.ReactNavigationInstrumentation()
const sentryBrowserHistory = isWeb() ? createBrowserHistory() : undefined

export function initSentry() {
  const env = getEnv()
  const tracingOrigins = ['localhost', 'krilo.io', 'hope.tech']

  Sentry.init({
    dsn: ifTrue(env.PLATFORM_SENTRY_APP_DSN !== 'disabled', env.PLATFORM_SENTRY_APP_DSN),
    integrations: isWeb()
      ? [
          new BrowserTracing({
            tracingOrigins,
            routingInstrumentation: reactRouterV5Instrumentation(sentryBrowserHistory!),
          }) as Integration /* workaround type bug in sentry expo */,
        ]
      : [
          new Sentry.Native.ReactNativeTracing({
            tracingOrigins,
            routingInstrumentation: sentryReactNavigationInstrumentation,
          }),
        ],
    release: env.PLATFORM_APP_RELEASE,
    dist: env.PLATFORM_APP_NATIVE_VERSION,
    environment: env.PLATFORM_STAGE,
    tracesSampleRate: 1,
  })
}

export function registerSentryReactNavigationInstrumentation(ref: MutableRefObject<any>) {
  if (sentryReactNavigationInstrumentation) {
    sentryReactNavigationInstrumentation.registerNavigationContainer(ref)
  }
}

export function getSentryBrowserHistory() {
  if (!sentryBrowserHistory) {
    throw new Error('No browser history.')
  }
  return sentryBrowserHistory
}

export function getNewSentryApolloLink() {
  return new SentryLink({
    setTransaction: false,
    attachBreadcrumbs: {
      includeVariables: true,
      includeError: true,
    },
  })
}

export function setSentryUser(user: User | null) {
  if (isWeb()) {
    Sentry.Browser.setUser(user)
  } else {
    Sentry.Native.setUser(user)
  }
}

export function captureSentryException(err: any, context?: Partial<ScopeContext>) {
  if (isWeb()) {
    Sentry.Browser.captureException(err, context)
  } else {
    Sentry.Native.captureException(err, context)
  }
}
