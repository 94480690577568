import { Action_Item_Types_Enum, Flow_Roles_Enum, Users } from '@src/gen/graphql/bindings'

export enum FlowActivityTypes {
  MESSAGE_SENT = 'message_sent',
  ACTION_ITEM_CREATED = 'action_item_created',
  ACTION_ITEM_COMPLETED = 'action_item_completed',
  ACTION_ITEM_CANCELED = 'action_item_canceled',
  PARTICIPANT_JOINED = 'participant_joined',
  PARTICIPANT_LEFT = 'participant_left',
  PARTICIPANT_VERIFIED = 'participant_verified',
  PARTICIPANT_INVITED = 'participant_invited',
  FLOW_FLOW_TYPE_STATUS_UPDATED = 'flow_flow_type_status_updated',
  FLOW_NAME_UPDATED = 'flow_name_updated',
  PARTICIPANT_FLOW_ROLE_TYPE_UPDATED = 'participant_flow_role_type_updated',
  FLOW_FIELD_UPDATED = 'flow_field_updated',
}

export type FlowActivity =
  | FlowActivityMessageSent
  | FlowActivityActionItemCreated
  | FlowActivityActionItemCompleted
  | FlowActivityActionItemCanceled
  | FlowActivityParticipantJoined
  | FlowActivityParticipantLeft
  | FlowActivityParticipantVerified
  | FlowActivityParticipantInvited
  | FlowActivityFlowFlowTypeStatusUpdated
  | FlowActivityFlowNameUpdated
  | FlowActivityParticipantFlowRoleTypeUpdated
  | FlowActivityFlowFieldUpdated

export type FlowActivityMessageSent = {
  type: FlowActivityTypes.MESSAGE_SENT
  category: string
  chat_content_summary: string
  sender: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  at: string
}

export type FlowActivityActionItemCreated = {
  type: FlowActivityTypes.ACTION_ITEM_CREATED
  category: string
  creator: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  assignee: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  action_item: {
    id: string
    input_summary: string
    type: Action_Item_Types_Enum
  }
  at: string
}

export type FlowActivityActionItemCompleted = {
  type: FlowActivityTypes.ACTION_ITEM_COMPLETED
  category: string
  creator: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  assignee: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  completer: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  action_item: {
    id: string
    input_summary: string
    type: Action_Item_Types_Enum
  }
  at: string
}

export type FlowActivityActionItemCanceled = {
  type: FlowActivityTypes.ACTION_ITEM_CANCELED
  category: string
  creator: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  assignee: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  canceler: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  action_item: {
    id: string
    input_summary: string
    type: Action_Item_Types_Enum
  }
  at: string
}

export type FlowActivityParticipantJoined = {
  type: FlowActivityTypes.PARTICIPANT_JOINED
  category: string
  participant: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  at: string
}

export type FlowActivityParticipantLeft = {
  type: FlowActivityTypes.PARTICIPANT_LEFT
  category: string
  participant: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  at: string
}

export type FlowActivityParticipantVerified = {
  type: FlowActivityTypes.PARTICIPANT_VERIFIED
  category: string
  participant: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  at: string
}

export type FlowActivityParticipantInvited = {
  type: FlowActivityTypes.PARTICIPANT_INVITED
  category: string
  inviter: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  invitee: Pick<Users, 'id' | 'first_name' | 'last_name'>
  at: string
}

export type FlowActivityFlowFlowTypeStatusUpdated = {
  type: FlowActivityTypes.FLOW_FLOW_TYPE_STATUS_UPDATED
  category: string
  updater: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  old_flow_type_status?: {
    id: string
    name: string
    flow_type_id: string
    flow_type_name: string
  }
  new_flow_type_status?: {
    id: string
    name: string
    flow_type_id: string
    flow_type_name: string
  }
  at: string
}

export type FlowActivityFlowNameUpdated = {
  type: FlowActivityTypes.FLOW_NAME_UPDATED
  category: string
  updater: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  old_name: string
  new_name: string
  at: string
}

export type FlowActivityParticipantFlowRoleTypeUpdated = {
  type: FlowActivityTypes.PARTICIPANT_FLOW_ROLE_TYPE_UPDATED
  category: string
  participant: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  updater: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  old_flow_role_type_name: string
  new_flow_role_type_name: string
  at: string
}

export type FlowActivityFlowFieldUpdated = {
  type: FlowActivityTypes.FLOW_FIELD_UPDATED
  category: string
  updater: Pick<Users, 'id' | 'first_name' | 'last_name'> & {
    role: Flow_Roles_Enum
  }
  old_flow_field?: {
    id: string
    name: string
  }
  new_flow_field?: {
    id: string
    name: string
  }
  at: string
}
