import { ChatBubble } from '@src/components/atoms'
import { FlowActivityChatBubble } from '@src/components/molecules/FlowActivityChatBubble'
import { Action_Items, Maybe, Message_Types_Enum, Messages, Users } from '@src/gen/graphql/bindings'
import { getUserFullName } from '@src/logic/data/manipulation'
import { useScreen } from '@src/logic/design'
import { ifTrue } from '@src/logic/utils'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

export type ChatRowProps = {
  message: Pick<Messages, 'chat_content' | 'created_at' | 'id' | 'system_activity' | 'type'> & {
    creator: Pick<Users, 'id' | 'first_name' | 'last_name'>
    action_item?: Maybe<
      Pick<Action_Items, 'id' | 'completed_at' | 'input' | 'type'> & {
        assignee: Pick<Users, 'id' | 'first_name' | 'last_name'>
        creator: Pick<Users, 'id' | 'first_name' | 'last_name'>
        completer?: Maybe<Pick<Users, 'id' | 'first_name' | 'last_name'>>
      }
    >
  }
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

const Styles = StyleSheet.create({
  itemOther: {
    alignSelf: 'flex-start',
    paddingBottom: 12,
  },
  itemOwn: {
    alignSelf: 'flex-end',
    paddingBottom: 12,
  },
  itemWeb: {
    width: '75%',
  },
  card: {
    marginBottom: 12,
  },
  cardWeb: {
    alignSelf: 'center',
    width: '60%',
  },
})

export function ChatRow({ message, user }: ChatRowProps) {
  const { isDesktop } = useScreen()

  const itemStyle = useMemo(
    () => [user.id === message.creator?.id ? Styles.itemOwn : Styles.itemOther, ifTrue(isDesktop, Styles.itemWeb)],
    [isDesktop, message.creator?.id, user.id],
  )

  const avatar = useMemo(() => ({ user: message.creator }), [message.creator])

  if (message.type === Message_Types_Enum.Chat) {
    if (!message.chat_content) {
      throw new Error('Chat content unexpectedly empty.')
    }

    return (
      <ChatBubble
        avatar={avatar}
        body={message.chat_content}
        colorVariant='lightDim'
        label={getUserFullName(message.creator)}
        own={message.creator.id === user.id}
        style={itemStyle}
      />
    )
  }

  return <FlowActivityChatBubble activity={message.system_activity} style={itemStyle} user={user} />
}
