import { Splash } from '@src/components/parts'
import { useUserPushTokenRegisterMutation } from '@src/gen/graphql'
import { useAuth } from '@src/logic/auth'
import { useConfig } from '@src/logic/config'
import { FlowActivity } from '@src/logic/data/manipulation'
import { processError, useErrors } from '@src/logic/errors'
import { getFlowActivityNotificationPath } from '@src/logic/routing/common'
import { isNotWeb, useRequiredContext } from '@src/logic/utils'
import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react'
import { Platform } from 'react-native'
import useAsyncEffect from 'use-async-effect'

export type PushContextType = {
  // TODO (ibrt): Notifications listener.
}

export const PushContext = createContext<PushContextType | undefined>(undefined)
export const usePush = () => useRequiredContext(PushContext)

export type PushProviderProps = {
  children?: ReactNode
}

type PushProviderState = {
  loading: boolean
  pushToken?: string
}

type MessageDataType = {
  type: string
  toUserId: string
  flowId: string
  flowOrgId?: string
  flowActivity?: FlowActivity
}

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: false,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
})

export function PushProvider({ children }: PushProviderProps) {
  const { user } = useAuth()
  const isPushAvailable = Device.isDevice && isNotWeb() && !!user
  const [doUserPushTokenRegister] = useUserPushTokenRegisterMutation()
  const [{ loading }, setState] = useState<PushProviderState>({ loading: isPushAvailable })
  const { release: appVersion, appBaseUrl } = useConfig()
  const { setRecoveryUrl } = useErrors()
  const [receivedUrl, setReceivedUrl] = useState<string>()

  useEffect(() => {
    if (receivedUrl) {
      setRecoveryUrl(receivedUrl)
      setReceivedUrl(undefined)
    }
  }, [receivedUrl, setReceivedUrl, setRecoveryUrl])

  useEffect(() => {
    Notifications.setNotificationHandler({
      handleNotification: async (notification) => ({
        shouldShowAlert: user?.id === notification.request.content.data.toUserId,
        shouldPlaySound: false,
        shouldSetBadge: false,
      }),
    })

    return Notifications.addNotificationResponseReceivedListener((notificationResponse) => {
      const data = notificationResponse.notification.request.content.data as MessageDataType
      if (user?.id === data.toUserId && data.type === 'flow_activity') {
        setReceivedUrl(
          appBaseUrl +
            getFlowActivityNotificationPath(data.flowId, data.flowOrgId, data.flowActivity?.category || 'status'),
        )
      }
    }).remove
  }, [user?.id])

  useAsyncEffect(async () => {
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      })
    }

    if (!isPushAvailable) {
      return
    }

    try {
      if ((await Notifications.getPermissionsAsync()).status !== 'granted') {
        if ((await Notifications.requestPermissionsAsync()).status !== 'granted') {
          setState({ loading: false })
          return
        }
      }

      const pushToken = (await Notifications.getExpoPushTokenAsync()).data
      setState({ loading: false, pushToken })

      // Note: this happens asynchronously after letting the UI load, which is why it traps the error.
      try {
        await doUserPushTokenRegister({
          variables: {
            pushToken,
            platform: Platform.OS,
            appVersion,
          },
        })
      } catch (err: any) {
        processError(err)
      }
    } catch (err: any) {
      processError(err)
      setState({ loading: false })
    }
  }, [appVersion, doUserPushTokenRegister, isPushAvailable, setState, user?.id])

  const context = useMemo(() => ({}), [])

  if (loading || receivedUrl) {
    return <Splash />
  }

  return <PushContext.Provider value={context}>{children}</PushContext.Provider>
}
