import { Card, Item, Text } from '@src/components/atoms'
import { Action_Item_Configurations } from '@src/gen/graphql/bindings'
import { getActionItemTypeLabel } from '@src/logic/data/manipulation'
import { CommonStyles } from '@src/logic/design'
import React from 'react'
import { ViewStyle } from 'react-native'

export type ActionItemTemplateConfigurationCardProps = {
  actionItemConfiguration: Pick<Action_Item_Configurations, 'input' | 'name' | 'prompt_cta' | 'type'>
  onDelete?: () => void
  onEdit?: () => void
  style?: ViewStyle
}

export function ActionItemConfigurationCard({
  actionItemConfiguration,
  onEdit,
  style,
}: ActionItemTemplateConfigurationCardProps) {
  return (
    <Card colorVariant='lightDim' style={style} onPress={onEdit}>
      <Text colorVariant='light' numberOfLines={1} style={CommonStyles.marginBottomCard} textVariant='title'>
        {actionItemConfiguration.name}
      </Text>
      <Item
        colorVariant='light'
        body={getActionItemTypeLabel(actionItemConfiguration.type)}
        label='Type'
        style={CommonStyles.marginBottomCard}
      />
      <Item
        colorVariant='light'
        body={actionItemConfiguration.input}
        label='Prompt'
        style={CommonStyles.marginBottomCard}
      />
      {actionItemConfiguration.prompt_cta && (
        <Item
          colorVariant='light'
          body={actionItemConfiguration.prompt_cta}
          label='Action'
          style={CommonStyles.marginBottomCard}
        />
      )}
    </Card>
  )
}
