import {
  ActionItemConfigurationEditActionParams,
  FlowConfigurationEditActionParams,
  FlowFieldConfigurationEditActionParams,
  FlowRoleTypeEditActionParams,
  OrgConfigurationEditMemberParams,
  OrgConfigurationInviteMemberParams,
  useRegisterActionItemConfigurationCreateAction,
  useRegisterActionItemConfigurationEditAction,
  useRegisterFlowConfigurationCreateAction,
  useRegisterFlowConfigurationEditAction,
  useRegisterFlowFieldConfigurationCreateAction,
  useRegisterFlowFieldConfigurationEditAction,
  useRegisterFlowRoleTypeCreateAction,
  useRegisterFlowRoleTypeEditAction,
  useRegisterOrganizationLogoEditAction,
  useRegisterOrganizationProfileEditAction,
  useRegisterOrgConfigurationEditMemberAction,
  useRegisterOrgConfigurationInviteMemberAction,
} from '@src/components/actions'
import { useRequiredContext } from '@src/logic/utils'
import React, { createContext, ReactNode, useMemo } from 'react'

export type OrgConfigurationContextType = {
  dispatchActionItemConfigurationEditAction?: (params: ActionItemConfigurationEditActionParams) => void
  dispatchFlowConfigurationEditAction?: (params: FlowConfigurationEditActionParams) => void
  dispatchFlowRoleTypeEditAction?: (params: FlowRoleTypeEditActionParams) => void
  dispatchEditMemberAction?: (params: OrgConfigurationEditMemberParams) => void
  dispatchInviteMemberAction?: (params: OrgConfigurationInviteMemberParams) => void
  dispatchFlowFieldConfigurationEditAction?: (params: FlowFieldConfigurationEditActionParams) => void
}

export const OrgConfigurationContext = createContext<OrgConfigurationContextType | undefined>(undefined)
export const useOrgConfiguration = () => useRequiredContext(OrgConfigurationContext)

export type OrgConfigurationProviderProps = {
  children?: ReactNode
}

export function OrgConfigurationProvider({ children }: OrgConfigurationProviderProps) {
  useRegisterFlowRoleTypeCreateAction()
  const dispatchFlowRoleTypeEditAction = useRegisterFlowRoleTypeEditAction()
  useRegisterActionItemConfigurationCreateAction()
  const dispatchActionItemConfigurationEditAction = useRegisterActionItemConfigurationEditAction()
  useRegisterFlowConfigurationCreateAction()
  useRegisterOrganizationProfileEditAction()
  useRegisterOrganizationLogoEditAction()
  const dispatchFlowConfigurationEditAction = useRegisterFlowConfigurationEditAction()
  const dispatchEditMemberAction = useRegisterOrgConfigurationEditMemberAction()
  useRegisterOrgConfigurationInviteMemberAction()
  useRegisterFlowFieldConfigurationCreateAction()
  const dispatchFlowFieldConfigurationEditAction = useRegisterFlowFieldConfigurationEditAction()

  const context = useMemo(
    () => ({
      dispatchActionItemConfigurationEditAction,
      dispatchFlowConfigurationEditAction,
      dispatchFlowRoleTypeEditAction,
      dispatchEditMemberAction,
      dispatchFlowFieldConfigurationEditAction,
    }),
    [
      dispatchActionItemConfigurationEditAction,
      dispatchFlowConfigurationEditAction,
      dispatchFlowRoleTypeEditAction,
      dispatchEditMemberAction,
      dispatchFlowFieldConfigurationEditAction,
    ],
  )

  return <OrgConfigurationContext.Provider value={context}>{children}</OrgConfigurationContext.Provider>
}
