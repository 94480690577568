import { ifWeb } from '@src/logic/utils'
import { mapValues } from 'lodash'
import { Platform, StyleSheet, TextStyle } from 'react-native'

export const COLOR_VARIANTS = <const>['light', 'lightDim', 'dark', 'darkDim', 'primary', 'success', 'warning', 'danger']
export type ColorVariants = typeof COLOR_VARIANTS[number]

export const TEXT_VARIANTS = <const>[
  'h1',
  'h2',
  'h3',
  'title',
  'nav1',
  'nav2',
  'cta',
  'body',
  'tag',
  'eyebrow',
  'smallText',
  'notification',
]
export type TextVariants = typeof TEXT_VARIANTS[number]

export const Theme: {
  colors: {
    [key in ColorVariants]: {
      color: string
      lighterColor?: string
      darkerColor?: string
      backgroundColor: string
      borderColor: string
    }
  }
  text: {
    [key in TextVariants]: {
      fontFamily: string
      fontSize: number
      fontWeight: TextStyle['fontWeight']
      letterSpacing?: number
      lineHeight: number
      textTransform?: TextStyle['textTransform']
    }
  }
  geometry: {
    pill: {
      height: number
      radius: number
      spacing: number
    }
    control: {
      height: number
      radius: number
      spacing: number
    }
    bubble: {
      radius: number
      spacing: number
    }
    card: {
      radius: number
      spacing: number
    }
    structure: {
      spacing: number
    }
    item: {
      height: number
    }
  }
  shadows: {
    standard: {
      elevation: number
      shadowColor: string
      shadowOffset: {
        width: number
        height: number
      }
      shadowOpacity: number
      shadowRadius: number
    }
    tabs: {
      elevation: number
      shadowColor: string
      shadowOffset: {
        width: number
        height: number
      }
      shadowOpacity: number
      shadowRadius: number
    }
  }
} = {
  colors: {
    light: {
      color: '#0B0E1C',
      backgroundColor: '#FFFFFF',
      borderColor: '#CDD7F8',
    },
    lightDim: {
      color: '#717584',
      backgroundColor: '#FBFCFF',
      borderColor: '#CDD7F8',
    },
    dark: {
      color: '#FFFFFF',
      backgroundColor: '#0B0E1C',
      borderColor: '#434A67',
    },
    darkDim: {
      color: '#858CA3',
      backgroundColor: '#202332',
      borderColor: '#434A67',
    },
    primary: {
      color: '#277CAA',
      lighterColor: '#4DA6D6',
      darkerColor: '#206990',
      backgroundColor: '#F6F8FF',
      borderColor: '#BBD8F3',
    },
    success: {
      color: '#008A27',
      backgroundColor: '#EDF9F0',
      borderColor: '#A5E5B8',
    },
    warning: {
      color: '#AD5C00',
      backgroundColor: '#FAF6F1',
      borderColor: '#EBD8C2',
    },
    danger: {
      color: '#CE2D4F',
      backgroundColor: '#FFF6F8',
      borderColor: '#F8C0CC',
    },
  },
  text: {
    h1: {
      fontFamily: 'Inter_700Bold',
      fontSize: 40,
      fontWeight: '700',
      lineHeight: 40 * 1.15,
    },
    h2: {
      fontFamily: 'Inter_300Light',
      fontSize: 40,
      fontWeight: '300',
      lineHeight: 40 * 1.15,
    },
    h3: {
      fontFamily: 'Inter_700Bold',
      fontSize: 24,
      fontWeight: '700',
      lineHeight: 24 * 1.15,
    },
    title: {
      fontFamily: 'Inter_700Bold',
      fontSize: 18,
      fontWeight: '700',
      lineHeight: 18 * 1.25,
    },
    nav1: {
      fontFamily: 'Inter_700Bold',
      fontSize: 16,
      fontWeight: '700',
      lineHeight: 16 * 1.25,
    },
    nav2: {
      fontFamily: 'Inter_400Regular',
      fontSize: 16,
      fontWeight: '400',
      lineHeight: 16 * 1.25,
    },
    cta: {
      fontFamily: 'Inter_600SemiBold',
      fontSize: 14,
      fontWeight: '600',
      lineHeight: 14 * 1.25,
    },
    body: {
      fontFamily: 'Inter_400Regular',
      fontSize: 14,
      fontWeight: '400',
      lineHeight: 14 * 1.25,
    },
    tag: {
      fontFamily: 'Inter_600SemiBold',
      fontSize: 12,
      fontWeight: '600',
      lineHeight: 12 * 1.5,
      letterSpacing: 12 * 0.1,
      textTransform: 'uppercase',
    },
    eyebrow: {
      fontFamily: 'Inter_400Regular',
      fontSize: 12,
      fontWeight: '400',
      letterSpacing: 12 * 0.1,
      lineHeight: 12 * 1.25,
      textTransform: 'uppercase',
    },
    smallText: {
      fontFamily: 'Inter_400Regular',
      fontSize: 12,
      fontWeight: '400',
      lineHeight: 12 * 1.25,
    },
    notification: {
      fontFamily: 'Inter_600SemiBold',
      fontSize: 10,
      fontWeight: '600',
      lineHeight: 10 * 1.5,
    },
  },
  geometry: {
    pill: {
      height: 24,
      radius: 4,
      spacing: 8,
    },
    control: {
      height: 40,
      radius: 4,
      spacing: 16,
    },
    bubble: {
      radius: 8,
      spacing: 12,
    },
    card: {
      radius: 8,
      spacing: 16,
    },
    structure: {
      spacing: Platform.OS === 'web' ? 24 : 16,
    },
    item: {
      height: 48,
    },
  },
  shadows: {
    standard: {
      elevation: 4,
      shadowColor: Platform.OS === 'android' ? '#B6B7BB' : '#0B0E1C',
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.05,
      shadowRadius: 8,
    },
    tabs: {
      elevation: 6,
      shadowColor: Platform.OS === 'android' ? '#9D9FA3' : '#0B0E1C',
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.1,
      shadowRadius: 12,
    },
  },
}

export const ForegroundStyles = StyleSheet.create(
  mapValues(Theme.colors, (v) => ({
    color: v.color,
  })),
)

export const BackgroundStyles = StyleSheet.create(
  mapValues(Theme.colors, (v) => ({
    backgroundColor: v.backgroundColor,
  })),
)

export const BorderStyles = StyleSheet.create(
  mapValues(Theme.colors, (v) => ({
    borderColor: v.borderColor,
    borderWidth: 1,
  })),
)

export const CommonStyles = StyleSheet.create({
  cursorNotAllowed: {
    ...ifWeb({ cursor: 'not-allowed' } as any),
  },
  outlineNone: {
    ...ifWeb({ outlineWidth: 0 } as any),
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  flexCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexOne: {
    flex: 1,
  },
  flexRow: {
    flexDirection: 'row',
  },
  paddingBottomCard: {
    paddingBottom: Theme.geometry.card.spacing,
  },
  marginBottomCard: {
    marginBottom: Theme.geometry.card.spacing,
  },
  marginBottomCardHalf: {
    marginBottom: Theme.geometry.card.spacing / 2,
  },
  marginRightCard: {
    marginRight: Theme.geometry.card.spacing,
  },
  marginRightCardHalf: {
    marginRight: Theme.geometry.card.spacing / 2,
  },
  marginLeftCard: {
    marginLeft: Theme.geometry.card.spacing,
  },
  marginLeftCardHalf: {
    marginLeft: Theme.geometry.card.spacing / 2,
  },
  marginBottomStructure: {
    marginBottom: Theme.geometry.structure.spacing,
  },
  marginBottomStructureHalf: {
    marginBottom: Theme.geometry.structure.spacing / 2,
  },
  marginRightStructure: {
    marginBottom: Theme.geometry.structure.spacing,
  },
  marginRightStructureHalf: {
    marginBottom: Theme.geometry.structure.spacing / 2,
  },
})

export function dim(colorVariant: 'light' | 'dark') {
  return colorVariant === 'light' ? 'lightDim' : 'darkDim'
}

export function maybeDim(condition: boolean, colorVariant: 'light' | 'dark') {
  return condition ? dim(colorVariant) : colorVariant
}
