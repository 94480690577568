import { Theme, useScreen } from '@src/logic/design'
import { ifDef, ifTrue } from '@src/logic/utils'
import React, { ReactNode, useMemo } from 'react'
import { ScrollView, StyleSheet, View, ViewStyle } from 'react-native'

export type ScreenSecondaryProps = {
  children?: ReactNode
  isScrollable: boolean
  style?: ViewStyle
}

const Styles = StyleSheet.create({
  view: {
    backgroundColor: Theme.colors.darkDim.backgroundColor,
  },
  viewMobile: {
    flex: 1,
  },
  viewWeb: {
    flexGrow: 0,
    width: 360,
  },
  containerView: {
    paddingTop: Theme.geometry.structure.spacing,
  },
})

export function ScreenSecondary({ children, isScrollable, style }: ScreenSecondaryProps) {
  const { isPhone, isTablet, isDesktop } = useScreen()
  const { screenHeight } = useScreen()
  const Container = isScrollable ? ScrollView : View

  const viewStyle = useMemo(
    () => [
      Styles.view,
      ifTrue(isPhone || isTablet, Styles.viewMobile),
      ifTrue(isDesktop, Styles.viewWeb),
      ifTrue(!isScrollable, Styles.containerView),
      ifDef(screenHeight, { height: screenHeight }),
      style,
    ],
    [screenHeight, style],
  )

  return (
    <Container contentContainerStyle={Styles.containerView} style={viewStyle}>
      {children}
    </Container>
  )
}
