import {
  ConditionalControl,
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectControl,
  SubmitButton,
  Text,
  TextInputControl,
} from '@src/components/atoms'
import { Action_Item_Types_Enum, Flow_Roles_Enum, Flows_Users, OrgConfigQuery, Users } from '@src/gen/graphql/bindings'
import {
  findOption,
  FlowFlowsUsersOptionType,
  getActionItemStartFromPreOptionsOrGroups,
  getActionItemTypePreOptionsOrGroups,
  getFlowFlowsUsersPreOptionsOrGroups,
  initSelect,
  SelectOptionType,
} from '@src/logic/data/manipulation'
import { CommonStyles } from '@src/logic/design'
import React, { useCallback, useMemo, useState } from 'react'

export const FLOW_ACTION_ITEM_CREATE_ACTION_ID = 'flowActionItemCreate'
export const FLOW_ACTION_ITEM_CREATE_ACTION_LABEL = 'Create Action Item'

export type FlowActionItemCreateActionValuesType = {
  flowId: string
  type: Action_Item_Types_Enum
  assignedToUserId: string
  input: string
  promptCta?: string
}

export type FlowActionItemCreateActionProps = {
  flow: {
    id: string
    flows_users: Array<Pick<Flows_Users, 'role'> & { user: Pick<Users, 'id' | 'first_name' | 'last_name'> }>
  }
  onSubmit: (values: FlowActionItemCreateActionValuesType) => void
  orgConfig: OrgConfigQuery
}

type FormType = {
  for: SelectOptionType<FlowFlowsUsersOptionType>
  input: string
  promptCta?: string
  type: SelectOptionType<Action_Item_Types_Enum>
}

export function FlowActionItemCreateAction({ flow, onSubmit, orgConfig }: FlowActionItemCreateActionProps) {
  const { defaultOption: defaultStartFromOption, optionsOrGroups: startFromOptionsOrGroups } = useMemo(
    () => initSelect(JSON.stringify, getActionItemStartFromPreOptionsOrGroups(orgConfig.action_item_configurations)),
    [orgConfig],
  )

  const { optionsOrGroups: typeOptionsOrGroups } = useMemo(
    () => initSelect((value) => value, getActionItemTypePreOptionsOrGroups()),
    [],
  )

  const { defaultOption: forDefaultOption, optionsOrGroups: forOptionsOrGroups } = useMemo(
    () =>
      initSelect(JSON.stringify, getFlowFlowsUsersPreOptionsOrGroups(flow.flows_users, orgConfig.users_organizations)),
    [flow],
  )

  const [startFrom, setStartFrom] = useState(defaultStartFromOption)

  const initialValues = useMemo<FormType>(
    () => ({
      ...startFrom.value,
      type: findOption(typeOptionsOrGroups, (o) => o.value === startFrom.value.type),
      for: forDefaultOption,
    }),
    [forOptionsOrGroups, startFrom, typeOptionsOrGroups],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        flowId: flow.id,
        type: values.type.value,
        input: values.input,
        promptCta: values.promptCta,
        assignedToUserId: values.for.value.id,
      })
    },
    [flow.id],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={FLOW_ACTION_ITEM_CREATE_ACTION_LABEL} />
      <ModalContent>
        <Text colorVariant='lightDim' textVariant='smallText' style={CommonStyles.marginBottomCardHalf}>
          Start From
        </Text>
        <Select
          colorVariant='light'
          onChange={setStartFrom}
          optionsOrGroups={startFromOptionsOrGroups}
          selectedOption={startFrom}
          style={CommonStyles.marginBottomCard}
        />
        <SelectControl isRequired={true} label='Type' name='type' optionsOrGroups={typeOptionsOrGroups} />
        <TextInputControl isRequired={true} label='Prompt' name='input' />
        <ConditionalControl<SelectOptionType<Action_Item_Types_Enum>>
          subscription='type'
          predicate={(value) => value.value === Action_Item_Types_Enum.Prompt}
          render={() => <TextInputControl isRequired={true} label='Action' name='promptCta' />}
        />
        <SelectControl
          isRequired={true}
          label='For'
          name='for'
          optionsOrGroups={forOptionsOrGroups}
          validate={(option, allValues) => {
            const typeValue = (allValues as { type: SelectOptionType<Action_Item_Types_Enum> }).type.value
            const forFlowUserRole = flow.flows_users.find((fu) => fu.user.id === option.value.id)?.role
            if (
              typeValue === Action_Item_Types_Enum.Upload &&
              forFlowUserRole === Flow_Roles_Enum.UnverifiedExternalParticipant
            ) {
              return 'Unverified users cannot be assigned File Request action items.'
            }
          }}
        />
      </ModalContent>
      <ModalFooter>
        <SubmitButton>Create</SubmitButton>
      </ModalFooter>
    </Form>
  )
}
