import { ActivityIndicator } from '@src/components/atoms/ActivityIndicator'
import { Text } from '@src/components/atoms/Text'
import { CommonStyles, Theme } from '@src/logic/design'
import React, { memo, useMemo } from 'react'
import { Image, StyleSheet, View, ViewStyle } from 'react-native'

export type ItemLogoLabelProps = {
  label: string
  logoAlt?: string
  logoUri: string
  showActivity?: boolean
  style?: ViewStyle
}

export const ItemLogoLabel = memo(ItemLogoLabelComponent)

const Styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  imageView: {
    borderColor: Theme.colors.light.borderColor,
    borderRadius: 4,
    borderWidth: 1,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    width: 128,
  },
  image: {
    height: 48,
    width: '100%',
  },
  activityIndicator: {
    marginRight: 8,
    marginTop: 6,
  },
})

function ItemLogoLabelComponent({ label, logoAlt, logoUri, showActivity, style }: ItemLogoLabelProps) {
  const viewStyle = useMemo(() => [Styles.view, style], [style])
  const source = useMemo(() => ({ uri: logoUri }), [logoUri])

  return (
    <View style={viewStyle}>
      <View style={Styles.imageView}>
        <Image accessibilityLabel={logoAlt} resizeMode='contain' source={source} style={Styles.image} />
      </View>
      <View style={CommonStyles.flexOne} />
      {showActivity && <ActivityIndicator style={Styles.activityIndicator} />}
      <Text colorVariant='lightDim' textVariant='body'>
        {label}
      </Text>
    </View>
  )
}
