import React, { createContext, ReactNode } from 'react'
import { ExtFlowsQuery, useExtFlowsQuery } from '@src/gen/graphql/bindings'
import { useRequiredContext } from '@src/logic/utils'

export type ExtFlowsContextType = ExtFlowsQuery
export const ExtFlowsContext = createContext<ExtFlowsContextType | undefined>(undefined)
export const useExtFlows = () => useRequiredContext(ExtFlowsContext)

export type ExtFlowsProviderProps = {
  children?: ReactNode
}

export function ExtFlowsProvider({ children }: ExtFlowsProviderProps) {
  const { data, error, loading, previousData } = useExtFlowsQuery()

  if (error) {
    throw error
  }

  if (loading && data === undefined && previousData === undefined) {
    return null
  }

  const finalData = data ?? previousData
  if (finalData === undefined) {
    throw new Error('No data.')
  }

  return <ExtFlowsContext.Provider value={finalData}>{children}</ExtFlowsContext.Provider>
}
