import { CommonActions, StackActions } from '@react-navigation/native'
import { NavigationState } from '@react-navigation/routers'
import { useConfig } from '@src/logic/config'
import { useScreen } from '@src/logic/design'
import { useErrors } from '@src/logic/errors'
import * as Linking from 'expo-linking'
import { useMemo } from 'react'
import { generatePath, matchPath, useParams } from 'react-router-dom'

export enum RouteIds {
  ROOT = 'ROOT',
  LINKS_FLOWS_INITIATE = 'LINKS_FLOWS_INITIATE',
  LINKS_AUTH_EMAIL = 'LINKS_AUTH_EMAIL',
  UNV_FLOW_SUMMARY = 'UNV_FLOW_SUMMARY',
  UNV_FLOW_CHAT = 'UNV_FLOW_CHAT',
  EXT_FLOWS = 'EXT_FLOWS',
  EXT_FLOW_SUMMARY = 'EXT_FLOW_SUMMARY',
  EXT_FLOW_CHAT = 'EXT_FLOW_CHAT',
  EXT_FLOW_ACTION_ITEMS = 'EXT_FLOW_ACTION_ITEMS',
  EXT_USER_PROFILE = 'EXT_USER_PROFILE',
  EXT_USER_NOTIFICATIONS = 'EXT_USER_NOTIFICATIONS',
  ORG_DASHBOARD_SUMMARY = 'ORG_DASHBOARD_SUMMARY',
  ORG_DASHBOARD_FLOWS = 'ORG_DASHBOARD_FLOWS',
  ORG_FLOWS_LIST = 'ORG_FLOWS_LIST',
  ORG_FLOWS_BOARD = 'ORG_FLOWS_BOARD',
  ORG_FLOW_SUMMARY = 'ORG_FLOW_SUMMARY',
  ORG_FLOW_CHAT = 'ORG_FLOW_CHAT',
  ORG_FLOW_ACTION_ITEMS = 'ORG_FLOW_ACTION_ITEMS',
  ORG_CONTACTS = 'ORG_CONTACTS',
  ORG_CONTACT = 'ORG_CONTACT',
  ORG_CONFIGURATION_PROFILE = 'ORG_CONFIGURATION_PROFILE',
  ORG_CONFIGURATION_SETTINGS = 'ORG_CONFIGURATION_SETTINGS',
  ORG_USER_PROFILE = 'ORG_USER_PROFILE',
  ORG_USER_NOTIFICATIONS = 'ORG_USER_NOTIFICATIONS',
  NOT_FOUND = 'NOT_FOUND',
}

export type OrgNavRouteIds =
  | RouteIds.ORG_DASHBOARD_SUMMARY
  | RouteIds.ORG_DASHBOARD_FLOWS
  | RouteIds.ORG_FLOWS_LIST
  | RouteIds.ORG_CONFIGURATION_PROFILE
  | RouteIds.ORG_CONTACTS
  | RouteIds.ORG_USER_PROFILE

export type OrgDashboardRouteIds = RouteIds.ORG_DASHBOARD_SUMMARY | RouteIds.ORG_DASHBOARD_FLOWS
export type OrgFlowsRouteIds = RouteIds.ORG_FLOWS_LIST | RouteIds.ORG_FLOWS_BOARD
export type OrgFlowRouteIds = RouteIds.ORG_FLOW_SUMMARY | RouteIds.ORG_FLOW_CHAT | RouteIds.ORG_FLOW_ACTION_ITEMS
export type OrgConfigurationRouteIds = RouteIds.ORG_CONFIGURATION_PROFILE | RouteIds.ORG_CONFIGURATION_SETTINGS
export type OrgUserRouteIds = RouteIds.ORG_USER_PROFILE | RouteIds.ORG_USER_NOTIFICATIONS

export type ExtNavRouteIds = RouteIds.EXT_FLOWS | RouteIds.EXT_USER_PROFILE
export type ExtFlowRouteIds = RouteIds.EXT_FLOW_SUMMARY | RouteIds.EXT_FLOW_CHAT | RouteIds.EXT_FLOW_ACTION_ITEMS
export type ExtUserRouteIds = RouteIds.EXT_USER_PROFILE | RouteIds.EXT_USER_NOTIFICATIONS

export type UnvFlowRouteIds = RouteIds.UNV_FLOW_SUMMARY | RouteIds.UNV_FLOW_CHAT

export enum RouteGroupIds {
  UNV_FLOW = 'UNV_FLOW',
  EXT_ROOT = 'EXT_ROOT',
  EXT_FLOW = 'EXT_FLOW',
  ORG_ROOT = 'ORG_ROOT',
  ORG_FLOWS = 'ORG_FLOWS',
  ORG_FLOW = 'ORG_FLOW',
}

export enum ScreenIds {
  ROOT = 'ROOT',
  LINKS_FLOWS_INITIATE = 'LINKS_FLOWS_INITIATE',
  LINKS_AUTH_EMAIL = 'LINKS_AUTH_EMAIL',
  UNV_FLOW_ROOT = 'UNV_FLOW_ROOT',
  UNV_FLOW_SUMMARY = 'UNV_FLOW_SUMMARY',
  UNV_FLOW_CHAT = 'UNV_FLOW_CHAT',
  EXT_ROOT = 'EXT_ROOT',
  EXT_SUB_ROOT = 'EXT_SUB_ROOT',
  EXT_FLOWS = 'EXT_FLOWS',
  EXT_FLOW_ROOT = 'EXT_FLOW_ROOT',
  EXT_FLOW_SUMMARY = 'EXT_FLOW_SUMMARY',
  EXT_FLOW_CHAT = 'EXT_FLOW_CHAT',
  EXT_FLOW_ACTION_ITEMS = 'EXT_FLOW_ACTION_ITEMS',
  EXT_USER_PROFILE = 'EXT_USER_PROFILE',
  EXT_USER_NOTIFICATIONS = 'EXT_USER_NOTIFICATIONS',
  ORG_ROOT = 'ORG_ROOT',
  ORG_SUB_ROOT = 'ORG_SUB_ROOT',
  ORG_DASHBOARD_SUMMARY = 'ORG_DASHBOARD_SUMMARY',
  ORG_DASHBOARD_FLOWS = 'ORG_DASHBOARD_FLOWS',
  ORG_FLOWS_ROOT = 'ORG_FLOWS_ROOT',
  ORG_FLOWS_LIST = 'ORG_FLOWS_LIST',
  ORG_FLOWS_BOARD = 'ORG_FLOWS_BOARD',
  ORG_FLOW_ROOT = 'ORG_FLOW_ROOT',
  ORG_FLOW_SUMMARY = 'ORG_FLOW_SUMMARY',
  ORG_FLOW_CHAT = 'ORG_FLOW_CHAT',
  ORG_FLOW_ACTION_ITEMS = 'ORG_FLOW_ACTION_ITEMS',
  ORG_CONTACTS = 'ORG_CONTACTS',
  ORG_CONTACT = 'ORG_CONTACT',
  ORG_CONFIGURATION_PROFILE = 'ORG_CONFIGURATION_PROFILE',
  ORG_CONFIGURATION_SETTINGS = 'ORG_CONFIGURATION_SETTINGS',
  ORG_USER_PROFILE = 'ORG_USER_PROFILE',
  ORG_USER_NOTIFICATIONS = 'ORG_USER_NOTIFICATIONS',
  NOT_FOUND = 'NOT_FOUND',
}

export type NavigateFunc = () => void
export type NavigateFuncOrg = (orgId: string) => void
export type NavigateFuncFlow = (flowId: string) => void
export type NavigateFuncOrgFlow = (orgId: string, flowId: string) => void
export type NavigateFuncContact = (contactId: string) => void
export type NavigateFuncRouteIds<T extends RouteIds> = (routeId: T) => void

export const ROUTE_ID_TO_PATH: { [key in RouteIds]: string } = {
  [RouteIds.ROOT]: '/',
  [RouteIds.LINKS_FLOWS_INITIATE]: '/links/flows/initiate/:flowConfigurationId',
  [RouteIds.LINKS_AUTH_EMAIL]: '/links/auth/email/:emailToken',
  [RouteIds.UNV_FLOW_SUMMARY]: '/unv/flow/:flowId/summary',
  [RouteIds.UNV_FLOW_CHAT]: '/unv/flow/:flowId/chat',
  [RouteIds.EXT_FLOWS]: '/ext/flows',
  [RouteIds.EXT_FLOW_SUMMARY]: '/ext/flow/:flowId/summary',
  [RouteIds.EXT_FLOW_CHAT]: '/ext/flow/:flowId/chat',
  [RouteIds.EXT_FLOW_ACTION_ITEMS]: '/ext/flow/:flowId/action-items',
  [RouteIds.EXT_USER_PROFILE]: '/ext/user/profile',
  [RouteIds.EXT_USER_NOTIFICATIONS]: '/ext/user/notifications',
  [RouteIds.ORG_DASHBOARD_SUMMARY]: '/org/:orgId/dashboard/summary',
  [RouteIds.ORG_DASHBOARD_FLOWS]: '/org/:orgId/dashboard/flows',
  [RouteIds.ORG_FLOWS_LIST]: '/org/:orgId/flows/list',
  [RouteIds.ORG_FLOWS_BOARD]: '/org/:orgId/flows/board',
  [RouteIds.ORG_FLOW_SUMMARY]: '/org/:orgId/flow/:flowId/summary',
  [RouteIds.ORG_FLOW_CHAT]: '/org/:orgId/flow/:flowId/chat',
  [RouteIds.ORG_FLOW_ACTION_ITEMS]: '/org/:orgId/flow/:flowId/action-items',
  [RouteIds.ORG_CONTACTS]: '/org/:orgId/contacts',
  [RouteIds.ORG_CONTACT]: '/org/:orgId/contacts/:contactId',
  [RouteIds.ORG_CONFIGURATION_PROFILE]: '/org/:orgId/configuration/profile',
  [RouteIds.ORG_CONFIGURATION_SETTINGS]: '/org/:orgId/configuration/settings',
  [RouteIds.ORG_USER_PROFILE]: '/org/:orgId/user/profile',
  [RouteIds.ORG_USER_NOTIFICATIONS]: '/org/:orgId/user/notifications',
  [RouteIds.NOT_FOUND]: '/not-found',
}

export const ROUTE_ID_TO_TITLE: { [key in RouteIds]: string } = {
  [RouteIds.ROOT]: '',
  [RouteIds.LINKS_FLOWS_INITIATE]: 'Connecting...',
  [RouteIds.LINKS_AUTH_EMAIL]: 'Verifying...',
  [RouteIds.UNV_FLOW_SUMMARY]: 'Flow',
  [RouteIds.UNV_FLOW_CHAT]: 'Flow',
  [RouteIds.EXT_FLOWS]: 'Flows',
  [RouteIds.EXT_FLOW_SUMMARY]: 'Flow',
  [RouteIds.EXT_FLOW_CHAT]: 'Flow',
  [RouteIds.EXT_FLOW_ACTION_ITEMS]: 'Flow',
  [RouteIds.EXT_USER_PROFILE]: 'User',
  [RouteIds.EXT_USER_NOTIFICATIONS]: 'User',
  [RouteIds.ORG_DASHBOARD_SUMMARY]: 'Dashboard',
  [RouteIds.ORG_DASHBOARD_FLOWS]: 'Dashboard',
  [RouteIds.ORG_FLOWS_LIST]: 'Flows',
  [RouteIds.ORG_FLOWS_BOARD]: 'Flows',
  [RouteIds.ORG_FLOW_SUMMARY]: 'Flow',
  [RouteIds.ORG_FLOW_CHAT]: 'Flow',
  [RouteIds.ORG_FLOW_ACTION_ITEMS]: 'Flow',
  [RouteIds.ORG_CONTACTS]: 'Contacts',
  [RouteIds.ORG_CONTACT]: 'Contact',
  [RouteIds.ORG_CONFIGURATION_PROFILE]: 'Configuration',
  [RouteIds.ORG_CONFIGURATION_SETTINGS]: 'Configuration',
  [RouteIds.ORG_USER_PROFILE]: 'User',
  [RouteIds.ORG_USER_NOTIFICATIONS]: 'User',
  [RouteIds.NOT_FOUND]: 'Not Found',
}

export const ROUTE_GROUP_ID_TO_PATH_PREFIX: { [key in RouteGroupIds]: string } = {
  [RouteGroupIds.UNV_FLOW]: '/unv/flow/:flowId',
  [RouteGroupIds.EXT_ROOT]: '/ext',
  [RouteGroupIds.EXT_FLOW]: '/ext/flow/:flowId',
  [RouteGroupIds.ORG_ROOT]: '/org/:orgId',
  [RouteGroupIds.ORG_FLOWS]: '/org/:orgId/flows',
  [RouteGroupIds.ORG_FLOW]: '/org/:orgId/flow/:flowId',
}

export const SCREEN_ID_TO_ROUTE_ID: { [key in ScreenIds]: RouteIds } = {
  [ScreenIds.ROOT]: RouteIds.ROOT,
  [ScreenIds.LINKS_FLOWS_INITIATE]: RouteIds.LINKS_FLOWS_INITIATE,
  [ScreenIds.LINKS_AUTH_EMAIL]: RouteIds.LINKS_AUTH_EMAIL,
  [ScreenIds.UNV_FLOW_ROOT]: RouteIds.UNV_FLOW_SUMMARY,
  [ScreenIds.UNV_FLOW_SUMMARY]: RouteIds.UNV_FLOW_SUMMARY,
  [ScreenIds.UNV_FLOW_CHAT]: RouteIds.UNV_FLOW_CHAT,
  [ScreenIds.EXT_ROOT]: RouteIds.EXT_FLOWS,
  [ScreenIds.EXT_SUB_ROOT]: RouteIds.EXT_FLOWS,
  [ScreenIds.EXT_FLOWS]: RouteIds.EXT_FLOWS,
  [ScreenIds.EXT_FLOW_ROOT]: RouteIds.EXT_FLOW_SUMMARY,
  [ScreenIds.EXT_FLOW_SUMMARY]: RouteIds.EXT_FLOW_SUMMARY,
  [ScreenIds.EXT_FLOW_CHAT]: RouteIds.EXT_FLOW_CHAT,
  [ScreenIds.EXT_FLOW_ACTION_ITEMS]: RouteIds.EXT_FLOW_ACTION_ITEMS,
  [ScreenIds.EXT_USER_PROFILE]: RouteIds.EXT_USER_PROFILE,
  [ScreenIds.EXT_USER_NOTIFICATIONS]: RouteIds.EXT_USER_NOTIFICATIONS,
  [ScreenIds.ORG_ROOT]: RouteIds.ORG_DASHBOARD_SUMMARY,
  [ScreenIds.ORG_SUB_ROOT]: RouteIds.ORG_DASHBOARD_SUMMARY,
  [ScreenIds.ORG_DASHBOARD_SUMMARY]: RouteIds.ORG_DASHBOARD_SUMMARY,
  [ScreenIds.ORG_DASHBOARD_FLOWS]: RouteIds.ORG_DASHBOARD_FLOWS,
  [ScreenIds.ORG_FLOWS_ROOT]: RouteIds.ORG_FLOWS_LIST,
  [ScreenIds.ORG_FLOWS_LIST]: RouteIds.ORG_FLOWS_LIST,
  [ScreenIds.ORG_FLOWS_BOARD]: RouteIds.ORG_FLOWS_BOARD,
  [ScreenIds.ORG_CONTACTS]: RouteIds.ORG_CONTACTS,
  [ScreenIds.ORG_CONTACT]: RouteIds.ORG_CONTACT,
  [ScreenIds.ORG_FLOW_ROOT]: RouteIds.ORG_FLOW_SUMMARY,
  [ScreenIds.ORG_FLOW_SUMMARY]: RouteIds.ORG_FLOW_SUMMARY,
  [ScreenIds.ORG_FLOW_CHAT]: RouteIds.ORG_FLOW_CHAT,
  [ScreenIds.ORG_FLOW_ACTION_ITEMS]: RouteIds.ORG_FLOW_ACTION_ITEMS,
  [ScreenIds.ORG_CONFIGURATION_PROFILE]: RouteIds.ORG_CONFIGURATION_PROFILE,
  [ScreenIds.ORG_CONFIGURATION_SETTINGS]: RouteIds.ORG_CONFIGURATION_SETTINGS,
  [ScreenIds.ORG_USER_PROFILE]: RouteIds.ORG_USER_PROFILE,
  [ScreenIds.ORG_USER_NOTIFICATIONS]: RouteIds.ORG_USER_NOTIFICATIONS,
  [ScreenIds.NOT_FOUND]: RouteIds.NOT_FOUND,
}

export const WEB_REDIRECTS = {
  '/unv/flow/:flowId': ROUTE_ID_TO_PATH[RouteIds.UNV_FLOW_CHAT],
  '/unv/flow/:flowId/summary': ROUTE_ID_TO_PATH[RouteIds.UNV_FLOW_CHAT],
  '/ext': ROUTE_ID_TO_PATH[RouteIds.EXT_FLOWS],
  '/ext/flow/:flowId': ROUTE_ID_TO_PATH[RouteIds.EXT_FLOW_CHAT],
  '/ext/flow/:flowId/summary': ROUTE_ID_TO_PATH[RouteIds.EXT_FLOW_CHAT],
  '/ext/user': ROUTE_ID_TO_PATH[RouteIds.EXT_USER_NOTIFICATIONS],
  '/ext/user/profile': ROUTE_ID_TO_PATH[RouteIds.EXT_USER_NOTIFICATIONS],
  '/org/:orgId': ROUTE_ID_TO_PATH[RouteIds.ORG_DASHBOARD_FLOWS],
  '/org/:orgId/dashboard': ROUTE_ID_TO_PATH[RouteIds.ORG_DASHBOARD_FLOWS],
  '/org/:orgId/dashboard/summary': ROUTE_ID_TO_PATH[RouteIds.ORG_DASHBOARD_FLOWS],
  '/org/:orgId/flows': ROUTE_ID_TO_PATH[RouteIds.ORG_FLOWS_LIST],
  '/org/:orgId/flow/:flowId': ROUTE_ID_TO_PATH[RouteIds.ORG_FLOW_CHAT],
  '/org/:orgId/flow/:flowId/summary': ROUTE_ID_TO_PATH[RouteIds.ORG_FLOW_CHAT],
  '/org/:orgId/configuration': ROUTE_ID_TO_PATH[RouteIds.ORG_CONFIGURATION_PROFILE],
  '/org/:orgId/configuration/profile': ROUTE_ID_TO_PATH[RouteIds.ORG_CONFIGURATION_SETTINGS],
  '/org/:orgId/user': ROUTE_ID_TO_PATH[RouteIds.ORG_USER_NOTIFICATIONS],
  '/org/:orgId/user/profile': ROUTE_ID_TO_PATH[RouteIds.ORG_USER_NOTIFICATIONS],
}

export function useMobileLinking() {
  const { isTablet } = useScreen()
  const { appBaseUrl } = useConfig()
  const { getRecoveryUrl } = useErrors()

  return useMemo(
    () => ({
      prefixes: [Linking.createURL('/'), appBaseUrl],
      async getInitialURL() {
        const recoveryUrl = getRecoveryUrl()
        if (recoveryUrl) {
          return recoveryUrl
        }
        return Linking.getInitialURL()
      },
      config: {
        screens: {
          [ScreenIds.ROOT]: '',
          [ScreenIds.LINKS_FLOWS_INITIATE]: 'links/flows/initiate/:flowConfigurationId',
          [ScreenIds.LINKS_AUTH_EMAIL]: 'links/auth/email/:emailToken',
          [ScreenIds.UNV_FLOW_ROOT]: {
            path: '/unv/flow/:flowId',
            screens: {
              [ScreenIds.UNV_FLOW_SUMMARY]: 'summary',
              [ScreenIds.UNV_FLOW_CHAT]: 'chat',
            },
          },
          [ScreenIds.EXT_ROOT]: {
            path: 'ext',
            screens: {
              [ScreenIds.EXT_SUB_ROOT]: {
                path: '',
                screens: {
                  [ScreenIds.EXT_FLOWS]: 'flows',
                  [ScreenIds.EXT_USER_PROFILE]: 'user/profile',
                  [ScreenIds.EXT_USER_NOTIFICATIONS]: 'user/notifications',
                },
              },
              [ScreenIds.EXT_FLOW_ROOT]: {
                path: 'flow/:flowId',
                screens: {
                  [ScreenIds.EXT_FLOW_SUMMARY]: 'summary',
                  [ScreenIds.EXT_FLOW_CHAT]: 'chat',
                  [ScreenIds.EXT_FLOW_ACTION_ITEMS]: 'action-items',
                },
              },
            },
          },
          [ScreenIds.ORG_ROOT]: {
            path: 'org/:orgId',
            screens: {
              ...(!isTablet ? { [ScreenIds.ORG_CONTACT]: 'contacts/:contactId' } : {}),
              [ScreenIds.ORG_SUB_ROOT]: {
                path: '',
                screens: {
                  [ScreenIds.ORG_DASHBOARD_SUMMARY]: 'dashboard/summary',
                  [ScreenIds.ORG_DASHBOARD_FLOWS]: 'dashboard/flows',
                  [ScreenIds.ORG_FLOWS_ROOT]: {
                    path: 'flows',
                    screens: {
                      [ScreenIds.ORG_FLOWS_LIST]: 'list',
                      [ScreenIds.ORG_FLOWS_BOARD]: 'board',
                    },
                  },
                  [ScreenIds.ORG_CONTACTS]: 'contacts',
                  ...(isTablet ? { [ScreenIds.ORG_CONTACT]: 'contacts/:contactId' } : {}),
                  [ScreenIds.ORG_CONFIGURATION_PROFILE]: 'configuration/profile',
                  [ScreenIds.ORG_CONFIGURATION_SETTINGS]: 'configuration/settings',
                  [ScreenIds.ORG_USER_PROFILE]: 'user/profile',
                  [ScreenIds.ORG_USER_NOTIFICATIONS]: 'user/notifications',
                },
              },
              [ScreenIds.ORG_FLOW_ROOT]: {
                path: 'flow/:flowId',
                screens: {
                  [ScreenIds.ORG_FLOW_SUMMARY]: 'summary',
                  [ScreenIds.ORG_FLOW_CHAT]: 'chat',
                  [ScreenIds.ORG_FLOW_ACTION_ITEMS]: 'action-items',
                },
              },
            },
          },
          [ScreenIds.NOT_FOUND]: '*',
        },
      },
    }),
    [isTablet],
  )
}

export function pathnameToRouteId(pathname: string): RouteIds {
  for (const routeId in ROUTE_ID_TO_PATH) {
    if (matchPath(pathname, { path: ROUTE_ID_TO_PATH[routeId as RouteIds], exact: true, strict: true })) {
      return routeId as RouteIds
    }
  }

  throw new Error('Pathname does not match any route.')
}

export function restrictedPathnameToRouteId<T extends RouteIds>(
  pathname: string,
  allowedRouteIds: { [key in T]: true },
): T {
  const routeId = pathnameToRouteId(pathname) as T
  if (!allowedRouteIds[routeId]) {
    throw new Error('Unexpected route id.')
  }
  return routeId
}

export function screenNameToRouteId(screenName: string): RouteIds {
  const routeId = SCREEN_ID_TO_ROUTE_ID[screenName as ScreenIds]
  if (!routeId) {
    throw new Error('Screen name does not match any route id.')
  }
  return routeId
}

export function restrictedScreenNameToRouteId<T extends RouteIds>(
  screenName: string,
  allowedRouteIds: { [key in T]: true },
): T {
  const routeId = screenNameToRouteId(screenName) as T
  if (!allowedRouteIds[routeId]) {
    throw new Error('Unexpected route id.')
  }
  return routeId
}

export function webUseParam(name: string): string {
  const params = useParams() as { [key: string]: string }
  if (!params[name]) {
    throw new Error(`Param "${name} not found.`)
  }
  return params[name]
}

export function mobileStateToScreenId(state: NavigationState): string {
  const route = state.routes[state.index ?? 0]
  if (!route.state) {
    return route.name
  }
  return mobileStateToScreenId(route.state as NavigationState)
}

export function mobileOrgBackAction(state: NavigationState, orgId: string) {
  return state.index > 0
    ? StackActions.pop()
    : CommonActions.reset({ index: 0, routes: [{ name: ScreenIds.ORG_ROOT, params: { orgId } }] })
}

export function mobileExtBackAction(state: NavigationState) {
  return state.index > 0
    ? StackActions.pop()
    : CommonActions.reset({ index: 0, routes: [{ name: ScreenIds.EXT_ROOT }] })
}

export function mobileScreenIdPageTitle(screenId: ScreenIds): string {
  return ROUTE_ID_TO_TITLE[SCREEN_ID_TO_ROUTE_ID[screenId]]
}

export function getFlowActivityNotificationPath(flowId: string, flowOrgId: string | undefined, category: string) {
  if (flowOrgId) {
    switch (category) {
      case 'messages':
        return generatePath(ROUTE_ID_TO_PATH[RouteIds.ORG_FLOW_CHAT], { flowId, orgId: flowOrgId })
      case 'action_items':
        return generatePath(ROUTE_ID_TO_PATH[RouteIds.ORG_FLOW_ACTION_ITEMS], { flowId, orgId: flowOrgId })
      default:
        return generatePath(ROUTE_ID_TO_PATH[RouteIds.ORG_FLOW_SUMMARY], { flowId, orgId: flowOrgId })
    }
  }

  switch (category) {
    case 'messages':
      return generatePath(ROUTE_ID_TO_PATH[RouteIds.EXT_FLOW_CHAT], { flowId })
    case 'action_items':
      return generatePath(ROUTE_ID_TO_PATH[RouteIds.EXT_FLOW_ACTION_ITEMS], { flowId })
    default:
      return generatePath(ROUTE_ID_TO_PATH[RouteIds.EXT_FLOW_SUMMARY], { flowId })
  }
}
