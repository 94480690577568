import {
  ScreenContent,
  ScreenPrimary,
  screenPrimaryFlowIdExtractor,
  ScreenPrimaryItemSeparator,
  screenPrimaryRenderSectionHeader,
  ScreenPrimarySectionSeparator,
  ScreenPrimaryStyles,
  ScreenSecondary,
  Tabs,
  Pane,
  UserProfile,
} from '@src/components/atoms'
import { FlowNotificationCard, UserPaneProfile } from '@src/components/molecules'
import { Attachment_Types_Enum, OrgFlowNotificationsQuery } from '@src/gen/graphql'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { useOrgFlowNotifications } from '@src/logic/data/providers'
import { CommonStyles, useScreen } from '@src/logic/design'
import { NavigateFuncFlow } from '@src/logic/routing/common'
import { useOrgRouting } from '@src/logic/routing/providers'
import { ArrayElement } from '@src/logic/utils'
import React, { Fragment, useCallback } from 'react'
import { FlatList, View } from 'react-native'
import {
  useRegisterUserProfileEditAction,
  useRegisterUserAvatarEditAction,
  useRegisterUserSignOutAction,
} from '@src/components/actions'

export type OrgUserPartProps = {
  view: 'profile' | 'notifications'
}

export function OrgUserPart({ view }: OrgUserPartProps) {
  const { isPhone } = useScreen()
  const { useUserTabs } = useOrgRouting()
  const userTabs = useUserTabs()

  return (
    <Fragment>
      {isPhone && <Tabs active={userTabs.activeTab?.id} onChange={userTabs.navigateTo} tabs={userTabs.tabs} />}
      <ScreenContent>
        {(!isPhone || view === 'profile') && <OrgUserProfilePart />}
        {(!isPhone || view === 'notifications') && <OrgUserNotificationsPart />}
      </ScreenContent>
    </Fragment>
  )
}

function OrgUserProfilePart() {
  const { user } = useAuthenticatedAuth()

  return (
    <ScreenSecondary isScrollable={true}>
      <UserPaneProfile user={user} />
    </ScreenSecondary>
  )
}

function OrgUserNotificationsPart() {
  const { flow_notifications, fetchMoreNotifications, clearNotification } = useOrgFlowNotifications()
  const { useNavigateToFlow } = useOrgRouting()
  const navigateToFlow = useNavigateToFlow()

  const ListHeaderComponent = useCallback(
    () =>
      screenPrimaryRenderSectionHeader({
        section: {
          title: 'Notifications',
          centeredVariant: 'medium',
        },
        style: CommonStyles.marginBottomStructure,
      }),
    [screenPrimaryRenderSectionHeader],
  )

  const renderItem = useCallback(
    ({ item, index }) => (
      <OrgUserNotificationsPartItem
        index={index}
        flowNotification={item}
        navigateToFlow={navigateToFlow}
        clearNotification={clearNotification}
      />
    ),
    [navigateToFlow],
  )

  return (
    <ScreenPrimary>
      <FlatList
        ItemSeparatorComponent={ScreenPrimaryItemSeparator}
        ListFooterComponent={ScreenPrimarySectionSeparator}
        ListHeaderComponent={ListHeaderComponent}
        data={flow_notifications}
        keyExtractor={screenPrimaryFlowIdExtractor}
        onEndReached={fetchMoreNotifications}
        onEndReachedThreshold={0.25}
        renderItem={renderItem}
        style={CommonStyles.flexOne}
      />
    </ScreenPrimary>
  )
}

type OrgUserNotificationsPartItemProps = {
  index: number
  flowNotification: ArrayElement<OrgFlowNotificationsQuery['flow_notifications']>
  navigateToFlow: NavigateFuncFlow
  clearNotification: (flowId: string, runAnimation: (toValue: number) => Promise<boolean>) => Promise<void>
}

function OrgUserNotificationsPartItem({
  flowNotification,
  navigateToFlow,
  clearNotification,
  index,
}: OrgUserNotificationsPartItemProps) {
  const handlePress = useCallback(
    () => navigateToFlow(flowNotification.flow_id),
    [flowNotification.flow_id, navigateToFlow],
  )

  return (
    <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
      <FlowNotificationCard
        flowNotification={flowNotification}
        onPress={handlePress}
        variant='org'
        index={index}
        clearNotification={clearNotification}
      />
    </View>
  )
}
