import { StackActions, useNavigation } from '@react-navigation/native'
import {
  ExtFlowRouteIds,
  ExtNavRouteIds,
  ExtUserRouteIds,
  NavigateFuncFlow,
  ROUTE_ID_TO_PATH,
  RouteIds,
  ScreenIds,
  Tab,
  Tabs,
  useMobileTabs,
  useWebTabs,
} from '@src/logic/routing/common'
import { useRequiredContext } from '@src/logic/utils'
import React, { createContext, ReactNode, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

export const EXT_NAV_TABS: Tab<ExtNavRouteIds>[] = [
  {
    id: RouteIds.EXT_FLOWS,
    label: 'My Conversations',
    secondaryIds: {
      [RouteIds.EXT_FLOW_SUMMARY]: true,
      [RouteIds.EXT_FLOW_CHAT]: true,
      [RouteIds.EXT_FLOW_ACTION_ITEMS]: true,
    },
  },
  {
    id: RouteIds.EXT_USER_PROFILE,
    label: 'User',
    secondaryIds: {
      [RouteIds.EXT_USER_NOTIFICATIONS]: true,
    },
  },
]

export const EXT_FLOW_TABS: Tab<ExtFlowRouteIds>[] = [
  {
    breakpoints: {
      phone: true,
    },
    id: RouteIds.EXT_FLOW_SUMMARY,
    label: 'Summary',
  },
  {
    id: RouteIds.EXT_FLOW_CHAT,
    label: 'Chat',
  },
  {
    id: RouteIds.EXT_FLOW_ACTION_ITEMS,
    label: 'Action Items',
  },
]

export const EXT_USER_TABS: Tab<ExtUserRouteIds>[] = [
  {
    breakpoints: {
      phone: true,
    },
    id: RouteIds.EXT_USER_PROFILE,
    label: 'Profile',
  },
  {
    id: RouteIds.EXT_USER_NOTIFICATIONS,
    label: 'Notifications',
  },
]

export type ExtRoutingProviderType = {
  useNavTabs: () => Tabs<ExtNavRouteIds>
  useFlowTabs: () => Tabs<ExtFlowRouteIds>
  useUserTabs: () => Tabs<ExtUserRouteIds>
  useNavigateToFlow: () => NavigateFuncFlow
}

export const ExtRoutingContext = createContext<ExtRoutingProviderType | undefined>(undefined)
export const useExtRouting = () => useRequiredContext(ExtRoutingContext)

export const ExtRoutingProvider = {
  Web: ExtRoutingProviderWeb,
  Mobile: ExtRoutingProviderMobile,
}

export type ExtRoutingProviderWebProps = {
  children?: ReactNode
}

function ExtRoutingProviderWeb({ children }: ExtRoutingProviderWebProps) {
  const value = useMemo<ExtRoutingProviderType>(
    () => ({
      useNavTabs: () => useWebTabs<ExtNavRouteIds>([], EXT_NAV_TABS),
      useFlowTabs: () => useWebTabs<ExtFlowRouteIds>(['flowId'], EXT_FLOW_TABS),
      useUserTabs: () => useWebTabs<ExtUserRouteIds>([], EXT_USER_TABS),
      useNavigateToFlow: () => {
        const { push } = useHistory()
        return (flowId: string) => push(generatePath(ROUTE_ID_TO_PATH[RouteIds.EXT_FLOW_CHAT], { flowId }))
      },
    }),
    [],
  )

  return <ExtRoutingContext.Provider value={value}>{children}</ExtRoutingContext.Provider>
}

export type ExtRoutingProviderMobileProps = {
  children?: ReactNode
}

function ExtRoutingProviderMobile({ children }: ExtRoutingProviderMobileProps) {
  const value = useMemo(
    () => ({
      useNavTabs: () => useMobileTabs<ExtNavRouteIds>(EXT_NAV_TABS),
      useFlowTabs: () => useMobileTabs<ExtFlowRouteIds>(EXT_FLOW_TABS),
      useUserTabs: () => useMobileTabs<ExtUserRouteIds>(EXT_USER_TABS),
      useNavigateToFlow: () => {
        const { dispatch } = useNavigation()
        return (flowId: string) => dispatch(StackActions.push(ScreenIds.EXT_FLOW_ROOT, { flowId }))
      },
    }),
    [],
  )

  return <ExtRoutingContext.Provider value={value}>{children}</ExtRoutingContext.Provider>
}
