import {
  ChatBox,
  ScreenContent,
  ScreenPrimary,
  ScreenPrimaryStyles,
  ScreenSecondary,
  Tabs,
} from '@src/components/atoms'
import {
  FlowChat,
  FlowPaneExternalParticipants,
  FlowPaneInternalParticipants,
  PaneOrganizationProfile,
} from '@src/components/molecules'
import { useUnvFlow } from '@src/logic/data/providers'
import { CommonStyles, Theme, useScreen } from '@src/logic/design'
import { mergeTabShowActivity, RouteIds } from '@src/logic/routing/common'
import { useUnvRouting } from '@src/logic/routing/providers'
import { ifTrue } from '@src/logic/utils'
import React, { Fragment, useMemo, useRef } from 'react'
import { StyleSheet, View } from 'react-native'

export type UnvFlowPartProps = {
  view: 'summary' | 'chat'
}

const Styles = StyleSheet.create({
  chatBoxMobile: {
    marginRight: 64 + Theme.geometry.structure.spacing,
  },
})

export function UnvFlowPart({ view }: UnvFlowPartProps) {
  const flowTabs = useFlowTabsWithActivity()
  const { isPhone } = useScreen()

  return (
    <Fragment>
      {isPhone && <Tabs active={flowTabs.activeTab?.id} onChange={flowTabs.navigateTo} tabs={flowTabs.tabs} />}
      <ScreenContent>
        {(!isPhone || view === 'summary') && <UnvFlowSummaryPart />}
        {view === 'chat' && <UnvFlowChatPart />}
      </ScreenContent>
    </Fragment>
  )
}

function UnvFlowSummaryPart() {
  const unvFlow = useUnvFlow()
  const hasUnseen = useStickyHasUnseen()

  unvFlow.useSeen({
    status: true,
    fields: true,
    internalParticipants: true,
    externalParticipants: true,
  })

  return (
    <ScreenSecondary isScrollable={false}>
      <PaneOrganizationProfile label='Your conversation with' org={unvFlow.flow.organization} />
      <FlowPaneInternalParticipants
        dispatchFlowUserAction={unvFlow.dispatchFlowUserViewAction}
        flow={unvFlow.flow}
        showActivity={hasUnseen.internalParticipants}
      />
      <FlowPaneExternalParticipants
        dispatchFlowUserAction={unvFlow.dispatchFlowUserViewAction}
        flow={unvFlow.flow}
        showActivity={hasUnseen.externalParticipants}
      />
    </ScreenSecondary>
  )
}

function UnvFlowChatPart() {
  const flowTabs = useFlowTabsWithActivity()
  const unvFlow = useUnvFlow()
  const { isPhone } = useScreen()

  unvFlow.useSeen({
    status: !isPhone,
    fields: !isPhone,
    internalParticipants: !isPhone,
    externalParticipants: !isPhone,
    messages: true,
  })

  const chatBoxViewStyle = useMemo(
    () => [ScreenPrimaryStyles.containerViewCenteredMedium, CommonStyles.marginBottomStructure],
    [],
  )

  return (
    <ScreenPrimary>
      {!isPhone && <Tabs active={flowTabs.activeTab?.id} onChange={flowTabs.navigateTo} tabs={flowTabs.tabs} />}
      <FlowChat messages={unvFlow.flow.messages} user={unvFlow.unvUser} />
      {unvFlow.messageCreate && (
        <View style={chatBoxViewStyle}>
          <ChatBox onSend={unvFlow.messageCreate} style={ifTrue(isPhone, Styles.chatBoxMobile)} />
        </View>
      )}
    </ScreenPrimary>
  )
}

function useFlowTabsWithActivity() {
  const { useFlowTabs } = useUnvRouting()
  const hasUnseen = useStickyHasUnseen()

  return mergeTabShowActivity(useFlowTabs(), {
    [RouteIds.UNV_FLOW_CHAT]: hasUnseen.messages,
    [RouteIds.UNV_FLOW_SUMMARY]: hasUnseen.status || hasUnseen.internalParticipants || hasUnseen.externalParticipants,
  })
}

function useStickyHasUnseen() {
  const { hasUnseen } = useUnvFlow()
  const hasUnseenRef = useRef(hasUnseen)

  hasUnseenRef.current = {
    actionItems: hasUnseenRef.current.actionItems || hasUnseen.actionItems,
    messages: hasUnseenRef.current.messages || hasUnseen.messages,
    status: hasUnseenRef.current.status || hasUnseen.status,
    fields: hasUnseenRef.current.fields || hasUnseen.fields,
    internalParticipants: hasUnseenRef.current.internalParticipants || hasUnseen.internalParticipants,
    externalParticipants: hasUnseenRef.current.externalParticipants || hasUnseen.externalParticipants,
  }

  return hasUnseenRef.current
}
