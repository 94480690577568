import { FlowUserEditActionParams } from '@src/components/actions'
import { FlowUserViewActionParams } from '@src/components/actions/FlowUserViewAction'
import { BadgeProps, Item, Pane, PaneButton, PaneHeaderProps } from '@src/components/atoms'
import { Flow_Role_Types, Flow_Roles_Enum, Flows_Users, Maybe, Users } from '@src/gen/graphql/bindings'
import { ArrayElement, ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { PlusIcon } from 'react-native-heroicons/solid'

export type FlowPaneInternalParticipantsProps = {
  dispatchFlowUserAction: (params: FlowUserEditActionParams | FlowUserViewActionParams) => void
  flow: {
    flows_users: Array<
      Pick<Flows_Users, 'role' | 'flow_id'> & {
        flow_role_type?: Maybe<Pick<Flow_Role_Types, 'id' | 'name'>>
        user: Pick<Users, 'id' | 'first_name' | 'last_name'>
      }
    >
  }
  onInvite?: () => void
  showActivity?: boolean
}

export function FlowPaneInternalParticipants({
  dispatchFlowUserAction,
  flow,
  onInvite,
  showActivity,
}: FlowPaneInternalParticipantsProps) {
  const internalParticipants = flow.flows_users
    .filter((fu) => fu.role == Flow_Roles_Enum.OrganizationOwner || fu.role == Flow_Roles_Enum.OrganizationParticipant)
    .map((fu) => ({ ...fu, fullName: `${fu.user.first_name} ${fu.user.last_name}` }))
    .sort((fu1, fu2) => fu1.fullName.localeCompare(fu2.fullName))

  const header = useMemo<PaneHeaderProps>(
    () => ({
      iconButton: onInvite ? { Icon: PlusIcon, onPress: onInvite } : undefined,
      label: 'Internal Participants',
      showActivity,
    }),
    [onInvite, showActivity],
  )

  return (
    <Pane header={header}>
      {internalParticipants.map((p) => (
        <FlowPaneInternalParticipantsItem
          key={p.user.id}
          flowUser={p}
          dispatchFlowUserAction={dispatchFlowUserAction}
        />
      ))}
    </Pane>
  )
}

type FlowPaneInternalParticipantsItemProps = {
  dispatchFlowUserAction: (params: FlowUserEditActionParams | FlowUserViewActionParams) => void
  flowUser: ArrayElement<FlowPaneInternalParticipantsProps['flow']['flows_users']> & { fullName: string }
}

function FlowPaneInternalParticipantsItem({ dispatchFlowUserAction, flowUser }: FlowPaneInternalParticipantsItemProps) {
  const user = flowUser.user
  const avatar = useMemo(() => ({ user }), [user.id, user.first_name, user.last_name])
  const ownerBadge = useMemo(() => ({ colorVariant: 'primary', label: 'Owner' } as BadgeProps), [])
  const handlePress = useCallback(() => dispatchFlowUserAction({ flowUser }), [dispatchFlowUserAction, flowUser])

  return (
    <PaneButton isActive={false} onPress={handlePress}>
      <Item
        avatar={avatar}
        badge={ifTrue(flowUser.role === Flow_Roles_Enum.OrganizationOwner, ownerBadge)}
        body={flowUser.fullName}
        colorVariant='dark'
        label={flowUser.flow_role_type?.name}
        labelPosition='bottom'
        numberOfLines={1}
      />
    </PaneButton>
  )
}
