import React, { ReactNode } from 'react'
import { useAuth } from '@src/logic/auth'
import { createHTTPGraphQLClient } from './client'
import { ApolloProvider } from '@apollo/client/react'
import { useConfig } from '@src/logic/config'

export type GraphQLProviderProps = {
  children?: ReactNode
}

export const GraphQLProvider = ({ children }: GraphQLProviderProps) => {
  const config = useConfig()
  const auth = useAuth()
  const client = createHTTPGraphQLClient(config, auth)

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
