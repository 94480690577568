export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never

export type RecursiveArray<T> = Array<T | RecursiveArray<T>>

export function flatten<T>(a: RecursiveArray<T>): Array<T> {
  let out: Array<T> = []

  a.forEach((e) => {
    if (Array.isArray(e)) {
      out.push(...flatten(e))
    } else {
      out.push(e)
    }
  })

  return out
}

export function ifTrue<T>(condition: boolean, value: T): T | undefined {
  return (condition as any) === true ? value : undefined
}

export function ifTrueThen<T>(condition: boolean, callback: () => T): T | undefined {
  return (condition as any) === true ? callback() : undefined
}

export function ifTruthy<T>(condition: any, value: T): T | undefined {
  return condition ? value : undefined
}

export function ifDef<T>(condition: any, value: T): T | undefined {
  return condition !== undefined && condition !== null ? value : undefined
}

export function ifDefThen<V, R>(value: V | undefined, callback: (value: V) => R): R | undefined {
  return value !== undefined && value !== null ? callback(value) : undefined
}

export function stringEmptyToUndefined(s: string | null | undefined): string | undefined {
  return s === undefined || s === null || s === '' ? undefined : s
}
