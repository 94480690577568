import React, { ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'

export type ScreenContentProps = {
  children?: ReactNode
}

const Styles = StyleSheet.create({
  view: {
    alignItems: 'stretch',
    flex: 1,
    flexDirection: 'row',
  },
})

export function ScreenContent({ children }: ScreenContentProps) {
  return <View style={Styles.view}>{children}</View>
}
