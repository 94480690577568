import { RouteIds, Tab, Tabs, UnvFlowRouteIds, useMobileTabs, useWebTabs } from '@src/logic/routing/common'
import { useRequiredContext } from '@src/logic/utils'
import React, { createContext, ReactNode, useMemo } from 'react'

export const UNV_FLOW_TABS: Tab<UnvFlowRouteIds>[] = [
  {
    breakpoints: {
      phone: true,
    },
    id: RouteIds.UNV_FLOW_SUMMARY,
    label: 'Summary',
  },
  {
    id: RouteIds.UNV_FLOW_CHAT,
    label: 'Chat',
  },
]

export type UnvRoutingProviderType = {
  useFlowTabs: () => Tabs<UnvFlowRouteIds>
}

export const UnvRoutingContext = createContext<UnvRoutingProviderType | undefined>(undefined)
export const useUnvRouting = () => useRequiredContext(UnvRoutingContext)

export const UnvRoutingProvider = {
  Web: UnvRoutingProviderWeb,
  Mobile: UnvRoutingProviderMobile,
}

export type UnvRoutingProviderWebProps = {
  children?: ReactNode
}

function UnvRoutingProviderWeb({ children }: UnvRoutingProviderWebProps) {
  const value = useMemo(
    () => ({
      useFlowTabs: () => useWebTabs<UnvFlowRouteIds>(['flowId'], UNV_FLOW_TABS),
    }),
    [],
  )

  return <UnvRoutingContext.Provider value={value}>{children}</UnvRoutingContext.Provider>
}

export type UnvRoutingProviderMobileProps = {
  children?: ReactNode
}

function UnvRoutingProviderMobile({ children }: UnvRoutingProviderMobileProps) {
  const value = useMemo(
    () => ({
      useFlowTabs: () => useMobileTabs<UnvFlowRouteIds>(UNV_FLOW_TABS),
    }),
    [],
  )

  return <UnvRoutingContext.Provider value={value}>{children}</UnvRoutingContext.Provider>
}
