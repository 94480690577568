import {
  ACTION_ITEM_ACTION_ID,
  ActionItemAction,
  ActionItemActionProps,
  ActionItemActionValuesType,
} from '@src/components/actions'
import { Attachment_Types_Enum, useExtActionItemCompleteMutation } from '@src/gen/graphql/bindings'
import { useActions } from '@src/logic/actions'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { useRequiredContext } from '@src/logic/utils'
import React, { createContext, Fragment, ReactNode, useCallback } from 'react'
import {
  useRegisterUserProfileEditAction,
  useRegisterUserAvatarEditAction,
  useRegisterUserSignOutAction,
} from '@src/components/actions'

export type ExtContextType = {}
export const ExtContext = createContext<ExtContextType | undefined>(undefined)
export const useExt = () => useRequiredContext(ExtContext)

export type ExtProviderProps = {
  children?: ReactNode
}

export function ExtProvider({ children }: ExtProviderProps) {
  return (
    <ExtContext.Provider value={{}}>
      <ExtProviderActions>{children}</ExtProviderActions>
    </ExtContext.Provider>
  )
}

type ExtProviderActionsProps = {
  children?: ReactNode
}

function ExtProviderActions({ children }: ExtProviderActionsProps) {
  useRegisterExtActionItemCompleteAction()
  return <Fragment>{children}</Fragment>
}

function useRegisterExtActionItemCompleteAction() {
  const [actionItemCompleteMutation] = useExtActionItemCompleteMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { user, useUploader } = useAuthenticatedAuth()
  const uploader = useUploader(Attachment_Types_Enum.SecureFile)

  const handleSubmit = useCallback(
    async (values: ActionItemActionValuesType) => {
      await actionItemCompleteMutation({
        variables: values,
      })
      dismissAllActions()
    },
    [actionItemCompleteMutation, dismissAllActions],
  )

  useRegisterAction(
    {
      id: ACTION_ITEM_ACTION_ID,
      modal: ({ actionItem }: Pick<ActionItemActionProps, 'actionItem'>) => (
        <ActionItemAction actionItem={actionItem} onSubmit={handleSubmit} uploader={uploader} user={user} />
      ),
    },
    [dispatchAction, handleSubmit, uploader, user],
  )
}
