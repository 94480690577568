import { IconButton } from '@src/components/atoms/IconButton'
import { Text } from '@src/components/atoms/Text'
import { Theme } from '@src/logic/design'
import React, { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import { ChevronLeftIcon, MenuIcon } from 'react-native-heroicons/solid'

export type MobileHeaderProps = {
  onPress: () => void
  title: string
  variant: 'main' | 'side'
}

export const MobileHeader = memo(MobileHeaderComponent)

const Styles = StyleSheet.create({
  innerView: {
    alignItems: 'center',
    backgroundColor: Theme.colors.dark.backgroundColor,
    flexDirection: 'row',
    height: 48,
    paddingLeft: 24,
    paddingRight: 24,
  },
  sideView: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: 48,
  },
  text: {
    flex: 1,
    textAlign: 'center',
  },
})

function MobileHeaderComponent({ onPress, title, variant }: MobileHeaderProps) {
  return (
    <View style={Styles.innerView}>
      <View style={Styles.sideView}>
        <IconButton colorVariant='dark' Icon={variant === 'main' ? MenuIcon : ChevronLeftIcon} onPress={onPress} />
      </View>
      <Text colorVariant='dark' numberOfLines={1} style={Styles.text} textVariant='nav1'>
        {title}
      </Text>
      <View style={Styles.sideView} />
    </View>
  )
}
