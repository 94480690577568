import { captureSentryException } from '@src/logic/config'
import { CustomError } from 'ts-custom-error'

export class UnauthorizedError extends CustomError {
  public constructor() {
    super('Unauthorized.')
    Object.defineProperty(this, 'name', { value: 'UnauthorizedError' })
  }
}

export class ClassifiedError extends CustomError {
  public constructor(err: Error) {
    super('Unexpected.')
  }
}

export function processError(err: any) {
  try {
    if (err.isProcessed) {
      return
    }

    console.error(err)
    err.isProcessed = true
    captureSentryException(err)
  } catch (err: any) {
    captureSentryException(err)
  }
}
