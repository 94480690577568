export enum Accept {
  IMAGES,
  DOCUMENTS,
  BOTH,
}

const mimeTypes: { [key in Accept]: { list: string[]; map: { [key: string]: {} }; reason: string } } = {
  [Accept.IMAGES]: {
    list: [
      'image/gif', // .gif
      'image/jpeg', // .jpg
      'image/png', // .png
    ],
    map: {
      'image/gif': {}, // .gif
      'image/jpeg': {}, // .jpg
      'image/png': {}, // .png
    },
    reason: 'common image types',
  },
  [Accept.DOCUMENTS]: {
    list: [
      'application/msword', // .doc
      'application/pdf', // .pdf
      'application/vnd.ms-excel', // .xls
      'application/vnd.ms-powerpoint', // .ppt
      'application/vnd.oasis.opendocument.presentation', // .odp
      'application/vnd.oasis.opendocument.spreadsheet', // .ods
      'application/vnd.oasis.opendocument.text', // .odt
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xslx
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'text/csv', // .csv
      'text/plain', // .txt
      'text/rtf', // .rtf
    ],
    map: {
      'application/msword': {}, // .doc
      'application/pdf': {}, // .pdf
      'application/vnd.ms-excel': {}, // .xls
      'application/vnd.ms-powerpoint': {}, // .ppt
      'application/vnd.oasis.opendocument.presentation': {}, // .odp
      'application/vnd.oasis.opendocument.spreadsheet': {}, // .ods
      'application/vnd.oasis.opendocument.text': {}, // .odt
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': {}, // .pptx
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {}, // .xslx
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {}, // .docx
      'text/csv': {}, // .csv
      'text/plain': {}, // .txt
      'text/rtf': {}, // .rtf
    },
    reason: 'common document types',
  },
  [Accept.BOTH]: {
    list: [
      'application/msword', // .doc
      'application/pdf', // .pdf
      'application/vnd.ms-excel', // .xls
      'application/vnd.ms-powerpoint', // .ppt
      'application/vnd.oasis.opendocument.presentation', // .odp
      'application/vnd.oasis.opendocument.spreadsheet', // .ods
      'application/vnd.oasis.opendocument.text', // .odt
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xslx
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'image/gif', // .gif
      'image/jpeg', // .jpg
      'image/png', // .png
      'text/csv', // .csv
      'text/plain', // .txt
      'text/rtf', // .rtf
    ],
    map: {
      'application/msword': {}, // .doc
      'application/pdf': {}, // .pdf
      'application/vnd.ms-excel': {}, // .xls
      'application/vnd.ms-powerpoint': {}, // .ppt
      'application/vnd.oasis.opendocument.presentation': {}, // .odp
      'application/vnd.oasis.opendocument.spreadsheet': {}, // .ods
      'application/vnd.oasis.opendocument.text': {}, // .odt
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': {}, // .pptx
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {}, // .xslx
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {}, // .docx
      'image/gif': {}, // .gif
      'image/jpeg': {}, // .jpg
      'image/png': {}, // .png
      'text/csv': {}, // .csv
      'text/plain': {}, // .txt
      'text/rtf': {}, // .rtf
    },
    reason: 'common image and document types',
  },
}

const extensions: { [key: string]: string } = {
  csv: 'text/csv',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  odp: 'application/vnd.oasis.opendocument.presentation',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  pdf: 'application/pdf',
  png: 'image/png',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  rtf: 'text/rtf',
  txt: 'text/plain',
  xls: 'application/vnd.ms-excel',
  xslx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export function getAcceptedMimeTypes(accept: Accept): string[] {
  return mimeTypes[accept].list
}

export function validateMimeType(accept: Accept, file: File): string | undefined {
  let mimeType = file.type

  if (!mimeType) {
    mimeType = guessMimeTypeFromFileName(file.name)
  }

  if (!mimeTypes[accept].map[mimeType]) {
    return `Unacceptable type (${mimeTypes[accept].reason})`
  }
}

export function guessMimeTypeFromFileName(name: string): string {
  if (!name || name.lastIndexOf('.') < 0) {
    return ''
  }
  return extensions[name.substring(name.lastIndexOf('.') + 1, name.length)]
}
