import { useFocusEffect } from '@react-navigation/native'
import { FloatingActionMenu, FloatingActionMenuItemType, Modal } from '@src/components/atoms'
import { useRequiredContext } from '@src/logic/utils'
import { createNanoEvents, Emitter } from 'nanoevents'
import React, {
  createContext,
  DependencyList,
  EffectCallback,
  Fragment,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Platform } from 'react-native'
import { SvgProps } from 'react-native-svg'

export type Action<T> = {
  id: string
  menuItem?: ActionMenuItem<T>
  modal?: (params: T) => JSX.Element
}

export type ActionDispatch<T> = {
  id: string
  params: T
}

export type ActionMenuItem<T> = {
  Icon: (props: SvgProps) => JSX.Element
  label: string
  params: T
}

export type ActionsContextType = {
  dismissAllActions: () => void
  dispatchAction: <T>(dispatch: ActionDispatch<T>) => void
  useRegisterAction: <T>(action: Action<T> | undefined, deps: DependencyList) => void
}

export const ActionsContext = createContext<ActionsContextType | undefined>(undefined)
export const useActions = () => useRequiredContext(ActionsContext)

export type ActionsProviderProps = {
  children?: ReactNode
}

type ActionsEmitterType = {
  dismissAllActions: () => void
  dispatchAction: <T>(dispatch: ActionDispatch<T>) => void
  updateActions: (actions: Array<Action<any>>) => void
}

export function ActionsProvider({ children }: ActionsProviderProps) {
  const actionsRef = useRef<Array<Action<any>>>([])
  const emitterRef = useRef(createNanoEvents<ActionsEmitterType>())

  const context = useMemo<ActionsContextType>(
    () => ({
      dismissAllActions: () => emitterRef.current.emit('dismissAllActions'),
      dispatchAction: (dispatch) => emitterRef.current.emit('dispatchAction', dispatch),
      useRegisterAction: (action, deps) => useRegisterAction(emitterRef, actionsRef, action, deps),
    }),
    [actionsRef, emitterRef, useRegisterAction],
  )

  return (
    <ActionsContext.Provider value={context}>
      <ActionsProviderInner emitter={emitterRef.current} />
      {children}
    </ActionsContext.Provider>
  )
}

type ActionsProviderInnerProps = {
  emitter: Emitter<ActionsEmitterType>
}

function ActionsProviderInner({ emitter }: ActionsProviderInnerProps) {
  const [actions, setActions] = useState<Array<Action<any>>>([])
  const [dispatching, setDispatching] = useState<ActionDispatch<any> | undefined>(undefined)

  useEffect(() => {
    const off1 = emitter.on('updateActions', setActions)
    const off2 = emitter.on('dispatchAction', setDispatching)
    const off3 = emitter.on('dismissAllActions', () => setDispatching(undefined))

    return () => {
      off1()
      off2()
      off3()
    }
  }, [emitter])

  const items = useMemo(
    () =>
      actions
        .filter((a) => a.menuItem !== undefined)
        .map((a) => ({
          Icon: a.menuItem!.Icon,
          label: a.menuItem!.label,
          onPress: () =>
            setDispatching({
              id: a.id,
              params: a.menuItem!.params,
            }),
        })),
    [actions, setDispatching],
  )

  const action = actions.find((a) => a.id === dispatching?.id)
  const handleClose = useCallback(() => setDispatching(undefined), [setDispatching])

  return (
    <Fragment>
      <FloatingActionMenu items={dispatching === undefined ? items : []} />
      <Modal isOpen={dispatching !== undefined} onClose={handleClose}>
        {dispatching && action?.modal ? action.modal(dispatching.params) : null}
      </Modal>
    </Fragment>
  )
}

function useRegisterAction<T>(
  emitterRef: MutableRefObject<Emitter<ActionsEmitterType>>,
  actionsRef: MutableRefObject<Array<Action<any>>>,
  action: Action<T> | undefined,
  deps: DependencyList,
) {
  useActionsEffect(() => {
    if (action === undefined) {
      return
    }

    const newActions = actionsRef.current.concat([action])
    actionsRef.current = newActions
    emitterRef.current.emit('updateActions', newActions)

    return () => {
      const newActions = actionsRef.current.filter((a) => a != action)
      actionsRef.current = newActions
      emitterRef.current.emit('updateActions', newActions)
    }
  }, deps)
}

function useActionsEffect(callback: EffectCallback, deps: DependencyList) {
  if (Platform.OS === 'web') {
    useEffect(callback, deps)
  } else {
    useFocusEffect(useCallback(callback, deps))
  }
}
