import {
  Badge,
  BadgeProps,
  MobileFilters,
  ScreenPrimary,
  screenPrimaryIdKeyExtractor,
  ScreenPrimaryItemSeparator,
  ScreenPrimarySectionSeparator,
  ScreenPrimaryStyles,
  screenPrimaryTitleKeyExtractor,
  Select,
  Text,
} from '@src/components/atoms'
import { OrgFlowCard } from '@src/components/molecules'
import { OrgFlowsHeaderPart } from '@src/components/parts/OrgFlowsHeaderPart'
import { Flow_Outcomes_Enum, Flow_Statuses_Enum, OrgFlowsQuery } from '@src/gen/graphql/bindings'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { getFlowBadge } from '@src/logic/data/manipulation'
import { useOrg, useOrgFlows, useOrgFlowsFilters } from '@src/logic/data/providers'
import { CommonStyles, Theme, useScreen } from '@src/logic/design'
import { NavigateFuncFlow } from '@src/logic/routing/common'
import { useOrgRouting } from '@src/logic/routing/providers'
import { ArrayElement } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

const Styles = StyleSheet.create({
  headerView: {
    paddingBottom: 0,
  },
  columnView: {
    width: 340,
  },
  columnHeaderView: {
    alignItems: 'flex-start',
    paddingBottom: Theme.geometry.structure.spacing,
    paddingTop: Theme.geometry.structure.spacing,
  },
})

export function OrgFlowsBoardPart() {
  const { isPhone } = useScreen()
  const { flowFilterOwner, flowFilterStatusType } = useOrgFlowsFilters()
  const { useNavigateToFlow } = useOrgRouting()

  const data = useData()
  const navigateToFlow = useNavigateToFlow()

  const renderItem = useCallback(
    ({ item }) => <OrgFlowsBoardPartColumn column={item} navigateToFlow={navigateToFlow} />,
    [navigateToFlow],
  )

  return (
    <ScreenPrimary>
      <OrgFlowsHeaderPart centeredVariant='full' />
      <FlatList
        contentContainerStyle={ScreenPrimaryStyles.containerViewHorizontal}
        data={data}
        horizontal={true}
        keyExtractor={screenPrimaryTitleKeyExtractor}
        renderItem={renderItem}
        style={CommonStyles.flexOne}
      />
      {isPhone && (
        <MobileFilters
          activeFilters={[...flowFilterOwner.mobileFiltersValue, ...flowFilterStatusType.mobileFiltersValue]}>
          <Text colorVariant='darkDim' style={CommonStyles.marginBottomCardHalf} textVariant='body'>
            Owner
          </Text>
          <Select colorVariant='dark' style={CommonStyles.marginBottomCardHalf} {...flowFilterOwner} />
          <Text colorVariant='darkDim' style={CommonStyles.marginBottomCardHalf} textVariant='body'>
            Status / Type
          </Text>
          <Select colorVariant='dark' style={CommonStyles.marginBottomCardHalf} {...flowFilterStatusType} />
        </MobileFilters>
      )}
    </ScreenPrimary>
  )
}

type OrgFlowsBoardPartColumnProps = {
  column: ArrayElement<ReturnType<typeof useData>>
  navigateToFlow: NavigateFuncFlow
}

function OrgFlowsBoardPartColumn({ column, navigateToFlow }: OrgFlowsBoardPartColumnProps) {
  const ListHeaderComponent = useCallback(
    () => (
      <View style={Styles.columnHeaderView}>
        <Badge {...column.badge} />
      </View>
    ),
    [column.badge],
  )

  const renderItem = useCallback(
    ({ item }) => <OrgFlowsBoardPartColumnItem flow={item} navigateToFlow={navigateToFlow} />,
    [navigateToFlow],
  )

  return (
    <View>
      <FlatList
        contentContainerStyle={{ paddingRight: 12, paddingLeft: 12 }}
        data={column.data || []}
        ListHeaderComponent={ListHeaderComponent}
        ListFooterComponent={ScreenPrimarySectionSeparator}
        ItemSeparatorComponent={ScreenPrimaryItemSeparator}
        keyExtractor={screenPrimaryIdKeyExtractor}
        onEndReached={column.fetchMoreFlows}
        onEndReachedThreshold={0.25}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        style={Styles.columnView}
      />
    </View>
  )
}

type OrgFlowsBoardPartColumnItemProps = {
  flow: ArrayElement<OrgFlowsQuery['flows']>
  navigateToFlow: NavigateFuncFlow
}

function OrgFlowsBoardPartColumnItem({ flow, navigateToFlow }: OrgFlowsBoardPartColumnItemProps) {
  const { user } = useAuthenticatedAuth()
  const handlePress = useCallback(() => navigateToFlow(flow.id), [flow.id, navigateToFlow])
  return <OrgFlowCard flow={flow} onPress={handlePress} user={user} />
}

function useData() {
  const { flowFilterStatusType, flowFilterOwner } = useOrgFlowsFilters()
  const { flows, fetchMoreFlows } = useOrgFlows()
  const { orgConfig } = useOrg()

  return useMemo(() => {
    if (flowFilterStatusType.selectedOption.value.flowTypeId) {
      const flowType = orgConfig.flow_types.find((ft) => ft.id === flowFilterStatusType.selectedOption.value.flowTypeId)
      if (!flowType) {
        throw new Error('Unknown flow type.')
      }

      return flowType.flow_type_statuses.map((fts) => ({
        title: fts.name,
        badge: { colorVariant: 'lightDim', displayVariant: 'outline', label: fts.name } as BadgeProps,
        data: flows.filter((f) => f.flow_type_status?.id === fts.id),
        fetchMoreFlows,
      }))
    }

    return [
      {
        title: 'New',
        badge: getFlowBadge('outline', Flow_Statuses_Enum.New),
        data: flows.filter((f) => f.status === Flow_Statuses_Enum.New || f.status === Flow_Statuses_Enum.Ready),
        fetchMoreFlows,
      },
      {
        title: 'Flow',
        badge: getFlowBadge('outline', Flow_Statuses_Enum.Flow),
        data: flows.filter((f) => f.status === Flow_Statuses_Enum.Flow),
        fetchMoreFlows,
      },
      {
        title: 'Won',
        badge: getFlowBadge('outline', Flow_Statuses_Enum.Closed, Flow_Outcomes_Enum.Won),
        data: flows.filter((f) => f.status === Flow_Statuses_Enum.Closed && f.outcome === Flow_Outcomes_Enum.Won),
        fetchMoreFlows,
      },
      {
        title: 'Lost',
        badge: getFlowBadge('outline', Flow_Statuses_Enum.Closed, Flow_Outcomes_Enum.Lost),
        data: flows.filter((f) => f.status === Flow_Statuses_Enum.Closed && f.outcome === Flow_Outcomes_Enum.Lost),
        fetchMoreFlows,
      },
      {
        title: 'Closed',
        badge: getFlowBadge('outline', Flow_Statuses_Enum.Closed, Flow_Outcomes_Enum.Irrelevant),
        data: flows.filter(
          (f) => f.status === Flow_Statuses_Enum.Closed && f.outcome === Flow_Outcomes_Enum.Irrelevant,
        ),
        fetchMoreFlows,
      },
    ]
  }, [flowFilterOwner, flowFilterStatusType, flows])
}
