import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  getItemAsync,
  SecureStoreOptions,
  setItemAsync,
  deleteItemAsync,
  WHEN_UNLOCKED_THIS_DEVICE_ONLY,
} from 'expo-secure-store'
import { Platform } from 'react-native'

export type AuthStorageType = {
  refreshToken?: string
  unverifiedAccessTokens: {
    [key: string]: string
  }
}

const AUTH_STORAGE_KEY_REFRESH = 'platform-auth-context-refresh'
const AUTH_STORAGE_KEY_UNVERIFIED = 'platform-auth-context-unverified'
const SECURE_STORAGE_OPTIONS: SecureStoreOptions = { keychainAccessible: WHEN_UNLOCKED_THIS_DEVICE_ONLY }

export async function loadAuthStorage(): Promise<AuthStorageType> {
  let refreshToken: string | null = null
  let unverifiedAccessTokens: { [key: string]: string } = {}

  try {
    refreshToken =
      Platform.OS === 'web'
        ? await AsyncStorage.getItem(AUTH_STORAGE_KEY_REFRESH)
        : await getItemAsync(AUTH_STORAGE_KEY_REFRESH, SECURE_STORAGE_OPTIONS)
  } catch (err) {
    console.error(err)
  }

  try {
    const value = await AsyncStorage.getItem(AUTH_STORAGE_KEY_UNVERIFIED)
    if (value) {
      unverifiedAccessTokens = JSON.parse(value)
    }
  } catch (err) {
    console.error(err)
  }

  return {
    refreshToken: refreshToken ? refreshToken : undefined,
    unverifiedAccessTokens,
  }
}

export async function saveAuthStorage(storage: AuthStorageType) {
  try {
    if (storage.refreshToken) {
      Platform.OS === 'web'
        ? await AsyncStorage.setItem(AUTH_STORAGE_KEY_REFRESH, storage.refreshToken)
        : await setItemAsync(AUTH_STORAGE_KEY_REFRESH, storage.refreshToken, SECURE_STORAGE_OPTIONS)
    } else {
      Platform.OS === 'web'
        ? await AsyncStorage.removeItem(AUTH_STORAGE_KEY_REFRESH)
        : await deleteItemAsync(AUTH_STORAGE_KEY_REFRESH, SECURE_STORAGE_OPTIONS)
    }

    Object.keys(storage.unverifiedAccessTokens).length > 0
      ? await AsyncStorage.setItem(AUTH_STORAGE_KEY_UNVERIFIED, JSON.stringify(storage.unverifiedAccessTokens))
      : await AsyncStorage.removeItem(AUTH_STORAGE_KEY_UNVERIFIED)
  } catch (err) {
    console.error(err)
  }
}
