import { Item, ModalContent, ModalFooter, ModalHeader, UserProfile } from '@src/components/atoms'
import { Flow_Role_Types, Flows_Users, Maybe, Users } from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { CommonStyles } from '@src/logic/design'
import React, { Fragment, useMemo } from 'react'

export const FLOW_USER_VIEW_ACTION_ID = 'flowUserView'
export const FLOW_USER_VIEW_ACTION_LABEL = 'Flow Participant'

export type FlowUserViewActionParams = {
  flowUser: Pick<Flows_Users, 'role' | 'flow_id'> & {
    flow_role_type?: Maybe<Pick<Flow_Role_Types, 'id' | 'name'>>
    user: Pick<Users, 'id' | 'first_name' | 'last_name'>
  }
}

export type FlowUserViewActionProps = FlowUserViewActionParams

export function FlowUserViewAction({ flowUser }: FlowUserViewActionProps) {
  return (
    <Fragment>
      <ModalHeader title={FLOW_USER_VIEW_ACTION_LABEL} />
      <ModalContent style={CommonStyles.marginBottomCard}>
        <UserProfile colorVariant='light' user={flowUser.user} style={CommonStyles.marginBottomCard} />
        <Item colorVariant='light' body={flowUser.flow_role_type?.name ?? 'Not Set'} label='Flow Role' />
      </ModalContent>
    </Fragment>
  )
}

export function useRegisterFlowUserViewAction() {
  const { dispatchAction, useRegisterAction } = useActions()

  useRegisterAction<FlowUserViewActionParams>(
    {
      id: FLOW_USER_VIEW_ACTION_ID,
      modal: ({ flowUser }) => <FlowUserViewAction flowUser={flowUser} />,
    },
    [],
  )

  return useMemo(
    () => (params: FlowUserViewActionParams) =>
      dispatchAction({
        id: FLOW_USER_VIEW_ACTION_ID,
        params,
      }),
    [dispatchAction],
  )
}
