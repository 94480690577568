import { PhotoUpload } from '@src/components/molecules'
import { Attachment_Types_Enum, Organization_Roles_Enum } from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { OrganizationType, useAuthenticatedAuth } from '@src/logic/auth'
import { useOrg } from '@src/logic/data/providers'
import { Uploader } from '@src/logic/data/uploads'
import { ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { PhotographIcon } from 'react-native-heroicons/solid'

export const ORGANIZATION_LOGO_EDIT_ACTION_ID = 'organizationLogoEdit'
export const ORGANIZATION_LOGO_EDIT_ACTION_NAME = 'Edit Organization Logo'

export type OrganizationLogoEditActionParams = {
  org: OrganizationType
}

export type OrganizationLogoEditActionProps = OrganizationLogoEditActionParams & {
  onSubmit: () => Promise<void>
  uploader: Uploader
}

export function OrganizationLogoEditAction({ onSubmit, uploader }: OrganizationLogoEditActionProps) {
  return <PhotoUpload onSubmit={onSubmit} uploader={uploader} />
}

export function useRegisterOrganizationLogoEditAction() {
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org } = useOrg()
  const { useUploader } = useAuthenticatedAuth()
  const uploader = useUploader(Attachment_Types_Enum.OrganizationProfilePicture, org.id)

  const handleSubmit = useCallback(async () => {
    dismissAllActions()
  }, [dismissAllActions])

  useRegisterAction<OrganizationLogoEditActionParams>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: ORGANIZATION_LOGO_EDIT_ACTION_ID,
      menuItem: {
        Icon: PhotographIcon,
        label: ORGANIZATION_LOGO_EDIT_ACTION_NAME,
        params: { org },
      },
      modal: ({ org }) => <OrganizationLogoEditAction org={org} uploader={uploader} onSubmit={handleSubmit} />,
    }),
    [handleSubmit, org, uploader],
  )

  return useMemo(
    () => () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, (params: OrganizationLogoEditActionParams) =>
        dispatchAction({
          id: ORGANIZATION_LOGO_EDIT_ACTION_ID,
          params,
        }),
      ),
    [dispatchAction, org.role],
  )
}
