import { ColorVariants, Theme, ForegroundStyles, TextVariants } from '@src/logic/design'
import { Control } from '@src/components/atoms/Control'
import { TextInput, TextInputProps } from '@src/components/atoms/TextInput'
import { stringSchemaToValidator } from '@src/logic/data/manipulation'
import { ifTrue } from '@src/logic/utils'
import { StringSchema } from 'joi'
import React from 'react'
import { useField } from 'react-final-form'

export type TextInputControlProps = {
  isRequired?: boolean
  label?: string
  multiline?: boolean
  numberOfLines?: number
  name: string
  placeholder?: string
  schema?: StringSchema
  textContentType?: TextInputProps['textContentType']
  colorVariant?: 'light' | 'dark'
}

export function TextInputControl({
  isRequired = false,
  label,
  multiline,
  name,
  numberOfLines,
  placeholder,
  schema,
  textContentType,
  colorVariant = 'light',
}: TextInputControlProps) {
  const {
    input: { onBlur, onChange, onFocus, value },
    meta: { error, submitting, touched },
  } = useField<string>(name, {
    subscription: {
      error: true,
      submitting: true,
      touched: true,
      value: true,
    },
    validate: stringSchemaToValidator(schema, isRequired, textContentType === 'emailAddress'),
  })

  return (
    <Control error={ifTrue(touched ?? false, error)} isRequired={isRequired} label={label}>
      <TextInput
        colorVariant={colorVariant}
        isDisabled={submitting}
        multiline={multiline}
        numberOfLines={numberOfLines}
        onBlur={() => onBlur()}
        onChange={onChange}
        onFocus={() => onFocus()}
        placeholder={placeholder}
        textContentType={textContentType}
        value={value}
      />
    </Control>
  )
}
