import {
  screenPrimaryIdKeyExtractor,
  ScreenPrimaryItemSeparator,
  ScreenPrimarySectionSeparator,
  ScreenPrimaryStyles,
  Text,
} from '@src/components/atoms'
import { ActionItemCard } from '@src/components/molecules'
import { Action_Items, Maybe, Users } from '@src/gen/graphql/bindings'
import { UserType } from '@src/logic/auth'
import { makeSections } from '@src/logic/data/manipulation'
import { CommonStyles } from '@src/logic/design'
import { ArrayElement, ifDefThen } from '@src/logic/utils'
import React, { memo, useCallback, useMemo } from 'react'
import { SectionList, View } from 'react-native'

export type FlowActionItemsProps = {
  actionItems: Array<
    Pick<
      Action_Items,
      'activity_counter' | 'completed_at' | 'created_at' | 'id' | 'input' | 'prompt_cta' | 'question_answer' | 'type'
    > & {
      assignee: Pick<Users, 'id' | 'first_name' | 'last_name'>
      creator: Pick<Users, 'id' | 'first_name' | 'last_name'>
      completer?: Maybe<Pick<Users, 'id' | 'first_name' | 'last_name'>>
      upload_attachments: Array<{
        id: string
        content_length: any
        content_type: string
        image_width?: number
        image_height?: number
        url?: Maybe<string>
      }>
    }
  >
  onPress: (actionItem: ArrayElement<FlowActionItemsProps['actionItems']>) => void
  seenActionItemsActivityCounter?: number
  user: UserType
}

export function FlowActionItems({ actionItems, onPress, seenActionItemsActivityCounter, user }: FlowActionItemsProps) {
  const sections = useMemo(() => {
    const waitingOnMe = actionItems.filter((ai) => !ai.completed_at && ai.assignee.id === user.id)
    const waitingOnOthers = actionItems.filter((ai) => !ai.completed_at && ai.assignee.id !== user.id)
    const completed = actionItems.filter((ai) => ai.completed_at)

    return makeSections([
      {
        title: 'Waiting on Me',
        data: waitingOnMe,
      },
      {
        title: 'Waiting on Others',
        data: waitingOnOthers,
      },
      {
        title: 'Completed',
        data: completed,
      },
    ])
  }, [actionItems])

  const renderItem = useCallback(
    ({ item }: { item: ArrayElement<FlowActionItemsProps['actionItems']> }) => {
      const showActivity =
        seenActionItemsActivityCounter !== undefined &&
        item.activity_counter > seenActionItemsActivityCounter &&
        // Hacky - prevents spurious activity indicators when FlowActionItems is still mounted.
        !(!item.completed_at && item.creator.id === user.id) && // not waiting and created by user
        !(!!item.completed_at && item.completer && item.completer.id === user.id) // not completed by user

      return <FlowActionItemsItem actionItem={item} onPress={onPress} user={user} showActivity={showActivity} />
    },
    [onPress, seenActionItemsActivityCounter, user],
  )

  const renderSectionHeader = useCallback(
    ({ section }) => (
      <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
        <Text colorVariant='lightDim' textVariant='eyebrow'>
          {section.title}
        </Text>
      </View>
    ),
    [],
  )

  return (
    <SectionList
      ItemSeparatorComponent={ScreenPrimaryItemSeparator}
      ListHeaderComponent={ScreenPrimarySectionSeparator}
      SectionSeparatorComponent={ScreenPrimarySectionSeparator}
      keyExtractor={screenPrimaryIdKeyExtractor}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      sections={sections}
      stickySectionHeadersEnabled={false}
      style={CommonStyles.flexOne}
    />
  )
}

type FlowActionItemsItemProps = {
  actionItem: ArrayElement<FlowActionItemsProps['actionItems']>
  onPress: (actionItem: ArrayElement<FlowActionItemsProps['actionItems']>) => void
  showActivity?: boolean
  user: UserType
}

const FlowActionItemsItem = memo(FlowActionItemsItemComponent)

function FlowActionItemsItemComponent({ actionItem, onPress, showActivity, user }: FlowActionItemsItemProps) {
  const handlePress = useCallback(() => onPress(actionItem), [actionItem])

  return (
    <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
      <ActionItemCard actionItem={actionItem} onPress={handlePress} showActivity={showActivity} user={user} />
    </View>
  )
}
