import {
  Button,
  FileUploadControl,
  Form,
  IconButton,
  Item,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SubmitButton,
  Text,
  TextInputControl,
} from '@src/components/atoms'
import { ActionItemCardProps } from '@src/components/molecules'
import { Action_Item_Types_Enum, Action_Items, Maybe, Users } from '@src/gen/graphql/bindings'
import { getActionItemBadge, getActionItemTypeLabel, getUserFullName } from '@src/logic/data/manipulation'
import { Accept, Uploader } from '@src/logic/data/uploads'
import { CommonStyles, Theme } from '@src/logic/design'
import { ArrayElement, formatTimeSince } from '@src/logic/utils'
import React, { Fragment, useCallback, useMemo } from 'react'
import { Linking, StyleSheet, View } from 'react-native'
import { DownloadIcon } from 'react-native-heroicons/outline'

export const ACTION_ITEM_ACTION_ID = 'actionItem'
export const ACTION_ITEM_ACTION_LABEL = 'Action Item'
export const ACTION_ITEM_COMPLETE_ACTION_LABEL = 'Complete Action Item'

export type ActionItemActionValuesType = {
  actionItemId: string
  completedByUserId: string
  questionAnswer?: string
  uploadAttachmentIds: string[]
}

export type ActionItemActionProps = {
  actionItem: Pick<
    Action_Items,
    'completed_at' | 'created_at' | 'id' | 'input' | 'prompt_cta' | 'question_answer' | 'type'
  > & {
    assignee: Pick<Users, 'id' | 'first_name' | 'last_name'>
    creator: Pick<Users, 'id' | 'first_name' | 'last_name'>
    completer?: Maybe<Pick<Users, 'id' | 'first_name' | 'last_name'>>
    upload_attachments: Array<{
      id: string
      content_length: any
      content_type: string
      image_width?: number
      image_height?: number
      url?: Maybe<string>
    }>
  }
  onDelete?: (actionItemId: string, canceledByUserId: string) => Promise<void>
  onSubmit: (values: ActionItemActionValuesType) => Promise<void>
  uploader?: Uploader
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

type FormType = {
  questionAnswer?: string
  uploadAttachmentIds: string[]
}

const Styles = StyleSheet.create({
  uploadAttachmentView: {
    alignItems: 'center',
    borderColor: Theme.colors.success.borderColor,
    borderRadius: Theme.geometry.control.radius,
    borderWidth: 1,
    flexDirection: 'row',
    height: Theme.geometry.control.height,
    marginBottom: Theme.geometry.control.spacing,
    paddingLeft: Theme.geometry.control.spacing,
    paddingRight: Theme.geometry.control.spacing / 2,
  },
})

export function ActionItemAction({ actionItem, onDelete, onSubmit, uploader, user }: ActionItemActionProps) {
  const canComplete = !actionItem.completed_at && actionItem.assignee.id === user.id
  const fromAvatar = useMemo(() => ({ user: actionItem.creator }), [actionItem.creator])

  const toAvatar = useMemo(
    () => ({ user: actionItem.completer ? actionItem.completer : actionItem.assignee }),
    [actionItem.completer, actionItem.assignee],
  )

  const avatarsViewStyle = useMemo(() => [CommonStyles.flexRow, CommonStyles.marginBottomCard], [])

  const initialValues = useMemo<FormType>(
    () => ({
      questionAnswer: actionItem.type === Action_Item_Types_Enum.Question ? '' : undefined,
      uploadAttachmentIds: [],
    }),
    [actionItem.type],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        ...values,
        actionItemId: actionItem.id,
        completedByUserId: user.id,
      })
    },
    [actionItem.id, user.id],
  )

  const handleDelete = useCallback(async () => {
    if (onDelete) {
      await onDelete(actionItem.id, user.id)
    }
  }, [onDelete])

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={canComplete ? ACTION_ITEM_COMPLETE_ACTION_LABEL : ACTION_ITEM_ACTION_LABEL} />
      <ModalContent>
        <Item
          badge={getActionItemBadge('solid', actionItem, user)}
          colorVariant='light'
          body={getActionItemTypeLabel(actionItem.type)}
          label={formatTimeSince(actionItem.created_at)}
          labelPosition='bottom'
          numberOfLines={1}
          style={CommonStyles.marginBottomCard}
        />
        <Text colorVariant='light' style={CommonStyles.marginBottomCard} textVariant='body' enableLinks={true}>
          {actionItem.input}
        </Text>
        <View style={avatarsViewStyle}>
          <Item
            avatar={fromAvatar}
            colorVariant='light'
            body={getUserFullName(actionItem.creator)}
            label='Created By'
            numberOfLines={1}
            style={CommonStyles.flexOne}
          />
          <Item
            avatar={toAvatar}
            colorVariant='light'
            body={getUserFullName(actionItem.completer ? actionItem.completer : actionItem.assignee)}
            label={actionItem.completed_at ? 'Completed By' : 'Assigned To'}
            numberOfLines={1}
            style={CommonStyles.flexOne}
          />
        </View>
        {actionItem.prompt_cta && !canComplete && (
          <Item
            body={actionItem.prompt_cta}
            colorVariant='light'
            label={`Action${actionItem.completed_at ? ' Taken' : ''}`}
            style={CommonStyles.marginBottomCard}
          />
        )}
        {actionItem.question_answer && (
          <Item
            body={actionItem.question_answer}
            colorVariant='light'
            label='Answer'
            style={CommonStyles.marginBottomCard}
          />
        )}
        {actionItem.upload_attachments.map((ua, i) => (
          <UploadAttachment index={i + 1} uploadAttachment={ua} />
        ))}
        {canComplete && (
          <Fragment>
            {actionItem.type === Action_Item_Types_Enum.Question && (
              <TextInputControl isRequired={true} label='Answer' name='questionAnswer' />
            )}
            {actionItem.type === Action_Item_Types_Enum.Upload && uploader ? (
              <FileUploadControl
                accept={Accept.BOTH}
                isRequired={true}
                label='Select Files'
                maxFiles={5}
                maxSize={25 * 1024 * 1024}
                name='uploadAttachmentIds'
                uploader={uploader}
              />
            ) : null}
          </Fragment>
        )}
      </ModalContent>
      <ModalFooter>
        {onDelete && (
          <Button colorVariant='light' onPress={handleDelete}>
            Delete
          </Button>
        )}
        {canComplete && (
          <SubmitButton style={CommonStyles.marginLeftCard}>
            {actionItem.prompt_cta ? actionItem.prompt_cta : 'Complete'}
          </SubmitButton>
        )}
      </ModalFooter>
    </Form>
  )
}

type UploadAttachmentProps = {
  index: number
  uploadAttachment: ArrayElement<ActionItemCardProps['actionItem']['upload_attachments']>
}

function UploadAttachment({ index, uploadAttachment }: UploadAttachmentProps) {
  return (
    <View style={Styles.uploadAttachmentView}>
      <Text colorVariant='light' numberOfLines={1} style={CommonStyles.flexOne} textVariant='body'>
        {`Attachment ${index}`}
      </Text>
      <IconButton
        colorVariant='light'
        Icon={DownloadIcon}
        onPress={() => Linking.openURL(uploadAttachment.url || '')}
      />
    </View>
  )
}
