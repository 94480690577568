import { Theme } from '@src/logic/design'
import * as React from 'react'
import { memo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import Svg, { Circle } from 'react-native-svg'

export type ActivityIndicatorProps = {
  style?: StyleProp<ViewStyle>
}

export const ActivityIndicator = memo(ActivityIndicatorComponent)

function ActivityIndicatorComponent({ style }: ActivityIndicatorProps) {
  return (
    <Svg height={4} width={4} style={style} viewBox='0 0 100 100'>
      <Circle cx={50} cy={50} r={50} fill={Theme.colors.primary.lighterColor} />
    </Svg>
  )
}
