import {
  ScreenContent,
  ScreenPrimary,
  screenPrimaryIdKeyExtractor,
  ScreenPrimaryItemSeparator,
  screenPrimaryRenderSectionHeader,
  ScreenPrimarySectionSeparator,
  ScreenPrimaryStyles,
  ScreenSecondary,
  Tabs,
} from '@src/components/atoms'
import { FlowPaneRecentActivity, FlowPaneSnapshot, OrgFlowCard, OrgFlowCardProps } from '@src/components/molecules'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { makeSections } from '@src/logic/data/manipulation'
import { useOrgDashboard } from '@src/logic/data/providers'
import { CommonStyles, useScreen } from '@src/logic/design'
import { NavigateFuncFlow } from '@src/logic/routing/common'
import { useOrgRouting } from '@src/logic/routing/providers'
import React, { Fragment, useCallback, useMemo } from 'react'
import { SectionList, View } from 'react-native'

export type OrgDashboardPartProps = {
  view: 'summary' | 'flows'
}

export function OrgDashboardPart({ view }: OrgDashboardPartProps) {
  const { isPhone } = useScreen()
  const { useDashboardTabs } = useOrgRouting()
  const dashboardTabs = useDashboardTabs()

  return (
    <Fragment>
      {isPhone && (
        <Tabs active={dashboardTabs.activeTab?.id} onChange={dashboardTabs.navigateTo} tabs={dashboardTabs.tabs} />
      )}
      <ScreenContent>
        {(!isPhone || view === 'summary') && <OrgDashboardSummaryPart />}
        {(!isPhone || view === 'flows') && <OrgDashboardFlowsPart />}
      </ScreenContent>
    </Fragment>
  )
}

function OrgDashboardSummaryPart() {
  const orgDashboard = useOrgDashboard()
  const { useNavigateToFlow } = useOrgRouting()
  const navigateToFlow = useNavigateToFlow()

  return (
    <ScreenSecondary isScrollable={false}>
      <FlowPaneSnapshot {...orgDashboard} />
      <FlowPaneRecentActivity navigateToFlow={navigateToFlow} {...orgDashboard} />
    </ScreenSecondary>
  )
}

function OrgDashboardFlowsPart() {
  const orgDashboard = useOrgDashboard()
  const { useNavigateToFlow } = useOrgRouting()
  const navigateToFlow = useNavigateToFlow()

  const sections = useMemo(
    () =>
      makeSections([
        {
          title: 'New Flows as Owner',
          centeredVariant: 'medium',
          withActionItems: false,
          data: orgDashboard.flows_new,
        },
        {
          title: 'Flows with Action Items Waiting on Me',
          centeredVariant: 'medium',
          withActionItems: true,
          data: orgDashboard.flows_with_action_items_waiting_on_me,
        },
        {
          title: 'Flows with Action Items Waiting on Others',
          centeredVariant: 'medium',
          withActionItems: true,
          data: orgDashboard.flows_with_action_items_waiting_on_others,
        },
        {
          title: 'Stale Flows',
          centeredVariant: 'medium',
          withActionItems: false,
          data: orgDashboard.flows_stale,
        },
      ]),
    [orgDashboard],
  )

  const renderItem = useCallback(
    ({ item }) => <OrgDashboardFlowsPartItem flow={item} navigateToFlow={navigateToFlow} />,
    [navigateToFlow],
  )

  return (
    <ScreenPrimary>
      <SectionList
        ItemSeparatorComponent={ScreenPrimaryItemSeparator}
        SectionSeparatorComponent={ScreenPrimarySectionSeparator}
        keyExtractor={screenPrimaryIdKeyExtractor}
        renderItem={renderItem}
        renderSectionHeader={screenPrimaryRenderSectionHeader}
        sections={sections}
        stickySectionHeadersEnabled={false}
        style={CommonStyles.flexOne}
      />
    </ScreenPrimary>
  )
}

type OrgDashboardFlowsPartItemProps = {
  flow: OrgFlowCardProps['flow']
  navigateToFlow: NavigateFuncFlow
}

function OrgDashboardFlowsPartItem({ flow, navigateToFlow }: OrgDashboardFlowsPartItemProps) {
  const { user } = useAuthenticatedAuth()
  const handlePress = useCallback(() => navigateToFlow(flow.id), [flow.id, navigateToFlow])
  return (
    <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
      <OrgFlowCard flow={flow} onPress={handlePress} user={user} />
    </View>
  )
}
