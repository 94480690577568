import { Card, Item, Text } from '@src/components/atoms'
import { Flow_Type_Statuses, Flow_Types } from '@src/gen/graphql/bindings'
import { CommonStyles } from '@src/logic/design'
import React from 'react'
import { ViewStyle } from 'react-native'

export type FlowTypeCardProps = {
  flowType: Pick<Flow_Types, 'id' | 'name'> & {
    flow_type_statuses: Array<Pick<Flow_Type_Statuses, 'id' | 'name'>>
  }
  style?: ViewStyle
}

export function FlowTypeCard({ flowType, style }: FlowTypeCardProps) {
  return (
    <Card colorVariant='lightDim' style={style}>
      <Text colorVariant='light' numberOfLines={1} style={CommonStyles.marginBottomCard} textVariant='title'>
        {flowType.name}
      </Text>
      <Item
        colorVariant='light'
        body={flowType.flow_type_statuses.map((fts) => fts.name).join('\n')}
        label='Stages'
        style={CommonStyles.marginBottomCard}
      />
    </Card>
  )
}
