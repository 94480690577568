import { SelectVirtualOverlayWeb } from '@src/components/atoms/Select'
import { SelectModal } from '@src/components/atoms/SelectModal'
import { ViewButton } from '@src/components/atoms/ViewButton'
import { SelectOptionOrGroupType, SelectOptionType, SelectValueBaseType } from '@src/logic/data/manipulation'
import React, { useCallback, useState } from 'react'
import { Platform, View, ViewStyle } from 'react-native'

export type ViewSelectProps<T extends SelectValueBaseType> = {
  isDisabled?: boolean
  onChange: (option: SelectOptionType<T>) => void
  optionsOrGroups: Array<SelectOptionOrGroupType<T>>
  selectedOption: SelectOptionType<T>
  style?: ViewStyle
}

export function ViewSelect<T extends SelectValueBaseType>({
  isDisabled,
  onChange,
  optionsOrGroups,
  selectedOption,
  style,
}: ViewSelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false)

  const handlePress = useCallback(() => setIsOpen(true), [setIsOpen])
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen])

  const handleChange = useCallback(
    (option: SelectOptionType<T>) => {
      setIsOpen(false)
      onChange(option)
    },
    [onChange, setIsOpen],
  )

  return (
    <View style={style}>
      <ViewButton isDisabled={isDisabled} hasChevron={true} onPress={handlePress}>{`${
        selectedOption.selectedLabel ? selectedOption.selectedLabel : selectedOption.label
      }`}</ViewButton>
      {Platform.OS === 'web' && (
        <SelectVirtualOverlayWeb
          isDisabled={isDisabled ?? false}
          onChange={handleChange}
          optionsOrGroups={optionsOrGroups}
          selectedOption={selectedOption}
        />
      )}
      {Platform.OS !== 'web' && (
        <SelectModal
          isOpen={isOpen}
          onChange={handleChange}
          onClose={handleClose}
          optionsOrGroups={optionsOrGroups}
          selectedOption={selectedOption}
        />
      )}
    </View>
  )
}
