import {
  addMilliseconds,
  differenceInMilliseconds,
  differenceInMinutes,
  format,
  formatDistance,
  formatRelative,
  isAfter,
  isToday,
  subDays,
  subMilliseconds,
  subSeconds,
} from 'date-fns'

export function formatTimeSince(at: Date | string): string {
  at = stringToDate(at)
  const now = new Date()

  if (isAfter(at, subSeconds(now.getTime(), 60))) {
    return 'just now'
  }

  if (isToday(at)) {
    return formatDistance(at, now, { addSuffix: true })
  }

  if (isAfter(at, subDays(now, 7))) {
    return formatRelative(at, now)
  }

  return `on ${format(at, 'PP')} at ${format(at, 'p')}`
}

export function absoluteDifferenceInMinutes(d1: Date | string, d2: Date | string): number {
  const diff = differenceInMinutes(stringToDate(d1), stringToDate(d2))
  return diff >= 0 ? diff : -diff
}

export function stringToDate(at: Date | string): Date {
  return typeof at === 'string' ? new Date(at) : at
}

export function serverTimeOffsetMs(localNow: Date | string, serverNow: Date | string, delay: number): number {
  return differenceInMilliseconds(stringToDate(localNow), stringToDate(serverNow)) - delay
}

export function serverTimeToLocal(serverTime: Date | string, offsetMs: number): Date {
  return addMilliseconds(stringToDate(serverTime), offsetMs)
}
