import {
  MobileFilters,
  ScreenPrimary,
  screenPrimaryIdKeyExtractor,
  ScreenPrimaryItemSeparator,
  ScreenPrimarySectionSeparator,
  ScreenPrimaryStyles,
  Select,
  Text,
} from '@src/components/atoms'
import { OrgFlowCard } from '@src/components/molecules'
import { OrgFlowsHeaderPart } from '@src/components/parts/OrgFlowsHeaderPart'
import { OrgFlowsQuery } from '@src/gen/graphql/bindings'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { useOrgFlows, useOrgFlowsFilters } from '@src/logic/data/providers'
import { CommonStyles, useScreen } from '@src/logic/design'
import { NavigateFuncFlow } from '@src/logic/routing/common'
import { useOrgRouting } from '@src/logic/routing/providers'
import { ArrayElement } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { FlatList, View } from 'react-native'

export function OrgFlowsListPart() {
  const { isPhone } = useScreen()
  const { flowFilterOwner, flowFilterStatusType } = useOrgFlowsFilters()
  const { flows, fetchMoreFlows } = useOrgFlows()
  const { useNavigateToFlow } = useOrgRouting()

  const navigateToFlow = useNavigateToFlow()

  const renderItem = useCallback(
    ({ item }) => <OrgFlowsListPartItem flow={item} navigateToFlow={navigateToFlow} />,
    [navigateToFlow],
  )

  return (
    <ScreenPrimary>
      <OrgFlowsHeaderPart centeredVariant='large' />
      <FlatList
        ItemSeparatorComponent={ScreenPrimaryItemSeparator}
        ListHeaderComponent={ScreenPrimarySectionSeparator}
        ListFooterComponent={ScreenPrimarySectionSeparator}
        data={flows}
        keyExtractor={screenPrimaryIdKeyExtractor}
        onEndReached={fetchMoreFlows}
        onEndReachedThreshold={0.25}
        renderItem={renderItem}
        style={CommonStyles.flexOne}
      />
      {isPhone && (
        <MobileFilters
          activeFilters={[...flowFilterOwner.mobileFiltersValue, ...flowFilterStatusType.mobileFiltersValue]}>
          <Text colorVariant='darkDim' style={CommonStyles.marginBottomCardHalf} textVariant='body'>
            Owner
          </Text>
          <Select colorVariant='dark' style={CommonStyles.marginBottomCardHalf} {...flowFilterOwner} />
          <Text colorVariant='darkDim' style={CommonStyles.marginBottomCardHalf} textVariant='body'>
            Status / Type
          </Text>
          <Select colorVariant='dark' style={CommonStyles.marginBottomCardHalf} {...flowFilterStatusType} />
        </MobileFilters>
      )}
    </ScreenPrimary>
  )
}

type OrgFlowsListPartItemProps = {
  flow: ArrayElement<OrgFlowsQuery['flows']>
  navigateToFlow: NavigateFuncFlow
}

function OrgFlowsListPartItem({ flow, navigateToFlow }: OrgFlowsListPartItemProps) {
  const { user } = useAuthenticatedAuth()
  const handlePress = useCallback(() => navigateToFlow(flow.id), [flow.id, navigateToFlow])
  return (
    <View style={ScreenPrimaryStyles.containerViewCenteredLarge}>
      <OrgFlowCard flow={flow} onPress={handlePress} user={user} />
    </View>
  )
}
