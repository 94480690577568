import { withSentryRouting } from '@sentry/react'
import { WebNav } from '@src/components/atoms'
import {
  ExtFlowPart,
  ExtFlowsPart,
  ExtUserPart,
  NotFound,
  OrgConfigurationPart,
  OrgContactsPart,
  OrgDashboardPart,
  OrgFlowPart,
  OrgFlowsBoardPart,
  OrgFlowsListPart,
  OrgNav,
  OrgUserPart,
  Splash,
  UnvFlowPart,
} from '@src/components/parts'
import { ExtNav } from '@src/components/parts/ExtNav'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { getSentryBrowserHistory } from '@src/logic/config'
import {
  ExtFlowNotificationsProvider,
  ExtFlowProvider,
  ExtFlowsProvider,
  ExtProvider,
  OrgConfigurationProvider,
  OrgContactProvider,
  OrgContactsProvider,
  OrgDashboardProvider,
  OrgFlowNotificationsProvider,
  OrgFlowProvider,
  OrgFlowsFiltersProvider,
  OrgFlowsProvider,
  OrgProvider,
  UnvFlowProvider,
  UserActionsProvider,
} from '@src/logic/data/providers'
import {
  ROUTE_GROUP_ID_TO_PATH_PREFIX,
  ROUTE_ID_TO_PATH,
  RouteGroupIds,
  RouteIds,
  useLinkAuthEmail,
  useLinkFlowInitiate,
  useLinkRoot,
  WEB_REDIRECTS,
} from '@src/logic/routing/common'
import { ExtRoutingProvider, OrgRoutingProvider, UnvRoutingProvider } from '@src/logic/routing/providers'
import React from 'react'
import { Router } from 'react-router'
import { generatePath, Redirect, Route as RouterRoute, RouteComponentProps, Switch, useParams } from 'react-router-dom'

const Route = withSentryRouting(RouterRoute)

type LinksFlowsInitiateRouteParams = {
  flowConfigurationId: string
}

type LinksAuthEmailRouteParams = {
  emailToken: string
}

type UnvFlowRootRouteParams = {
  flowId: string
}

type ExtFlowRootRouteParams = {
  flowId: string
}

type OrgRootRouteParams = {
  orgId: string
}

type OrgFlowRootRouteParams = {
  flowId: string
}

export function WebRouter() {
  return (
    <Router history={getSentryBrowserHistory()}>
      <Switch>
        {Object.entries(WEB_REDIRECTS).map(([from, to]) => (
          <Redirect key={from} exact from={from} to={to} />
        ))}
        <Route exact path={ROUTE_ID_TO_PATH[RouteIds.ROOT]} component={RootRoute} />
        <Route exact path={ROUTE_ID_TO_PATH[RouteIds.LINKS_FLOWS_INITIATE]} component={LinksFlowsInitiateRoute} />
        <Route exact path={ROUTE_ID_TO_PATH[RouteIds.LINKS_AUTH_EMAIL]} component={LinksAuthEmailRoute} />
        <Route path={ROUTE_GROUP_ID_TO_PATH_PREFIX[RouteGroupIds.UNV_FLOW]} component={UnvFlowRootRoute} />
        <Route path={ROUTE_GROUP_ID_TO_PATH_PREFIX[RouteGroupIds.EXT_ROOT]} component={ExtRootRoute} />
        <Route path={ROUTE_GROUP_ID_TO_PATH_PREFIX[RouteGroupIds.ORG_ROOT]} component={OrgRootRoute} />
        <Route path='*' component={NotFoundRoute} />
      </Switch>
    </Router>
  )
}

function RootRoute({ history: { replace } }: RouteComponentProps) {
  useAuthenticatedAuth()
  useLinkRoot(
    (orgId) => replace(generatePath(ROUTE_ID_TO_PATH[RouteIds.ORG_DASHBOARD_SUMMARY], { orgId })),
    () => replace(generatePath(ROUTE_ID_TO_PATH[RouteIds.EXT_FLOWS])),
  )
  return <Splash />
}

function LinksFlowsInitiateRoute({
  history: { replace },
  location: { search },
  match: { params },
}: RouteComponentProps<LinksFlowsInitiateRouteParams>) {
  const query = new URLSearchParams(search)

  useLinkFlowInitiate(
    params.flowConfigurationId,
    query.get('email'),
    query.get('firstName'),
    query.get('lastName'),
    (flowId) => replace(generatePath(ROUTE_ID_TO_PATH[RouteIds.UNV_FLOW_SUMMARY], { flowId })),
  )

  return <Splash />
}

function LinksAuthEmailRoute({
  history: { replace },
  match: { params },
}: RouteComponentProps<LinksAuthEmailRouteParams>) {
  useLinkAuthEmail(
    params.emailToken,
    (orgId) => replace(generatePath(ROUTE_ID_TO_PATH[RouteIds.ORG_DASHBOARD_SUMMARY], { orgId })),
    () => replace(generatePath(ROUTE_ID_TO_PATH[RouteIds.EXT_FLOWS])),
    (flowId) => replace(generatePath(ROUTE_ID_TO_PATH[RouteIds.EXT_FLOW_SUMMARY], { flowId })),
    (orgId, flowId) => replace(generatePath(ROUTE_ID_TO_PATH[RouteIds.ORG_FLOW_SUMMARY], { orgId, flowId })),
  )
  return <Splash />
}

function UnvFlowRootRoute({ match: { params } }: RouteComponentProps<UnvFlowRootRouteParams>) {
  return (
    <>
      <WebNav />
      <UnvFlowProvider flowId={params.flowId}>
        <UnvRoutingProvider.Web>
          <Switch>
            <Route exact path={ROUTE_ID_TO_PATH[RouteIds.UNV_FLOW_CHAT]} component={UnvFlowChatRoute} />
            <Route path='*' component={NotFoundRoute} />
          </Switch>
        </UnvRoutingProvider.Web>
      </UnvFlowProvider>
    </>
  )
}

function UnvFlowChatRoute() {
  return <UnvFlowPart view='chat' />
}

function ExtRootRoute({}: RouteComponentProps) {
  return (
    <>
      <ExtRoutingProvider.Web>
        <ExtProvider>
          <ExtNav />
          <Switch>
            <Route exact path={ROUTE_ID_TO_PATH[RouteIds.EXT_FLOWS]} component={ExtFlowsRoute} />
            <Route path={ROUTE_GROUP_ID_TO_PATH_PREFIX[RouteGroupIds.EXT_FLOW]} component={ExtFlowRootRoute} />
            <Route
              exact
              path={ROUTE_ID_TO_PATH[RouteIds.EXT_USER_NOTIFICATIONS]}
              component={ExtUserNotificationsRoute}
            />
          </Switch>
        </ExtProvider>
      </ExtRoutingProvider.Web>
    </>
  )
}

function ExtFlowsRoute() {
  return (
    <ExtFlowsProvider>
      <ExtFlowsPart />
    </ExtFlowsProvider>
  )
}

function ExtFlowRootRoute({ match: { params } }: RouteComponentProps<ExtFlowRootRouteParams>) {
  return (
    <ExtFlowProvider flowId={params.flowId}>
      <Switch>
        <Route exact path={ROUTE_ID_TO_PATH[RouteIds.EXT_FLOW_CHAT]} component={ExtFlowChatRoute} />
        <Route exact path={ROUTE_ID_TO_PATH[RouteIds.EXT_FLOW_ACTION_ITEMS]} component={ExtFlowActionItemsRoute} />
        <Route path='*' component={NotFoundRoute} />
      </Switch>
    </ExtFlowProvider>
  )
}

function ExtFlowChatRoute() {
  return <ExtFlowPart view='chat' />
}

function ExtFlowActionItemsRoute() {
  return <ExtFlowPart view='actionItems' />
}

function ExtUserNotificationsRoute() {
  return (
    <ExtFlowNotificationsProvider>
      <UserActionsProvider>
        <ExtUserPart view='notifications' />
      </UserActionsProvider>
    </ExtFlowNotificationsProvider>
  )
}

function OrgRootRoute({ match: { params } }: RouteComponentProps<OrgRootRouteParams>) {
  return (
    <>
      <OrgProvider orgId={params.orgId}>
        <OrgRoutingProvider.Web>
          <OrgNav />
          <Switch>
            <Route exact path={ROUTE_ID_TO_PATH[RouteIds.ORG_DASHBOARD_FLOWS]} component={OrgDashboardFlowsRoute} />
            <Route path={ROUTE_GROUP_ID_TO_PATH_PREFIX[RouteGroupIds.ORG_FLOWS]} component={OrgFlowsRootRoute} />
            <Route path={ROUTE_GROUP_ID_TO_PATH_PREFIX[RouteGroupIds.ORG_FLOW]} component={OrgFlowRootRoute} />
            <Route exact path={ROUTE_ID_TO_PATH[RouteIds.ORG_CONTACTS]} component={OrgContactsRoute} />
            <Route exact path={ROUTE_ID_TO_PATH[RouteIds.ORG_CONTACT]} component={OrgContactsRoute} />
            <Route
              exact
              path={ROUTE_ID_TO_PATH[RouteIds.ORG_CONFIGURATION_SETTINGS]}
              component={OrgConfigurationSettingsRoute}
            />
            <Route
              exact
              path={ROUTE_ID_TO_PATH[RouteIds.ORG_USER_NOTIFICATIONS]}
              component={OrgUserNotificationsRoute}
            />
            <Route path='*' component={NotFoundRoute} />
          </Switch>
        </OrgRoutingProvider.Web>
      </OrgProvider>
    </>
  )
}

function OrgDashboardFlowsRoute() {
  return (
    <OrgDashboardProvider>
      <OrgDashboardPart view='flows' />
    </OrgDashboardProvider>
  )
}

function OrgFlowsRootRoute() {
  return (
    <OrgFlowsFiltersProvider>
      <OrgFlowsProvider>
        <Switch>
          <Route exact path={ROUTE_ID_TO_PATH[RouteIds.ORG_FLOWS_LIST]} component={OrgFlowsListRoute} />
          <Route exact path={ROUTE_ID_TO_PATH[RouteIds.ORG_FLOWS_BOARD]} component={OrgFlowsBoardRoute} />
          <Route path='*' component={NotFoundRoute} />
        </Switch>
      </OrgFlowsProvider>
    </OrgFlowsFiltersProvider>
  )
}

function OrgFlowsListRoute() {
  return (
    <OrgFlowsProvider.Guard>
      <OrgFlowsListPart />
    </OrgFlowsProvider.Guard>
  )
}

function OrgFlowsBoardRoute() {
  return (
    <OrgFlowsProvider.Guard>
      <OrgFlowsBoardPart />
    </OrgFlowsProvider.Guard>
  )
}

function OrgFlowRootRoute({ match: { params } }: RouteComponentProps<OrgFlowRootRouteParams>) {
  return (
    <OrgFlowProvider flowId={params.flowId}>
      <Switch>
        <Route exact path={ROUTE_ID_TO_PATH[RouteIds.ORG_FLOW_CHAT]} component={OrgFlowChatRoute} />
        <Route exact path={ROUTE_ID_TO_PATH[RouteIds.ORG_FLOW_ACTION_ITEMS]} component={OrgFlowActionItemsRoute} />
        <Route path='*' component={NotFoundRoute} />
      </Switch>
    </OrgFlowProvider>
  )
}

function OrgFlowChatRoute() {
  return <OrgFlowPart view='chat' />
}

function OrgFlowActionItemsRoute() {
  return <OrgFlowPart view='actionItems' />
}

function OrgContactsRoute() {
  const { contactId } = useParams() as { contactId?: string }

  return (
    <OrgContactsProvider>
      {contactId && (
        <OrgContactProvider contactId={contactId}>
          <OrgContactsPart contactId={contactId} view='detail' />
        </OrgContactProvider>
      )}
      {!contactId && <OrgContactsPart view='list' />}
    </OrgContactsProvider>
  )
}

function OrgConfigurationSettingsRoute() {
  return (
    <OrgConfigurationProvider>
      <OrgConfigurationPart view='settings' />
    </OrgConfigurationProvider>
  )
}

function OrgUserNotificationsRoute() {
  return (
    <OrgFlowNotificationsProvider>
      <UserActionsProvider>
        <OrgUserPart view='notifications' />
      </UserActionsProvider>
    </OrgFlowNotificationsProvider>
  )
}

function NotFoundRoute() {
  return <NotFound />
}
