import { BackgroundStyles, CommonStyles } from '@src/logic/design/theme'
import { ifTrue, isWeb, usePrevious, useRequiredContext } from '@src/logic/utils'
import { StatusBar } from 'expo-status-bar'
import { throttle } from 'lodash'
import { createNanoEvents, Unsubscribe } from 'nanoevents'
import React, { createContext, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { Dimensions, KeyboardAvoidingView, PanResponder, Platform, ScaledSize, View } from 'react-native'
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'
import useAsyncEffect from 'use-async-effect'

const PHONE_BREAKPOINT_MAX_WIDTH = 768
const TABLET_BREAKPOINT_MAX_WIDTH = 1024

export type Breakpoint = 'phone' | 'tablet' | 'desktop'

export function calculateBreakpoint(width: number, allowDesktop: boolean): Breakpoint {
  if (width < PHONE_BREAKPOINT_MAX_WIDTH) {
    return 'phone'
  }
  if (width < TABLET_BREAKPOINT_MAX_WIDTH || !allowDesktop) {
    return 'tablet'
  }
  return 'desktop'
}

export type OnUserActivityEmitterType = { userActivity: () => void }
export type OnUserActivityFunc = (cb: OnUserActivityEmitterType['userActivity']) => Unsubscribe

export type ScreenContextType = {
  breakpoint: Breakpoint
  isPhone: boolean
  isTablet: boolean
  isDesktop: boolean
  screenHeight: string | number | undefined
  onUserActivity: OnUserActivityFunc
}

export function getScreenContext(
  breakpoint: Breakpoint,
  screenHeight: string | number | undefined,
  onUserActivity: OnUserActivityFunc,
) {
  return {
    breakpoint,
    screenHeight: breakpoint === 'desktop' ? screenHeight : undefined,
    isPhone: breakpoint === 'phone',
    isTablet: breakpoint === 'tablet',
    isDesktop: breakpoint === 'desktop',
    onUserActivity,
  }
}

export const ScreenContext = createContext<ScreenContextType | undefined>(undefined)
export const useScreen = () => useRequiredContext(ScreenContext)

export type ScreenProviderProps = {
  children?: ReactNode
}

export function ScreenProvider({ children }: ScreenProviderProps) {
  const emitterRef = useRef(createNanoEvents<OnUserActivityEmitterType>())
  const onUserActivityRef = useRef<OnUserActivityFunc>((cb) => emitterRef.current.on('userActivity', cb))
  const emitUserActivityRef = useRef(throttle(() => emitterRef.current.emit('userActivity'), 10000))

  useEffect(() => {
    return () => {
      emitUserActivityRef.current.cancel()
    }
  }, [])

  return isWeb() ? (
    <ScreenProviderWeb emitUserActivity={emitUserActivityRef.current} onUserActivity={onUserActivityRef.current}>
      {children}
    </ScreenProviderWeb>
  ) : (
    <ScreenProviderMobile emitUserActivity={emitUserActivityRef.current} onUserActivity={onUserActivityRef.current}>
      {children}
    </ScreenProviderMobile>
  )
}

type ScreenProviderWebProps = {
  children?: ReactNode
  emitUserActivity: () => void
  onUserActivity: OnUserActivityFunc
}

function ScreenProviderWeb({ children, emitUserActivity, onUserActivity }: ScreenProviderWebProps) {
  const breakpointRef = useRef<Breakpoint>(calculateBreakpoint(window.innerWidth, true))
  const [, setRender] = useState({})

  useAsyncEffect((isMounted) => {
    const handleResize = () => {
      emitUserActivity()
      const newBreakpoint = calculateBreakpoint(window.innerWidth, true)
      if (breakpointRef.current != newBreakpoint) {
        breakpointRef.current = newBreakpoint
        if (isMounted()) {
          setRender({})
        }
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const screenContext = useMemo<ScreenContextType>(
    () => getScreenContext(breakpointRef.current, '100vh', onUserActivity),
    [breakpointRef.current, onUserActivity],
  )

  const prevBreakpoint = usePrevious(breakpointRef.current)
  useEffect(() => {
    if (prevBreakpoint !== breakpointRef.current) {
      setRender({})
    }
  })

  if (prevBreakpoint !== breakpointRef.current) {
    return null
  }

  return (
    <ScreenContext.Provider value={screenContext}>
      <ScreenWrapper emitUserActivity={emitUserActivity}>{children}</ScreenWrapper>
    </ScreenContext.Provider>
  )
}

type ScreenProviderMobileProps = {
  children?: ReactNode
  emitUserActivity: () => void
  onUserActivity: OnUserActivityFunc
}

function ScreenProviderMobile({ children, emitUserActivity, onUserActivity }: ScreenProviderMobileProps) {
  const breakpointRef = useRef<Breakpoint>(calculateBreakpoint(Dimensions.get('window').width, false))
  const [, setRender] = useState({})

  useAsyncEffect((isMounted) => {
    const handleResize = ({ window }: { window: ScaledSize }) => {
      const newBreakpoint = calculateBreakpoint(window.width, false)
      if (breakpointRef.current != newBreakpoint) {
        breakpointRef.current = newBreakpoint
        if (isMounted()) {
          setRender({})
        }
      }
    }

    Dimensions.addEventListener('change', handleResize)
    return () => Dimensions.removeEventListener('change', handleResize)
  }, [])

  const screenContext = useMemo<ScreenContextType>(
    () => getScreenContext(breakpointRef.current, undefined, onUserActivity),
    [breakpointRef.current, onUserActivity],
  )

  const prevBreakpoint = usePrevious(breakpointRef.current)
  useEffect(() => {
    if (prevBreakpoint !== breakpointRef.current) {
      setRender({})
    }
  })

  if (prevBreakpoint !== breakpointRef.current) {
    return null
  }

  return (
    <ScreenContext.Provider value={screenContext}>
      <ScreenWrapper emitUserActivity={emitUserActivity}>{children}</ScreenWrapper>
    </ScreenContext.Provider>
  )
}

type ScreenWrapperProps = {
  children?: ReactNode
  emitUserActivity: () => void
}

function ScreenWrapper({ children, emitUserActivity }: ScreenWrapperProps) {
  const { isDesktop } = useScreen()

  const keyboardAvoidingViewStyle = useMemo(() => [CommonStyles.flexOne], [])
  const safeAreaViewStyle = useMemo(() => [BackgroundStyles.dark, CommonStyles.flexOne], [])

  const viewStyle = useMemo(
    () => [
      Platform.OS === 'web' ? BackgroundStyles.lightDim : BackgroundStyles.darkDim,
      CommonStyles.flexOne,
      CommonStyles.overflowHidden,
      ifTrue(isDesktop, CommonStyles.flexRow),
    ],
    [isDesktop],
  )

  const panResponder = useMemo(() => {
    const cb = () => {
      emitUserActivity()
      return false
    }

    return PanResponder.create({
      onMoveShouldSetPanResponderCapture: cb,
      onPanResponderTerminationRequest: cb,
      onStartShouldSetPanResponderCapture: cb,
    })
  }, [emitUserActivity])

  const mouseProps = useMemo(
    () => ({
      onMouseMove: emitUserActivity,
    }),
    [emitUserActivity],
  )

  return (
    <SafeAreaProvider>
      <StatusBar style='light' />
      <SafeAreaView style={safeAreaViewStyle}>
        <KeyboardAvoidingView behavior='padding' enabled={Platform.OS === 'ios'} style={keyboardAvoidingViewStyle}>
          <View style={viewStyle} {...panResponder.panHandlers} {...mouseProps}>
            {children}
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </SafeAreaProvider>
  )
}
