import { BadgeProps, Item, Pane, PaneStyles } from '@src/components/atoms'
import { OrgDashboardQuery } from '@src/gen/graphql/bindings'
import React, { useMemo } from 'react'

export type FlowPaneSnapshotProps = Pick<
  OrgDashboardQuery,
  'stats_action_items_waiting_on_me' | 'stats_open_flows_as_owner' | 'stats_open_flows_as_participant'
>

export function FlowPaneSnapshot(props: FlowPaneSnapshotProps) {
  const stats = useMemo(
    () =>
      getStats(
        props.stats_action_items_waiting_on_me.aggregate?.count,
        props.stats_open_flows_as_owner.aggregate?.count,
        props.stats_open_flows_as_participant.aggregate?.count,
      ),
    [
      props.stats_action_items_waiting_on_me.aggregate?.count,
      props.stats_open_flows_as_owner.aggregate?.count,
      props.stats_open_flows_as_participant.aggregate?.count,
    ],
  )

  const header = useMemo(() => ({ label: 'Snapshot' }), [])

  return (
    <Pane header={header}>
      <Item
        badge={stats.actionItemsWaitingOnMe.badge}
        body={stats.actionItemsWaitingOnMe.label}
        colorVariant='dark'
        style={PaneStyles.item}
      />
      <Item
        badge={stats.openFlowsAsOwner.badge}
        body={stats.openFlowsAsOwner.label}
        colorVariant='dark'
        style={PaneStyles.item}
      />
      <Item
        badge={stats.openFlowsAsParticipant.badge}
        body={stats.openFlowsAsParticipant.label}
        colorVariant='dark'
        style={PaneStyles.item}
      />
    </Pane>
  )
}

type StatsItem = {
  count: string
  label: string
  badge: BadgeProps
}

type Stats = {
  actionItemsWaitingOnMe: StatsItem
  openFlowsAsOwner: StatsItem
  openFlowsAsParticipant: StatsItem
  badgeWidth: number
}

function getStats(
  actionItemsWaitingOnMe: number | undefined = 0,
  openFlowsAsOwner: number | undefined = 0,
  openFlowsAsParticipant: number | undefined = 0,
): Stats {
  const max = Math.max(actionItemsWaitingOnMe, openFlowsAsOwner, openFlowsAsParticipant)
  const maxLength = `${max}`.length

  return {
    actionItemsWaitingOnMe: {
      count: `${actionItemsWaitingOnMe}`,
      label: 'Action Items Waiting on Me',
      badge: { label: `${actionItemsWaitingOnMe}`, colorVariant: 'warning' } as BadgeProps,
    },
    openFlowsAsOwner: {
      count: `${openFlowsAsOwner}`,
      label: 'Open Flows as Owner',
      badge: { label: `${openFlowsAsOwner}`, colorVariant: 'warning' } as BadgeProps,
    },
    openFlowsAsParticipant: {
      count: `${openFlowsAsParticipant}`,
      label: 'Open Flows as Participant',
      badge: { label: `${openFlowsAsParticipant}`, colorVariant: 'warning' } as BadgeProps,
    },
    badgeWidth: 24 + maxLength * 8,
  }
}
