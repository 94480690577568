import { Splash } from '@src/components/parts'
import {
  AnonRefreshTokenDocument,
  AnonRefreshTokenMutation,
  AnonRefreshTokenMutationVariables,
  AnonVerifyEmailTokenDocument,
  AnonVerifyEmailTokenMutation,
  AnonVerifyEmailTokenMutationVariables,
  Organization_Roles_Enum,
  Organizations,
  Users,
  UserUpdateLastSeenAtDocument,
  UserUpdateLastSeenAtMutation,
  UserUpdateLastSeenAtMutationVariables,
  UserUserAndUserOrganizationsDocument,
  UserUserAndUserOrganizationsQuery,
  UserUserAndUserOrganizationsQueryVariables,
} from '@src/gen/graphql/bindings'
import { setSentryUser, useConfig } from '@src/logic/config'
import { useUploader } from '@src/logic/data/uploads'
import { useScreen } from '@src/logic/design'
import { processError, UnauthorizedError } from '@src/logic/errors'
import { serverTimeOffsetMs } from '@src/logic/utils'
import { differenceInMilliseconds } from 'date-fns'
import { GraphQLClient } from 'graphql-request'
import { isEqual, merge, throttle } from 'lodash'
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import { HasuraClaims, parseAccessToken } from './accessTokens'
import { AuthStorageType, loadAuthStorage, saveAuthStorage } from './authStorage'

const USER_ACTIVITY_NOTIFICATION_INTERVAL = 5 * 60 * 1000

export enum AuthRoles {
  ORGANIZATION_USER = 'organization_user',
  EXTERNAL_USER = 'external_user',
  USER = 'user',
  UNVERIFIED_EXTERNAL_USER = 'unverified_external_user',
  ANONYMOUS = 'anonymous',
}

export type EmailTokenVerification = {
  newState: AuthStateType
  originatingFlowId?: string
  originatingFlowOrgId?: string
  originatingOrgInviteOrgId?: string
}

export type OrganizationType = Pick<Organizations, 'id' | 'name'> & {
  role: Organization_Roles_Enum
}

export type UserType = Pick<Users, 'id' | 'email' | 'first_name' | 'last_name' | 'is_verified'> & {
  organizations: OrganizationType[]
}

export type AuthStateType = {
  loading: boolean
  mutable: {
    accessToken?: string
    storage: AuthStorageType
    serverTimeOffsetMs?: number
  }
  user?: UserType
}

export type AuthContextType = Omit<AuthStateType, 'loading'> & {
  refresh: () => Promise<AuthStateType>
  verifyEmailToken: (emailToken: string) => Promise<EmailTokenVerification>
  storeUnverifiedAccessToken: (unverifiedAccessToken: string) => Promise<void>
  signOut: () => Promise<void>
  useUploader: typeof useUploader
}

export type AuthContextAuthenticatedType = Omit<AuthContextType, 'user'> & {
  user: UserType
}

export const AuthContext = createContext<AuthContextType>(undefined!)

export function useAuth() {
  return useContext(AuthContext)
}

export function useAuthenticatedAuth(): AuthContextAuthenticatedType {
  const auth = useAuth()
  if (!auth.user) {
    throw new UnauthorizedError()
  }

  return {
    ...auth,
    user: auth.user,
  }
}

export function useUnverifiedHasuraClaims(flowId: string): HasuraClaims {
  const auth = useAuth()

  const rawToken = auth.mutable.storage.unverifiedAccessTokens[flowId]
  if (!rawToken) {
    throw new Error('Not found.')
  }

  const hasuraClaims = parseAccessToken(rawToken)
  if (!hasuraClaims) {
    throw new Error('Not found.') // TODO(ibrt): Better error?
  }

  return hasuraClaims
}

export type AuthProviderProps = {
  children?: ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {
  const config = useConfig()
  const client = useMemo(() => new GraphQLClient(config.hasuraUrl), [config.hasuraUrl])
  const { onUserActivity } = useScreen()

  const [state, setState] = useState<AuthStateType>({
    loading: true,
    mutable: {
      storage: {
        unverifiedAccessTokens: {},
      },
    },
  })

  const updateState = async (newState: AuthStateType) => {
    await saveAuthStorage(newState.mutable.storage)

    // Note: we actually trigger a re-render only if the non-mutable parts of the state change.
    // Care needs to be put when modifying the callbacks, as they must need to be mutated only on re-render.
    if (state.loading !== newState.loading || !isEqual(state.user, newState.user)) {
      setState(newState)
    } else {
      state.mutable.accessToken = newState.mutable.accessToken
      state.mutable.storage = newState.mutable.storage
    }
  }

  setSentryUser(
    state.user
      ? {
          id: state.user.id,
          email: state.user.email,
        }
      : null,
  )

  useAsyncEffect(async () => {
    if (state.loading) {
      await updateState(await loadState(client))
    }
  }, [])

  useEffect(() => {
    if (!state.user) {
      return
    }

    let lastSeenAt: Date | undefined = undefined

    let notifyLastSeenAt = throttle(
      () => {
        if (lastSeenAt && state.mutable.accessToken) {
          const agoMs = differenceInMilliseconds(new Date(), lastSeenAt)
          console.info(`Notifying activity for user ${state.user?.id} (${agoMs}ms ago).`)
          // TODO(ibrt): Refresh token if needed.
          updateUserLastSeenAt(client, state.mutable.accessToken, agoMs)
        }
      },
      USER_ACTIVITY_NOTIFICATION_INTERVAL,
      {
        leading: true,
        trailing: true,
      },
    )

    const unsubscribe = onUserActivity(() => {
      lastSeenAt = new Date()
      notifyLastSeenAt()
    })

    return () => {
      unsubscribe()
      notifyLastSeenAt.cancel()
    }
  }, [client, state])

  if (state.loading) {
    return <Splash />
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        mutable: state.mutable,
        refresh: async () => await refresh(client, state, updateState),
        verifyEmailToken: async (emailToken: string) => await verifyEmailToken(client, state, updateState, emailToken),
        storeUnverifiedAccessToken: async (unverfiedAccessToken: string) =>
          await storeUnverifiedAccessToken(client, state, updateState, unverfiedAccessToken),
        signOut: async () => await signOut(state, updateState),
        useUploader,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

async function loadState(client: GraphQLClient): Promise<AuthStateType> {
  const storage = await loadAuthStorage()
  if (!storage.refreshToken) {
    return {
      loading: false,
      mutable: {
        storage,
      },
    }
  }

  try {
    const {
      refresh_token: { access_token },
    } = await client.request<AnonRefreshTokenMutation, AnonRefreshTokenMutationVariables>(AnonRefreshTokenDocument, {
      refreshToken: storage.refreshToken,
    })

    const { user, serverTimeOffsetMs } = await loadUser(client, access_token)

    return {
      loading: false,
      mutable: {
        accessToken: access_token,
        storage: storage,
        serverTimeOffsetMs,
      },
      user,
    }
  } catch (err: any) {
    // TODO(ibrt): Distinguish expired vs. network error.
    console.error(err)
    return {
      loading: false,
      mutable: {
        storage: storage,
      },
    }
  }
}

async function refresh(
  client: GraphQLClient,
  currentState: AuthStateType,
  updateState: (newState: AuthStateType) => Promise<void>,
): Promise<AuthStateType> {
  try {
    if (!currentState.mutable.storage.refreshToken) {
      return currentState
    }

    const {
      refresh_token: { access_token },
    } = await client.request<AnonRefreshTokenMutation, AnonRefreshTokenMutationVariables>(AnonRefreshTokenDocument, {
      refreshToken: currentState.mutable.storage.refreshToken,
    })

    const { user, serverTimeOffsetMs } = await loadUser(client, access_token)

    const newState = merge({}, currentState, {
      mutable: {
        accessToken: access_token,
        serverTimeOffsetMs,
      },
      user,
    })

    await updateState(newState)
    return newState
  } catch (err: any) {
    // TODO(ibrt): Distinguish expired vs. network error.
    console.error(err)
    return currentState
  }
}

async function verifyEmailToken(
  client: GraphQLClient,
  currentState: AuthStateType,
  updateState: (newState: AuthStateType) => Promise<void>,
  emailToken: string,
): Promise<EmailTokenVerification> {
  const {
    verify_email_token: {
      access_token,
      refresh_token,
      originating_flow_id,
      originating_flow_org_id,
      originating_org_invite_org_id,
    },
  } = await client.request<AnonVerifyEmailTokenMutation, AnonVerifyEmailTokenMutationVariables>(
    AnonVerifyEmailTokenDocument,
    { emailToken },
  )

  const { user, serverTimeOffsetMs } = await loadUser(client, access_token)

  const verification: EmailTokenVerification = {
    newState: {
      loading: false,
      mutable: {
        accessToken: access_token,
        storage: {
          refreshToken: refresh_token,
          unverifiedAccessTokens: currentState.mutable.storage.unverifiedAccessTokens,
        },
        serverTimeOffsetMs,
      },
      user,
    },
    originatingFlowId: originating_flow_id || undefined,
    originatingFlowOrgId: originating_flow_org_id || undefined,
    originatingOrgInviteOrgId: originating_org_invite_org_id || undefined,
  }

  await updateState(verification.newState)
  return verification
}

async function storeUnverifiedAccessToken(
  client: GraphQLClient,
  currentState: AuthStateType,
  updateState: (newState: AuthStateType) => Promise<void>,
  unverifiedAccessToken: string,
) {
  const hasuraClaims = parseAccessToken(unverifiedAccessToken)
  if (!hasuraClaims || !hasuraClaims.flowId) {
    throw new Error('Unexpectedly invalid unverifiedAccessToken.')
  }

  await updateState(
    merge({}, currentState, {
      mutable: {
        storage: {
          unverifiedAccessTokens: {
            [hasuraClaims.flowId]: unverifiedAccessToken,
          },
        },
      },
    }),
  )
}

async function loadUser(
  client: GraphQLClient,
  accessToken: string,
): Promise<{
  user: UserType
  serverTimeOffsetMs: number
}> {
  const startTime = new Date()

  const data = await client.request<UserUserAndUserOrganizationsQuery, UserUserAndUserOrganizationsQueryVariables>(
    UserUserAndUserOrganizationsDocument,
    {},
    {
      Authorization: `Bearer ${accessToken}`,
    },
  )

  const now = new Date()
  const delay = differenceInMilliseconds(now, startTime) / 2

  if (data.users.length !== 1) {
    throw new Error('Unexpected number of users.')
  }

  return {
    user: {
      ...data.users[0],
      organizations: data.users_organizations.map((userOrg) => ({ ...userOrg.organization, role: userOrg.role })),
    },
    serverTimeOffsetMs: serverTimeOffsetMs(now, data.server_now[0].server_time, delay),
  }
}

async function updateUserLastSeenAt(client: GraphQLClient, accessToken: string, agoMs: number): Promise<void> {
  try {
    await client.request<UserUpdateLastSeenAtMutation, UserUpdateLastSeenAtMutationVariables>(
      UserUpdateLastSeenAtDocument,
      {
        agoMs,
      },
      {
        Authorization: `Bearer ${accessToken}`,
      },
    )
  } catch (err: any) {
    processError(err)
  }
}

async function signOut(currentState: AuthStateType, updateState: (newState: AuthStateType) => Promise<void>) {
  await updateState({
    loading: false,
    mutable: {
      storage: {
        unverifiedAccessTokens: currentState.mutable.storage.unverifiedAccessTokens,
      },
    },
  })
}
