import React, { createContext, ReactNode, useMemo, useState } from 'react'
import { OrgDashboardQuery, useOrgDashboardQuery } from '@src/gen/graphql/bindings'
import { useRequiredContext } from '@src/logic/utils'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { sub } from 'date-fns'
import { useOrg } from '@src/logic/data/providers/OrgProvider'

const STALE_DURATION = {
  days: 3,
}

export type OrgDashboardContextType = OrgDashboardQuery
export const OrgDashboardContext = createContext<OrgDashboardContextType | undefined>(undefined)
export const useOrgDashboard = () => useRequiredContext(OrgDashboardContext)

export type OrgDashboardProviderProps = {
  children?: ReactNode
}

export function OrgDashboardProvider({ children }: OrgDashboardProviderProps) {
  const { user } = useAuthenticatedAuth()
  const { org } = useOrg()
  const flowStaleDate = useMemo(() => sub(new Date(), STALE_DURATION).toISOString(), [])

  const { data, error, loading, previousData } = useOrgDashboardQuery({
    variables: {
      flowStaleDate: flowStaleDate,
      orgId: org.id,
      userId: user.id,
    },
  })

  if (error) {
    throw error
  }

  if (loading && data === undefined && previousData === undefined) {
    return null
  }

  const finalData = data ?? previousData
  if (finalData === undefined) {
    throw new Error('No data.')
  }

  return <OrgDashboardContext.Provider value={finalData}>{children}</OrgDashboardContext.Provider>
}
