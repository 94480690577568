import { BottomTabScreenProps, createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import {
  CompositeScreenProps,
  DefaultTheme,
  NavigationContainer,
  RouteProp,
  TabActions,
  useLinkTo,
} from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import { MobileHeader } from '@src/components/atoms'
import {
  ExtFlowPart,
  ExtFlowsPart,
  ExtMobileHeader,
  ExtUserPart,
  NotFound,
  OrgConfigurationPart,
  OrgContactsPart,
  OrgDashboardPart,
  OrgFlowPart,
  OrgFlowsBoardPart,
  OrgFlowsListPart,
  OrgMobileHeader,
  OrgUserPart,
  Splash,
  UnvFlowPart,
} from '@src/components/parts'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { registerSentryReactNavigationInstrumentation } from '@src/logic/config'
import {
  ExtFlowNotificationsProvider,
  ExtFlowProvider,
  ExtFlowsProvider,
  OrgConfigurationProvider,
  OrgContactProvider,
  OrgContactsProvider,
  OrgDashboardProvider,
  OrgFlowNotificationsProvider,
  OrgFlowProvider,
  OrgFlowsFiltersProvider,
  OrgFlowsProvider,
  OrgProvider,
  UnvFlowProvider,
  useOrg,
  UserActionsProvider,
} from '@src/logic/data/providers'
import { Theme, useScreen } from '@src/logic/design'
import {
  mobileExtBackAction,
  mobileOrgBackAction,
  mobileScreenIdPageTitle,
  ROUTE_ID_TO_PATH,
  RouteIds,
  ScreenIds,
  useLinkAuthEmail,
  useLinkFlowInitiate,
  useLinkRoot,
  useMobileLinking,
} from '@src/logic/routing/common'
import { ExtRoutingProvider, OrgRoutingProvider, UnvRoutingProvider } from '@src/logic/routing/providers'
import { useRequiredDebouncedOnPress } from '@src/logic/utils'
import React, { Fragment, useCallback, useEffect, useMemo, useRef } from 'react'
import { Platform } from 'react-native'
import { generatePath } from 'react-router-dom'

type RootTabParams = {
  [ScreenIds.ROOT]: {}
  [ScreenIds.LINKS_FLOWS_INITIATE]: {
    email: string
    firstName: string
    lastName: string
    flowConfigurationId: string
  }
  [ScreenIds.LINKS_AUTH_EMAIL]: {
    emailToken: string
  }
  [ScreenIds.UNV_FLOW_ROOT]: {
    flowId: string
  }
  [ScreenIds.EXT_ROOT]: {}
  [ScreenIds.ORG_ROOT]: {
    orgId: string
  }
  [ScreenIds.NOT_FOUND]: {}
}

type UnvFlowRootTabParams = {
  [ScreenIds.UNV_FLOW_SUMMARY]: {}
  [ScreenIds.UNV_FLOW_CHAT]: {}
}

type ExtRootStackParams = {
  [ScreenIds.EXT_SUB_ROOT]: {}
  [ScreenIds.EXT_FLOW_ROOT]: {
    flowId: string
  }
}

type ExtSubRootTabParams = {
  [ScreenIds.EXT_FLOWS]: {}
  [ScreenIds.EXT_USER_PROFILE]: {}
  [ScreenIds.EXT_USER_NOTIFICATIONS]: {}
}

type ExtFlowRootTabParams = {
  [ScreenIds.EXT_FLOW_SUMMARY]: {}
  [ScreenIds.EXT_FLOW_CHAT]: {}
  [ScreenIds.EXT_FLOW_ACTION_ITEMS]: {}
}

type OrgRootStackParams = {
  [ScreenIds.ORG_SUB_ROOT]: {}
  [ScreenIds.ORG_FLOW_ROOT]: {
    flowId: string
  }
  // Note: used only if isTablet = false.
  [ScreenIds.ORG_CONTACT]: {
    contactId: string
  }
}

type OrgSubRootTabParams = {
  [ScreenIds.ORG_DASHBOARD_SUMMARY]: {}
  [ScreenIds.ORG_DASHBOARD_FLOWS]: {}
  [ScreenIds.ORG_FLOWS_ROOT]: {}
  [ScreenIds.ORG_CONTACTS]: {}
  // Note: used only if isTablet = true
  [ScreenIds.ORG_CONTACT]: {
    contactId: string
  }
  [ScreenIds.ORG_CONFIGURATION_PROFILE]: {}
  [ScreenIds.ORG_CONFIGURATION_SETTINGS]: {}
  [ScreenIds.ORG_USER_PROFILE]: {}
  [ScreenIds.ORG_USER_NOTIFICATIONS]: {}
}

type OrgFlowsRootTabParams = {
  [ScreenIds.ORG_FLOWS_LIST]: {}
  [ScreenIds.ORG_FLOWS_BOARD]: {}
}

type OrgFlowRootTabParams = {
  [ScreenIds.ORG_FLOW_SUMMARY]: {}
  [ScreenIds.ORG_FLOW_CHAT]: {}
  [ScreenIds.ORG_FLOW_ACTION_ITEMS]: {}
}

const TAB_NAVIGATOR_DEFAULT_SCREEN_OPTIONS = { headerShown: false, unmountOnBlur: true }
const TAB_NAVIGATOR_NO_TAB_BAR = () => null

const RootTabNavigator = createBottomTabNavigator<RootTabParams>()
const UnvFlowRootTabNavigator = createBottomTabNavigator<UnvFlowRootTabParams>()
const ExtRootNativeStackNavigator = createNativeStackNavigator<ExtRootStackParams>()
const ExtSubRootTabNavigator = createBottomTabNavigator<ExtSubRootTabParams>()
const ExtFlowRootTabNavigator = createBottomTabNavigator<ExtFlowRootTabParams>()
const OrgRootNativeStackNavigator = createNativeStackNavigator<OrgRootStackParams>()
const OrgSubRootTabNavigator = createBottomTabNavigator<OrgSubRootTabParams>()
const OrgFlowsRootTabNavigator = createBottomTabNavigator<OrgFlowsRootTabParams>()
const OrgFlowRootTabNavigator = createBottomTabNavigator<OrgFlowRootTabParams>()

const defaultOptions = ({ route }: { route: RouteProp<any> }) => ({
  title: mobileScreenIdPageTitle(route.name as ScreenIds),
})

export function MobileRouter() {
  const mobileLinking = useMobileLinking()
  const navigationRef = useRef(null)
  const handleReady = useCallback(() => registerSentryReactNavigationInstrumentation(navigationRef), [navigationRef])

  const theme = useMemo(
    () => ({ ...DefaultTheme, colors: { ...DefaultTheme.colors, background: Theme.colors.dark.backgroundColor } }),
    [],
  )

  return (
    <NavigationContainer theme={theme} linking={mobileLinking} onReady={handleReady} ref={navigationRef}>
      <RootTabNavigator.Navigator
        screenOptions={TAB_NAVIGATOR_DEFAULT_SCREEN_OPTIONS}
        tabBar={TAB_NAVIGATOR_NO_TAB_BAR}>
        <RootTabNavigator.Screen name={ScreenIds.ROOT} component={RootScreen} options={defaultOptions} />
        <RootTabNavigator.Screen
          name={ScreenIds.LINKS_FLOWS_INITIATE}
          component={LinksFlowsInitiateScreen}
          options={defaultOptions}
        />
        <RootTabNavigator.Screen
          name={ScreenIds.LINKS_AUTH_EMAIL}
          component={LinksAuthEmailScreen}
          options={defaultOptions}
        />
        <RootTabNavigator.Screen
          name={ScreenIds.UNV_FLOW_ROOT}
          component={UnvFlowRootScreen}
          options={defaultOptions}
        />
        <RootTabNavigator.Screen name={ScreenIds.EXT_ROOT} component={ExtRootScreen} options={defaultOptions} />
        <RootTabNavigator.Screen name={ScreenIds.ORG_ROOT} component={OrgRootScreen} options={defaultOptions} />
        <RootTabNavigator.Screen name={ScreenIds.NOT_FOUND} component={NotFound} options={defaultOptions} />
      </RootTabNavigator.Navigator>
    </NavigationContainer>
  )
}

type RootScreenProps = BottomTabScreenProps<RootTabParams, ScreenIds.ROOT>

function RootScreen({ navigation: { navigate } }: RootScreenProps) {
  useAuthenticatedAuth()

  useLinkRoot(
    (orgId) => navigate(ScreenIds.ORG_ROOT, { orgId }),
    () => navigate(ScreenIds.EXT_ROOT, {}),
  )
  return <Splash />
}

type LinksFlowsInitiateScreenProps = BottomTabScreenProps<RootTabParams, ScreenIds.LINKS_FLOWS_INITIATE>

function LinksFlowsInitiateScreen({ navigation: { navigate }, route: { params } }: LinksFlowsInitiateScreenProps) {
  useLinkFlowInitiate(params.flowConfigurationId, params.email, params.firstName, params.lastName, (flowId) =>
    navigate(ScreenIds.UNV_FLOW_ROOT, { flowId }),
  )

  return <Splash />
}

type LinksAuthEmailScreenProps = BottomTabScreenProps<RootTabParams, ScreenIds.LINKS_AUTH_EMAIL>

function LinksAuthEmailScreen({ navigation: { navigate }, route: { params } }: LinksAuthEmailScreenProps) {
  const linkTo = useLinkTo()

  useLinkAuthEmail(
    params.emailToken,
    (orgId) => navigate(ScreenIds.ORG_ROOT, { orgId }),
    () => navigate(ScreenIds.EXT_ROOT, {}),
    (flowId) => linkTo(generatePath(ROUTE_ID_TO_PATH[RouteIds.EXT_FLOW_SUMMARY], { flowId })),
    (orgId, flowId) => linkTo(generatePath(ROUTE_ID_TO_PATH[RouteIds.ORG_FLOW_SUMMARY], { orgId, flowId })),
  )
  return <Splash />
}

type UnvFlowRootScreenProps = BottomTabScreenProps<RootTabParams, ScreenIds.UNV_FLOW_ROOT>

function UnvFlowRootScreen({ route }: UnvFlowRootScreenProps) {
  return (
    <UnvFlowProvider flowId={route.params.flowId}>
      <UnvRoutingProvider.Mobile>
        <UnvFlowRootTabNavigator.Navigator
          screenOptions={TAB_NAVIGATOR_DEFAULT_SCREEN_OPTIONS}
          tabBar={TAB_NAVIGATOR_NO_TAB_BAR}>
          <UnvFlowRootTabNavigator.Screen
            name={ScreenIds.UNV_FLOW_SUMMARY}
            component={UnvFlowSummaryScreen}
            options={defaultOptions}
          />
          <UnvFlowRootTabNavigator.Screen
            name={ScreenIds.UNV_FLOW_CHAT}
            component={UnvFlowChatScreen}
            options={defaultOptions}
          />
        </UnvFlowRootTabNavigator.Navigator>
      </UnvRoutingProvider.Mobile>
    </UnvFlowProvider>
  )
}

function UnvFlowSummaryScreen({ navigation: { dispatch } }: any) {
  const { isTablet } = useScreen()

  useEffect(() => {
    if (isTablet) {
      // TODO(ibrt): Figure out why this needs an extra tick in order to not be dropped.
      setTimeout(() => dispatch(TabActions.jumpTo(ScreenIds.UNV_FLOW_CHAT)), 0)
    }
  })

  return isTablet ? null : <UnvFlowPart view='summary' />
}

function UnvFlowChatScreen() {
  return <UnvFlowPart view='chat' />
}

type ExtRootScreenProps = BottomTabScreenProps<RootTabParams, ScreenIds.EXT_ROOT>

function ExtRootScreen({}: ExtRootScreenProps) {
  return (
    <ExtRoutingProvider.Mobile>
      <ExtRootNativeStackNavigator.Navigator
        screenOptions={{
          animation: Platform.OS === 'ios' ? 'slide_from_right' : 'default',
          headerShown: false,
        }}>
        <ExtRootNativeStackNavigator.Screen
          name={ScreenIds.EXT_SUB_ROOT}
          component={ExtSubRootScreen}
          options={defaultOptions}
        />
        <ExtRootNativeStackNavigator.Screen
          name={ScreenIds.EXT_FLOW_ROOT}
          component={ExtFlowRootScreen}
          options={defaultOptions}
        />
      </ExtRootNativeStackNavigator.Navigator>
    </ExtRoutingProvider.Mobile>
  )
}

type ExtSubRootScreenProps = CompositeScreenProps<
  NativeStackScreenProps<ExtRootStackParams, ScreenIds.EXT_SUB_ROOT>,
  BottomTabScreenProps<RootTabParams>
>

function ExtSubRootScreen({}: ExtSubRootScreenProps) {
  return (
    <>
      <ExtMobileHeader />
      <ExtSubRootTabNavigator.Navigator
        screenOptions={TAB_NAVIGATOR_DEFAULT_SCREEN_OPTIONS}
        tabBar={TAB_NAVIGATOR_NO_TAB_BAR}>
        <ExtSubRootTabNavigator.Screen name={ScreenIds.EXT_FLOWS} component={ExtFlowsScreen} options={defaultOptions} />
        <ExtSubRootTabNavigator.Screen
          name={ScreenIds.EXT_USER_PROFILE}
          component={ExtUserProfileScreen}
          options={defaultOptions}
        />
        <ExtSubRootTabNavigator.Screen
          name={ScreenIds.EXT_USER_NOTIFICATIONS}
          component={ExtUserNotificationsScreen}
          options={defaultOptions}
        />
      </ExtSubRootTabNavigator.Navigator>
    </>
  )
}

type ExtFlowsScreenProps = CompositeScreenProps<
  BottomTabScreenProps<ExtSubRootTabParams, ScreenIds.EXT_FLOWS>,
  CompositeScreenProps<NativeStackScreenProps<ExtRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function ExtFlowsScreen({}: ExtFlowsScreenProps) {
  return (
    <>
      <ExtFlowsProvider>
        <ExtFlowsPart />
      </ExtFlowsProvider>
    </>
  )
}

type ExtUserProfileScreenProps = CompositeScreenProps<
  BottomTabScreenProps<ExtSubRootTabParams, ScreenIds.EXT_USER_PROFILE>,
  CompositeScreenProps<NativeStackScreenProps<ExtRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function ExtUserProfileScreen({ navigation: { dispatch } }: ExtUserProfileScreenProps) {
  const { isTablet } = useScreen()

  useEffect(() => {
    if (isTablet) {
      // TODO(ibrt): Figure out why this needs an extra tick in order to not be dropped.
      setTimeout(() => dispatch(TabActions.jumpTo(ScreenIds.EXT_USER_NOTIFICATIONS)), 0)
    }
  })

  if (isTablet) {
    return null
  }

  return (
    <UserActionsProvider>
      <ExtUserPart view='profile' />
    </UserActionsProvider>
  )
}

type ExtUserNotificationsScreenProps = CompositeScreenProps<
  BottomTabScreenProps<ExtSubRootTabParams, ScreenIds.EXT_USER_NOTIFICATIONS>,
  CompositeScreenProps<NativeStackScreenProps<ExtRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function ExtUserNotificationsScreen({}: ExtUserNotificationsScreenProps) {
  return (
    <ExtFlowNotificationsProvider>
      <UserActionsProvider>
        <ExtUserPart view='notifications' />
      </UserActionsProvider>
    </ExtFlowNotificationsProvider>
  )
}

type ExtFlowRootScreenProps = CompositeScreenProps<
  NativeStackScreenProps<ExtRootStackParams, ScreenIds.EXT_FLOW_ROOT>,
  BottomTabScreenProps<RootTabParams>
>

function ExtFlowRootScreen({ navigation: { dispatch, getState }, route }: ExtFlowRootScreenProps) {
  const state = getState()
  const onBack = useCallback(() => dispatch(mobileExtBackAction(state)), [state])
  const debouncedOnBack = useRequiredDebouncedOnPress(onBack)

  return (
    <>
      <MobileHeader onPress={debouncedOnBack} title='Flow' variant='side' />
      <ExtFlowProvider flowId={route.params.flowId}>
        <ExtFlowRootTabNavigator.Navigator
          screenOptions={TAB_NAVIGATOR_DEFAULT_SCREEN_OPTIONS}
          tabBar={TAB_NAVIGATOR_NO_TAB_BAR}>
          <ExtFlowRootTabNavigator.Screen
            name={ScreenIds.EXT_FLOW_SUMMARY}
            component={ExtFlowSummaryScreen}
            options={defaultOptions}
          />
          <ExtFlowRootTabNavigator.Screen
            name={ScreenIds.EXT_FLOW_CHAT}
            component={ExtFlowChatScreen}
            options={defaultOptions}
          />
          <ExtFlowRootTabNavigator.Screen
            name={ScreenIds.EXT_FLOW_ACTION_ITEMS}
            component={ExtFlowActionItemsScreen}
            options={defaultOptions}
          />
        </ExtFlowRootTabNavigator.Navigator>
      </ExtFlowProvider>
    </>
  )
}

function ExtFlowSummaryScreen({ navigation: { dispatch } }: any) {
  const { isTablet } = useScreen()

  useEffect(() => {
    if (isTablet) {
      // TODO(ibrt): Figure out why this needs an extra tick in order to not be dropped.
      setTimeout(() => dispatch(TabActions.jumpTo(ScreenIds.EXT_FLOW_CHAT)), 0)
    }
  })

  return isTablet ? null : <ExtFlowPart view='summary' />
}

function ExtFlowChatScreen() {
  return <ExtFlowPart view='chat' />
}

function ExtFlowActionItemsScreen() {
  return <ExtFlowPart view='actionItems' />
}

type OrgRootScreenProps = BottomTabScreenProps<RootTabParams, ScreenIds.ORG_ROOT>

function OrgRootScreen({ route }: OrgRootScreenProps) {
  const { isTablet } = useScreen()

  return (
    <OrgProvider orgId={route.params.orgId}>
      <OrgRoutingProvider.Mobile>
        <OrgRootNativeStackNavigator.Navigator
          screenOptions={{
            animation: Platform.OS === 'ios' ? 'slide_from_right' : 'default',
            headerShown: false,
          }}>
          <OrgRootNativeStackNavigator.Screen
            name={ScreenIds.ORG_SUB_ROOT}
            component={OrgSubRootScreen}
            options={defaultOptions}
          />
          <OrgRootNativeStackNavigator.Screen
            name={ScreenIds.ORG_FLOW_ROOT}
            component={OrgFlowRootScreen}
            options={defaultOptions}
          />
          {!isTablet && (
            <OrgRootNativeStackNavigator.Screen
              name={ScreenIds.ORG_CONTACT}
              component={OrgContactScreenPhone}
              options={defaultOptions}
            />
          )}
        </OrgRootNativeStackNavigator.Navigator>
      </OrgRoutingProvider.Mobile>
    </OrgProvider>
  )
}

type OrgSubRootScreenProps = CompositeScreenProps<
  NativeStackScreenProps<OrgRootStackParams, ScreenIds.ORG_SUB_ROOT>,
  BottomTabScreenProps<RootTabParams>
>

function OrgSubRootScreen({}: OrgSubRootScreenProps) {
  const { isTablet } = useScreen()

  return (
    <Fragment>
      <OrgMobileHeader />
      <OrgSubRootTabNavigator.Navigator
        screenOptions={TAB_NAVIGATOR_DEFAULT_SCREEN_OPTIONS}
        tabBar={TAB_NAVIGATOR_NO_TAB_BAR}>
        <OrgSubRootTabNavigator.Screen
          name={ScreenIds.ORG_DASHBOARD_SUMMARY}
          component={OrgDashboardSummaryScreen}
          options={defaultOptions}
        />
        <OrgSubRootTabNavigator.Screen
          name={ScreenIds.ORG_DASHBOARD_FLOWS}
          component={OrgDashboardFlowsScreen}
          options={defaultOptions}
        />
        <OrgSubRootTabNavigator.Screen
          name={ScreenIds.ORG_FLOWS_ROOT}
          component={OrgFlowsRootScreen}
          options={defaultOptions}
        />
        <OrgSubRootTabNavigator.Screen
          name={ScreenIds.ORG_CONTACTS}
          component={OrgContactsScreen}
          options={defaultOptions}
        />
        {isTablet && (
          <OrgSubRootTabNavigator.Screen
            name={ScreenIds.ORG_CONTACT}
            component={OrgContactScreenTablet}
            options={defaultOptions}
          />
        )}
        <OrgSubRootTabNavigator.Screen
          name={ScreenIds.ORG_CONFIGURATION_PROFILE}
          component={OrgConfigurationProfileScreen}
          options={defaultOptions}
        />
        <OrgSubRootTabNavigator.Screen
          name={ScreenIds.ORG_CONFIGURATION_SETTINGS}
          component={OrgConfigurationSettingsScreen}
          options={defaultOptions}
        />
        <OrgSubRootTabNavigator.Screen
          name={ScreenIds.ORG_USER_PROFILE}
          component={OrgUserProfileScreen}
          options={defaultOptions}
        />
        <OrgSubRootTabNavigator.Screen
          name={ScreenIds.ORG_USER_NOTIFICATIONS}
          component={OrgUserNotificationsScreen}
          options={defaultOptions}
        />
      </OrgSubRootTabNavigator.Navigator>
    </Fragment>
  )
}

type OrgDashboardSummaryScreenProps = CompositeScreenProps<
  BottomTabScreenProps<OrgSubRootTabParams, ScreenIds.ORG_DASHBOARD_SUMMARY>,
  CompositeScreenProps<NativeStackScreenProps<OrgRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function OrgDashboardSummaryScreen({}: OrgDashboardSummaryScreenProps) {
  return (
    <OrgDashboardProvider>
      <OrgDashboardPart view='summary' />
    </OrgDashboardProvider>
  )
}

type OrgDashboardFlowsScreenProps = CompositeScreenProps<
  BottomTabScreenProps<OrgSubRootTabParams, ScreenIds.ORG_DASHBOARD_FLOWS>,
  CompositeScreenProps<NativeStackScreenProps<OrgRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function OrgDashboardFlowsScreen({}: OrgDashboardFlowsScreenProps) {
  return (
    <OrgDashboardProvider>
      <OrgDashboardPart view='flows' />
    </OrgDashboardProvider>
  )
}

type OrgFlowsRootScreenProps = CompositeScreenProps<
  BottomTabScreenProps<OrgSubRootTabParams, ScreenIds.ORG_FLOWS_ROOT>,
  CompositeScreenProps<NativeStackScreenProps<OrgRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function OrgFlowsRootScreen({}: OrgFlowsRootScreenProps) {
  return (
    <OrgFlowsFiltersProvider>
      <OrgFlowsProvider>
        <OrgFlowsRootTabNavigator.Navigator
          screenOptions={TAB_NAVIGATOR_DEFAULT_SCREEN_OPTIONS}
          tabBar={TAB_NAVIGATOR_NO_TAB_BAR}>
          <OrgFlowsRootTabNavigator.Screen
            name={ScreenIds.ORG_FLOWS_LIST}
            component={OrgFlowsListPart}
            options={defaultOptions}
          />
          <OrgFlowsRootTabNavigator.Screen
            name={ScreenIds.ORG_FLOWS_BOARD}
            component={OrgFlowsBoardPart}
            options={defaultOptions}
          />
        </OrgFlowsRootTabNavigator.Navigator>
      </OrgFlowsProvider>
    </OrgFlowsFiltersProvider>
  )
}

type OrgContactsScreenProps = CompositeScreenProps<
  BottomTabScreenProps<OrgSubRootTabParams, ScreenIds.ORG_CONTACTS>,
  CompositeScreenProps<NativeStackScreenProps<OrgRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function OrgContactsScreen({}: OrgContactsScreenProps) {
  return (
    <OrgContactsProvider>
      <OrgContactsPart view='list' />
    </OrgContactsProvider>
  )
}

type OrgContactsScreenTabletProps = CompositeScreenProps<
  BottomTabScreenProps<OrgSubRootTabParams, ScreenIds.ORG_CONTACT>,
  CompositeScreenProps<NativeStackScreenProps<OrgRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function OrgContactScreenTablet({ route }: OrgContactsScreenTabletProps) {
  return (
    <OrgContactsProvider>
      <OrgContactProvider contactId={route.params.contactId}>
        <OrgContactsPart contactId={route.params.contactId} view='detail' />
      </OrgContactProvider>
    </OrgContactsProvider>
  )
}

type OrgConfigurationProfileScreenProps = CompositeScreenProps<
  BottomTabScreenProps<OrgSubRootTabParams, ScreenIds.ORG_CONFIGURATION_PROFILE>,
  CompositeScreenProps<NativeStackScreenProps<OrgRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function OrgConfigurationProfileScreen({}: OrgConfigurationProfileScreenProps) {
  return (
    <OrgConfigurationProvider>
      <OrgConfigurationPart view='profile' />
    </OrgConfigurationProvider>
  )
}

type OrgConfigurationSettingsScreenProps = CompositeScreenProps<
  BottomTabScreenProps<OrgSubRootTabParams, ScreenIds.ORG_CONFIGURATION_SETTINGS>,
  CompositeScreenProps<NativeStackScreenProps<OrgRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function OrgConfigurationSettingsScreen({}: OrgConfigurationSettingsScreenProps) {
  return (
    <OrgConfigurationProvider>
      <OrgConfigurationPart view='settings' />
    </OrgConfigurationProvider>
  )
}

type OrgUserProfileScreenProps = CompositeScreenProps<
  BottomTabScreenProps<OrgSubRootTabParams, ScreenIds.ORG_USER_PROFILE>,
  CompositeScreenProps<NativeStackScreenProps<OrgRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function OrgUserProfileScreen({ navigation: { dispatch } }: OrgUserProfileScreenProps) {
  const { isTablet } = useScreen()

  useEffect(() => {
    if (isTablet) {
      // TODO(ibrt): Figure out why this needs an extra tick in order to not be dropped.
      setTimeout(() => dispatch(TabActions.jumpTo(ScreenIds.ORG_USER_NOTIFICATIONS)), 0)
    }
  })

  if (isTablet) {
    return null
  }

  return (
    <UserActionsProvider>
      <OrgUserPart view='profile' />
    </UserActionsProvider>
  )
}

type OrgUserNotificationsScreenProps = CompositeScreenProps<
  BottomTabScreenProps<OrgSubRootTabParams, ScreenIds.ORG_USER_NOTIFICATIONS>,
  CompositeScreenProps<NativeStackScreenProps<OrgRootStackParams>, BottomTabScreenProps<RootTabParams>>
>

function OrgUserNotificationsScreen({}: OrgUserNotificationsScreenProps) {
  return (
    <OrgFlowNotificationsProvider>
      <UserActionsProvider>
        <OrgUserPart view='notifications' />
      </UserActionsProvider>
    </OrgFlowNotificationsProvider>
  )
}

type OrgFlowRootScreenProps = CompositeScreenProps<
  NativeStackScreenProps<OrgRootStackParams, ScreenIds.ORG_FLOW_ROOT>,
  BottomTabScreenProps<RootTabParams>
>

function OrgFlowRootScreen({ navigation: { dispatch, getState }, route: { params } }: OrgFlowRootScreenProps) {
  const { org } = useOrg()
  const state = getState()
  const onBack = useCallback(() => dispatch(mobileOrgBackAction(state, org.id)), [state, org.id])
  const debouncedOnBack = useRequiredDebouncedOnPress(onBack)

  return (
    <>
      <MobileHeader onPress={debouncedOnBack} title='Flow' variant='side' />
      <OrgFlowProvider flowId={params.flowId}>
        <OrgFlowRootTabNavigator.Navigator
          screenOptions={TAB_NAVIGATOR_DEFAULT_SCREEN_OPTIONS}
          tabBar={TAB_NAVIGATOR_NO_TAB_BAR}>
          <OrgFlowRootTabNavigator.Screen
            name={ScreenIds.ORG_FLOW_SUMMARY}
            component={OrgFlowSummaryScreen}
            options={defaultOptions}
          />
          <OrgFlowRootTabNavigator.Screen
            name={ScreenIds.ORG_FLOW_CHAT}
            component={OrgFlowChatScreen}
            options={defaultOptions}
          />
          <OrgFlowRootTabNavigator.Screen
            name={ScreenIds.ORG_FLOW_ACTION_ITEMS}
            component={OrgFlowActionItemsScreen}
            options={defaultOptions}
          />
        </OrgFlowRootTabNavigator.Navigator>
      </OrgFlowProvider>
    </>
  )
}

function OrgFlowSummaryScreen({ navigation: { dispatch } }: any) {
  const { isTablet } = useScreen()

  useEffect(() => {
    if (isTablet) {
      // TODO(ibrt): Figure out why this needs an extra tick in order to not be dropped.
      setTimeout(() => dispatch(TabActions.jumpTo(ScreenIds.ORG_FLOW_CHAT)), 0)
    }
  })

  return isTablet ? null : <OrgFlowPart view='summary' />
}

function OrgFlowChatScreen() {
  return <OrgFlowPart view='chat' />
}

function OrgFlowActionItemsScreen() {
  return <OrgFlowPart view='actionItems' />
}

type OrgContactScreenProps = CompositeScreenProps<
  NativeStackScreenProps<OrgRootStackParams, ScreenIds.ORG_CONTACT>,
  BottomTabScreenProps<RootTabParams>
>

function OrgContactScreenPhone({ route, navigation: { dispatch, getState } }: OrgContactScreenProps) {
  const { org } = useOrg()
  const state = getState()
  const onBack = useCallback(() => dispatch(mobileOrgBackAction(state, org.id)), [state, org.id])
  const debouncedOnBack = useRequiredDebouncedOnPress(onBack)

  return (
    <>
      <MobileHeader onPress={debouncedOnBack} title='Contact' variant='side' />
      <OrgContactProvider contactId={route.params.contactId}>
        <OrgContactsPart contactId={route.params.contactId} view='detail' />
      </OrgContactProvider>
    </>
  )
}
