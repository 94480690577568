import {
  Card,
  ScreenContent,
  ScreenPrimary,
  screenPrimaryIdKeyExtractor,
  ScreenPrimaryItemSeparator,
  screenPrimaryRenderSectionHeader,
  ScreenPrimarySectionSeparator,
  ScreenPrimaryStyles,
  ScreenSecondary,
  Tabs,
  Text,
} from '@src/components/atoms'
import {
  ActionItemConfigurationCard,
  FlowConfigurationCard,
  FlowFieldConfigurationCard,
  FlowTypeCard,
  PaneOrganizationProfile,
} from '@src/components/molecules'
import { Organization_Roles_Enum } from '@src/gen/graphql/bindings'
import { makeSections } from '@src/logic/data/manipulation'
import { useOrg, useOrgConfiguration } from '@src/logic/data/providers'
import { CommonStyles, useScreen } from '@src/logic/design'
import { useOrgRouting } from '@src/logic/routing/providers'
import { ifDefThen, ifTrue } from '@src/logic/utils'
import React, { Fragment, useCallback, useMemo } from 'react'
import { SectionList, View } from 'react-native'

export type OrgConfigurationPartProps = {
  view: 'profile' | 'settings'
}

export function OrgConfigurationPart({ view }: OrgConfigurationPartProps) {
  const { isPhone } = useScreen()
  const { useConfigurationTabs } = useOrgRouting()
  const configurationTabs = useConfigurationTabs()

  return (
    <Fragment>
      {isPhone && (
        <Tabs
          active={configurationTabs.activeTab?.id}
          onChange={configurationTabs.navigateTo}
          tabs={configurationTabs.tabs}
        />
      )}
      <ScreenContent>
        {(!isPhone || view === 'profile') && <OrgConfigurationProfilePart />}
        {(!isPhone || view === 'settings') && <OrgConfigurationSettingsPart />}
      </ScreenContent>
    </Fragment>
  )
}

function OrgConfigurationProfilePart() {
  const { org, orgConfig } = useOrg()
  const { dispatchEditMemberAction } = useOrgConfiguration()
  return (
    <ScreenSecondary isScrollable={false}>
      <PaneOrganizationProfile
        label='Profile'
        org={org}
        orgConfig={orgConfig}
        onEditMember={ifTrue(org.role === Organization_Roles_Enum.Admin, (member, type) =>
          ifDefThen(dispatchEditMemberAction, (v) => {
            return v({ user: member.user, org, role: member?.role, type })
          }),
        )}
      />
    </ScreenSecondary>
  )
}

function OrgConfigurationSettingsPart() {
  const { orgConfig } = useOrg()
  const {
    dispatchFlowRoleTypeEditAction,
    dispatchActionItemConfigurationEditAction,
    dispatchFlowConfigurationEditAction,
    dispatchFlowFieldConfigurationEditAction,
  } = useOrgConfiguration()

  const sections = useMemo(
    () =>
      makeSections([
        {
          title: 'Action Item Templates',
          type: 'actionItemConfiguration',
          centeredVariant: 'medium',
          data: orgConfig.action_item_configurations,
        },
        {
          title: 'Flow Types',
          type: 'flowType',
          centeredVariant: 'medium',
          data: orgConfig.flow_types,
        },
        {
          title: 'Flow Roles',
          type: 'flowRoleType',
          centeredVariant: 'medium',
          data: orgConfig.flow_role_types,
        },
        {
          title: 'Flow Field Templates',
          type: 'flowFieldConfiguration',
          centeredVariant: 'medium',
          data: orgConfig.flow_field_configurations,
        },
        {
          title: 'Flow Templates',
          type: 'flowConfiguration',
          centeredVariant: 'medium',
          data: orgConfig.flow_configurations,
        },
      ]),
    [orgConfig],
  )

  const renderItem = useCallback(({ item, section }) => {
    switch (section.type) {
      case 'actionItemConfiguration':
        return (
          <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
            <ActionItemConfigurationCard
              onEdit={ifDefThen(
                dispatchActionItemConfigurationEditAction,
                (v) => () => v({ actionItemConfiguration: item }),
              )}
              actionItemConfiguration={item}
            />
          </View>
        )
      case 'flowType':
        return (
          <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
            <FlowTypeCard flowType={item} />
          </View>
        )
      case 'flowRoleType':
        return (
          <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
            <Card
              colorVariant='lightDim'
              onPress={ifDefThen(dispatchFlowRoleTypeEditAction, (v) => () => v({ flowRoleType: item }))}>
              <Text colorVariant='light' textVariant='title' style={CommonStyles.marginBottomCard}>
                {item.name}
              </Text>
            </Card>
          </View>
        )
      case 'flowFieldConfiguration':
        return (
          <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
            <FlowFieldConfigurationCard
              onPress={ifDefThen(
                dispatchFlowFieldConfigurationEditAction,
                (v) => () => v({ flowFieldConfiguration: item }),
              )}
              flowFieldConfiguration={item}
            />
          </View>
        )
      case 'flowConfiguration':
        return (
          <View style={ScreenPrimaryStyles.containerViewCenteredMedium}>
            <FlowConfigurationCard
              onEdit={ifDefThen(dispatchFlowConfigurationEditAction, (v) => () => v({ flowConfiguration: item }))}
              flowConfiguration={item}
            />
          </View>
        )
      default:
        throw new Error('Unknown type.')
    }
  }, [])

  return (
    <ScreenPrimary>
      <SectionList
        ItemSeparatorComponent={ScreenPrimaryItemSeparator}
        SectionSeparatorComponent={ScreenPrimarySectionSeparator}
        keyExtractor={screenPrimaryIdKeyExtractor}
        renderItem={renderItem}
        renderSectionHeader={screenPrimaryRenderSectionHeader}
        sections={sections}
        stickySectionHeadersEnabled={false}
        style={CommonStyles.flexOne}
      />
    </ScreenPrimary>
  )
}
