import { CommonStyles, dim, Theme } from '@src/logic/design'
import { getMouseHandlers, ifDef, ifTrue } from '@src/logic/utils'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { Pressable, ViewStyle } from 'react-native'
import { SvgProps } from 'react-native-svg'

export type IconButtonProps = {
  colorVariant: 'light' | 'dark'
  Icon: (props: SvgProps) => JSX.Element
  iconRotation?: number
  isDisabled?: boolean
  onPress: () => void
  style?: ViewStyle
}

export const IconButton = memo(IconButtonComponent)

function IconButtonComponent({
  colorVariant,
  Icon,
  iconRotation,
  isDisabled = false,
  onPress,
  style,
}: IconButtonProps) {
  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  const pressableStyle = useMemo(
    () => [CommonStyles.flexCenter, ifTrue(isDisabled, CommonStyles.cursorNotAllowed), style],
    [isDisabled, style],
  )

  const iconStyle = useMemo(
    () => [
      ifDef(iconRotation, {
        transform: [{ rotate: `${iconRotation}deg` }],
      }),
    ],
    [iconRotation],
  )

  const color = useMemo(
    () =>
      isDisabled
        ? Theme.colors[colorVariant].borderColor
        : isHover || isPressed
        ? Theme.colors[colorVariant].color
        : Theme.colors[dim(colorVariant)].color,
    [colorVariant, isDisabled, isHover, isPressed],
  )

  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handlePressIn = useCallback(() => setIsPressed(true), [setIsPressed])
  const handlePressOut = useCallback(() => setIsPressed(false), [setIsPressed])

  return (
    <Pressable
      {...handleMouse}
      disabled={isDisabled}
      hitSlop={16}
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      style={pressableStyle}>
      <Icon color={color} height={24} style={iconStyle} width={24} />
    </Pressable>
  )
}
