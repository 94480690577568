import { BackgroundStyles, BorderStyles, ForegroundStyles, Theme } from '@src/logic/design'
import React, { memo, useMemo } from 'react'
import { StyleSheet, Text, View, ViewStyle } from 'react-native'

export type BadgeProps = {
  colorVariant: 'lightDim' | 'primary' | 'success' | 'warning' | 'danger'
  displayVariant: 'outline' | 'solid'
  label: string
  style?: ViewStyle
}

export const Badge = memo(BadgeComponent)

const Styles = StyleSheet.create({
  view: {
    borderRadius: Theme.geometry.pill.radius,
    height: Theme.geometry.pill.height,
    paddingLeft: Theme.geometry.pill.spacing,
    paddingRight: Theme.geometry.pill.spacing,
  },
  textOutline: {
    ...Theme.text.tag,
    lineHeight: Theme.geometry.pill.height - 2,
  },
  textSolid: {
    ...Theme.text.tag,
    lineHeight: Theme.geometry.pill.height,
  },
})

function BadgeComponent({ colorVariant, displayVariant, label, style }: BadgeProps) {
  const viewStyle = useMemo(
    () => [
      Styles.view,
      displayVariant === 'outline'
        ? [BackgroundStyles.light, BorderStyles[colorVariant]]
        : BackgroundStyles[colorVariant],
      style,
    ],
    [colorVariant, displayVariant, style],
  )

  const textStyle = useMemo(
    () => [displayVariant === 'outline' ? Styles.textOutline : Styles.textSolid, ForegroundStyles[colorVariant]],
    [colorVariant],
  )

  return (
    <View style={viewStyle}>
      <Text style={textStyle}>{label}</Text>
    </View>
  )
}
