import {
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectControl,
  SubmitButton,
  TextInputControl,
} from '@src/components/atoms'
import {
  Flow_Outcomes_Enum,
  Flow_Statuses_Enum,
  Flow_Type_Statuses,
  Flow_Types,
  Flows,
  Maybe,
  OrgConfigQuery,
} from '@src/gen/graphql'
import {
  findOption,
  FlowStatusTypeOptionType,
  FlowTypeStatusOptionType,
  getFlowStatusTypePreOptionsOrGroups,
  getFlowTypeStatusPreOptionsOrGroups,
  initSelect,
  SelectOptionType,
} from '@src/logic/data/manipulation'
import { useIsFirstRender, usePrevious } from '@src/logic/utils'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useField } from 'react-final-form'

export const FLOW_STATUS_EDIT_ACTION_ID = 'flowStatusEdit'
export const FLOW_STATUS_EDIT_ACTION_LABEL = 'Edit Flow Status'

export type FlowStatusEditActionValuesType = {
  flowId: string
  flowTypeStatusId?: string
  name: string
  outcome?: Flow_Outcomes_Enum
  status: Flow_Statuses_Enum
}

export type FlowStatusEditActionProps = {
  flow: Pick<Flows, 'id' | 'name' | 'outcome' | 'status'> & {
    flow_type_status?: Maybe<
      Pick<Flow_Type_Statuses, 'id' | 'name'> & {
        flow_type: Pick<Flow_Types, 'id' | 'name'>
      }
    >
  }
  onSubmit: (values: FlowStatusEditActionValuesType) => Promise<void>
  orgConfig: OrgConfigQuery
}

type FormType = {
  name: string
  statusType: SelectOptionType<FlowStatusTypeOptionType>
  typeStatus?: SelectOptionType<FlowTypeStatusOptionType>
}

export function FlowStatusEditAction({ flow, onSubmit, orgConfig }: FlowStatusEditActionProps) {
  if (flow.status === Flow_Statuses_Enum.Closed) {
    return null
  }

  const { defaultOption: defaultStatusTypeOption, optionsOrGroups: statusTypeOptionsOrGroups } = useMemo(
    () => initSelect(JSON.stringify, getFlowStatusTypePreOptionsOrGroups(flow, orgConfig.flow_types)),
    [flow, orgConfig],
  )

  const { defaultOption: defaultTypeStatusOption } = useMemo(
    () =>
      defaultStatusTypeOption.value.flowTypeId
        ? initSelect(
            JSON.stringify,
            getFlowTypeStatusPreOptionsOrGroups(
              defaultStatusTypeOption.value.flowTypeId,
              flow.flow_type_status,
              orgConfig.flow_types,
            ),
          )
        : { defaultOption: undefined },
    [defaultStatusTypeOption, flow, orgConfig],
  )

  const initialValues = useMemo(
    () => ({
      name: flow.name,
      statusType: defaultStatusTypeOption,
      typeStatus: defaultTypeStatusOption,
    }),
    [defaultStatusTypeOption, defaultTypeStatusOption, flow.name],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        flowId: flow.id,
        flowTypeStatusId: values.typeStatus?.value.flowTypeStatusId,
        name: values.name,
        outcome: values.statusType.value.outcome,
        status: values.statusType.value.status,
      })
    },
    [onSubmit],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={FLOW_STATUS_EDIT_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl isRequired={true} label='Name' name='name' />
        <SelectControl label='Status / Type' name='statusType' optionsOrGroups={statusTypeOptionsOrGroups} />
        <StatusControl flow={flow} orgConfig={orgConfig} />
      </ModalContent>
      <ModalFooter>
        <SubmitButton>Apply</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

function StatusControl({ flow, orgConfig }: Pick<FlowStatusEditActionProps, 'flow' | 'orgConfig'>) {
  const {
    input: { value: statusType },
  } = useField<SelectOptionType<FlowStatusTypeOptionType>>('statusType', {
    subscription: { value: true },
  })

  const {
    input: { onChange: onTypeStatusChange },
  } = useField<SelectOptionType<FlowTypeStatusOptionType>>('typeStatus')

  const { optionsOrGroups } = statusType.value.flowTypeId
    ? initSelect(
        JSON.stringify,
        getFlowTypeStatusPreOptionsOrGroups(statusType.value.flowTypeId, flow.flow_type_status, orgConfig.flow_types),
      )
    : { optionsOrGroups: undefined }

  const prevStatusType = usePrevious(statusType)
  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (!isFirstRender && prevStatusType?.value.flowTypeId !== statusType.value.flowTypeId) {
      onTypeStatusChange(
        statusType.value.flowTypeId && optionsOrGroups ? findOption(optionsOrGroups, (o) => o.isDefault) : undefined,
      )
    }
  })

  return optionsOrGroups ? <SelectControl label='Stage' name='typeStatus' optionsOrGroups={optionsOrGroups} /> : null
}
