import { ChatBubble } from '@src/components/atoms'
import { Users } from '@src/gen/graphql'
import {
  FlowActivity,
  FlowActivityActionItemCanceled,
  FlowActivityActionItemCompleted,
  FlowActivityActionItemCreated,
  FlowActivityFlowFieldUpdated,
  FlowActivityFlowFlowTypeStatusUpdated,
  FlowActivityFlowNameUpdated,
  FlowActivityParticipantFlowRoleTypeUpdated,
  FlowActivityParticipantInvited,
  FlowActivityParticipantJoined,
  FlowActivityParticipantLeft,
  FlowActivityParticipantVerified,
  FlowActivityTypes,
  getUserFullName,
} from '@src/logic/data/manipulation'
import React, { memo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

export type FlowActivityChatBubbleProps = {
  activity: FlowActivity
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

export const FlowActivityChatBubble = memo(FlowActivityChatBubbleComponent)

function FlowActivityChatBubbleComponent({ activity, style, user }: FlowActivityChatBubbleProps) {
  switch (activity.type) {
    case FlowActivityTypes.MESSAGE_SENT:
      throw new Error('Unsupported flow activity type for FlowActivityChatBubble.')
    case FlowActivityTypes.ACTION_ITEM_CREATED:
      return <FlowActivityChatBubbleActionItemCreated activity={activity} style={style} user={user} />
    case FlowActivityTypes.ACTION_ITEM_COMPLETED:
      return <FlowActivityChatBubbleActionItemCompleted activity={activity} style={style} user={user} />
    case FlowActivityTypes.ACTION_ITEM_CANCELED:
      return <FlowActivityChatBubbleActionItemCanceled activity={activity} style={style} user={user} />
    case FlowActivityTypes.PARTICIPANT_JOINED:
      return <FlowActivityChatBubbleParticipantJoined activity={activity} style={style} user={user} />
    case FlowActivityTypes.PARTICIPANT_LEFT:
      return <FlowActivityChatBubbleParticipantLeft activity={activity} style={style} user={user} />
    case FlowActivityTypes.PARTICIPANT_VERIFIED:
      return <FlowActivityChatBubbleParticipantVerified activity={activity} style={style} user={user} />
    case FlowActivityTypes.PARTICIPANT_INVITED:
      return <FlowActivityChatBubbleParticipantInvited activity={activity} style={style} user={user} />
    case FlowActivityTypes.FLOW_FLOW_TYPE_STATUS_UPDATED:
      return <FlowActivityChatBubbleFlowFlowTypeStatusUpdated activity={activity} style={style} user={user} />
    case FlowActivityTypes.FLOW_NAME_UPDATED:
      return <FlowActivityChatBubbleFlowNameUpdated activity={activity} style={style} user={user} />
    case FlowActivityTypes.PARTICIPANT_FLOW_ROLE_TYPE_UPDATED:
      return <FlowActivityChatBubbleParticipantFlowRoleTypeUpdated activity={activity} style={style} user={user} />
    case FlowActivityTypes.FLOW_FIELD_UPDATED:
      return <FlowActivityChatBubbleFlowFieldUpdated activity={activity} style={style} user={user} />
  }
}

type FlowActivityChatBubbleActionItemCreatedProps = {
  activity: FlowActivityActionItemCreated
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

function FlowActivityChatBubbleActionItemCreated({
  activity,
  style,
  user,
}: FlowActivityChatBubbleActionItemCreatedProps) {
  return (
    <ChatBubble
      avatar={{ user: activity.creator }}
      body={`Created an Action Item for ${getUserFullName(activity.assignee)}`}
      colorVariant={activity.assignee.id === user.id ? 'warning' : 'primary'}
      label={getUserFullName(activity.creator)}
      own={activity.creator.id === user.id}
      style={style}
    />
  )
}

type FlowActivityChatBubbleActionItemCompletedProps = {
  activity: FlowActivityActionItemCompleted
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

function FlowActivityChatBubbleActionItemCompleted({
  activity,
  style,
  user,
}: FlowActivityChatBubbleActionItemCompletedProps) {
  return (
    <ChatBubble
      avatar={{ user: activity.completer }}
      label={getUserFullName(activity.completer)}
      colorVariant='success'
      body={`Completed an Action Item from ${getUserFullName(activity.creator)}`}
      own={activity.completer.id === user.id}
      style={style}
    />
  )
}

type FlowActivityChatBubbleActionItemCanceledProps = {
  activity: FlowActivityActionItemCanceled
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

function FlowActivityChatBubbleActionItemCanceled({
  activity,
  style,
  user,
}: FlowActivityChatBubbleActionItemCanceledProps) {
  return (
    <ChatBubble
      avatar={{ user: activity.canceler }}
      label={getUserFullName(activity.canceler)}
      colorVariant='primary'
      body={`Deleted an Action Item for ${getUserFullName(activity.assignee)}`}
      own={activity.canceler.id === user.id}
      style={style}
    />
  )
}

type FlowActivityChatBubbleParticipantJoinedProps = {
  activity: FlowActivityParticipantJoined
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

function FlowActivityChatBubbleParticipantJoined({
  activity,
  style,
  user,
}: FlowActivityChatBubbleParticipantJoinedProps) {
  return (
    <ChatBubble
      avatar={{ user: activity.participant }}
      label={getUserFullName(activity.participant)}
      colorVariant='primary'
      body={`Joined the Flow`}
      own={activity.participant.id === user.id}
      style={style}
    />
  )
}

type FlowActivityChatBubbleParticipantLeftProps = {
  activity: FlowActivityParticipantLeft
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

function FlowActivityChatBubbleParticipantLeft({ activity, style, user }: FlowActivityChatBubbleParticipantLeftProps) {
  return (
    <ChatBubble
      avatar={{ user: activity.participant }}
      label={getUserFullName(activity.participant)}
      colorVariant='primary'
      body={`Left the Flow`}
      own={activity.participant.id === user.id}
      style={style}
    />
  )
}

type FlowActivityChatBubbleParticipantVerifiedProps = {
  activity: FlowActivityParticipantVerified
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

function FlowActivityChatBubbleParticipantVerified({
  activity,
  style,
  user,
}: FlowActivityChatBubbleParticipantVerifiedProps) {
  return (
    <ChatBubble
      avatar={{ user: activity.participant }}
      label={getUserFullName(activity.participant)}
      colorVariant='primary'
      body={`Is now a Verified Participant`}
      own={activity.participant.id === user.id}
      style={style}
    />
  )
}

type FlowActivityChatBubbleParticipantInvitedProps = {
  activity: FlowActivityParticipantInvited
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

function FlowActivityChatBubbleParticipantInvited({
  activity,
  style,
  user,
}: FlowActivityChatBubbleParticipantInvitedProps) {
  return (
    <ChatBubble
      avatar={{ user: activity.inviter }}
      label={getUserFullName(activity.inviter)}
      colorVariant='primary'
      body={`Invited ${getUserFullName(activity.invitee)}`}
      own={activity.inviter.id === user.id}
      style={style}
    />
  )
}

type FlowActivityChatBubbleFlowFlowTypeStatusUpdatedProps = {
  activity: FlowActivityFlowFlowTypeStatusUpdated
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

function FlowActivityChatBubbleFlowFlowTypeStatusUpdated({
  activity,
  style,
  user,
}: FlowActivityChatBubbleFlowFlowTypeStatusUpdatedProps) {
  if (activity.new_flow_type_status) {
    return (
      <ChatBubble
        avatar={{ user: activity.updater }}
        body={`Updated the Flow Stage to "${activity.new_flow_type_status.flow_type_name} / ${activity.new_flow_type_status.name}"`}
        colorVariant='primary'
        label={getUserFullName(activity.updater)}
        own={activity.updater.id === user.id}
        style={style}
      />
    )
  }

  return (
    <ChatBubble
      avatar={{ user: activity.updater }}
      body='Cleared the Flow Stage'
      colorVariant='primary'
      label={getUserFullName(activity.updater)}
      own={activity.updater.id === user.id}
      style={style}
    />
  )
}

type FlowActivityChatBubbleFlowNameUpdatedProps = {
  activity: FlowActivityFlowNameUpdated
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

function FlowActivityChatBubbleFlowNameUpdated({ activity, user, style }: FlowActivityChatBubbleFlowNameUpdatedProps) {
  return (
    <ChatBubble
      avatar={{ user: activity.updater }}
      body={`Updated the Flow Name to "${activity.new_name}"`}
      colorVariant='primary'
      label={getUserFullName(activity.updater)}
      own={activity.updater.id === user.id}
      style={style}
    />
  )
}

type FlowActivityChatBubbleParticipantFlowRoleTypeUpdatedProps = {
  activity: FlowActivityParticipantFlowRoleTypeUpdated
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

function FlowActivityChatBubbleParticipantFlowRoleTypeUpdated({
  activity,
  user,
  style,
}: FlowActivityChatBubbleParticipantFlowRoleTypeUpdatedProps) {
  const body = activity.new_flow_role_type_name
    ? `Updated the Flow Role of ${getUserFullName(activity.participant)} to ${activity.new_flow_role_type_name}`
    : `Cleared the Flow Role of ${getUserFullName(activity.participant)}`

  return (
    <ChatBubble
      avatar={{ user: activity.updater }}
      body={body}
      colorVariant='primary'
      label={getUserFullName(activity.updater)}
      own={activity.updater.id === user.id}
      style={style}
    />
  )
}

type FlowActivityChatBubbleFlowFieldUpdatedProps = {
  activity: FlowActivityFlowFieldUpdated
  style?: StyleProp<ViewStyle>
  user: Pick<Users, 'id' | 'first_name' | 'last_name'>
}

function FlowActivityChatBubbleFlowFieldUpdated({
  activity,
  user,
  style,
}: FlowActivityChatBubbleFlowFieldUpdatedProps) {
  if (!activity.old_flow_field && activity.new_flow_field) {
    return (
      <ChatBubble
        avatar={{ user: activity.updater }}
        body={`Created the "${activity.new_flow_field.name}" Flow Field`}
        colorVariant='primary'
        label={getUserFullName(activity.updater)}
        own={activity.updater.id === user.id}
        style={style}
      />
    )
  }

  if (activity.old_flow_field && activity.new_flow_field) {
    return (
      <ChatBubble
        avatar={{ user: activity.updater }}
        body={`Updated the "${activity.new_flow_field.name}" Flow Field`}
        colorVariant='primary'
        label={getUserFullName(activity.updater)}
        own={activity.updater.id === user.id}
        style={style}
      />
    )
  }

  if (activity.old_flow_field && !activity.new_flow_field) {
    return (
      <ChatBubble
        avatar={{ user: activity.updater }}
        body={`Deleted the "${activity.old_flow_field.name}" Flow Field`}
        colorVariant='primary'
        label={getUserFullName(activity.updater)}
        own={activity.updater.id === user.id}
        style={style}
      />
    )
  }

  throw new Error('Invalid activity.')
}
