import { Card, ItemBadgeLabel, ItemLogoLabel, ScreenPrimaryStyles, Text } from '@src/components/atoms'
import { Flow_Notifications } from '@src/gen/graphql/bindings'
import { useConfig } from '@src/logic/config'
import { FlowActivity } from '@src/logic/data/manipulation'
import { CommonStyles, Theme } from '@src/logic/design'
import { formatTimeSince, ifTrue } from '@src/logic/utils'
import React, { Fragment, memo, useCallback, useMemo, useRef, useState } from 'react'
import { Animated, Dimensions, Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import { CheckIcon } from 'react-native-heroicons/solid'
import { FlowActivityItem } from './FlowActivityItem'

export type FlowNotificationCardProps = {
  index: number
  flowNotification: Pick<
    Flow_Notifications,
    'recent_activities' | 'organization_id' | 'organization_name' | 'updated_at' | 'flow_id'
  > & {
    flow_name?: string
  }
  onPress?: () => void
  style?: ViewStyle
  variant: 'org' | 'ext'
  clearNotification: (flowId: string, runAnimation: (toValue: number) => Promise<boolean>) => Promise<void>
}

export const FlowNotificationCard = memo(FlowNotificationCardComponent)

const Styles = StyleSheet.create({
  titleText: {
    marginBottom: 8,
    marginTop: 8,
  },
  earlierActivityText: {
    alignSelf: 'center',
    marginBottom: 16,
  },
  container: {
    flexDirection: 'row',
  },
  cardWrapper: {
    flex: 1,
  },
  buttonWrapper: {
    marginLeft: Theme.geometry.control.spacing,
    marginTop: Theme.geometry.control.spacing,
  },
  clearIconStyle: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.light.borderColor,
    padding: 2,
    borderWidth: 1,
    borderRadius: 50,
    minWidth: 24,
    minHeight: 24,
  },
})

function FlowNotificationCardComponent({
  flowNotification,
  onPress,
  style,
  variant,
  clearNotification,
  index,
}: FlowNotificationCardProps) {
  const animationValueRef = useRef(new Animated.Value(0))
  const [isClearing, setIsClearing] = useState(false)

  const runAnimation = useCallback(
    (toValue: number): Promise<boolean> => {
      setIsClearing(Boolean(toValue))
      return new Promise((resolve, reject) => {
        Animated.timing(animationValueRef.current, {
          toValue,
          duration: 200,
          useNativeDriver: true,
        }).start(() => resolve(true))
      })
    },
    [isClearing, animationValueRef],
  )
  const [isExpanded, setIsExpanded] = useState(false)
  const recentActivities = flowNotification.recent_activities as FlowActivity[]
  const { getOrganizationProfilePictureUrl } = useConfig()
  const windowWidth = Dimensions.get('window').width

  const animatedViewStyle = useMemo(
    () => [
      Styles.container,
      {
        transform: [
          {
            translateX: animationValueRef.current.interpolate({
              inputRange: [0, 1],
              outputRange: [0, -windowWidth],
            }),
          },
        ],
      },
    ],
    [animationValueRef, windowWidth],
  )

  return (
    <Animated.View style={animatedViewStyle}>
      <View style={Styles.cardWrapper}>
        <Card colorVariant='lightDim' isExpanded={isExpanded} onExpand={setIsExpanded} onPress={onPress} style={style}>
          {variant === 'ext' && (
            <Fragment>
              <ItemLogoLabel
                label={formatTimeSince(flowNotification.updated_at)}
                logoAlt={flowNotification.organization_name}
                logoUri={getOrganizationProfilePictureUrl(flowNotification.organization_id)}
              />
              <Text
                colorVariant='light'
                numberOfLines={ifTrue(!isExpanded, 1)}
                style={Styles.titleText}
                textVariant='title'>
                {flowNotification.organization_name}
              </Text>
            </Fragment>
          )}
          {variant === 'org' && flowNotification.flow_name && (
            <Fragment>
              <ItemBadgeLabel
                badge={{ label: 'Flow Activity', colorVariant: 'lightDim' }}
                label={formatTimeSince(flowNotification.updated_at)}
              />
              <Text
                colorVariant='light'
                numberOfLines={ifTrue(!isExpanded, 1)}
                style={Styles.titleText}
                textVariant='title'>
                {flowNotification.flow_name}
              </Text>
            </Fragment>
          )}
          {recentActivities.length > 0 && (
            <FlowActivityItem
              colorVariant='light'
              activity={recentActivities[0]}
              style={CommonStyles.marginBottomCard}
            />
          )}
          {isExpanded && (
            <Fragment>
              <Text colorVariant='lightDim' style={Styles.earlierActivityText} textVariant='eyebrow'>
                Earlier Activity
              </Text>
              {recentActivities.slice(1).map((activity, i) => (
                <FlowActivityItem
                  colorVariant='light'
                  activity={activity}
                  key={i}
                  style={CommonStyles.marginBottomCard}
                />
              ))}
            </Fragment>
          )}
        </Card>
      </View>
      <View style={Styles.buttonWrapper}>
        <Pressable
          hitSlop={16}
          onPress={() => clearNotification(flowNotification.flow_id, runAnimation)}
          style={Styles.clearIconStyle}>
          {isClearing ? <CheckIcon color='green' height={24} width={24} /> : null}
        </Pressable>
      </View>
    </Animated.View>
  )
}
