import {
  Form,
  Item,
  ItemProps,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectControl,
  SubmitButton,
} from '@src/components/atoms'
import {
  Organization_Roles_Enum,
  OrgConfigQuery,
  OrgContactQuery,
  OrgContactStartFlowMutationVariables,
  useOrgContactStartFlowMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { getUserFullName, initSelect, SelectOptionType } from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'
import { useOrgRouting } from '@src/logic/routing/providers'
import { ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { ChatAlt2Icon } from 'react-native-heroicons/solid'

export const CONTACT_START_FLOW_ACTION_ID = 'contactStartFlow'
export const CONTACT_START_FLOW_ACTION_LABEL = 'Start Flow with Contact'

export type ContactStartFlowActionValues = Omit<OrgContactStartFlowMutationVariables, 'orgId'>

export type ContactStartFlowActionProps = {
  contact: NonNullable<OrgContactQuery['contacts_by_pk']>
  onSubmit: (values: ContactStartFlowActionValues) => Promise<void>
  orgConfig: OrgConfigQuery
}

type FormType = {
  flowConfigurationId: SelectOptionType<string>
}

export function ContactStartFlowAction({ contact, onSubmit, orgConfig }: ContactStartFlowActionProps) {
  const { defaultOption, optionsOrGroups } = useMemo(
    () =>
      initSelect<string>(
        (v) => v,
        orgConfig.flow_configurations.map((fc, i) => ({
          isDefault: i === 0,
          label: fc.name,
          value: fc.id,
        })),
      ),
    [orgConfig],
  )

  const initialValues = useMemo<FormType>(
    () => ({
      flowConfigurationId: defaultOption,
    }),
    [defaultOption],
  )

  const avatar = useMemo(() => ({ user: contact.user }), [contact.user])

  const badge = useMemo<ItemProps['badge'] | undefined>(
    () => ifTrue(contact.user.is_verified, { label: 'Unverified', colorVariant: 'warning' }),
    [contact.user.is_verified],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        contactId: contact.id,
        flowConfigurationId: values.flowConfigurationId.value,
      })
    },
    [onSubmit],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={CONTACT_START_FLOW_ACTION_LABEL} />
      <ModalContent>
        <Item
          avatar={avatar}
          badge={badge}
          body={getUserFullName(contact.user)}
          colorVariant='light'
          style={CommonStyles.marginBottomCard}
        />
        <SelectControl
          isRequired={true}
          label='Flow Template'
          name='flowConfigurationId'
          optionsOrGroups={optionsOrGroups}
        />
      </ModalContent>
      <ModalFooter>
        <SubmitButton>Start Flow</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterContactStartFlowAction(contact: ContactStartFlowActionProps['contact']) {
  const [doOrgContactStartFlow] = useOrgContactStartFlowMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org, orgConfig } = useOrg()
  const { useNavigateToFlow } = useOrgRouting()
  const navigateToFlow = useNavigateToFlow()

  const handleSubmit = useCallback(
    async (values: ContactStartFlowActionValues) => {
      const resp = await doOrgContactStartFlow({
        variables: {
          ...values,
          orgId: org.id,
        },
      })
      dismissAllActions()

      const flowId = resp.data?.initiate_flow_as_organization_user.flow_id
      if (flowId) {
        navigateToFlow(flowId)
      }
    },
    [doOrgContactStartFlow, dismissAllActions, org.id],
  )

  useRegisterAction<undefined>(
    ifTrue(org.role === Organization_Roles_Enum.Admin || org.role === Organization_Roles_Enum.Editor, {
      id: CONTACT_START_FLOW_ACTION_ID,
      menuItem: {
        Icon: ChatAlt2Icon,
        label: CONTACT_START_FLOW_ACTION_LABEL,
        params: undefined,
      },
      modal: () => <ContactStartFlowAction contact={contact} orgConfig={orgConfig} onSubmit={handleSubmit} />,
    }),
    [handleSubmit, org.role, orgConfig],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin || org.role === Organization_Roles_Enum.Editor, () =>
        dispatchAction<undefined>({
          id: CONTACT_START_FLOW_ACTION_ID,
          params: undefined,
        }),
      ),
    [dispatchAction, org.role],
  )
}
