import { MobileHeader, Modal } from '@src/components/atoms'
import { ExtNav } from '@src/components/parts/ExtNav'
import { ROUTE_ID_TO_TITLE } from '@src/logic/routing/common'
import { useExtRouting } from '@src/logic/routing/providers'
import React, { memo, useCallback, useState } from 'react'

export const ExtMobileHeader = memo(ExtMobileHeaderComponent)

function ExtMobileHeaderComponent() {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = useCallback(() => setIsOpen(true), [setIsOpen])
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen])
  const { useNavTabs } = useExtRouting()

  const navTabs = useNavTabs()
  const title = navTabs.activeTab ? ROUTE_ID_TO_TITLE[navTabs.activeTab.id] : 'Title'

  return (
    <>
      <MobileHeader onPress={handleOpen} title={title} variant='main' />
      <Modal isOpen={isOpen} onClose={handleClose} variant='bare'>
        <ExtNav onClose={handleClose} />
      </Modal>
    </>
  )
}
