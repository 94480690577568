import { ACTION_ITEM_ACTION_ID } from '@src/components/actions'
import {
  ChatBox,
  ScreenContent,
  ScreenPrimary,
  ScreenPrimaryStyles,
  ScreenSecondary,
  Tabs,
} from '@src/components/atoms'
import {
  FlowActionItems,
  FlowActionItemsProps,
  FlowChat,
  FlowPaneExternalParticipants,
  FlowPaneFields,
  FlowPaneInternalParticipants,
  PaneOrganizationProfile,
} from '@src/components/molecules'
import { useActions } from '@src/logic/actions'
import { useAuthenticatedAuth } from '@src/logic/auth'
import { useExtFlow } from '@src/logic/data/providers'
import { CommonStyles, Theme, useScreen } from '@src/logic/design'
import { mergeTabShowActivity, RouteIds } from '@src/logic/routing/common'
import { useExtRouting } from '@src/logic/routing/providers'
import { ArrayElement, ifTrue } from '@src/logic/utils'
import React, { Fragment, useCallback, useMemo, useRef } from 'react'
import { StyleSheet, View } from 'react-native'

export type ExtFlowPartProps = {
  view: 'summary' | 'chat' | 'actionItems'
}

const Styles = StyleSheet.create({
  chatBoxMobile: {
    marginRight: 64 + Theme.geometry.structure.spacing,
  },
})

export function ExtFlowPart({ view }: ExtFlowPartProps) {
  const flowTabs = useFlowTabsWithActivity()
  const { isPhone } = useScreen()

  return (
    <Fragment>
      {isPhone && <Tabs active={flowTabs.activeTab?.id} onChange={flowTabs.navigateTo} tabs={flowTabs.tabs} />}
      <ScreenContent>
        {(!isPhone || view === 'summary') && <ExtFlowSummaryPart />}
        {view === 'chat' && <ExtFlowChatPart />}
        {view === 'actionItems' && <ExtFlowActionItemsPart />}
      </ScreenContent>
    </Fragment>
  )
}

function ExtFlowSummaryPart() {
  const extFlow = useExtFlow()
  const hasUnseen = useStickyHasUnseen()

  extFlow.useSeen({
    status: true,
    fields: true,
    internalParticipants: true,
    externalParticipants: true,
  })

  return (
    <ScreenSecondary isScrollable={true}>
      <PaneOrganizationProfile label='Your conversation with' org={extFlow.flow.organization} />
      {extFlow.flow.flow_fields && extFlow.flow.flow_fields.length > 0 && (
        <FlowPaneFields flow={extFlow.flow} showActivity={hasUnseen.fields} />
      )}
      <FlowPaneInternalParticipants
        dispatchFlowUserAction={extFlow.dispatchFlowUserViewAction}
        flow={extFlow.flow}
        showActivity={hasUnseen.internalParticipants}
      />
      <FlowPaneExternalParticipants
        dispatchFlowUserAction={extFlow.dispatchFlowUserViewAction}
        flow={extFlow.flow}
        showActivity={hasUnseen.internalParticipants}
      />
    </ScreenSecondary>
  )
}

function ExtFlowChatPart() {
  const extFlow = useExtFlow()
  const flowTabs = useFlowTabsWithActivity()
  const { isPhone } = useScreen()
  const { user } = useAuthenticatedAuth()

  extFlow.useSeen({
    status: !isPhone,
    fields: !isPhone,
    internalParticipants: !isPhone,
    externalParticipants: !isPhone,
    messages: true,
  })

  const chatBoxViewStyle = useMemo(
    () => [ScreenPrimaryStyles.containerViewCenteredMedium, CommonStyles.marginBottomStructure],
    [],
  )

  return (
    <ScreenPrimary>
      {!isPhone && <Tabs active={flowTabs.activeTab?.id} onChange={flowTabs.navigateTo} tabs={flowTabs.tabs} />}
      <FlowChat messages={extFlow.flow.messages} user={user} />
      {extFlow.messageCreate && (
        <View style={chatBoxViewStyle}>
          <ChatBox onSend={extFlow.messageCreate} style={ifTrue(isPhone, Styles.chatBoxMobile)} />
        </View>
      )}
    </ScreenPrimary>
  )
}

function ExtFlowActionItemsPart() {
  const extFlow = useExtFlow()
  const flowTabs = useFlowTabsWithActivity()
  const { dispatchAction } = useActions()
  const { isPhone } = useScreen()
  const { user } = useAuthenticatedAuth()
  const seenActionItemsActivityCounterRef = useRef(extFlow.flowUser?.seen_action_items_activity_counter)

  extFlow.useSeen({
    status: !isPhone,
    fields: !isPhone,
    internalParticipants: !isPhone,
    externalParticipants: !isPhone,
    actionItems: true,
  })

  const handlePress = useCallback(
    (actionItem: ArrayElement<FlowActionItemsProps['actionItems']>) =>
      dispatchAction({ id: ACTION_ITEM_ACTION_ID, params: { actionItem } }),
    [dispatchAction],
  )

  return (
    <ScreenPrimary>
      {!isPhone && <Tabs active={flowTabs.activeTab?.id} onChange={flowTabs.navigateTo} tabs={flowTabs.tabs} />}
      <FlowActionItems
        actionItems={extFlow.flow.action_items}
        onPress={handlePress}
        seenActionItemsActivityCounter={seenActionItemsActivityCounterRef.current}
        user={user}
      />
    </ScreenPrimary>
  )
}

function useFlowTabsWithActivity() {
  const { useFlowTabs } = useExtRouting()
  const hasUnseen = useStickyHasUnseen()

  return mergeTabShowActivity(useFlowTabs(), {
    [RouteIds.EXT_FLOW_ACTION_ITEMS]: hasUnseen.actionItems,
    [RouteIds.EXT_FLOW_CHAT]: hasUnseen.messages,
    [RouteIds.EXT_FLOW_SUMMARY]: hasUnseen.status || hasUnseen.internalParticipants || hasUnseen.externalParticipants,
  })
}

function useStickyHasUnseen() {
  const { hasUnseen } = useExtFlow()
  const hasUnseenRef = useRef(hasUnseen)

  hasUnseenRef.current = {
    actionItems: hasUnseenRef.current.actionItems || hasUnseen.actionItems,
    messages: hasUnseenRef.current.messages || hasUnseen.messages,
    status: hasUnseenRef.current.status || hasUnseen.status,
    fields: hasUnseenRef.current.fields || hasUnseen.fields,
    internalParticipants: hasUnseenRef.current.internalParticipants || hasUnseen.internalParticipants,
    externalParticipants: hasUnseenRef.current.externalParticipants || hasUnseen.externalParticipants,
  }

  return hasUnseenRef.current
}
