import {
  Action_Item_Types_Enum,
  Flow_Field_Types_Enum,
  Flow_Field_Visibilities_Enum,
  OrgConfigQuery,
} from '@src/gen/graphql'
import { getActionItemTypeLabel } from '@src/logic/data/manipulation/actionItems'
import { SelectPreOptionOrGroupType } from '@src/logic/data/manipulation/select'

export function getFlowFieldVisibilityLabel(flowFieldVisibility: Flow_Field_Visibilities_Enum) {
  switch (flowFieldVisibility) {
    case Flow_Field_Visibilities_Enum.InternalOnly:
      return 'Internal Only'
    case Flow_Field_Visibilities_Enum.InternalAndExternal:
      return 'Internal And External'
  }
}

export function getFlowFieldVisibilitiesPreOptionsOrGroups(
  defaultFlowFieldVisibility?: Flow_Field_Visibilities_Enum,
): SelectPreOptionOrGroupType<Flow_Field_Visibilities_Enum>[] {
  return Object.entries(Flow_Field_Visibilities_Enum).map(([_, visibility], i) => ({
    isDefault: defaultFlowFieldVisibility ? defaultFlowFieldVisibility === visibility : i === 0,
    label: getFlowFieldVisibilityLabel(visibility),
    value: visibility,
  }))
}

export function getFlowFieldTypeLabel(flowFieldType: Flow_Field_Types_Enum) {
  switch (flowFieldType) {
    case Flow_Field_Types_Enum.Number:
      return 'Number'
    case Flow_Field_Types_Enum.Text:
      return 'Text'
  }
}

export function getFlowFieldTypesPreOptionsOrGroups(
  defaultFlowFieldType?: Flow_Field_Types_Enum,
): SelectPreOptionOrGroupType<Flow_Field_Types_Enum>[] {
  return Object.entries(Flow_Field_Types_Enum).map(([_, type], i) => ({
    isDefault: defaultFlowFieldType ? defaultFlowFieldType === type : i === 0,
    label: getFlowFieldTypeLabel(type),
    value: type,
  }))
}

export type FlowFieldStartFromOptionType = {
  name: string
  visibility: Flow_Field_Visibilities_Enum
  type: Flow_Field_Types_Enum
}

export function getFlowFieldStartFromPreOptionsOrGroups(
  flowFieldConfigurations: OrgConfigQuery['flow_field_configurations'],
): SelectPreOptionOrGroupType<FlowFieldStartFromOptionType>[] {
  return [
    {
      label: 'Blank',
      options: [
        {
          isDefault: true,
          label: 'Blank',
          value: {
            name: '',
            visibility: Flow_Field_Visibilities_Enum.InternalAndExternal,
            type: Flow_Field_Types_Enum.Number,
          },
        },
      ],
    },
    {
      label: 'Templates',
      options: flowFieldConfigurations.map((ffc) => ({
        isDefault: false,
        label: ffc.name,
        selectedLabel: `Templates / ${ffc.name}`,
        value: {
          name: ffc.name,
          visibility: ffc.visibility,
          type: ffc.type,
        },
      })),
    },
  ]
}
