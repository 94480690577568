import {
  Button,
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectControl,
  SubmitButton,
  TextInputControl,
} from '@src/components/atoms'
import {
  Flow_Statuses_Enum,
  Organization_Roles_Enum,
  OrgConfigDocument,
  OrgConfigQuery,
  OrgFlowConfigurationUpdateMutationVariables,
  useOrgFlowConfigurationDeleteMutation,
  useOrgFlowConfigurationUpdateMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { getUserFullName, initSelect, SelectOptionType } from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'
import { ArrayElement, ifTrue, stringEmptyToUndefined } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'

export const FLOW_CONFIGURATION_EDIT_ACTION_ID = 'flowConfigurationEdit'
export const FLOW_CONFIGURATION_EDIT_ACTION_LABEL = 'Edit Flow Template'

export type FlowConfigurationEditActionValues = OrgFlowConfigurationUpdateMutationVariables

export type FlowConfigurationEditActionParams = {
  flowConfiguration: ArrayElement<OrgConfigQuery['flow_configurations']>
}

export type FlowConfigurationEditActionProps = FlowConfigurationEditActionParams & {
  onDelete: (id: string) => Promise<void>
  onSubmit: (values: FlowConfigurationEditActionValues) => Promise<void>
  orgConfig: OrgConfigQuery
}

type FormType = {
  flowTypeStatusId?: SelectOptionType<string>
  name: string
  ownerId: SelectOptionType<string>
  welcomeChatMessage?: string
}

export function FlowConfigurationEditAction({
  flowConfiguration,
  onDelete,
  onSubmit,
  orgConfig,
}: FlowConfigurationEditActionProps) {
  const defaultFlowTypeStatusId = orgConfig.flow_types.find((ft) =>
    ft.flow_type_statuses.find((fts) => fts.id === flowConfiguration.flow_type_status?.id),
  )?.flow_type_statuses[0].id

  const { defaultOption: defaultFlowTypeStatusOption, optionsOrGroups: flowTypeStatusOptionsOrGroups } = useMemo(
    () =>
      initSelect<string>(
        (v) => v,
        [
          {
            label: 'New',
            options: [
              {
                isDefault: !defaultFlowTypeStatusId,
                label: 'No Type',
                selectedLabel: 'New / No Type',
                value: '',
              },
            ],
          },
          {
            label: 'In Flow',
            options: orgConfig.flow_types.map((ft) => ({
              isDefault: defaultFlowTypeStatusId === ft.flow_type_statuses[0].id,
              label: ft.name,
              selectedLabel: `In Flow / ${ft.name} / ${ft.flow_type_statuses[0].name}`,
              value: ft.flow_type_statuses[0].id,
            })),
          },
        ],
      ),
    [defaultFlowTypeStatusId, orgConfig.flow_types],
  )

  const defaultOwnerId = orgConfig.users_organizations
    .filter((uo) => uo.role === Organization_Roles_Enum.Admin || uo.role === Organization_Roles_Enum.Editor)
    .find((uo) => uo.user.id === flowConfiguration.owner?.id)?.user.id

  const { defaultOption: defaultOwnerOption, optionsOrGroups: ownerOptionGroups } = useMemo(
    () =>
      initSelect<string>(
        (v) => v,
        orgConfig.users_organizations
          .filter((uo) => uo.role === Organization_Roles_Enum.Admin || uo.role === Organization_Roles_Enum.Editor)
          .map((uo) => ({ ...uo, fullName: getUserFullName(uo.user) }))
          .sort((uo1, uo2) => uo1.fullName.localeCompare(uo2.fullName))
          .map((uo, i) => ({
            isDefault: defaultOwnerId ? defaultOwnerId === uo.user.id : i == 0,
            label: uo.fullName,
            value: uo.user.id,
          })),
      ),
    [defaultOwnerId, orgConfig.users_organizations],
  )

  const initialValues = useMemo<FormType>(
    () => ({
      flowTypeStatusId: defaultFlowTypeStatusOption,
      name: flowConfiguration.name,
      ownerId: defaultOwnerOption,
      welcomeChatMessage: flowConfiguration.welcome_chat_message || '',
    }),
    [defaultFlowTypeStatusOption, defaultOwnerOption],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        flowTypeStatusId: stringEmptyToUndefined(values.flowTypeStatusId?.value),
        id: flowConfiguration.id,
        name: values.name,
        ownerId: values.ownerId.value,
        status: stringEmptyToUndefined(values.flowTypeStatusId?.value)
          ? Flow_Statuses_Enum.Flow
          : Flow_Statuses_Enum.New,
        welcomeChatMessage: values.welcomeChatMessage,
      })
    },
    [onSubmit],
  )

  const handleDelete = useCallback(() => onDelete(flowConfiguration.id), [flowConfiguration.id, onDelete])

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={FLOW_CONFIGURATION_EDIT_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl
          isRequired={true}
          label='Template Name'
          name='name'
          placeholder='e.g. Inbound from Company Website...'
        />
        <SelectControl
          isRequired={true}
          label='Initial Flow Status / Type / Stage'
          name='flowTypeStatusId'
          optionsOrGroups={flowTypeStatusOptionsOrGroups}
        />
        <SelectControl isRequired={true} label='Initial Owner' name='ownerId' optionsOrGroups={ownerOptionGroups} />
        <TextInputControl
          isRequired={false}
          label='Welcome Chat Message'
          name='welcomeChatMessage'
          placeholder='e.g. Hello!'
        />
      </ModalContent>
      <ModalFooter>
        <Button colorVariant='light' onPress={handleDelete}>
          Delete
        </Button>
        <SubmitButton style={CommonStyles.marginLeftCard}>Save</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterFlowConfigurationEditAction() {
  const [doFlowConfigurationUpdate] = useOrgFlowConfigurationUpdateMutation()
  const [doFlowConfigurationDelete] = useOrgFlowConfigurationDeleteMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org, orgConfig } = useOrg()

  const handleSubmit = useCallback(
    async (values: FlowConfigurationEditActionValues) => {
      await doFlowConfigurationUpdate({
        variables: values,
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doFlowConfigurationUpdate, dismissAllActions],
  )

  const handleDelete = useCallback(
    async (id: string) => {
      await doFlowConfigurationDelete({
        variables: {
          id,
        },
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doFlowConfigurationDelete, dismissAllActions],
  )

  useRegisterAction<FlowConfigurationEditActionParams>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: FLOW_CONFIGURATION_EDIT_ACTION_ID,
      modal: ({ flowConfiguration }) => (
        <FlowConfigurationEditAction
          flowConfiguration={flowConfiguration}
          onDelete={handleDelete}
          onSubmit={handleSubmit}
          orgConfig={orgConfig}
        />
      ),
    }),
    [handleDelete, handleSubmit, orgConfig, org.role],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, (params: FlowConfigurationEditActionParams) =>
        dispatchAction({
          id: FLOW_CONFIGURATION_EDIT_ACTION_ID,
          params,
        }),
      ),
    [dispatchAction, org.role],
  )
}
