import { Form, ModalContent, ModalFooter, ModalHeader, SubmitButton, TextInputControl } from '@src/components/atoms'
import React, { useCallback, useMemo } from 'react'

export const FLOW_EXTERNAL_PARTICIPANT_INVITE_ACTION_ID = 'flowExternalParticipantInvite'
export const FLOW_EXTERNAL_PARTICIPANT_INVITE_ACTION_LABEL = 'Invite External Participant'

export type FlowExternalParticipantInviteActionValuesType = {
  flowId: string
  email: string
  firstName: string
  lastName: string
}

export type FlowExternalParticipantInviteActionProps = {
  flowId: string
  onSubmit: (values: FlowExternalParticipantInviteActionValuesType) => Promise<void>
}

type FormType = {
  email: string
  firstName: string
  lastName: string
}

export function FlowExternalParticipantInviteAction({ flowId, onSubmit }: FlowExternalParticipantInviteActionProps) {
  const initialValues = useMemo<FormType>(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
    }),
    [],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        flowId,
        ...values,
      })
    },
    [onSubmit],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={FLOW_EXTERNAL_PARTICIPANT_INVITE_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl isRequired={true} label='First Name' name='firstName' />
        <TextInputControl isRequired={true} label='Last Name' name='lastName' />
        <TextInputControl isRequired={true} textContentType='emailAddress' label='Email' name='email' />
      </ModalContent>
      <ModalFooter>{<SubmitButton>Invite</SubmitButton>}</ModalFooter>
    </Form>
  )
}
