import { ActivityIndicator } from '@src/components/atoms/ActivityIndicator'
import { BadgeProps } from '@src/components/atoms/Badge'
import { IconButton, IconButtonProps } from '@src/components/atoms/IconButton'
import { Text } from '@src/components/atoms/Text'
import { CommonStyles, Theme } from '@src/logic/design'
import React, { memo, useMemo } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

export type PaneHeaderProps = {
  badge?: Omit<BadgeProps, 'displayVariant' | 'style'>
  iconButton?: Omit<IconButtonProps, 'colorVariant' | 'style'>
  label?: string
  showActivity?: boolean
  style?: ViewStyle
  title?: string
}

export const PaneHeader = memo(PaneHeaderComponent)

const Styles = StyleSheet.create({
  view: {
    alignItems: 'center',
    backgroundColor: Theme.colors.darkDim.backgroundColor,
    flexDirection: 'row',
    height: 24,
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
  },
  titleText: {
    flex: 1,
    paddingRight: 8,
  },
  badge: {
    marginLeft: 16,
  },
  activityIndicator: {
    position: 'absolute',
    right: 24,
  },
})

function PaneHeaderComponent({ iconButton, label, style, showActivity, title }: PaneHeaderProps) {
  const viewStyle = useMemo(() => [Styles.view, style], [style])

  return (
    <View style={viewStyle}>
      {label && (
        <Text colorVariant='darkDim' textVariant='eyebrow'>
          {label}
        </Text>
      )}
      {title && (
        <Text colorVariant='dark' numberOfLines={1} style={Styles.titleText} textVariant='title'>
          {title}
        </Text>
      )}
      {!title && (iconButton || showActivity) && <View style={CommonStyles.flexOne} />}
      {iconButton && <IconButton colorVariant='dark' {...iconButton} />}
      {showActivity && <ActivityIndicator style={Styles.activityIndicator} />}
    </View>
  )
}
