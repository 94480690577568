import { ColorVariants, Theme, ForegroundStyles, TextVariants } from '@src/logic/design'
import React, { forwardRef, memo, useMemo } from 'react'
import { StyleSheet, Text as RNText, TextProps as RNTextProps, TextStyle } from 'react-native'
import HyperLink from '@src/components/atoms/HyperLink'

export type TextProps = Pick<RNTextProps, 'ellipsizeMode' | 'numberOfLines'> & {
  children?: string
  colorVariant: ColorVariants
  style?: TextStyle
  textVariant: TextVariants
  enableLinks?: boolean
}

const Styles = StyleSheet.create({
  ...Theme.text,
  link: {
    color: Theme.colors.primary.color,
    textDecorationColor: Theme.colors.primary.color,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
  },
})

export const Text = memo(
  forwardRef<RNText, TextProps>(
    (
      { children, colorVariant, ellipsizeMode, numberOfLines, style, textVariant, enableLinks = false }: TextProps,
      ref,
    ) => {
      const textStyle = useMemo(
        () => [Styles[textVariant], ForegroundStyles[colorVariant], style],
        [colorVariant, textVariant, style],
      )

      return enableLinks ? (
        <HyperLink linkDefault={true} linkStyle={Styles.link}>
          <RNText ellipsizeMode={ellipsizeMode} numberOfLines={numberOfLines} ref={ref} style={textStyle}>
            {children}
          </RNText>
        </HyperLink>
      ) : (
        <RNText ellipsizeMode={ellipsizeMode} numberOfLines={numberOfLines} ref={ref} style={textStyle}>
          {children}
        </RNText>
      )
    },
  ),
)
