import {
  Button,
  ConditionalControl,
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectControl,
  SubmitButton,
  TextInputControl,
} from '@src/components/atoms'
import {
  Action_Item_Types_Enum,
  OrgActionItemConfigurationUpdateMutationVariables,
  Organization_Roles_Enum,
  OrgConfigDocument,
  OrgConfigQuery,
  useOrgActionItemConfigurationDeleteMutation,
  useOrgActionItemConfigurationUpdateMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import {
  findOption,
  getActionItemTypePreOptionsOrGroups,
  initSelect,
  SelectOptionType,
} from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'
import { ArrayElement, ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'

export const ACTION_ITEM_CONFIGURATION_EDIT_ACTION_ID = 'actionItemConfigurationEdit'
export const ACTION_ITEM_CONFIGURATION_EDIT_ACTION_LABEL = 'Edit Action Item Template'

export type ActionItemConfigurationEditActionParams = {
  actionItemConfiguration: ArrayElement<OrgConfigQuery['action_item_configurations']>
}

export type ActionItemConfigurationEditActionValues = OrgActionItemConfigurationUpdateMutationVariables

export type ActionItemConfigurationEditActionProps = ActionItemConfigurationEditActionParams & {
  onDelete: (id: string) => Promise<void>
  onSubmit: (values: ActionItemConfigurationEditActionValues) => Promise<void>
}

type FormType = {
  input: string
  name: string
  promptCta?: string
  type: SelectOptionType<Action_Item_Types_Enum>
}

export function ActionItemConfigurationEditAction({
  actionItemConfiguration,
  onDelete,
  onSubmit,
}: ActionItemConfigurationEditActionProps) {
  const { optionsOrGroups: typeOptionsOrGroups } = useMemo(
    () => initSelect((value) => value, getActionItemTypePreOptionsOrGroups()),
    [],
  )

  const initialValues = useMemo<FormType>(
    () => ({
      input: actionItemConfiguration.input,
      name: actionItemConfiguration.name,
      type: findOption(typeOptionsOrGroups, (o) => o.value === actionItemConfiguration.type),
      promptCta: actionItemConfiguration.prompt_cta || undefined,
    }),
    [actionItemConfiguration.type, typeOptionsOrGroups],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        ...values,
        id: actionItemConfiguration.id,
        type: values.type.value,
      })
    },
    [onSubmit],
  )

  const handleDelete = useCallback(() => onDelete(actionItemConfiguration.id), [actionItemConfiguration.id, onDelete])

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={ACTION_ITEM_CONFIGURATION_EDIT_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl isRequired={true} label='Name' name='name' placeholder='e.g. Upload Purchase Order...' />
        <SelectControl isRequired={true} label='Type' name='type' optionsOrGroups={typeOptionsOrGroups} />
        <TextInputControl isRequired={true} label='Prompt' name='input' />
        <ConditionalControl<SelectOptionType<Action_Item_Types_Enum>>
          subscription='type'
          predicate={(value) => value.value === Action_Item_Types_Enum.Prompt}
          render={() => <TextInputControl isRequired={true} label='Action' name='promptCta' />}
        />
      </ModalContent>
      <ModalFooter>
        <Button colorVariant='light' onPress={handleDelete}>
          Delete
        </Button>
        <SubmitButton style={CommonStyles.marginLeftCard}>Save</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterActionItemConfigurationEditAction() {
  const [doActionItemConfigurationUpdate] = useOrgActionItemConfigurationUpdateMutation()
  const [doActionItemConfigurationDelete] = useOrgActionItemConfigurationDeleteMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org } = useOrg()

  const handleSubmit = useCallback(
    async (values: ActionItemConfigurationEditActionValues) => {
      await doActionItemConfigurationUpdate({
        variables: values,
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doActionItemConfigurationUpdate, dismissAllActions],
  )

  const handleDelete = useCallback(
    async (id: string) => {
      await doActionItemConfigurationDelete({
        variables: {
          id,
        },
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doActionItemConfigurationDelete, dismissAllActions],
  )

  useRegisterAction<ActionItemConfigurationEditActionParams>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: ACTION_ITEM_CONFIGURATION_EDIT_ACTION_ID,
      modal: ({ actionItemConfiguration }) => (
        <ActionItemConfigurationEditAction
          actionItemConfiguration={actionItemConfiguration}
          onDelete={handleDelete}
          onSubmit={handleSubmit}
        />
      ),
    }),
    [handleDelete, handleSubmit, org.role],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, (params: ActionItemConfigurationEditActionParams) =>
        dispatchAction({
          id: ACTION_ITEM_CONFIGURATION_EDIT_ACTION_ID,
          params,
        }),
      ),
    [dispatchAction, org.role],
  )
}
