import React, { createContext, ReactChild, ReactNode } from 'react'
import { OrgFlowsQuery, useOrgFlowsQuery } from '@src/gen/graphql/bindings'
import { useRequiredContext } from '@src/logic/utils'
import { useOrgFlowsFilters } from '@src/logic/data/providers/OrgFlowsFiltersProvider'
import { useOrg } from '@src/logic/data/providers/OrgProvider'

const PAGE_LIMIT = 25

export type OrgFlowsContextType = ReturnType<typeof useContext>
export const OrgFlowsContext = createContext<OrgFlowsContextType | undefined>(undefined)
export const useOrgFlows = () => useRequiredContext(OrgFlowsContext)

export type OrgFlowsProviderProps = {
  children?: ReactNode
}

export function OrgFlowsProvider({ children }: OrgFlowsProviderProps) {
  const { org } = useOrg()
  const { flowFilterOwner, flowFilterStatusType } = useOrgFlowsFilters()

  const { data, previousData, error, loading, fetchMore } = useOrgFlowsQuery({
    variables: {
      orgId: org.id,
      limit: PAGE_LIMIT + 1,
      offset: 0,
      ...flowFilterOwner.selectedOption.value,
      ...flowFilterStatusType.selectedOption.value,
    },
  })

  let isFetchingMore = false

  const fetchMoreFlows = async () => {
    if (data !== undefined && !isFetchingMore) {
      try {
        isFetchingMore = true
        await fetchMore({
          variables: {
            orgId: org.id,
            limit: PAGE_LIMIT + 1,
            ...flowFilterOwner.selectedOption.value,
            ...flowFilterStatusType.selectedOption.value,
            offset: data.flows.length,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return {
              flows: [...previousResult.flows, ...(fetchMoreResult?.flows || [])],
            }
          },
        })
      } finally {
        isFetchingMore = false
      }
    }
  }

  if (error) {
    throw error
  }

  if (loading && data === undefined && previousData === undefined) {
    return null
  }

  const finalData = data ?? previousData
  if (finalData === undefined) {
    throw new Error('No data.')
  }

  return <OrgFlowsContext.Provider value={useContext(finalData, fetchMoreFlows)}>{children}</OrgFlowsContext.Provider>
}

OrgFlowsProvider.Guard = OrgFlowsProviderGuard

export type OrgFlowsProviderGuardProps = {
  children?: ReactChild
}

export function OrgFlowsProviderGuard({ children }: OrgFlowsProviderGuardProps) {
  return React.useContext(OrgFlowsContext) !== undefined && children ? <>{children}</> : null
}

function useContext(data: OrgFlowsQuery, fetchMoreFlows: () => Promise<void>) {
  return {
    flows: data.flows,
    fetchMoreFlows,
  }
}
