import { PaneHeader, PaneHeaderProps } from '@src/components/atoms/PaneHeader'
import { CommonStyles, Theme } from '@src/logic/design'
import { ifTrue } from '@src/logic/utils'
import React, { memo, ReactNode, useMemo } from 'react'
import { ScrollView, StyleSheet, View, ViewStyle } from 'react-native'

export type PaneProps = {
  children?: ReactNode
  header: Omit<PaneHeaderProps, 'style'>
  headerChildren?: ReactNode
  scrollableBehavior?: 'none' | 'delegated' | 'managed'
  style?: ViewStyle
}

export const Pane = memo(PaneComponent)

export const PaneStyles = StyleSheet.create({
  contentView: {
    paddingBottom: Theme.geometry.structure.spacing / 2,
    paddingTop: Theme.geometry.structure.spacing,
  },
  item: {
    height: 48,
    marginLeft: Theme.geometry.structure.spacing,
    marginRight: Theme.geometry.structure.spacing,
  },
})

const Styles = StyleSheet.create({
  view: {
    alignItems: 'stretch',
    backgroundColor: Theme.colors.darkDim.backgroundColor,
    paddingBottom: Theme.geometry.structure.spacing,
  },
  topSeparatorView: {
    borderTopColor: Theme.colors.darkDim.borderColor,
    borderTopWidth: 1,
    marginLeft: Theme.geometry.structure.spacing,
    marginRight: Theme.geometry.structure.spacing,
    marginTop: 8,
  },
  bottomSeparatorView: {
    borderTopColor: Theme.colors.darkDim.borderColor,
    borderTopWidth: 1,
    marginLeft: Theme.geometry.structure.spacing,
    marginRight: Theme.geometry.structure.spacing,
  },
})

function PaneComponent({ children, header, headerChildren, scrollableBehavior = 'none', style }: PaneProps) {
  const viewStyle = useMemo(
    () => [Styles.view, ifTrue(scrollableBehavior !== 'none', CommonStyles.flexOne), style],
    [scrollableBehavior, style],
  )

  const contentViewStyle = useMemo(
    () => [
      ifTrue(scrollableBehavior !== 'delegated', PaneStyles.contentView),
      ifTrue(scrollableBehavior === 'delegated', CommonStyles.flexOne),
    ],
    [scrollableBehavior],
  )

  return (
    <View style={viewStyle}>
      <PaneHeader {...header} />
      {headerChildren}
      <View style={Styles.topSeparatorView} />
      {scrollableBehavior !== 'managed' && <View style={contentViewStyle}>{children}</View>}
      {scrollableBehavior === 'managed' && (
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={contentViewStyle}
          style={CommonStyles.flexOne}>
          {children}
        </ScrollView>
      )}
      {scrollableBehavior !== 'none' && <View style={Styles.bottomSeparatorView} />}
    </View>
  )
}
