import { Text } from '@src/components/atoms/Text'
import { Theme } from '@src/logic/design'
import { getMouseHandlers, ifTrue, usePrevious } from '@src/logic/utils'
import React, { Fragment, memo, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Animated, Easing, Pressable, StyleSheet, View } from 'react-native'
import { PlusIcon } from 'react-native-heroicons/solid'
import { SvgProps } from 'react-native-svg'

const ANIMATION_DURATION = 200

export type FloatingActionMenuProps = {
  items: FloatingActionMenuItemType[]
}

export type FloatingActionMenuItemType = {
  Icon: (props: SvgProps) => JSX.Element
  label: string
  onPress: () => void
}

export const FloatingActionMenu = memo(FloatingActionMenuComponent)

const Styles = StyleSheet.create({
  buttonView: {
    bottom: -64,
    height: 64,
    position: 'absolute',
    right: 16,
    width: 64,
    zIndex: 1000,
  },
  buttonPressable: {
    alignItems: 'center',
    backgroundColor: Theme.colors.primary.color,
    borderRadius: 32,
    height: 64,
    justifyContent: 'center',
    width: 64,
  },
  buttonPressableHighlight: {
    backgroundColor: Theme.colors.primary.darkerColor,
  },
  item: {
    alignItems: 'center',
    bottom: 24,
    flexDirection: 'row',
    opacity: 0,
    position: 'absolute',
    right: 24,
  },
  itemTooltip: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.lightDim.borderColor,
    borderRadius: 4,
    borderWidth: 1,
    marginRight: 8,
    padding: 8,
  },
  itemPressable: {
    alignItems: 'center',
    backgroundColor: Theme.colors.primary.color,
    borderRadius: 24,
    height: 48,
    justifyContent: 'center',
    width: 48,
  },
  itemPressableHighlight: {
    backgroundColor: Theme.colors.primary.darkerColor,
  },
})

function FloatingActionMenuComponent({ items }: FloatingActionMenuProps) {
  const animationValueRef = useRef(new Animated.Value(0))
  const [isOpen, setIsOpen] = useState(false)

  const runAnimation = useCallback(() => {
    animationValueRef.current.stopAnimation((value) => {
      Animated.timing(animationValueRef.current, {
        toValue: isOpen ? 0 : 1,
        duration: (isOpen ? value : 1 - value) * ANIMATION_DURATION,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false, // true breaks stopAnimation callback value
      }).start()
    })
  }, [animationValueRef, isOpen])

  const handlePress = useCallback(() => {
    setIsOpen(!isOpen)
    runAnimation()
  }, [isOpen, setIsOpen])

  return (
    <Fragment>
      <FloatingActionMenuButton
        animationValueRef={animationValueRef}
        hasItems={items.length > 0}
        onPress={handlePress}
      />
      {items.map((item, i) => (
        <FloatingActionMenuItem
          animationValueRef={animationValueRef}
          index={i}
          item={item}
          key={i}
          onClose={handlePress}
        />
      ))}
    </Fragment>
  )
}

type FloatingActionMenuButtonProps = {
  animationValueRef: MutableRefObject<Animated.Value>
  hasItems: boolean
  onPress: () => void
}

const FloatingActionMenuButton = memo(FloatingActionMenuButtonComponent)

function FloatingActionMenuButtonComponent({ animationValueRef, hasItems, onPress }: FloatingActionMenuButtonProps) {
  const mountAnimationValueRef = useRef(new Animated.Value(0))
  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)
  const prevHasItems = usePrevious(hasItems)

  const runMountAnimation = useCallback(() => {
    mountAnimationValueRef.current.stopAnimation((value) => {
      Animated.timing(mountAnimationValueRef.current, {
        toValue: hasItems ? 0 : 1,
        duration: (hasItems ? value : 1 - value) * ANIMATION_DURATION,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false, // true breaks stopAnimation callback value
      }).start()
    })
  }, [mountAnimationValueRef, hasItems])

  useEffect(() => {
    if (prevHasItems !== hasItems) {
      runMountAnimation()
    }
  }, [hasItems, prevHasItems, runMountAnimation])

  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handlePressIn = useCallback(() => setIsPressed(true), [setIsPressed])
  const handlePressOut = useCallback(() => setIsPressed(false), [setIsPressed])

  const pressableStyle = useMemo(
    () => [Styles.buttonPressable, ifTrue(isHover || isPressed, Styles.buttonPressableHighlight)],
    [isHover, isPressed],
  )

  const viewStyle = useMemo(
    () => [
      Styles.buttonView,
      {
        transform: [
          {
            translateY: mountAnimationValueRef.current.interpolate({
              inputRange: [0, 1],
              outputRange: [-80, 0],
            }),
          },
        ],
      },
    ],
    [mountAnimationValueRef],
  )

  const innerViewStyle = useMemo(
    () => ({
      transform: [
        {
          rotate: animationValueRef.current.interpolate({
            inputRange: [0, 1],
            outputRange: ['0deg', '45deg'],
          }),
        },
      ],
    }),
    [animationValueRef],
  )

  return (
    <Animated.View style={viewStyle}>
      <Pressable
        {...handleMouse}
        onPress={onPress}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
        style={pressableStyle}>
        <Animated.View style={innerViewStyle}>
          <PlusIcon color={Theme.colors.dark.color} height={24} width={24} />
        </Animated.View>
      </Pressable>
    </Animated.View>
  )
}

type FloatingActionMenuItemProps = {
  animationValueRef: MutableRefObject<Animated.Value>
  index: number
  item: FloatingActionMenuItemType
  onClose: () => void
}

const FloatingActionMenuItem = memo(FloatingActionMenuItemComponent)

function FloatingActionMenuItemComponent({ animationValueRef, index, item, onClose }: FloatingActionMenuItemProps) {
  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  const Icon = item.Icon
  index = index + 1

  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handlePress = useCallback(() => {
    onClose()
    item.onPress()
  }, [item.onPress, onClose])

  const handlePressIn = useCallback(() => setIsPressed(true), [setIsPressed])
  const handlePressOut = useCallback(() => setIsPressed(false), [setIsPressed])

  const pressableStyle = useMemo(
    () => [Styles.itemPressable, ifTrue(isHover || isPressed, Styles.itemPressableHighlight)],
    [isHover, isPressed],
  )

  const viewStyle = useMemo(
    () => [
      Styles.item,
      {
        opacity: animationValueRef.current.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
        }),
        transform: [
          {
            translateY: animationValueRef.current.interpolate({
              inputRange: [0, 1],
              outputRange: [0, -64 * index - 8],
            }),
          },
          {
            translateX: animationValueRef.current.interpolate({
              inputRange: [0, 1],
              outputRange: [256, 0],
            }),
          },
        ],
        zIndex: 1000 - index,
      },
    ],
    [animationValueRef, index],
  )

  return (
    <Animated.View style={viewStyle}>
      <View style={Styles.itemTooltip}>
        <Text colorVariant='lightDim' textVariant='cta'>
          {item.label}
        </Text>
      </View>
      <Pressable
        {...handleMouse}
        onPress={handlePress}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
        style={pressableStyle}>
        <Icon color={Theme.colors.dark.color} height={24} width={24} />
      </Pressable>
    </Animated.View>
  )
}
