import { useRegisterContactStartFlowAction } from '@src/components/actions'
import {
  OrgContactFlowsQuery,
  OrgContactQuery,
  useOrgContactFlowsQuery,
  useOrgContactQuery,
} from '@src/gen/graphql/bindings'
import { useOrg } from '@src/logic/data/providers/OrgProvider'
import { useRequiredContext } from '@src/logic/utils'
import React, { createContext, ReactNode, useMemo } from 'react'

const FLOWS_LIMIT = 10

export type OrgContactContextType = {
  contact: NonNullable<OrgContactQuery['contacts_by_pk']>
  recentFlows: OrgContactFlowsQuery['flows']
}

export const OrgContactContext = createContext<OrgContactContextType | undefined>(undefined)
export const useOrgContact = () => useRequiredContext(OrgContactContext)

export type OrgContactProviderProps = {
  children?: ReactNode
  contactId: string
}

export function OrgContactProvider({ children, contactId }: OrgContactProviderProps) {
  const { data, error, loading, previousData } = useOrgContactQuery({
    variables: {
      contactId,
    },
  })

  if (error) {
    throw error
  }

  if (loading && data === undefined && previousData === undefined) {
    return null
  }

  const finalData = data ?? previousData
  if (finalData === undefined) {
    throw new Error('No data.')
  }

  const contact = finalData.contacts_by_pk
  if (!contact) {
    throw new Error('Not found.')
  }

  return <OrgContactProviderInner contact={contact}>{children}</OrgContactProviderInner>
}

type OrgContactProviderInnerProps = Pick<OrgContactContextType, 'contact'> & {
  children?: ReactNode
}

function OrgContactProviderInner({ children, contact }: OrgContactProviderInnerProps) {
  const { org } = useOrg()

  const { data, error, loading, previousData } = useOrgContactFlowsQuery({
    variables: {
      contactUserId: contact.user.id,
      limit: FLOWS_LIMIT,
      orgId: org.id,
    },
  })

  if (error) {
    throw error
  }

  if (loading && data === undefined && previousData === undefined) {
    return null
  }

  const finalData = data ?? previousData
  if (finalData === undefined) {
    throw new Error('No data.')
  }

  return (
    <OrgContactProviderInnerInner contact={contact} recentFlows={finalData.flows}>
      {children}
    </OrgContactProviderInnerInner>
  )
}

type OrgContactProviderInnerInnerProps = OrgContactContextType & {
  children?: ReactNode
}

function OrgContactProviderInnerInner({ children, contact, recentFlows }: OrgContactProviderInnerInnerProps) {
  useRegisterContactStartFlowAction(contact)

  const context = useMemo<OrgContactContextType>(
    () => ({
      contact,
      recentFlows,
    }),
    [contact, recentFlows],
  )

  return <OrgContactContext.Provider value={context}>{children}</OrgContactContext.Provider>
}
