import { ActionsProvider } from '@src/logic/actions'
import { AuthProvider } from '@src/logic/auth'
import { ConfigProvider, initSentry } from '@src/logic/config'
import { ScreenProvider, useDesignLoaders } from '@src/logic/design'
import { ErrorsProvider } from '@src/logic/errors'
import { GraphQLProvider } from '@src/logic/graphql'
import { PushProvider } from '@src/logic/push'
import { Router } from '@src/logic/routing'
import { isWeb } from '@src/logic/utils'
import { registerRootComponent } from 'expo'
import AppLoading from 'expo-app-loading'
import { InitialProps } from 'expo/build/launch/withExpoRoot.types'
import React from 'react'
import * as Sentry from 'sentry-expo'

function App() {
  if (!useDesignLoaders()) {
    return <AppLoading />
  }

  return (
    <ConfigProvider>
      <ScreenProvider>
        <ErrorsProvider>
          <AuthProvider>
            <GraphQLProvider>
              <PushProvider>
                <ActionsProvider>
                  <Router />
                </ActionsProvider>
              </PushProvider>
            </GraphQLProvider>
          </AuthProvider>
        </ErrorsProvider>
      </ScreenProvider>
    </ConfigProvider>
  )
}

initSentry()
const WrappedApp = isWeb() ? App : Sentry.Native.wrap<InitialProps>(App)
registerRootComponent(WrappedApp)
