import { Button, IconButton, Text } from '@src/components/atoms'
import { Accept, fileListToArray, getAcceptedMimeTypes, ManagedFile } from '@src/logic/data/uploads'
import { BackgroundStyles, BorderStyles, CommonStyles, dim, Theme } from '@src/logic/design'
import * as DocumentPicker from 'expo-document-picker'
import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { Platform, StyleSheet, View, ViewStyle } from 'react-native'
import { TrashIcon } from 'react-native-heroicons/outline'
import { RefreshIcon } from 'react-native-heroicons/solid'

export type FileUploadProps = {
  accept: Accept
  colorVariant: 'light' | 'dark'
  isDisabled?: boolean
  managedFiles: ManagedFile[]
  maxFiles: number
  maxSize: number
  queue: (files: File[]) => void
  remainingMaxFiles: number
  remove: (internalId: number) => void
  style?: ViewStyle
}

const Styles = StyleSheet.create({
  dropzoneView: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  uploadAttachmentView: {
    alignItems: 'center',
    borderRadius: Theme.geometry.control.radius,
    flexDirection: 'row',
    height: Theme.geometry.control.height,
    marginTop: Theme.geometry.control.spacing,
    paddingLeft: Theme.geometry.control.spacing,
    paddingRight: Theme.geometry.control.spacing / 2,
  },
  previewViewError: {
    borderColor: Theme.colors.danger.color,
    borderWidth: 1,
  },
})

export function FileUpload({
  accept,
  colorVariant,
  isDisabled = false,
  managedFiles,
  maxFiles,
  queue,
  remainingMaxFiles,
  remove,
  style,
}: FileUploadProps) {
  const handlePress = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      // Note: on iOS simulator setting this breaks everything, on web it actually accepts a list of mime types.
      type: Platform.OS === 'web' ? (getAcceptedMimeTypes(accept) as unknown as string) : undefined,
      copyToCacheDirectory: true,
      multiple: true,
    })

    if (result.type === 'success') {
      if (Platform.OS === 'web') {
        queue(fileListToArray(result.output))
      } else {
        // Note: this is incredibly stupid - the picker does return something that *almost* behaves like a File but not
        // quite. I'm patching it here and I've verified it actually works on iOS / Android.
        const anyResult = result as any
        anyResult.type = anyResult.mimeType
        queue([anyResult])
      }
    }
  }

  return (
    <View style={style}>
      <View style={Styles.dropzoneView}>
        <Button colorVariant={colorVariant} isDisabled={isDisabled || !remainingMaxFiles} onPress={handlePress}>
          Select Files
        </Button>
        <Dropzone colorVariant={colorVariant} isDisabled={isDisabled} queue={queue} />
        <Text colorVariant={dim(colorVariant)} textVariant='body'>
          {`${maxFiles - remainingMaxFiles}/${maxFiles}`}
        </Text>
      </View>
      <Previews colorVariant={colorVariant} isDisabled={isDisabled} managedFiles={managedFiles} remove={remove} />
    </View>
  )
}

type DropzoneProps = Pick<FileUploadProps, 'colorVariant' | 'isDisabled' | 'queue'>

function Dropzone({ colorVariant, isDisabled, queue }: DropzoneProps) {
  const [dropping, setDropping] = useState(false)

  if (Platform.OS !== 'web') {
    return <View style={CommonStyles.flexOne} />
  }

  return (
    <div
      onDragOver={(event) => {
        event.preventDefault()
        if (!isDisabled) {
          setDropping(true)
        }
      }}
      onDragLeave={(event) => {
        event.preventDefault()
        setDropping(false)
      }}
      onDrop={(event) => {
        event.preventDefault()
        setDropping(false)
        if (!isDisabled) {
          queue(fileListToArray(event.dataTransfer.files))
        }
      }}
      style={{
        alignItems: 'center',
        alignSelf: 'stretch',
        border: `1px dashed ${
          dropping && !isDisabled ? Theme.colors[colorVariant].color : Theme.colors[dim(colorVariant)].borderColor
        }`,
        borderRadius: Theme.geometry.control.radius,
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        marginLeft: Theme.geometry.control.spacing,
        marginRight: Theme.geometry.control.spacing,
        paddingLeft: Theme.geometry.control.spacing,
        paddingRight: Theme.geometry.control.spacing,
      }}>
      <Text colorVariant={dim(colorVariant)} textVariant='body'>
        ...or drop here...
      </Text>
    </div>
  )
}

type PreviewsProps = {
  colorVariant: 'light' | 'dark'
  isDisabled?: boolean
  managedFiles: ManagedFile[]
  remove: (internalId: number) => void
}

function Previews({ colorVariant, isDisabled, managedFiles, remove }: PreviewsProps) {
  return (
    <Fragment>
      {managedFiles
        .filter((mf) => !mf.error)
        .map((mf) => (
          <Preview
            colorVariant={colorVariant}
            isDisabled={isDisabled}
            key={mf.internalId}
            managedFile={mf}
            remove={remove}
          />
        ))}
      {managedFiles
        .filter((mf) => mf.error)
        .map((mf) => (
          <Preview
            colorVariant={colorVariant}
            isDisabled={isDisabled}
            key={mf.internalId}
            managedFile={mf}
            remove={remove}
          />
        ))}
    </Fragment>
  )
}

type PreviewProps = {
  colorVariant: 'light' | 'dark'
  isDisabled?: boolean
  managedFile: ManagedFile
  remove: (internalId: number) => void
}

function Preview({ colorVariant, isDisabled, managedFile, remove }: PreviewProps) {
  const viewStyle = useMemo(
    () => [
      Styles.uploadAttachmentView,
      BackgroundStyles[colorVariant === 'light' ? 'light' : 'darkDim'],
      managedFile.error ? Styles.previewViewError : BorderStyles[colorVariant],
    ],
    [colorVariant, managedFile.error],
  )

  const textColorVariant = managedFile.loading ? dim(colorVariant) : colorVariant
  const handlePress = useCallback(() => remove(managedFile.internalId), [managedFile.internalId, remove])

  return (
    <View style={viewStyle}>
      <Text colorVariant={textColorVariant} numberOfLines={1} style={CommonStyles.flexOne} textVariant='body'>
        {`${managedFile.file.name} ${managedFile.error ? ` - ${managedFile.error}` : ''}`}
      </Text>
      {managedFile.loading && <RefreshIcon color={Theme.colors[dim(colorVariant)].color} height={20} width={20} />}
      {!managedFile.loading && (
        <IconButton colorVariant={colorVariant} Icon={TrashIcon} isDisabled={isDisabled} onPress={handlePress} />
      )}
    </View>
  )
}
