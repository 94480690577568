import { Form, ModalContent, ModalFooter, ModalHeader, SubmitButton, TextInputControl } from '@src/components/atoms'
import {
  Organization_Roles_Enum,
  Organizations,
  OrgConfigDocument,
  OrgOrganizationUpdateMutationVariables,
  useOrgOrganizationUpdateMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { useAuthenticatedAuth } from '@src/logic/auth'

import { useOrg } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'
import { ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { PencilIcon } from 'react-native-heroicons/solid'

export const ORGANIZATION_PROFILE_EDIT_ACTION_ID = 'organizationProfileEdit'
export const ORGANIZATION_PROFILE_EDIT_ACTION_LABEL = 'Edit Organization Profile'

export type OrganizationProfileEditActionValues = Pick<OrgOrganizationUpdateMutationVariables, 'name'>

export type OrganizationProfileEditActionParams = {
  org: Pick<Organizations, 'id' | 'name'>
}

export type OrganizationProfileEditActionProps = OrganizationProfileEditActionParams & {
  onSubmit: (values: OrganizationProfileEditActionValues) => Promise<void>
  org: Pick<Organizations, 'id' | 'name'>
}

type FormType = {
  name: Organizations['name']
}

export function OrganizationProfileEditAction({ onSubmit, org }: OrganizationProfileEditActionProps) {
  const initialValues = useMemo<FormType>(
    () => ({
      name: org.name,
    }),
    [org],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={onSubmit}>
      <ModalHeader title={ORGANIZATION_PROFILE_EDIT_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl isRequired={true} label='Organization Name' name='name' />
      </ModalContent>
      <ModalFooter>
        <SubmitButton style={CommonStyles.marginLeftCard}>Save</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterOrganizationProfileEditAction() {
  const [doOrgOrganizationUpdate] = useOrgOrganizationUpdateMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org } = useOrg()
  const { refresh } = useAuthenticatedAuth()

  const handleSubmit = useCallback(
    async (values: OrganizationProfileEditActionValues) => {
      await doOrgOrganizationUpdate({
        variables: {
          ...values,
          orgId: org.id,
        },
        refetchQueries: [OrgConfigDocument],
      })
      await refresh()
      dismissAllActions()
    },
    [doOrgOrganizationUpdate, dismissAllActions, org.id, refresh],
  )

  useRegisterAction<OrganizationProfileEditActionParams>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: ORGANIZATION_PROFILE_EDIT_ACTION_ID,
      menuItem: {
        Icon: PencilIcon,
        label: ORGANIZATION_PROFILE_EDIT_ACTION_LABEL,
        params: { org },
      },
      modal: ({ org }) => <OrganizationProfileEditAction org={org} onSubmit={handleSubmit} />,
    }),
    [handleSubmit, org],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, (params: OrganizationProfileEditActionParams) =>
        dispatchAction({
          id: ORGANIZATION_PROFILE_EDIT_ACTION_ID,
          params,
        }),
      ),
    [dispatchAction, org.role],
  )
}
