import { BackgroundStyles, Theme } from '@src/logic/design'
import { getMouseHandlers, ifTrue } from '@src/logic/utils'
import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react'
import { Pressable, StyleSheet, ViewStyle } from 'react-native'

export type PaneButtonProps = {
  children?: ReactNode
  isActive: boolean
  onPress: () => void
  style?: ViewStyle
}

const Styles = StyleSheet.create({
  pressable: {
    height: 48,
    justifyContent: 'center',
    paddingLeft: Theme.geometry.structure.spacing,
    paddingRight: Theme.geometry.structure.spacing,
  },
})

export const PaneButton = memo(PaneButtonComponent)

function PaneButtonComponent({ children, isActive, onPress, style }: PaneButtonProps) {
  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  const pressableStyle = useMemo(
    () => [
      Styles.pressable,
      BackgroundStyles.darkDim,
      ifTrue(isActive || isHover || isPressed, BackgroundStyles.dark),
      style,
    ],
    [isActive, isHover, isPressed, style],
  )
  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handlePressIn = useCallback(() => setIsPressed(true), [setIsPressed])
  const handlePressOut = useCallback(() => setIsPressed(false), [setIsPressed])

  return (
    <Pressable
      {...handleMouse}
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      style={pressableStyle}>
      {children}
    </Pressable>
  )
}
