import { CommonStyles, Theme } from '@src/logic/design'
import { getMouseHandlers, ifTrue } from '@src/logic/utils'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { Pressable, StyleProp, StyleSheet, Text, ViewStyle } from 'react-native'

export type ButtonProps = {
  children: string
  colorVariant: 'light' | 'dark' | 'primary'
  isDisabled?: boolean
  onPress: () => void
  style?: StyleProp<ViewStyle>
}

export const Button = memo(ButtonComponent)

const Styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    borderRadius: Theme.geometry.control.radius,
    borderWidth: 1,
    height: Theme.geometry.control.height,
    paddingLeft: Theme.geometry.control.spacing,
    paddingRight: Theme.geometry.control.spacing,
  },
  text: {
    ...Theme.text.cta,
    lineHeight: (Theme.geometry.control.height ?? 0) - 2,
  },
})

const VariantStyles = {
  light: StyleSheet.create({
    pressable: {
      backgroundColor: Theme.colors.light.backgroundColor,
      borderColor: Theme.colors.light.borderColor,
    },
    pressableDisabled: {
      backgroundColor: Theme.colors.lightDim.backgroundColor,
      borderColor: Theme.colors.lightDim.borderColor,
    },
    pressablePressed: {
      backgroundColor: Theme.colors.lightDim.backgroundColor,
      borderColor: Theme.colors.light.color,
    },
    pressableHover: {
      backgroundColor: Theme.colors.light.backgroundColor,
      borderColor: Theme.colors.light.color,
    },
    text: {
      color: Theme.colors.light.color,
    },
    textDisabled: {
      color: Theme.colors.light.borderColor,
    },
  }),
  dark: StyleSheet.create({
    pressable: {
      backgroundColor: Theme.colors.darkDim.backgroundColor,
      borderColor: Theme.colors.dark.borderColor,
    },
    pressableDisabled: {
      backgroundColor: Theme.colors.darkDim.backgroundColor,
      borderColor: Theme.colors.darkDim.borderColor,
    },
    pressablePressed: {
      backgroundColor: Theme.colors.dark.backgroundColor,
      borderColor: Theme.colors.dark.color,
    },
    pressableHover: {
      backgroundColor: Theme.colors.darkDim.borderColor,
      borderColor: Theme.colors.dark.color,
    },
    text: {
      color: Theme.colors.dark.color,
    },
    textDisabled: {
      color: Theme.colors.dark.borderColor,
    },
  }),
  primary: StyleSheet.create({
    pressable: {
      backgroundColor: Theme.colors.primary.color,
      borderColor: Theme.colors.primary.color,
    },
    pressableDisabled: {
      backgroundColor: Theme.colors.primary.color,
      borderColor: Theme.colors.primary.color,
    },
    pressablePressed: {
      backgroundColor: Theme.colors.primary.darkerColor,
      borderColor: Theme.colors.primary.darkerColor,
    },
    pressableHover: {
      backgroundColor: Theme.colors.primary.darkerColor,
      borderColor: Theme.colors.primary.darkerColor,
    },
    text: {
      color: Theme.colors.dark.color,
    },
    textDisabled: {
      color: Theme.colors.dark.color,
      opacity: 0.4,
    },
  }),
}

function ButtonComponent({ children, colorVariant, isDisabled = false, onPress, style }: ButtonProps) {
  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  const pressableStyle = useMemo(
    () => [
      Styles.pressable,
      ifTrue(isDisabled, CommonStyles.cursorNotAllowed),
      ifTrue(isDisabled, VariantStyles[colorVariant].pressableDisabled),
      ifTrue(!isDisabled && isPressed, VariantStyles[colorVariant].pressablePressed),
      ifTrue(!isDisabled && !isPressed && isHover, VariantStyles[colorVariant].pressableHover),
      ifTrue(!isDisabled && !isPressed && !isHover, VariantStyles[colorVariant].pressable),
      style,
    ],
    [colorVariant, isDisabled, isHover, isPressed, style],
  )

  const textStyle = useMemo(
    () => [
      Styles.text,
      ifTrue(isDisabled, VariantStyles[colorVariant].textDisabled),
      ifTrue(!isDisabled, VariantStyles[colorVariant].text),
    ],
    [colorVariant, isDisabled],
  )

  const handleMouse = useMemo(
    () =>
      getMouseHandlers(
        () => setIsHover(true),
        () => setIsHover(false),
      ),
    [setIsHover],
  )

  const handlePressIn = useCallback(() => setIsPressed(true), [setIsPressed])
  const handlePressOut = useCallback(() => setIsPressed(false), [setIsPressed])

  return (
    <Pressable
      {...handleMouse}
      disabled={isDisabled}
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      style={pressableStyle}>
      <Text style={textStyle}>{children}</Text>
    </Pressable>
  )
}
