import { Button, Text } from '@src/components/atoms'
import { FullScreen } from '@src/components/atoms'
import { noop } from 'lodash'
import React from 'react'

export function NotFound() {
  return (
    <FullScreen>
      <Text colorVariant='light' textVariant='title'>
        Sorry, this page could not be found.
      </Text>
      <Button colorVariant='light' onPress={noop}>
        Go to Home
      </Button>
    </FullScreen>
  )
}
