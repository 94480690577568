import {
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectControl,
  SubmitButton,
  TextInputControl,
} from '@src/components/atoms'
import {
  Flow_Statuses_Enum,
  Organization_Roles_Enum,
  OrgConfigDocument,
  OrgConfigQuery,
  OrgFlowConfigurationInsertMutationVariables,
  useOrgFlowConfigurationInsertMutation,
} from '@src/gen/graphql'
import { useActions } from '@src/logic/actions'
import { getUserFullName, initSelect, SelectOptionType } from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers'
import { ifTrue, stringEmptyToUndefined } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { PlusSmIcon } from 'react-native-heroicons/solid'

export const FLOW_CONFIGURATION_CREATE_ACTION_ID = 'flowConfigurationCreate'
export const FLOW_CONFIGURATION_CREATE_ACTION_LABEL = 'Create Flow Template'

export type FlowConfigurationCreateActionValues = Omit<OrgFlowConfigurationInsertMutationVariables, 'orgId'>

export type FlowConfigurationCreateActionProps = {
  onSubmit: (values: FlowConfigurationCreateActionValues) => Promise<void>
  orgConfig: OrgConfigQuery
}

type FormType = {
  flowTypeStatusId?: SelectOptionType<string>
  name: string
  ownerId: SelectOptionType<string>
  welcomeChatMessage?: string
}

export function FlowConfigurationCreateAction({ onSubmit, orgConfig }: FlowConfigurationCreateActionProps) {
  const { defaultOption: defaultFlowTypeStatusOption, optionsOrGroups: flowTypeStatusOptionsOrGroups } = useMemo(
    () =>
      initSelect<string>(JSON.stringify, [
        {
          label: 'New',
          options: [
            {
              isDefault: true,
              label: 'No Type',
              selectedLabel: 'New / No Type',
              value: '',
            },
          ],
        },
        {
          label: 'In Flow',
          options: orgConfig.flow_types.map((ft) => ({
            isDefault: false,
            label: ft.name,
            selectedLabel: `In Flow / ${ft.name} / ${ft.flow_type_statuses[0].name}`,
            value: ft.flow_type_statuses[0].id,
          })),
        },
      ]),
    [orgConfig.flow_types],
  )

  const { defaultOption: defaultOwnerOption, optionsOrGroups: ownerOptionGroups } = useMemo(
    () =>
      initSelect<string>(
        (v) => v,
        orgConfig.users_organizations
          .filter((uo) => uo.role === Organization_Roles_Enum.Admin || uo.role === Organization_Roles_Enum.Editor)
          .map((uo) => ({ ...uo, fullName: getUserFullName(uo.user) }))
          .sort((uo1, uo2) => uo1.fullName.localeCompare(uo2.fullName))
          .map((uo, i) => ({
            isDefault: i == 0,
            label: uo.fullName,
            value: uo.user.id,
          })),
      ),
    [],
  )

  const initialValues = useMemo<FormType>(
    () => ({
      flowTypeStatusId: defaultFlowTypeStatusOption,
      name: '',
      ownerId: defaultOwnerOption,
      welcomeChatMessage: '',
    }),
    [defaultFlowTypeStatusOption, defaultOwnerOption],
  )

  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        flowTypeStatusId: stringEmptyToUndefined(values.flowTypeStatusId?.value),
        name: values.name,
        ownerId: values.ownerId.value,
        status: stringEmptyToUndefined(values.flowTypeStatusId?.value)
          ? Flow_Statuses_Enum.Flow
          : Flow_Statuses_Enum.New,
        welcomeChatMessage: values.welcomeChatMessage,
      })
    },
    [onSubmit],
  )

  return (
    <Form<FormType> initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalHeader title={FLOW_CONFIGURATION_CREATE_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl
          isRequired={true}
          label='Template Name'
          name='name'
          placeholder='e.g. Inbound from Company Website...'
        />
        <SelectControl
          isRequired={true}
          label='Initial Flow Status / Type / Stage'
          name='flowTypeStatusId'
          optionsOrGroups={flowTypeStatusOptionsOrGroups}
        />
        <SelectControl isRequired={true} label='Initial Owner' name='ownerId' optionsOrGroups={ownerOptionGroups} />
        <TextInputControl
          isRequired={false}
          label='Welcome Chat Message'
          name='welcomeChatMessage'
          placeholder='e.g. Hello!'
        />
      </ModalContent>
      <ModalFooter>
        <SubmitButton>Create</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterFlowConfigurationCreateAction() {
  const [doFlowConfigurationInsert] = useOrgFlowConfigurationInsertMutation()
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org, orgConfig } = useOrg()

  const handleSubmit = useCallback(
    async (values: FlowConfigurationCreateActionValues) => {
      await doFlowConfigurationInsert({
        variables: {
          ...values,
          orgId: org.id,
        },
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
    },
    [doFlowConfigurationInsert, dismissAllActions, org.id],
  )

  useRegisterAction<undefined>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: FLOW_CONFIGURATION_CREATE_ACTION_ID,
      menuItem: {
        Icon: PlusSmIcon,
        label: FLOW_CONFIGURATION_CREATE_ACTION_LABEL,
        params: undefined,
      },
      modal: () => <FlowConfigurationCreateAction orgConfig={orgConfig} onSubmit={handleSubmit} />,
    }),
    [handleSubmit, orgConfig, org.role],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, () =>
        dispatchAction<undefined>({
          id: FLOW_CONFIGURATION_CREATE_ACTION_ID,
          params: undefined,
        }),
      ),
    [dispatchAction, org.role],
  )
}
