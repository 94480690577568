import React, { useCallback, useMemo } from 'react'
import { ifTrue } from '@src/logic/utils'
import {
  Form,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SubmitButton,
  TextInputControl,
  SelectControl,
} from '@src/components/atoms'
import {
  Organization_Roles_Enum,
  OrgConfigDocument,
  OrgSendOrganizationInviteMutationVariables,
  useOrgSendOrganizationInviteMutation,
  OrgConfigQuery,
} from '@src/gen/graphql'
import { getOrgRolesOptionsOrGroups } from '@src/logic/data/manipulation/org'
import { useActions } from '@src/logic/actions'
import { PlusIcon } from 'react-native-heroicons/solid'
import { initSelect, SelectOptionType } from '@src/logic/data/manipulation'
import { useOrg } from '@src/logic/data/providers'
import { CommonStyles } from '@src/logic/design'

import { useAuthenticatedAuth, OrganizationType } from '@src/logic/auth'

export const ORG_CONFIGURATION_INVITE_MEMBER_ACTION_ID = 'orgConfigurationInviteMember'
export const ORG_CONFIGURATION_INVITE_MEMBER_ACTION_LABEL = 'Invite a new member'

export type OrgConfigurationInviteMemberValues = OrgSendOrganizationInviteMutationVariables

export type OrgConfigurationInviteMemberParams = {
  org: OrganizationType
}

export type OrgConfigurationInviteMemberProps = OrgConfigurationInviteMemberParams & {
  onSubmit: (values: OrgConfigurationInviteMemberValues) => Promise<void>
  orgConfig: OrgConfigQuery
  org: OrganizationType
}

type FormType = {
  firstName: string
  lastName: string
  email: string
  initialRole: SelectOptionType<Organization_Roles_Enum>
}

export function OrgConfigurationInviteMember({ onSubmit, org, orgConfig }: OrgConfigurationInviteMemberProps) {
  const handleSubmit = useCallback(
    async (values: FormType) => {
      await onSubmit({
        ...values,
        orgId: org.id,
        initialRole: values.initialRole.value,
      })
    },
    [onSubmit],
  )

  const { defaultOption: defaultOption, optionsOrGroups: orgRoles } = useMemo(
    () => initSelect((value) => value, getOrgRolesOptionsOrGroups()),
    [],
  )

  const initialValues = useMemo<FormType>(
    () => ({
      firstName: '',
      lastName: '',
      email: '',
      initialRole: defaultOption,
    }),
    [defaultOption],
  )

  return (
    <Form<FormType> onSubmit={handleSubmit} initialValues={initialValues}>
      <ModalHeader title={ORG_CONFIGURATION_INVITE_MEMBER_ACTION_LABEL} />
      <ModalContent>
        <TextInputControl isRequired={true} label='First' name='firstName' placeholder='Enter first name...' />
        <TextInputControl isRequired={true} label='Last' name='lastName' placeholder='Enter last name...' />
        <TextInputControl isRequired={true} label='Email' name='email' placeholder='Enter email address...' />
        <SelectControl isRequired={true} label='Role' name='initialRole' optionsOrGroups={orgRoles} />
      </ModalContent>
      <ModalFooter>
        <SubmitButton style={CommonStyles.marginLeftCard}>Save</SubmitButton>
      </ModalFooter>
    </Form>
  )
}

export function useRegisterOrgConfigurationInviteMemberAction() {
  const { dispatchAction, dismissAllActions, useRegisterAction } = useActions()
  const { org, orgConfig } = useOrg()
  const { refresh } = useAuthenticatedAuth()
  const [doOrgInviteMutation] = useOrgSendOrganizationInviteMutation()
  const handleSubmit = useCallback(
    async (values: OrgConfigurationInviteMemberValues) => {
      await doOrgInviteMutation({
        variables: {
          ...values,
          orgId: org?.id,
        },
        refetchQueries: [OrgConfigDocument],
      })
      dismissAllActions()
      refresh()
    },
    [dismissAllActions],
  )

  useRegisterAction<OrgConfigurationInviteMemberParams>(
    ifTrue(org.role === Organization_Roles_Enum.Admin, {
      id: ORG_CONFIGURATION_INVITE_MEMBER_ACTION_ID,
      menuItem: {
        Icon: PlusIcon,
        label: ORG_CONFIGURATION_INVITE_MEMBER_ACTION_LABEL,
        params: { org },
      },
      modal: ({ org }) => <OrgConfigurationInviteMember orgConfig={orgConfig} onSubmit={handleSubmit} org={org} />,
    }),
    [handleSubmit, org.role],
  )

  return useMemo(
    () =>
      ifTrue(org.role === Organization_Roles_Enum.Admin, (params: OrgConfigurationInviteMemberParams) =>
        dispatchAction({
          id: ORG_CONFIGURATION_INVITE_MEMBER_ACTION_ID,
          params,
        }),
      ),
    [dispatchAction, org.role],
  )
}
