import { CommonStyles, Theme } from '@src/logic/design'
import { ifTrue } from '@src/logic/utils'
import React, { useCallback, useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import BouncyCheckbox from 'react-native-bouncy-checkbox'
import { CheckIcon } from 'react-native-heroicons/solid'

export type CheckboxProps<T> = {
  isChecked: boolean
  isDisabled?: boolean
  onChange: (value: T, isChecked: boolean) => void
  style?: StyleProp<ViewStyle>
  value: T
}

const Styles = {
  iconChecked: {
    backgroundColor: Theme.colors.primary.color,
    borderColor: Theme.colors.primary.color,
    color: Theme.colors.light.backgroundColor,
  },
  iconUnchecked: {
    backgroundColor: Theme.colors.light.backgroundColor,
    borderColor: Theme.colors.light.borderColor,
    color: Theme.colors.light.backgroundColor,
  },
  iconCheckedDisabled: {
    backgroundColor: Theme.colors.lightDim.backgroundColor,
    borderColor: Theme.colors.lightDim.borderColor,
    color: Theme.colors.lightDim.borderColor,
  },
  iconUncheckedDisabled: {
    backgroundColor: Theme.colors.lightDim.backgroundColor,
    borderColor: Theme.colors.lightDim.borderColor,
    color: Theme.colors.lightDim.backgroundColor,
  },
}

export function Checkbox<T>({ isChecked, isDisabled = false, onChange, style, value }: CheckboxProps<T>) {
  const iconStyle = useMemo(
    () => [
      ifTrue(!isDisabled && isChecked, Styles.iconChecked),
      ifTrue(!isDisabled && !isChecked, Styles.iconUnchecked),
      ifTrue(isDisabled && isChecked, Styles.iconCheckedDisabled),
      ifTrue(isDisabled && !isChecked, Styles.iconUncheckedDisabled),
      ifTrue(isDisabled, CommonStyles.cursorNotAllowed),
    ],
    [isChecked, isDisabled],
  )

  const handlePress = useCallback(() => onChange(value, !isChecked), [isChecked, onChange, value])

  return (
    <BouncyCheckbox
      disableBuiltInState={true}
      disableText={true}
      disabled={isDisabled}
      iconComponent={<CheckIcon size={16} />}
      iconStyle={iconStyle}
      isChecked={isChecked}
      onPress={handlePress}
      size={24}
      style={style}
    />
  )
}
